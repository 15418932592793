import { ClientNPCVisit, NPC } from '@vmk-legacy/server-protos'
import type { Sprite } from 'pixi.js'
import { Client } from '../../../Client.js'
import { Helpers } from '../../../util/Helpers.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export class TreehouseHandler extends WalkableRoomExtension {
    override async init(): Promise<this> {
        await super.init()

        console.log('TreehouseHandler.init')

        const npcArrangements = [
            'pirate_climb_rope',
            'pirate_climb_mast',
            'pirate_look',
            'pirate_steer',
            'pirate_talk',
            'pirate_weave'
        ]

        this.loadArrangements(npcArrangements)

        const pirate = this.room.sprites.getChildByName('pirate') as Sprite
        if (pirate) {
            const npc = this.room.addNPC('npc-blackheart', 0, 0, {}, 'Captain Blackheart', true)
            npc.getVisual().position.set(pirate.x + pirate.width / 2, pirate.y + pirate.height / 2)
            this.room.addEntity(npc)
        }

        this.loop()

        return this
    }

    private async loop() {
        if (!this.running) {
            console.log('not running yet')
            return
        }
        console.log('playing arrangement')
        this.playArrangement('pirate_steer')?.removeTapListeners().addTapListener(this.onTap)
        await Helpers.delay(5000)
        this.playArrangement('pirate_climb_rope')?.removeTapListeners().addTapListener(this.onTap)
        await Helpers.delay(5000)
        this.playArrangement('pirate_look')?.removeTapListeners().addTapListener(this.onTap)
        await Helpers.delay(5000)
        this.playArrangement('pirate_weave')?.removeTapListeners().addTapListener(this.onTap)
        await Helpers.delay(1200)
        this.playArrangement('pirate_weave')?.removeTapListeners().addTapListener(this.onTap)
        await Helpers.delay(2000)
        this.playArrangement('pirate_look')?.removeTapListeners().addTapListener(this.onTap)
        await Helpers.delay(5000)
        this.playArrangement('pirate_climb_mast')?.removeTapListeners().addTapListener(this.onTap)
        await Helpers.delay(5000)
        this.loop()
    }

    onTap = () => {
        Client.shared.serverBroker.send(
            new ClientNPCVisit({
                npc: NPC.Blackheart
            })
        )
    }
}
