export class Fonts {
    static readonly Foxley_16 = {
        fontFamily: 'vmkl-foxley-white',
        fontSize: 16,
        lineHeight: 16
    }
    static readonly Foxley_12 = {
        fontFamily: 'vmkl-foxley-white',
        fontSize: 12,
        lineHeight: 12
    }
    static readonly FoxleyBold_16 = {
        fontFamily: 'vmkl-foxley-bold-white',
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: 16
    }
    static readonly Folio_12 = {
        fontFamily: 'vmkl-folio-white',
        fontWeight: 'bold',
        fontSize: 12,
        lineHeight: 12
    }
}
