import type { EMagicEffectTypes } from '@vmk-legacy/common-ts'
import { MagicEffect } from '@vmk-legacy/render-utils'
import { Client } from '../../Client.js'
import type { EntityRoomIdentifier } from '../../enums.js'
import { MagicDefinitions } from '../../room/entities/fx/MagicDefinitions.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class EntityMagicModule extends ServerEvent {
    static readonly type = MessageType.ENTITY_MAGIC

    async handle(data: {
        entityId: EntityRoomIdentifier
        magic: EMagicEffectTypes
        starting: boolean
        ending: boolean
        param?: string | number
    }) {
        if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
            const entity = Client.shared.roomViewer.getEntityByRef(data.entityId)
            if (!entity) {
                return
            }

            if (data.ending) {
                entity.visual.getMagic()?.setAnim('outro')
            } else {
                const magicDef = MagicDefinitions.defs[data.magic]
                if (!magicDef) {
                    return
                }
                entity.visual.setMagic(
                    new MagicEffect(
                        Client.shared.essentialAssets,
                        Client.shared.figuresMgr,
                        entity.visual,
                        magicDef,
                        data.starting,
                        data.param
                    )
                )
            }
        }
    }
}
