import { Pooler } from '@vmk-legacy/render-utils'
import { BitmapText } from 'pixi.js'
import { Client } from '../../../../Client.js'
import { EWindow } from '../../../../enums'
import { Fonts } from '../../../../Fonts'
import { StringUtil } from '../../../../util/StringUtil'
import { BitmapTextButton } from '../../../buttons/BitmapTextButton'
import { Field } from '../../../fields/Field'
import { PixiTextType, TextColor, VMKLFont, VMKLText } from '../../../VMKLText'
import { UIWindowView } from '../../UIWindowView'
import type { InventoryWindow } from '../InventoryWindow'

export class CreditView extends UIWindowView<InventoryWindow> {
    creditCountTF: BitmapText
    youHaveTF: BitmapText
    creditTF: BitmapText

    constructor() {
        super()

        const creditBox = Pooler.newContainer()

        const bg = new Field('b', 234)
        creditBox.addChild(bg)

        this.creditCountTF = new BitmapText('?', {
            ...Fonts.FoxleyBold_16
        })
        this.creditCountTF.y = 5
        creditBox.addChild(this.creditCountTF)

        this.youHaveTF = new BitmapText('You have', {
            ...Fonts.Foxley_16
        })
        this.youHaveTF.y = 5
        creditBox.addChild(this.youHaveTF)

        this.creditTF = new BitmapText('Credit', {
            ...Fonts.Foxley_16
        })
        this.creditTF.y = 5
        creditBox.addChild(this.creditTF)

        this.format()

        creditBox.x = Math.round((374 - creditBox.width) / 2)

        this.addChild(creditBox)

        const howToEarn = new VMKLText({
            type: PixiTextType.text,
            text: 'You earn more credit by playing the games and exploring the kingdom.\n\nThe more credit you earn, the more you get to spend on furnishings, clothing, pins, posters and extra rooms!\n\nMake sure you visit the VMK shops to collect the Shopping Catalog pages.',
            font: VMKLFont.foxley,
            size: 16,
            color: TextColor.white,
            wordWrapWidth: 345,
            lineHeight: 16,
            whiteSpace: 'pre'
        })
        howToEarn.x = 5
        howToEarn.y = 30

        this.addChild(howToEarn)

        const goShopping = new BitmapTextButton(110, 'GO SHOPPING', 'a')
        goShopping.x = 128
        goShopping.y = 170
        goShopping.addEventListener('pointerup', () => {
            Client.shared.userInterface.getWin(EWindow.Shopping, true)
        })
        this.addChild(goShopping)

        this.update()
    }

    update() {
        const credits = Client.shared.selfRecord.getCredits() || 0
        if (credits == null) {
            this.creditCountTF.text = '?'
        } else {
            this.creditCountTF.text = StringUtil.addNumberCommas(credits)
        }
        this.format()
    }

    format() {
        this.creditCountTF.x = Math.round((246 - this.creditCountTF.width) / 2) + 6
        this.youHaveTF.x = this.creditCountTF.x - this.youHaveTF.width - 5
        this.creditTF.x = this.creditCountTF.x + this.creditCountTF.width + 4
    }
}
