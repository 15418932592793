import type { ItemDefUid } from '@vmk-legacy/common-ts'
import { EClothingPartSet, EItemType } from '@vmk-legacy/common-ts'
import type { AssetProvider, AvatarOutfit } from '@vmk-legacy/render-utils'
import { Pooler } from '@vmk-legacy/render-utils'
import type { Container, DestroyOptions } from 'pixi.js'
import { getVar } from '../../../../assets/ExternalConfigManager.js'
import { Client } from '../../../../Client.js'
import { AvatarStack } from '../../../../data/AvatarStack.js'
import type { ItemStack } from '../../../../data/ItemStack.js'
import { RegistrationStep1View } from '../../../../registration/RegistrationStep1View.js'
import type { AvatarVisual } from '../../../../room/entities/AvatarVisual.js'
import { ThumbPickerColor } from '../../../containers/ThumbPickerColor.js'
import { ThumbPresenter } from '../../../containers/ThumbPresenter.js'
import { PagedThumbnailBox } from '../../../thumbs/PagedThumbnailBox.js'
import { UIWindowView } from '../../UIWindowView.js'
import { CharacterWindow } from '../CharacterWindow.js'

export class CharacterAvatarView extends UIWindowView<CharacterWindow> {
    padding = 10
    avatar: AvatarVisual
    character: CharacterWindow

    private hairOptions: PagedThumbnailBox
    private hairColorOptions: PagedThumbnailBox
    private headOptions: PagedThumbnailBox
    private skinColorOptions: PagedThumbnailBox

    private selectedHair!: ThumbPresenter
    private selectedHairColor!: ThumbPresenter
    private selectedHead!: ThumbPresenter
    private selectedSkinColor!: ThumbPresenter

    hairIds: string[] = []
    private thumbPaddding: number = 250

    constructor(
        readonly provider: AssetProvider,
        avatar: AvatarVisual
    ) {
        super()

        this.avatar = avatar

        this.character = new CharacterWindow()

        this.hairOptions = new PagedThumbnailBox({
            rows: 1,
            cols: 5,
            items: [],
            spacing: 3,
            onItemTap: (item: AvatarStack) => {
                this.hairOptions.setSelected(item)
                const outfit = this.avatar.getOutfit()
                outfit.hairId = item.defUid
                this.selectedHair.presentThumb(item.defIndex)
                this.avatar.setUpdatedOutfit(outfit)
                this.character.setStoredAvatarEntries(item.defType, item.defUid)
            },
            pageTurnSpacing: 225,
            pageTurnFormat: PagedThumbnailBox.FORMAT_BLANK
        })
        this.hairOptions.x = 15
        this.hairOptions.y = 15
        this.addChild(this.hairOptions)

        const hairController = this.hairOptions.getControllerContainer()
        hairController.y = this.hairOptions.y + 15
        this.addChild(hairController)

        this.hairColorOptions = new PagedThumbnailBox({
            rows: 1,
            cols: 5,
            items: [],
            spacing: 3,
            onItemTap: (item: AvatarStack) => {
                this.hairColorOptions.setSelected(item)
                const outfit = this.avatar.getOutfit()
                outfit.hairTint = item.defUid
                this.selectedHairColor.presentThumb(item.defIndex)
                this.avatar.setUpdatedOutfit(outfit)
                this.character.setStoredAvatarEntries(item.defType, item.defUid)
            },
            pageTurnSpacing: 225,
            pageTurnFormat: PagedThumbnailBox.FORMAT_BLANK
        })
        this.hairColorOptions.x = 15
        this.hairColorOptions.y = this.hairOptions.y + 55
        this.addChild(this.hairColorOptions)

        const hairColorController = this.hairColorOptions.getControllerContainer()
        hairColorController.y = this.hairColorOptions.y + 15
        this.addChild(hairColorController)

        this.headOptions = new PagedThumbnailBox({
            rows: 1,
            cols: 5,
            items: [],
            spacing: 3,
            onItemTap: (item: AvatarStack) => {
                this.headOptions.setSelected(item)
                const outfit = this.avatar.getOutfit()
                const parsedIds: string[] = item.defUid.split('-')
                outfit.headId = Number.parseInt(parsedIds[0])
                outfit.faceId = Number.parseInt(parsedIds[1])
                outfit.eyeId = Number.parseInt(parsedIds[2])
                this.selectedHead.presentThumb(item.defIndex)
                this.avatar.setUpdatedOutfit(outfit)
                this.character.setStoredAvatarEntries(item.defType, item.defUid)
            },
            pageTurnSpacing: 225,
            pageTurnFormat: PagedThumbnailBox.FORMAT_BLANK
        })
        this.headOptions.x = 15
        this.headOptions.y = 135
        this.addChild(this.headOptions)

        const headController = this.headOptions.getControllerContainer()
        headController.y = this.headOptions.y + 15
        this.addChild(headController)

        this.skinColorOptions = new PagedThumbnailBox({
            rows: 1,
            cols: 5,
            items: [],
            spacing: 3,
            onItemTap: (item: AvatarStack) => {
                this.skinColorOptions.setSelected(item)
                const outfit = this.avatar.getOutfit()
                outfit.skinTint = item.defUid
                this.selectedSkinColor.presentThumb(item.defIndex)
                this.avatar.setUpdatedOutfit(outfit)
                this.character.setStoredAvatarEntries(item.defType, item.defUid)
            },
            pageTurnSpacing: 225,
            pageTurnFormat: PagedThumbnailBox.FORMAT_BLANK
        })
        this.skinColorOptions.x = 15
        this.skinColorOptions.y = this.headOptions.y + 55
        this.addChild(this.skinColorOptions)

        const skinColorController = this.skinColorOptions.getControllerContainer()
        skinColorController.y = this.skinColorOptions.y + 20
        this.addChild(skinColorController)

        this.updateHeadOptions()
        this.updateHairColorOptions()
        this.updateHairOptions()
        this.updateSkinColorOptions()
    }

    apply(outfit: AvatarOutfit) {
        const retrievedHair = this.character.getStoredAvatarEntries('hair')
        const retrievedHairColor = this.character.getStoredAvatarEntries('hair-color')
        const retrievedHead = this.character.getStoredAvatarEntries('head')
        const retrievedSkinColor = this.character.getStoredAvatarEntries('skin-color')

        if (retrievedHair) {
            this.selectedHair.setItem(retrievedHair)
        } else {
            this.selectedHair.setItem(outfit.hairId)
        }

        if (retrievedHairColor) {
            this.selectedHairColor.setItem(retrievedHairColor)
        } else {
            this.selectedHairColor.setItem(outfit.hairTint.toString())
        }

        if (retrievedHead) {
            this.selectedHead.setItem(retrievedHead)
        } else {
            this.selectedHead.setItem(`${outfit.headId}-${outfit.faceId}-${outfit.eyeId}`)
        }

        if (retrievedSkinColor) {
            this.selectedSkinColor.setItem(retrievedSkinColor)
        } else {
            this.selectedSkinColor.setItem(outfit.skinTint.toString())
        }
    }

    setHairIds(ids: ItemDefUid[]) {
        this.hairIds = ids
        this.updateHairOptions()
    }

    updateHairOptions() {
        const hairEntries = this.hairIds.concat(...getVar('global_hair')).map((i: string, index: number) => ({
            id: i,
            type: 'hair',
            index: index
        }))
        this.selectedHair = new ThumbPresenter(this.provider, hairEntries)
        this.selectedHair.x = this.thumbPaddding
        this.selectedHair.y = 20
        this.addChild(this.selectedHair)
        const hairSelection = this.getAvatarObjects(hairEntries)
        this.hairOptions.setItems(...hairSelection)
    }

    updateHairColorOptions() {
        const hairColors = RegistrationStep1View.HAIR_COLOR_IDS.map((i: number, index: number) => ({
            id: i,
            type: 'hair-color',
            index: index
        }))
        const hairColorSprites: Container[] = []
        hairColors.forEach((color) => {
            hairColorSprites.push(new ThumbPickerColor(color.id, 36, 36))
        })
        this.selectedHairColor = new ThumbPresenter(this.provider, hairColors, hairColorSprites)
        this.selectedHairColor.x = this.thumbPaddding
        this.selectedHairColor.y = this.hairOptions.y + 60
        this.addChild(this.selectedHairColor)
        const hairColorSelection = this.getAvatarObjects(hairColors)
        this.hairColorOptions.setItems(...hairColorSelection)
    }

    updateHeadOptions() {
        const headEntries: { id: string; type: string; index: number }[] = []
        let index = 0
        // Combine face, head, and eyes
        const headIds: number[] = getVar('global_heads')
        const faceIds: number[] = getVar('global_faces')
        const eyeIds: number[] = getVar('global_eyes')
        const combos: number[][] = []
        const noggins: Container[] = []
        for (const faceId of faceIds) {
            for (const eyeId of eyeIds) {
                for (const headId of headIds) {
                    headEntries.push({
                        id: `${headId}-${faceId}-${eyeId}`,
                        type: 'head',
                        index: index
                    })
                    index++
                    // render avi head
                    // with headId, faceId, eyeId.. head + face tinted
                    const noggin: Container = Pooler.newContainer()
                    const head = Pooler.newSprite(`std_hd_${headId}_3_0`)
                    const face = Pooler.newSprite(`std_fc_${faceId}_3_0`)
                    const eyes = Pooler.newSprite(`std_ey_${eyeId}_3_0`)
                    head.x = -22
                    face.x = -22
                    eyes.x = -22
                    head.y = -33
                    face.y = -33
                    eyes.y = -33
                    noggin.addChild(head, face, eyes)
                    combos.push([headId, faceId, eyeId])
                    noggin['headId'] = headId
                    noggin['faceId'] = faceId
                    noggin['eyeId'] = eyeId
                    noggins.push(noggin)
                }
            }
        }
        this.selectedHead = new ThumbPresenter(this.provider, headEntries, noggins)
        this.selectedHead.x = this.thumbPaddding
        this.selectedHead.y = 140
        this.addChild(this.selectedHead)
        const headSelection = this.getAvatarObjects(headEntries)
        this.headOptions.setItems(...headSelection)
    }

    updateSkinColorOptions() {
        const skinColors = RegistrationStep1View.SKIN_COLOR_IDS.map((i: number, index: number) => ({
            id: i,
            type: 'skin-color',
            index: index
        }))
        const skinColorSprites: Container[] = []
        skinColors.forEach((color) => {
            skinColorSprites.push(new ThumbPickerColor(color.id, 36, 36))
        })
        this.selectedSkinColor = new ThumbPresenter(this.provider, skinColors, skinColorSprites)
        this.selectedSkinColor.x = this.thumbPaddding
        this.selectedSkinColor.y = this.headOptions.y + 60
        this.addChild(this.selectedSkinColor)
        const skinColorSelection = this.getAvatarObjects(skinColors)
        this.skinColorOptions.setItems(...skinColorSelection)
    }

    getAvatarObjects(objectEntries: { id: string | number; type: string; index: number }[]): AvatarStack[] {
        const items: AvatarStack[] = []
        objectEntries.forEach((item) => {
            const hairItem = new AvatarStack(item.id, item.type, item.index)
            items.push(hairItem)
        })
        return items
    }

    getHairFromInventory(clothingItemPart?: EClothingPartSet): ItemStack[] {
        const items: ItemStack[] = []
        Client.shared.selfRecord.getInventory().forEach((item) => {
            if (item.defType === EItemType.Clothing) {
                if (clothingItemPart) {
                    if (Client.shared.figuresMgr.getClothingItemPart(item.defUid) === clothingItemPart) {
                        items.push(item)
                    }
                } else {
                    items.push(item)
                }
            }
        })
        return items
    }

    update() {
        this.updateHeadOptions()
        this.updateHairColorOptions()
        this.updateHairOptions()
        this.updateSkinColorOptions()

        const hairIds: ItemDefUid[] = []
        this.getHairFromInventory(EClothingPartSet.Hair).forEach((item) => {
            const defUid = item.defUid
            if (!hairIds.includes(defUid)) {
                hairIds.push(defUid)
            }
        })
        this.setHairIds(hairIds)

        this.apply(Client.shared.selfRecord.getOutfit())
    }

    override destroy(_options?: DestroyOptions | boolean): void {
        super.destroy(_options)
    }
}
