import { EPerm, ERoomWhoEnters } from '@vmk-legacy/common-ts'
import { Pooler } from '@vmk-legacy/render-utils'
import type { Container, Sprite } from 'pixi.js'
import { BitmapText, Rectangle } from 'pixi.js'
import { getText } from '../../assets/ExternalConfigManager.js'
import { Client } from '../../Client.js'
import { Constants } from '../../Constants.js'
import { EWindow, UILayer } from '../../enums.js'
import { Fonts } from '../../Fonts.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../buttons/BitmapTextButtonType.js'
import { CheckBox } from '../buttons/CheckBox.js'
import { ImageButton } from '../buttons/ImageButton.js'
import { CheckBoxController } from '../CheckBoxController.js'
import { FieldH } from '../fields/FieldH.js'
import { VMKLText } from '../VMKLText'
import { PopupBox } from './PopupBox.js'
import { RoomEntranceSettings } from './RoomEntranceSettings.js'
import { RoomInfoPopup } from './RoomInfoPopup.js'
import { Separator } from './Separator.js'
import { UIWindow } from './UIWindow.js'

export class RoomInfoBox extends UIWindow {
    readonly kind = EWindow.RoomInfoBox
    override readonly layer = UILayer.GameWindows
    override isDraggable = false
    override exitBtn: ImageButton
    override header: Sprite

    roomDesc = ''
    roomOwner = ''
    roomId = -1

    private roomNameTxt: VMKLText

    private readonly BOX_WIDTH = 320
    private readonly BOX_HEIGHT = 150
    private readonly ROW_1_Y = 7

    private roomNameX: number
    private roomNameWidth: number
    private instanceLabel: VMKLText
    private instanceName: VMKLText
    private roomDescTxt: VMKLText
    private background: PopupBox
    private editDescriptionButton: BitmapTextButton
    private furnishModeCheckbox: CheckBox
    private furnishModeCheckboxController: CheckBoxController
    private clearRoomButton: BitmapTextButton
    private allowInThisRoomValue: VMKLText
    private createTicketsButton: BitmapTextButton
    private changeWhoCanEnterButton: BitmapTextButton

    static currentData?: any
    private guestRoomControls: Container
    private allowInThisRoomLabel: VMKLText

    override async _buildWindow(): Promise<void> {
        this.background = new PopupBox(this.BOX_WIDTH, this.BOX_HEIGHT, false)
        this.addChild(this.background)

        // TOP ROW BUTTONS & TEXT
        const qButton = new ImageButton('button.helpb.active', 'button.helpb.pressed')
        qButton.x = 8
        qButton.y = this.ROW_1_Y
        this.addChild(qButton)

        const xButton = new ImageButton('button.closeb.active', 'button.closeb.pressed')
        xButton.x = this.BOX_WIDTH - 24
        xButton.y = this.ROW_1_Y
        this.addChild(xButton)

        this.exitBtn = xButton
        this.roomNameX = qButton.x + qButton.width + 4
        this.roomNameWidth = xButton.x - qButton.x - 31
        const roomNameField = new FieldH(this.roomNameWidth)
        this.addChild(roomNameField)

        roomNameField.x = this.roomNameX
        roomNameField.y = this.ROW_1_Y

        this.roomNameTxt = new VMKLText({ bold: true })
        this.roomNameTxt.x = this.roomNameX + Math.round((this.roomNameWidth - this.roomNameTxt.width) / 2)
        this.roomNameTxt.y = this.ROW_1_Y + 1
        this.addChild(this.roomNameTxt)

        this.instanceLabel = new VMKLText({ text: 'Instance:', bold: true })
        this.instanceLabel.x = 8
        this.instanceLabel.y = 30
        this.addChild(this.instanceLabel)

        this.instanceName = new VMKLText()
        this.instanceName.x = 8 + this.instanceLabel.width + 3
        this.instanceName.y = 31
        this.addChild(this.instanceName)

        this.roomDescTxt = new VMKLText({ wordWrapWidth: 300 })
        this.roomDescTxt.style.wordWrapWidth = 300
        this.roomDescTxt.x = 8
        this.roomDescTxt.y = 31 + 16
        this.addChild(this.roomDescTxt)

        this.guestRoomControls = this.addChild(Pooler.newContainer())

        const separator1 = new Separator(304)
        separator1.y = 150
        separator1.x = 8
        this.guestRoomControls.addChild(separator1)

        this.editDescriptionButton = new BitmapTextButton(122, 'EDIT DESCRIPTION', BitmapTextButtonType.GREEN)
        this.editDescriptionButton.x = 180
        this.editDescriptionButton.y = 124
        this.guestRoomControls.addChild(this.editDescriptionButton)
        this.editDescriptionButton.addEventListener('pointerup', () => {
            Client.shared.userInterface.register(
                new RoomInfoPopup(this.roomId, this.roomNameTxt.text, this.roomDesc),
                true
            )
        })

        this.furnishModeCheckbox = new CheckBox('furniMode', 'FURNISHING MODE', false, true)
        this.furnishModeCheckbox.setActive(false)
        this.furnishModeCheckbox.x = 8
        this.furnishModeCheckbox.y = 156
        this.guestRoomControls.addChild(this.furnishModeCheckbox)

        this.furnishModeCheckboxController = new CheckBoxController([this.furnishModeCheckbox])

        this.furnishModeCheckboxController.setChangeHandler((status) => {
            Client.shared.roomViewer.furniController.setFurniMode(status.furniMode)
        })

        const separator2 = new Separator(304)
        separator2.x = 8
        separator2.y = 177
        this.guestRoomControls.addChild(separator2)

        this.clearRoomButton = new BitmapTextButton(122, 'CLEAR ROOM', BitmapTextButtonType.RED)
        this.clearRoomButton.x = 180
        this.clearRoomButton.y = 153
        this.clearRoomButton.addEventListener('pointerup', () => {
            Client.shared.helpers
                .confirm({
                    title: 'Clear Room',
                    message:
                        'Are you sure you want to clear all items in the room? The items will be put back in to your inventory.'
                })
                .then((confirmed) => {
                    if (confirmed) {
                        Client.shared.serverBroker.send('clear_furni')
                    }
                })
        })
        this.guestRoomControls.addChild(this.clearRoomButton)

        this.allowInThisRoomLabel = new VMKLText({ text: 'Allow in this room', bold: true })
        this.allowInThisRoomLabel.x = 8
        this.allowInThisRoomLabel.y = 182
        this.guestRoomControls.addChild(this.allowInThisRoomLabel)

        this.allowInThisRoomValue = new VMKLText({ text: 'Everyone', bold: true })
        this.allowInThisRoomValue.x = 8
        this.allowInThisRoomValue.y = 195
        this.guestRoomControls.addChild(this.allowInThisRoomValue)

        this.createTicketsButton = new BitmapTextButton(122, 'CREATE TICKETS', BitmapTextButtonType.GREEN)
        this.createTicketsButton.x = 8
        this.createTicketsButton.y = 213
        this.createTicketsButton.disable()
        this.guestRoomControls.addChild(this.createTicketsButton)

        this.changeWhoCanEnterButton = new BitmapTextButton(
            150,
            getText('whoenters.button'),
            BitmapTextButtonType.GREEN
        )
        this.changeWhoCanEnterButton.y = 213
        this.changeWhoCanEnterButton.x = 150
        this.changeWhoCanEnterButton.addEventListener('pointerup', () => this.showEntranceOptions())
        this.guestRoomControls.addChild(this.changeWhoCanEnterButton)

        this.addChild(this.guestRoomControls)

        Client.shared.userInterface.toolbar?.stopInfoGlow()

        if (RoomInfoBox.currentData) {
            this.onRoomInfoUpdate(RoomInfoBox.currentData)
        }
    }

    async windowWasBuilt(): Promise<any> {
        return Promise.resolve(undefined)
    }

    showEntranceOptions(): void {
        const settingsWin = new RoomEntranceSettings()
        Client.shared.userInterface.register(settingsWin)
    }

    static getWhoEntersIdentifier(whoEnters: ERoomWhoEnters): string {
        return {
            [ERoomWhoEnters.Everyone]: 'everyone',
            [ERoomWhoEnters.FriendsAndTickets]: 'almosteveryone',
            [ERoomWhoEnters.FriendsOnly]: 'friendsonly',
            [ERoomWhoEnters.TicketsOnly]: 'ticketpeople',
            [ERoomWhoEnters.OwnerOnly]: 'owner'
        }[whoEnters]
    }

    onRoomInfoUpdate(payload: {
        roomId: number
        roomName: string
        roomOwner?: string
        roomDesc: string
        instance?: string
        whoEnters?: ERoomWhoEnters
    }): void {
        this.background.changeHeight(this.BOX_HEIGHT)
        this.roomId = payload.roomId
        this.roomOwner = payload.roomOwner
        this.roomDesc = payload.roomDesc

        this.roomNameTxt.text = payload.roomName
        this.roomDescTxt.text = payload.roomDesc

        if ('whoEnters' in payload) {
            if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
                Client.shared.roomViewer.whoEnters = payload.whoEnters
            }

            this.allowInThisRoomValue.text = getText(
                RoomInfoBox.getWhoEntersIdentifier(payload.whoEnters) + '.entrystate'
            )
        }

        if (payload.instance) {
            this.instanceName.text = payload.instance
            this.instanceLabel.text = 'Instance'
            this.guestRoomControls.visible = false
        } else {
            this.instanceName.text = payload.roomOwner
            this.instanceLabel.text = 'Owner'

            this.guestRoomControls.visible = true

            const fullEdit =
                payload.roomOwner === Client.shared.selfRecord.getIgn() ||
                Client.shared.selfRecord.can(EPerm.RoomsGuestFurni)
            if (fullEdit) {
                // staff or own room
                this.createTicketsButton.visible = true
                this.allowInThisRoomValue.visible = true
                this.changeWhoCanEnterButton.visible = true
                this.clearRoomButton.visible = true
                this.editDescriptionButton.visible = true
                this.allowInThisRoomLabel.visible = true
            } else {
                this.createTicketsButton.visible = false
                this.allowInThisRoomValue.visible = false
                this.changeWhoCanEnterButton.visible = false
                this.clearRoomButton.visible = false
                this.editDescriptionButton.visible = false
                this.allowInThisRoomLabel.visible = false
            }

            if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
                this.furnishModeCheckbox.setActive(Client.shared.roomViewer.furniController.isEnabled())
            }
        }

        this.roomNameTxt.x = this.roomNameX + Math.round((this.roomNameWidth - this.roomNameTxt.width) / 2)
        this.y = Constants.SIZE[1] - this.height - 27
        this.background.changeHeight(this.height + 15)
        this.hitArea = new Rectangle(0, 0, this.width, this.height)
    }

    override getWindowWidth(): number {
        return this.BOX_WIDTH
    }

    override getWindowHeight(): number {
        return this.BOX_HEIGHT
    }

    override setVisible(visible: boolean): void {
        this.visible = visible
    }

    override reset(): void {
        // clear fields,
        // set active tab,
        // etc.
    }
}
