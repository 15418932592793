import { EItemType } from '@vmk-legacy/common-ts'
import { Client } from '../../Client.js'
import { ItemStack } from '../../data/ItemStack.js'
import { EWindow } from '../../enums.js'
import type { CharacterWindow } from '../../ui/windows/character/CharacterWindow.js'
import type { InventoryWindow } from '../../ui/windows/inventory/InventoryWindow.js'
import type { TradeWindow } from '../../ui/windows/trade/TradeWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class InvUpdateModule extends ServerEvent {
    static readonly type = MessageType.INV_UPDATE

    async handle(updates: {
        [key: string]: [number | null, EItemType, number]
    }) {
        console.log('inv update', updates)

        const inv = Client.shared.selfRecord.getInventory()

        for (const key in updates) {
            const mainId = updates[key][0]
            const count = updates[key][2]

            if (inv.has(key)) {
                if (count > 0) {
                    const stack = inv.get(key)
                    stack.setMainId(mainId)
                    stack.setCount(count)
                } else {
                    inv.delete(key)
                }
            } else {
                if (count > 0) {
                    const [defUid, teleId, stars, expires] = key.split('.')
                    const type = updates[key][1]
                    inv.set(
                        key,
                        new ItemStack(
                            mainId,
                            defUid,
                            type,
                            count,
                            +stars,
                            +teleId,
                            !!(type === EItemType.Pin && expires > 0),
                            expires > 0 ? Number(expires) : null
                        )
                    )
                }
            }
        }

        const invWin = (await Client.shared.userInterface.getWin(EWindow.Inventory)) as InventoryWindow
        if (invWin) {
            invWin.updateCurrentView()
        }
        const tradeWin = (await Client.shared.userInterface.getWin(EWindow.Trading)) as TradeWindow
        if (tradeWin) {
            tradeWin.reset()
        }
        const charWin = (await Client.shared.userInterface?.getWin(EWindow.Character)) as CharacterWindow
        if (charWin) {
            charWin.inventoryUpdated()
        }
    }
}
