import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import { Container, Graphics } from 'pixi.js'
import type { TextKey } from '../../assets/ExternalConfigManager.js'
import { getText } from '../../assets/ExternalConfigManager.js'
import { UISoundLibrary } from '../UISoundLibrary.js'
import { PixiTextType, TextColor, VMKLFont, VMKLText } from '../VMKLText'
import { BitmapTextButtonType } from './BitmapTextButtonType.js'

export class BitmapTextButton extends Container {
    private totalWidth: number
    private totalHeight: number

    private textfield: VMKLText

    private enabled: boolean

    private activeState: Graphics
    private pressedState: Graphics

    constructor(
        public innerWidth: number,
        private _text: TextKey,
        readonly type: string | BitmapTextButtonType
    ) {
        super()
        this.rerender()
    }

    rerender() {
        const height = 20
        const colors = {
            [BitmapTextButtonType.ORANGE]: [0xff7a13, 0xfce02b],
            [BitmapTextButtonType.PINK]: [0xc0218f, 0xe530da],
            [BitmapTextButtonType.BLUE]: [0x4f00e7, 0xae31eb],
            [BitmapTextButtonType.GREEN]: [0x43d30c, 0x8cfe2b],
            [BitmapTextButtonType.RED]: [0xec201e, 0xff7a13],
            [BitmapTextButtonType.LIME]: [0x84b640, 0xfce02b],
            [BitmapTextButtonType.TEAL]: [0x19bdba, 0x02e8e1],
            [BitmapTextButtonType.GOLD]: [0xb39d1f, 0xe8cc3f],
            [BitmapTextButtonType.LIGHT_BLUE]: [0x3073dd, 0x3699f2]
        }
        const btnWidth = this.innerWidth

        const strokeWidth = 2

        const g = new Graphics()
            .roundRect(0, 0, btnWidth, height, 5)
            .fill(colors[this.type][0])
            .stroke({ color: 0xFFFFFF, width: strokeWidth, alignment: 0 })

        this.activeState?.destroy()
        this.activeState = g

        const g2 = new Graphics()
            .roundRect(0, 0, btnWidth, height, 5)
            .fill(colors[this.type][1])
            .stroke({ color: 0xFFFFFF, width: strokeWidth, alignment: 0 })

        this.pressedState?.destroy()
        this.pressedState = g2

        this.pressedState.cacheAsBitmap = true
        this.activeState.cacheAsBitmap = true

        this.addChild(this.pressedState, this.activeState)

        this.pressedState.visible = false
        this.activeState.eventMode = 'static'
        this.eventMode = 'static'
        this.cursor = 'pointer'

        this.textfield = new VMKLText({
            type: PixiTextType.text,
            text: getText(this._text).toUpperCase(),
            font: VMKLFont.folio,
            size: 14,
            align: 'center',
            color: TextColor.white,
            anchorX: 0.5,
            textBaseline: 'alphabetic'
        })
        this.totalWidth = btnWidth
        this.totalHeight = 19
        this.textfield.position.set(btnWidth / 2, 2)

        this.addChild(this.textfield)

        this.enabled = true

        let tapping = false

        this.addEventListener('pointerdown', () => {
            tapping = true
            this.pressedState.visible = true
            this.activeState.visible = false
        })

        this.addEventListener('pointerout', () => {
            tapping = false
            this.pressedState.visible = false
            this.activeState.visible = true
        })

        this.addEventListener('pointerup', () => {
            if (!tapping) {
                return
            }
            tapping = false
            SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.UIClick)
            this.pressedState.visible = false
            this.activeState.visible = true
        })
    }

    set text(value: string) {
        this.setText(value)
    }

    setText(text: string): void {
        this._text = text

        this.textfield.text = text.toUpperCase()

        this.activeState.cacheAsBitmap = false
        this.pressedState.cacheAsBitmap = false
        this.activeState.cacheAsBitmap = true
        this.pressedState.cacheAsBitmap = true
    }

    disable(): this {
        this.alpha = 0.3
        this.enabled = false
        this.eventMode = 'auto'

        this.activeState.cacheAsBitmap = false
        this.pressedState.cacheAsBitmap = false
        this.pressedState.cacheAsBitmap = true
        this.activeState.cacheAsBitmap = true

        return this
    }

    enable(): this {
        this.alpha = 1
        this.enabled = true
        this.eventMode = 'static'

        this.activeState.cacheAsBitmap = false
        this.pressedState.cacheAsBitmap = false
        this.pressedState.cacheAsBitmap = true
        this.activeState.cacheAsBitmap = true

        return this
    }

    isEnabled(): boolean {
        return this.enabled
    }
}
