import { TintColor } from '@vmk-legacy/common-ts'
import type { AssetProvider } from '@vmk-legacy/render-utils'
import { AvatarOutfit, Pooler } from '@vmk-legacy/render-utils'
import { BitmapText, Container, Graphics, type Sprite } from 'pixi.js'
import { getText, getVar } from '../assets/ExternalConfigManager.js'
import { Client } from '../Client.js'
import { Fonts } from '../Fonts.js'
import { AvatarVisual } from '../room/entities/AvatarVisual.js'
import { BitmapTextButton } from '../ui/buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../ui/buttons/BitmapTextButtonType.js'
import { ImageButton } from '../ui/buttons/ImageButton.js'
import { ThumbPicker } from '../ui/containers/ThumbPicker.js'
import { DOMText } from '../ui/DOMText.js'
import { TextColor } from '../ui/VMKLText.js'
import { ScaleField } from '../ui/fields/ScaleField.js'
import { Helpers } from '../util/Helpers.js'
import type { RegistrationView } from './RegistrationView.js'

export class RegistrationStep1View extends Container {
    private avatar: AvatarVisual
    private nameTf: DOMText
    private currentRoomIndex = -1
    private roomCastIds: number[] = [1, 53, 54, 67]
    private guestRooms: Sprite[] = []
    private roomNameTf: BitmapText
    private nameFields: ScaleField[] = []
    private readonly MAX_GENERATED_NAME_LENGTH: number = 18
    private ign: string
    private nouns: string[]
    private adjectives: string[]

    static HAIR_COLOR_IDS: number[] = [
        7, 8, 9, 10, 11, 12, 19, 20, 21, 22, 24, 25, 26, 29, 31, 32, 37, 38, 52, 53, 66, 78, 99, 150, 193
    ]
    static SKIN_COLOR_IDS: number[] = [
        22, 24, 20, 15, 16, 17, 19, 21, 23, 18, 13, 112, 130, 161, 162, 180, 182, 183, 185
    ]

    private nextBtn: BitmapTextButton
    override parent: RegistrationView

    constructor(
        nouns: string[],
        adjectives: string[],
        readonly provider: AssetProvider
    ) {
        super()

        this.setup(nouns, adjectives)
    }

    setup(nouns: string[], adjectives: string[]): void {
        this.nouns = nouns
        this.adjectives = adjectives

        const background = Pooler.newSprite('reg.step1.bg')
        background.position.set(19, 104)
        this.addChild(background)

        const step = Pooler.newSprite('reg.stepnumbers.bg')
        step.x = 666
        step.y = 26
        this.addChild(step)
        const step1Active = Pooler.newSprite('reg.step1.active')
        step1Active.x = 700
        step1Active.y = 25
        this.addChild(step1Active)
        const step2Passive = Pooler.newSprite('reg.step2.passive')
        step2Passive.x = 725
        step2Passive.y = 25
        this.addChild(step2Passive)
        const step3Passive = Pooler.newSprite('reg.step3.passive')
        step3Passive.x = 750
        step3Passive.y = 25
        this.addChild(step3Passive)

        const typeName = new BitmapText('Type a name', Fonts.FoxleyBold_16)
        typeName.tint = 0x000000
        typeName.x = 50
        typeName.y = 226
        this.addChild(typeName)

        const chooseName = new BitmapText('Or choose below', Fonts.FoxleyBold_16)
        chooseName.tint = 0x000000
        chooseName.x = 50
        chooseName.y = 267
        this.addChild(chooseName)

        const getMoreNames = new BitmapTextButton(124, 'GET MORE NAMES', BitmapTextButtonType.TEAL)
        getMoreNames.x = 56
        getMoreNames.y = 403
        this.addChild(getMoreNames)
        getMoreNames.addEventListener('pointerup', () => {
            this.generateNameFields()
        })

        const approvalText = new BitmapText(
            "You will enter the game with a temporary name. You'll get your own name in a couple of days after it has been approved.",
            {
                ...Fonts.Foxley_16,
                wordWrap: true
            }
        )
        approvalText.tint = 0x000000
        approvalText.x = 50
        approvalText.y = 437
        approvalText.style.wordWrapWidth = 145
        this.addChild(approvalText)

        const heads = getVar('global_heads')
        const eyes = getVar('global_eyes')
        const faces = getVar('global_faces')
        const hair = getVar('global_hair')
        const hats = getVar('reg_hats')
        const shirts = getVar('reg_shirts')
        const pants = getVar('reg_pants')
        const shoes = getVar('reg_shoes')

        if (!heads || !eyes || !faces || !hair || !hats || !shirts || !pants || !shoes) {
            void Client.shared.helpers.alert(
                'Oops, the avatar clothing data was unable to be loaded. Please refresh and try again, or report this issue if it persists.'
            )
            return
        }

        this.avatar = new AvatarVisual(this.provider, 3)
        this.avatar.setUpdatedOutfit(
            new AvatarOutfit({
                headId: heads[0],
                eyeId: eyes[0],
                faceId: faces[0],
                hairId: hair[0],

                hatId: hats[0],
                shirtId: shirts[0],
                pantsId: pants[0],
                shoesId: shoes[0],

                skinTint: RegistrationStep1View.SKIN_COLOR_IDS[0],
                eyeTint: 1,
                hairTint: RegistrationStep1View.HAIR_COLOR_IDS[0]
            })
        )
        this.avatar.cursor = 'default'
        this.avatar.eventMode = 'auto'
        this.avatar.interactiveChildren = false
        this.avatar.disableVortex()
        this.avatar.x = 367
        this.avatar.y = 474
        this.avatar.scale.set(2)
        this.addChild(this.avatar)

        const PICKER_Y = 227
        const PICKER_SPACING = 48

        const hatItems = hats.map((i) => {
            return {
                id: i,
                type: 'hair'
            }
        })

        const hatPicker = new ThumbPicker(this.provider, hatItems, (index: number) => {
            const outfit = this.avatar.getOutfit()
            outfit.hatId = hatItems[index].id
            this.avatar.setUpdatedOutfit(outfit)
        })
        hatPicker.x = 218
        hatPicker.y = PICKER_Y
        this.addChild(hatPicker)

        const hairItems = hair.map((i) => {
            return {
                id: i,
                type: 'hair'
            }
        })
        const hairPicker = new ThumbPicker(this.provider, hairItems, (index: number) => {
            const outfit = this.avatar.getOutfit()
            outfit.hairId = hairItems[index].id
            this.avatar.setUpdatedOutfit(outfit)
        })
        hairPicker.x = 218
        hairPicker.y = PICKER_Y + PICKER_SPACING
        this.addChild(hairPicker)

        // Combine face, head, and eyes
        const headItems: { id: string; type: string }[] = []

        const combos: number[][] = []
        const noggins: Container[] = []
        faces.forEach((faceId) => {
            eyes.forEach((eyeId) => {
                heads.forEach((headId) => {
                    headItems.push({
                        id: `${headId}-${faceId}-${eyeId}`,
                        type: 'head'
                    })
                    // render avi head
                    // with headId, faceId, eyeId.. head + face tinted
                    const noggin: Container = Pooler.newContainer()
                    const head = Pooler.newSprite(`std_hd_${headId}_3_0`)
                    const face = Pooler.newSprite(`std_fc_${faceId}_3_0`)
                    const eyes = Pooler.newSprite(`std_ey_${eyeId}_3_0`)
                    head.x -= 22
                    face.x -= 22
                    eyes.x -= 22
                    head.y -= 33
                    face.y -= 33
                    eyes.y -= 33
                    noggin.addChild(head, face, eyes)
                    // this.addChild(noggin);
                    noggin.x = 0
                    noggin.y = 0
                    combos.push([headId, faceId, eyeId])
                    noggins.push(noggin)
                })
            })
        })
        const headPicker = new ThumbPicker(
            this.provider,
            headItems,
            (index: number) => {
                const outfit = this.avatar.getOutfit()
                const combo = combos[index]
                outfit.headId = combo[0]
                outfit.faceId = combo[1]
                outfit.eyeId = combo[2]
                this.avatar.setUpdatedOutfit(outfit)
            },
            noggins
        )
        headPicker.x = 218
        headPicker.y = PICKER_Y + 2 * PICKER_SPACING
        this.addChild(headPicker)

        const shirtItems = shirts.map((i) => {
            return {
                id: i,
                type: 'pants'
            }
        })

        const shirtPicker = new ThumbPicker(this.provider, shirtItems, (index: number) => {
            const outfit = this.avatar.getOutfit()
            outfit.shirtId = shirtItems[index].id
            this.avatar.setUpdatedOutfit(outfit)
        })
        shirtPicker.x = 218
        shirtPicker.y = PICKER_Y + 3 * PICKER_SPACING
        this.addChild(shirtPicker)

        const pantsItems = pants.map((i) => {
            return {
                id: i,
                type: 'pants'
            }
        })
        const pantsPicker = new ThumbPicker(this.provider, pantsItems, (index: number) => {
            const outfit = this.avatar.getOutfit()
            outfit.pantsId = pantsItems[index].id
            this.avatar.setUpdatedOutfit(outfit)
        })
        pantsPicker.x = 218
        pantsPicker.y = PICKER_Y + 4 * PICKER_SPACING
        this.addChild(pantsPicker)

        const shoesItems = shoes.map((i) => {
            return {
                id: i,
                type: 'shoes'
            }
        })

        const shoesPicker = new ThumbPicker(
            this.provider,
            shoesItems,
            (index: number) => {
                const outfit = this.avatar.getOutfit()
                outfit.shoesId = shoesItems[index].id
                this.avatar.setUpdatedOutfit(outfit)
            },
            [],
            'noshoes.preview'
        )
        shoesPicker.x = 218
        shoesPicker.y = PICKER_Y + 5 * PICKER_SPACING
        this.addChild(shoesPicker)

        const hairColors = RegistrationStep1View.HAIR_COLOR_IDS.map((i) => {
            return {
                id: i,
                type: 'color'
            }
        })
        const hairColorSprites: Container[] = []
        hairColors.forEach((color) => {
            const g = new Graphics()
            g.beginFill(TintColor[color.id] ?? color.id)
            g.drawRect(0, 0, 36, 36)
            g.endFill()
            const c = Pooler.newContainer()
            c.addChild(g)
            hairColorSprites.push(c)
        })
        const hairColorPicker = new ThumbPicker(
            this.provider,
            hairColors,
            (index: number) => {
                const outfit = this.avatar.getOutfit()
                outfit.hairTint = hairColors[index].id
                this.avatar.setUpdatedOutfit(outfit)
            },
            hairColorSprites
        )
        hairColorPicker.x = 436
        hairColorPicker.y = PICKER_Y + PICKER_SPACING
        this.addChild(hairColorPicker)

        //

        const skinColors = RegistrationStep1View.SKIN_COLOR_IDS.map((i) => {
            return {
                id: i,
                type: 'color'
            }
        })
        const skinColorSprites: Container[] = []
        skinColors.forEach((color) => {
            const g = new Graphics()
            g.beginFill(TintColor[color.id] ?? color.id)
            g.drawRect(0, 0, 36, 36)
            g.endFill()
            const c = Pooler.newContainer()
            c.addChild(g)
            skinColorSprites.push(c)
        })
        const skinColorPicker = new ThumbPicker(
            this.provider,
            skinColors,
            (index: number) => {
                const outfit = this.avatar.getOutfit()
                outfit.skinTint = skinColors[index].id
                this.avatar.setUpdatedOutfit(outfit)
            },
            skinColorSprites
        )
        skinColorPicker.x = 436
        skinColorPicker.y = PICKER_Y + 2 * PICKER_SPACING
        this.addChild(skinColorPicker)

        this.nameTf = new DOMText({
            kind: 'field',
            className: 'messenger',
            id: 'reg.name',
            pattern: '(?!.*(?:[_\\-.]{2}))[A-Za-z]{1}[A-Za-z0-9_.\\-]{0,23}[A-Za-z0-9]{1}',
            maxlength: 25,
            fieldWidth: 130,
            fieldHeight: 19,
            fontSize: 16,
            fontColor: TextColor.black,
            initialValue: '',
            bgObject: null
        })
        this.nameTf.position.set(56, 240)
        this.addChild(this.nameTf)

        this.generateNameFields()

        this.guestRooms = []
        if (this.roomCastIds.length > 0) {
            this.currentRoomIndex = 0
            this.roomCastIds.forEach((id) => {
                const guestRoom = Pooler.newSprite(`reg.roomstyle.${id}`)
                guestRoom.x = 583
                guestRoom.y = 234
                this.guestRooms.push(guestRoom)
                this.addChild(guestRoom)
                guestRoom.visible = false
            })
            this.guestRooms[this.currentRoomIndex].visible = true
        }

        this.roomNameTf = new BitmapText('', Fonts.Foxley_16)
        this.roomNameTf.tint = 0x000000
        this.roomNameTf.width = 160
        this.roomNameTf.x = 580 + Math.round((160 - this.roomNameTf.width) / 2)
        this.roomNameTf.y = 355
        this.addChild(this.roomNameTf)

        this.updateRoomPreview()

        const arrowLeft = new ImageButton('char.button.left.active', 'char.button.left.pressed', '', '')
        this.addChild(arrowLeft)
        arrowLeft.addEventListener('pointerup', () => {
            if (this.currentRoomIndex > -1) {
                this.currentRoomIndex--
                if (this.currentRoomIndex < 0) {
                    this.currentRoomIndex = this.roomCastIds.length - 1
                }
                this.updateRoomPreview()
            }
        })
        arrowLeft.x = 562
        arrowLeft.y = 286

        const arrowRight = new ImageButton('char.button.right.active', 'char.button.right.pressed', '', '')
        this.addChild(arrowRight)
        arrowRight.x = 60
        arrowRight.addEventListener('pointerup', () => {
            if (this.currentRoomIndex > -1) {
                this.currentRoomIndex++
                if (this.currentRoomIndex > this.roomCastIds.length - 1) {
                    this.currentRoomIndex = 0
                }
                this.updateRoomPreview()
            }
        })
        arrowRight.x = 744
        arrowRight.y = 286

        const starterPackTf = new BitmapText(
            "When you enter the game, you'll get to keep the clothing you choose now. You'll also get a starter pack.",
            {
                ...Fonts.FoxleyBold_16,
                wordWrap: true
            }
        )
        starterPackTf.tint = 0x000000
        starterPackTf.style.wordWrapWidth = 165
        starterPackTf.x = 580
        starterPackTf.y = 377
        this.addChild(starterPackTf)

        this.nextBtn = new BitmapTextButton(75, 'NEXT', BitmapTextButtonType.GREEN)
        this.nextBtn.x = 694
        this.nextBtn.y = 489
        this.addChild(this.nextBtn)

        this.nextBtn.addEventListener('pointerup', () => {
            this.onNextTap()
        })

        headPicker.updateThumb(false)
        hairPicker.updateThumb(false)
        hatPicker.updateThumb(false)
        shirtPicker.updateThumb(false)
        pantsPicker.updateThumb(false)
        hairColorPicker.updateThumb(false)
        skinColorPicker.updateThumb(false)
        shoesPicker.updateThumb(false)
    }

    getOutfit(): AvatarOutfit {
        return this.avatar.getOutfit()
    }

    getIgn(): string {
        return this.ign
    }

    private async onNextTap(): Promise<void> {
        const ign = this.nameTf.getValue().trim()
        if (ign.length < 2) {
            Client.shared.helpers.alert({
                title: 'Invalid Name',
                message: 'Your in-game name must be at least two characters long. Please try a different name!'
            })
            return
        }
        if (ign.length > 25) {
            Client.shared.helpers.alert({
                title: 'Invalid Name',
                message: 'Your in-game name must be 25 characters or shorter. Please try a different name!'
            })
            return
        }

        const charFlag = !new RegExp(/^(?!.*(?:[_\-.]{2}))[A-Za-z]{1}[A-Za-z0-9_.-]{0,23}[A-Za-z0-9]{1}$/g).test(ign)
        if (charFlag) {
            Client.shared.helpers.alert({
                title: 'Invalid Name',
                message:
                    "Your in-game name can include letters, numbers, periods (.), underscores (_), and dashes (-). It must begin with a letter & end with a letter or number. It can't have repeating periods, underscores, or dashes. Try again!"
            })
            return
        }

        if (
            await Client.shared.helpers.confirm(
                `You have chosen "${ign}" as your name and ${this.roomNameTf.text} as your guest room. Are you sure?`
            )
        ) {
            this.ign = ign

            Client.shared.serverBroker.offEvent('ign_status')
            Client.shared.serverBroker.onEvent('ign_status', (payload: any) => {
                this.onIgnStatus(payload)
            })

            Client.shared.serverBroker.send('reg_ign_check', {
                ign: this.ign
            })
        }
    }

    onIgnStatus(payload: any): void {
        const available = payload.available
        if (available === true) {
            this.nextBtn.disable()
            this.avatar.actions.move = false
            this.avatar.reloadOutfitItems()

            Helpers.delay(1750).then(() => {
                this.parent.goToStep(2)
            })
        } else {
            if (payload.note === 'prefix') {
                Client.shared.helpers.alert({
                    tag: 'regname',
                    title: 'Invalid Name',
                    message: 'Your in-game name cannot begin with that prefix. Please try a different name!'
                })
            } else {
                Client.shared.helpers.alert({
                    tag: 'regname',
                    title: 'Name Unavailable',
                    message:
                        'Sorry! That in-game name is already in use or does not meet our standards. Please choose a different in-game name. Thank you!'
                })
            }
        }
    }

    generateRandomIgn(): string {
        if (!(this.nouns && this.adjectives && this.nouns.length > 0 && this.adjectives.length > 0)) {
            return null
        }
        const name =
            this.getRandomNameElement(this.adjectives) +
            this.getRandomNameElement(this.adjectives) +
            this.getRandomNameElement(this.nouns)

        if (name.length > this.MAX_GENERATED_NAME_LENGTH) {
            return this.generateRandomIgn()
        }

        return name
    }

    private getRandomNameElement(arr: string[]): string {
        return arr[Math.floor(Math.random() * arr.length)]
    }

    private generateNameFields(): void {
        this.nameFields.forEach((field) => {
            this.removeChild(field)
        })

        for (let i = 0; i < 5; i++) {
            const scaleField = new ScaleField(140, 18, 0x88bb3f, this.generateRandomIgn(), false, 0xffffff)
            scaleField.x = 51
            scaleField.y = 290 + 21 * i
            scaleField.cursor = 'pointer'
            scaleField.eventMode = 'static'
            scaleField.addEventListener('pointerup', () => {
                this.nameTf.setValue(scaleField.getText())
            })
            this.addChild(scaleField)
            this.nameFields.push(scaleField)
        }
    }

    getRoomId(): number {
        return this.roomCastIds[this.currentRoomIndex]
    }

    updateRoomPreview(): void {
        this.guestRooms.forEach((room) => {
            room.visible = false
        })
        this.guestRooms[this.currentRoomIndex].visible = true
        const roomName = getText(`room.${this.roomCastIds[this.currentRoomIndex]}.name`)
        this.roomNameTf.text = roomName
        this.roomNameTf.x = 580 + Math.round((160 - this.roomNameTf.width) / 2)
    }

    setVisible(visible = true): void {
        this.visible = visible
    }
}
