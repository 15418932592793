import { NamedColorPresets } from '@vmk-legacy/common-ts'
import type { AssetProvider } from '@vmk-legacy/render-utils'
import { buildClothingItem, Pooler } from '@vmk-legacy/render-utils'
import type { Container, RenderTexture, Texture } from 'pixi.js'
import { getText } from '../assets/ExternalConfigManager.js'
import { Client } from '../Client.js'
import { ThumbPickerColor } from '../ui/containers/ThumbPickerColor.js'

export class AvatarStack implements AvatarStackedProvider {
    readonly defUid: string | number
    readonly defIndex: number
    readonly defType: string

    constructor(defUid: string | number, defType: string, defIndex: number) {
        this.defUid = defUid
        this.defIndex = defIndex
        this.defType = defType
    }

    get name(): string {
        return this.getName()
    }

    getName(): string {
        switch (this.defType) {
            case 'head':
                return getText('Head #' + (this.defIndex + 1))
            case 'hair-color':
                for (const color of NamedColorPresets) {
                    if (color.num == this.defUid) {
                        return getText(color.name)
                    }
                }
            case 'skin-color':
                for (const color of NamedColorPresets) {
                    if (color.num == this.defUid) {
                        return getText(color.name)
                    }
                }
            default:
                return getText('item.' + this.defUid)
        }
    }

    get key(): string {
        return this.defUid + '.0.0.0'
    }

    getKey(): string {
        return this.key
    }

    getCount(): number {
        return 1
    }

    getNote(): string | null {
        return null
    }

    getRendition(provider: AssetProvider): Promise<Texture> {
        return AvatarStack.getRendition(this.defUid, this.defType, provider)
    }

    static async getRendition(defUid: string | number, defType: string, provider: AssetProvider): Promise<Texture> {
        switch (defType) {
            case 'head':
                const splitIds: string[] = defUid.split('-')
                const headId = splitIds[0]
                const faceId = splitIds[1]
                const eyeId = splitIds[2]
                const noggin: Container = Pooler.newContainer()
                const head = Pooler.newSprite(`std_hd_${headId}_3_0`)
                const face = Pooler.newSprite(`std_fc_${faceId}_3_0`)
                const eyes = Pooler.newSprite(`std_ey_${eyeId}_3_0`)
                head.x = -22
                face.x = -22
                eyes.x = -22
                head.y = -33
                face.y = -33
                eyes.y = -33
                noggin.addChild(head, face, eyes)
                const headTex = Client.shared.renderer.generateTexture(noggin)
                Pooler.release(noggin)
                return headTex
            case 'hair':
                const hairContainer = await buildClothingItem(defUid, 3, Client.shared.figuresMgr, provider)
                const hairTex = Client.shared.renderer.generateTexture(hairContainer)
                Pooler.release(hairContainer)
                return hairTex
            case 'hair-color':
            case 'skin-color':
                const colorContainer = Pooler.newContainer()
                colorContainer.addChild(new ThumbPickerColor(defUid, 36, 36))
                const colorTex = Client.shared.renderer.generateTexture(colorContainer)
                Pooler.release(colorContainer)
                return colorTex
            default:
                throw new Error('could not load thumb for item type ' + defType)
        }
    }
}

export interface AvatarStackedProvider {
    getKey(): string

    getCount(): number

    getRendition(provider: AssetProvider): Promise<Texture | RenderTexture>

    getName(): string

    getNote(): string | null
}
