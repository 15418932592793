import { BitmapText, Container, NineSlicePlane, Texture } from 'pixi.js'
import { Fonts } from '../../Fonts.js'

export class ScaleField extends Container {
    private text = ''
    private field: NineSlicePlane

    constructor(
        width: number,
        height: number,
        tint: number,
        text?: string,
        bold?: boolean,
        textColor?: number,
        textPadY?: number,
        center?: boolean
    ) {
        super()
        this.field = new NineSlicePlane(Texture.from('scale.field'), 4, 4, 4, 4)
        this.field.width = width
        this.field.height = height
        this.field.tint = tint
        this.addChild(this.field)

        if (!textPadY) {
            textPadY = 0
        }

        if (!center) {
            center = false
        }

        if (text) {
            this.text = text
            if (!bold) {
                bold = false
            }
            const tf = new BitmapText(text, bold ? Fonts.FoxleyBold_16 : Fonts.Foxley_16)
            if (!textColor) {
                textColor = 0xffffff
            }
            tf.style.fill = textColor
            tf.x = 5
            tf.y = 2 + textPadY + (bold ? -1 : 0)
            tf.style.wordWrapWidth = width - 10
            tf.style.wordWrap = true
            this.addChild(tf)

            if (center) {
                tf.x = Math.round((width - tf.width) / 2)
            }
        }
    }

    setTint(newTint: number): void {
        this.field.tint = newTint
    }

    getText(): string {
        return this.text
    }
}
