import { Pooler } from '@vmk-legacy/render-utils'
import type { Container } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { Client } from '../../../../Client.js'
import { Fonts } from '../../../../Fonts.js'
import { ScrollArea } from '../../../containers/ScrollArea.js'
import { UIWindowView } from '../../UIWindowView.js'
import type { ModWindow } from '../ModWindow.js'
import type { UsersView } from './UsersView.js'

export class StatusView extends UIWindowView<ModWindow> {
    padding = 5

    usersContainer: Container

    constructor() {
        super()

        const statusPaddingRight = 5
        const serverStatus = new BitmapText('CONNECTION TO SERVER:', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        this.addChild(serverStatus)
        // let serverValue = new BitmapText(Networker.connected ? 'OK' : 'NOT CONNECTED', {
        const serverValue = new BitmapText('OK', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        serverValue.tint = 0x6cff00 // Networker.connected ? 0x6CFF00 : 0xFFFB00;
        serverValue.x = serverStatus.x + serverStatus.width + statusPaddingRight
        this.addChild(serverValue)

        const usersHeader = new BitmapText('ONLINE USERS', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        usersHeader.y = 55
        this.addChild(usersHeader)

        this.setUsers([])

        const eventsHeader = new BitmapText('UPCOMING EVENTS', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        eventsHeader.y = 287
        this.addChild(eventsHeader)

        const events = Pooler.newContainer()

        for (let i = 0; i < 0; i++) {
            const eventName = new BitmapText('Test Event ' + (i + 1), Fonts.FoxleyBold_16)
            const eventDetails = new BitmapText('Tue. Apr 23 @ 12:00pm EST (HOST_???)', {
                ...Fonts.Foxley_16
            })
            eventDetails.x = eventName.width + 5
            eventName.y = 18 * i
            eventDetails.y = eventName.y + 1
            events.addChild(eventName, eventDetails)
        }

        const eventsScrollArea = new ScrollArea(344, 45, events)
        this.addChild(eventsScrollArea)
        eventsScrollArea.y = eventsHeader.y + 22

        setInterval(() => {
            this.requestStatus()
        }, 30000)

        this.once('added', () => {
            Client.shared.serverBroker.onEvent('mk_status', (data: any) => {
                this.onStatus(data)
            })
            this.requestStatus()
        })
    }

    setUsers(users: any[]) {
        if (this.usersContainer) {
            this.removeChild(this.usersContainer)
        }
        this.usersContainer = Pooler.newContainer()

        const usersDOContainer: Container = Pooler.newContainer()

        function compareStrings(a: string, b: string) {
            // Assuming you want case-insensitive comparison
            a = a.toLowerCase()
            b = b.toLowerCase()

            return a < b ? -1 : a > b ? 1 : 0
        }

        const testUsers: any[] = users

        testUsers.sort((a, b) => {
            return compareStrings(a.name, b.name)
        })

        let tintStaffNames = false
        const boldStaffNames = true
        for (let i = 0; i < testUsers.length; i++) {
            const testTF = new BitmapText(testUsers[i].name, {
                fontFamily: this.isStaffType(testUsers[i].type) && boldStaffNames ? 'FoxleyBold' : 'Foxley',
                fontSize: 16
            })
            testTF.eventMode = 'static'
            testTF.cursor = 'pointer'
            testTF.addEventListener('pointerup', () => {
                ;(this.parent.usersTab.view as UsersView).nameField.getElement().placeholder = testUsers[i].name
                ;(this.parent.usersTab.view as UsersView).setSearching(true)
                this.parent.setActiveTab(this.parent.usersTab)
                Client.shared.serverBroker.send('mk_search', {
                    ign: testUsers[i].name
                })
            })
            if (tintStaffNames) {
                switch (testUsers[i].type) {
                    case 'gr':
                        testTF.tint = 0xfd3e81
                        break
                    case 'get':
                        testTF.tint = 0xffd166
                        break
                    case 'dev':
                        testTF.tint = 0x20a3f3
                        break
                    case 'pr':
                        testTF.tint = 0xfc512f
                        break
                    case 'da':
                        testTF.tint = 0x81c14b
                        break
                    case 'host':
                        testTF.tint = 0x54f2f2
                        break
                    default:
                        break
                }
            }
            usersDOContainer.addChild(testTF)
            testTF.y = 16 * i
        }

        const usersScrollArea = new ScrollArea(150, 160, usersDOContainer)
        this.usersContainer.addChild(usersScrollArea)
        usersScrollArea.y = 78

        const onlineUserSummary = new BitmapText(testUsers.length + ' users connected', {
            ...Fonts.FoxleyBold_16
        })
        /*
         * Request modStatusPacket from server every n seconds
         * Update values accordingly
         */
        onlineUserSummary.x =
            usersScrollArea.x + Math.round((usersScrollArea.getContainerWidth() - onlineUserSummary.width) / 2)
        onlineUserSummary.y = 265
        this.usersContainer.addChild(onlineUserSummary)

        const staffHeader = new BitmapText('ONLINE STAFF', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        staffHeader.x = 195
        staffHeader.y = 55
        this.usersContainer.addChild(staffHeader)
        const staffDOContainer = Pooler.newContainer()
        let c = 0
        tintStaffNames = true
        testUsers.forEach((testUser) => {
            if (this.isStaffType(testUser.type)) {
                const testTF = new BitmapText(testUser.name, {
                    fontFamily: this.isStaffType(testUser.type) && boldStaffNames ? 'FoxleyBold' : 'Foxley',
                    fontSize: 16
                })
                testTF.eventMode = 'static'
                testTF.cursor = 'pointer'
                testTF.addEventListener('pointerup', () => {
                    ;(this.parent.usersTab.view as UsersView).nameField.getElement().placeholder = testUser.name
                    ;(this.parent.usersTab.view as UsersView).setSearching(true)
                    this.parent.setActiveTab(this.parent.usersTab)
                    Client.shared.serverBroker.send('mk_search', {
                        ign: testUser.name
                    })
                })
                if (tintStaffNames) {
                    switch (testUser.type) {
                        case 'gr':
                            testTF.tint = 0xfd3e81
                            break
                        case 'get':
                            testTF.tint = 0xffd166
                            break
                        case 'dev':
                            testTF.tint = 0x20a3f3
                            break
                        case 'pr':
                            testTF.tint = 0xfc512f
                            break
                        case 'da':
                            testTF.tint = 0x81c14b
                            break
                        case 'host':
                            testTF.tint = 0x54f2f2
                            break
                        default:
                            break
                    }
                }

                staffDOContainer.addChild(testTF)
                testTF.y = 16 * c
                c++
            }
        })
        const staffScrollArea = new ScrollArea(150, 160, staffDOContainer)
        staffScrollArea.x = staffHeader.x
        staffScrollArea.y = usersScrollArea.y
        this.usersContainer.addChild(staffScrollArea)
        const onlineStaffSummary = new BitmapText(c + ' staff connected', {
            ...Fonts.FoxleyBold_16
        })
        onlineStaffSummary.x =
            staffScrollArea.x + Math.round((staffScrollArea.getContainerWidth() - onlineStaffSummary.width) / 2)
        onlineStaffSummary.y = 265
        this.usersContainer.addChild(onlineStaffSummary)

        this.addChild(this.usersContainer)
    }

    requestStatus() {
        Client.shared.serverBroker.send('mk_status_req')
    }

    onStatus(data: any) {
        this.setUsers(data.users)
    }

    private isStaffType(type: string): boolean {
        return !('user' === type || '' == type || type == null)
    }

    update() {
        //
    }
}
