import type { EItemType } from '@vmk-legacy/common-ts'
import { Pooler } from '@vmk-legacy/render-utils'
import type { Container } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { getVar } from '../../../../assets/ExternalConfigManager.js'
import { Client } from '../../../../Client.js'
import type { Item } from '../../../../data/Item.js'
import type { ItemStack } from '../../../../data/ItemStack.js'
import { Fonts } from '../../../../Fonts.js'
import { Helpers } from '../../../../util/Helpers.js'
import { PagedThumbnailBox } from '../../../thumbs/PagedThumbnailBox.js'
import { UIWindowView } from '../../UIWindowView.js'
import type { TradeWindow } from '../TradeWindow.js'

export class TradeView extends UIWindowView<TradeWindow> {
    padding = 5
    private itemName: BitmapText
    items: Item[]
    itemType: EItemType
    thumbContainer: PagedThumbnailBox
    controller: Container

    previewImage = Pooler.newContainer()

    constructor(
        readonly tradeWin: TradeWindow,
        itemType: EItemType
    ) {
        super()

        this.items = []
        this.itemType = itemType

        this.addChild(this.previewImage)

        this.itemName = new BitmapText('', Fonts.Foxley_16)
        this.setItemName('Click on the stuff you want to trade')
        this.addChild(this.itemName)

        const previewBox = Pooler.newSprite('previewbox')
        previewBox.scale.set(0.5)
        previewBox.x = 352
        previewBox.y = 10
        this.addChild(previewBox)

        this.reset()
    }

    reset() {
        const thumbItems = [...Client.shared.selfRecord.getInventory().values()].filter(
            (i) => i.defType === this.itemType
        )

        if (this.thumbContainer) {
            this.thumbContainer.setItems(...thumbItems)
        } else {
            this.thumbContainer = new PagedThumbnailBox({
                rows: 2,
                cols: 8,
                items: thumbItems,
                spacing: 2,
                search: true,
                onItemTap: (item: ItemStack, preview: Container) => {
                    // on item tap
                    this.thumbContainer.setSelected(item)
                    this.tradeWin.getTheirOfferContainer().setSelected(null)
                    this.tradeWin.getYourOfferContainer().setSelected(null)
                    let itemName = item.getName()
                    if (item.stars > 0) {
                        itemName += ` (${item.stars} star${item.stars === 1 ? '' : 's'})`
                    }
                    this.setItemName(itemName)
                    this.setPreviewImage(preview)
                },
                onItemDoubleTap: (item: ItemStack) => {
                    const isTradeable = !getVar('nontradable').includes(item.defUid)
                    if (!isTradeable) {
                        return
                    }
                    // on item double tap
                    Client.shared.serverBroker.send('trade_offer_add', [
                        item.defUid,
                        item.stars,
                        item.expires,
                        item.teleporterId
                    ])
                },
                pageTurnSpacing: 100,
                pageTurnFormat: PagedThumbnailBox.FORMAT_E,
                dimUntradeables: true
            })
            this.thumbContainer.y = 20

            this.controller = this.thumbContainer.getControllerContainer()
            this.controller.x = 100
            this.controller.y = 143

            this.addChild(this.controller, this.thumbContainer)
            this.thumbContainer.setSelected(null)
        }
    }

    getThumbContainer(): PagedThumbnailBox {
        return this.thumbContainer
    }

    setPreviewImage(container: Container) {
        if (this.previewImage === container) {
            return
        }

        this.removeChild(this.previewImage)

        this.previewImage = container
        this.previewImage.cacheAsBitmap = true
        this.previewImage.x = 355
        this.previewImage.y = 15

        Helpers.scalePreviewImage(this.previewImage, 100, 125)

        this.addChild(this.previewImage)
    }

    setItemName(itemName: string) {
        this.itemName.text = itemName
        this.itemName.x = Math.round((341 - this.itemName.width) / 2)
    }

    update() {}
}
