import { ESndGrp, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import { BitmapText, Container, type Sprite, Texture } from 'pixi.js'
import { getText } from '../../assets/ExternalConfigManager.js'
import { Client } from '../../Client.js'
import { Constants } from '../../Constants.js'
import { EWindow, UILayer } from '../../enums.js'
import { Fonts } from '../../Fonts.js'
import { ImageButton } from '../../ui/buttons/ImageButton.js'
import { UISoundLibrary } from '../../ui/UISoundLibrary.js'
import type { IWindow } from '../../ui/windows/IWindow.js'

export class JungleCruiseInstructionPopup extends Container implements IWindow {
    readonly kind = EWindow.Other
    layer = UILayer.Legacy
    private currentPage = 0
    private prevArrow: ImageButton
    private nextArrow: ImageButton

    header: Container
    isDraggable = true
    exitBtn: ImageButton

    setVisible(visible: boolean): void {
        this.container.visible = visible
        this.containerContainer.visible = visible
        this.visible = visible
    }

    reset(): void {
        //
    }

    container: Container
    private title: string
    private body: string // \n for newline

    private totalWidth: number
    private totalHeight: number

    private titleText: BitmapText
    private bodyText: BitmapText
    private bodyPic: Sprite

    private prevContainer: Container
    private nextContainer: Container

    private containerContainer: Container

    private playBtn: ImageButton
    private highScoresBtn: ImageButton
    private cancelBtn: ImageButton

    constructor() {
        super()

        this.exitBtn = new ImageButton('button.jcclose.active', 'button.jcclose.pressed')

        this.containerContainer = Pooler.newContainer()
        this.container = Pooler.newContainer()

        this.eventMode = 'static'
        this.header = Pooler.newSprite()

        const bg = Pooler.newContainer()
        const winTop = bg.addChild(Pooler.newSprite('jc.window.top'))
        winTop.anchor.set(0)

        const winMiddle = bg.addChild(Pooler.newSprite('jc.window.middle'))
        winMiddle.position.set(22, winTop.height)
        winMiddle.anchor.set(0)
        winMiddle.height = 352

        const winBottom = bg.addChild(Pooler.newSprite('jc.window.bottom'))
        winBottom.anchor.set(0)
        winBottom.position.set(2, winMiddle.height + winMiddle.y)

        this.containerContainer.addChild(bg)

        this.exitBtn.position.set(425, 45)
        this.containerContainer.addChild(this.exitBtn)

        this.titleText = new BitmapText(this.title || '', {
            fontFamily: 'Folio',
            fontSize: 12,
            align: 'left'
        })
        const titlewidth: number = this.titleText.width
        this.titleText.x = Math.round((484 - titlewidth) / 2)
        this.titleText.y = 86
        this.container.addChild(this.titleText)

        this.bodyText = new BitmapText({
            text: this.body || '',
            style: {
                ...Fonts.Foxley_16,
                wordWrap: true,
            }
        })
        this.bodyText.style.wordWrapWidth = 385
        this.bodyText.position.set(53, 128)
        this.container.addChild(this.bodyText)

        this.bodyPic = Pooler.newSprite()
        this.bodyPic.position.set(48, 210)
        this.container.addChild(this.bodyPic)

        this.containerContainer.addChild(this.container)
        this.addChild(this.containerContainer)
        this.center()

        this.prevContainer = Pooler.newContainer()
        this.prevArrow = new ImageButton('button.arrowprev.active', 'button.arrowprev.pressed')
        this.prevContainer.addChild(this.prevArrow)
        this.container.addChild(this.prevContainer)
        const prevText = new BitmapText(getText('pirates.previouspage'), {
            fontFamily: 'Folio',
            fontSize: 12
        })
        this.prevContainer.addChild(prevText)
        prevText.x = 30
        prevText.y = 8
        this.prevContainer.position.set(50, 420)
        this.prevContainer.addEventListener('pointerup', () => {
            SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.UIClick)
            this.setPage(this.currentPage - 1)
        })

        this.nextContainer = Pooler.newContainer()
        this.nextArrow = new ImageButton('button.arrownext.active', 'button.arrownext.pressed')
        this.nextArrow.x = 63
        this.nextContainer.addChild(this.nextArrow)
        this.container.addChild(this.nextContainer)
        const nextText = new BitmapText(getText('pirates.nextpage'), {
            fontFamily: 'Folio',
            fontSize: 12
        })
        nextText.y = 8
        this.nextContainer.addChild(nextText)
        this.nextContainer.position.set(350, 420)
        this.nextContainer.addEventListener('pointerup', () => {
            SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.UIClick)
            this.setPage(this.currentPage + 1)
        })

        const spacer = 132

        this.playBtn = new ImageButton('button.jcstartgame.active', 'button.jcstartgame.pressed', '', '')
        this.playBtn.position.set(50, 460)
        this.container.addChild(this.playBtn)
        this.playBtn.addEventListener('pointerup', () => {
            this.playBtn.eventMode = 'auto'
            this.playBtn.alpha = 0.2
            Client.shared.serverBroker.requestRoom('jc.game')
            Client.shared.userInterface.removeWindow(this)
        })

        this.highScoresBtn = new ImageButton('button.jchighscores.active', 'button.jchighscores.pressed', '', '')
        this.highScoresBtn.position.set(50 + spacer, 460)
        this.container.addChild(this.highScoresBtn)
        this.highScoresBtn.addEventListener('pointerup', () => {
            this.showHighscores()
        })

        this.cancelBtn = new ImageButton('button.jccancel.active', 'button.jccancel.pressed', '', '')
        this.cancelBtn.position.set(50 + spacer + spacer, 460)
        this.container.addChild(this.cancelBtn)
        this.cancelBtn.addEventListener('pointerup', () => {
            this.destroy()
        })

        this.setPage(0)

        this.highScoresBtn.visible = true
        this.bodyPic.visible = true
        this.setTitle(getText('junglecruise.signup.window.title'))
        this.setPage(0)
        this.bodyText.y = 128

        Client.shared.serverBroker.send('jc_highscores_req', {})
        Client.shared.serverBroker.onEvent('jc_highscores', (hs: any) => {
            if (hs) {
                this.updateHighscores(hs)
            }
        })
    }

    getDirection(place: number): number {
        let dir = 4
        if (place === 2) {
            dir = 5
        }
        if (place === 3) {
            dir = 3
        }
        return dir
    }

    updateHighscores(hs: any): void {
        for (const entry of hs) {
            //
        }
    }

    showHighscores(): void {
        //
    }

    setPage(page: number): void {
        if (page < 0 || page > 5) {
            return
        }
        if (!Number.isInteger(page)) {
            return
        }

        this.currentPage = page

        this.prevContainer.eventMode = 'static'
        this.prevContainer.interactiveChildren = true
        this.nextContainer.eventMode = 'static'
        this.nextContainer.interactiveChildren = true
        this.prevArrow.visible = this.nextArrow.visible = true

        this.bodyPic.texture = Texture.from(`jc.instructions.image${page + 1}`)
        this.bodyText.text = getText(`jc.instructions.page.${page}`).replace(/\\r/g, '\n')

        if (page === 0) {
            this.prevContainer.eventMode = 'auto'
            this.prevContainer.interactiveChildren = false
            this.prevArrow.visible = false
        } else if (page === 5) {
            this.nextArrow.visible = false
            this.nextContainer.eventMode = 'auto'
            this.nextContainer.interactiveChildren = false
        }
    }

    setTitle(title: string): void {
        this.titleText.text = title
        const titlewidth: number = this.titleText.width
        this.titleText.x = Math.round((this.width - titlewidth) / 2)
    }

    onIgnore(): void {
        //
    }

    onOk(): void {
        //
    }

    center(): void {
        this.containerContainer.x = Math.round((Constants.SIZE[0] - this.width) / 2)
        this.containerContainer.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }

    tryRemove(): void {
        if (this.parent !== null) {
            this.parent.removeChild(this)
        }
    }

    getWidth(): number {
        return this.totalWidth
    }

    getHeight(): number {
        return this.totalHeight
    }
}
