import type { Sprite } from 'pixi.js'
import { Assets, Texture } from 'pixi.js'
import { Client } from '../../../Client.js'
import { EWindow } from '../../../enums.js'
import type { IPaginator } from '../../../server/messages/MessagesModule.js'
import type { BitmapTextButton } from '../../buttons/BitmapTextButton.js'
import type { DOMText } from '../../DOMText.js'
import LegacyWindow from '../LegacyWindow.js'
import type { PhotoCatalog } from './PhotoCatalog.js'

export class PhotoCatalogView extends LegacyWindow {
    readonly kind = EWindow.Other

    photoIndex = 0
    photoData: IPaginator<[number, string]>

    #viewer: Sprite
    #caption: DOMText

    #lastPhotoId?: number
    #lastTex?: Texture

    constructor(readonly container: PhotoCatalog) {
        super('vmk_fto_catalog_view')
        this.isEmbedded = true
        this.embeddedParent = container
    }

    override async windowWasBuilt(): Promise<void> {
        this.#viewer = this.getElement('fto.view')
        this.#caption = this.getElement('camera.caption.input')
        this.#caption.getElement().maxLength = 100

        // const sepia = new ColorMatrixFilter();
        // sepia.contrast(0.25, false);
        // sepia.tint(0xFFEA37, true);
        // sepia.sepia(true);

        // this.#viewer.filters = [sepia];

        const publishBtn = this.getElement('fto.publishtoalbum.button')
        const viewAllBtn = this.getElement('fto.viewall.button')
        const deleteBtn = this.getElement('fto.delete.button')
        const placeBtn = this.getElement('fto.placeonwall.button')
        const discordBtn = this.getElement('fto.postdiscord.button') as BitmapTextButton

        const nextBtn = this.getElement('fto.nextpage.button')
        const backBtn = this.getElement('fto.previouspage.button')

        nextBtn.addEventListener('pointertap', async () => {
            nextBtn.disable()
            backBtn.disable()
            saveBtn.disable()
            deleteBtn.disable()
            discordBtn.disable()

            const maxPage = Math.ceil(this.photoData.total / this.photoData.per)

            if (this.photoData.data.length === this.photoIndex + 1) {
                if (maxPage > this.photoData.page) {
                    await this.container.requestPage(this.photoData.page + 1)
                }
                this.photoIndex = 0
            } else {
                this.photoIndex++
            }
            await this.showPhoto(this.photoIndex)

            nextBtn.enable()
            backBtn.enable()
            saveBtn.enable()
            deleteBtn.enable()
            discordBtn.enable()
        })
        backBtn.addEventListener('pointertap', async () => {
            nextBtn.disable()
            backBtn.disable()
            saveBtn.disable()
            deleteBtn.disable()
            discordBtn.disable()

            const maxPage = Math.ceil(this.photoData.total / this.photoData.per)
            if (this.photoIndex === 0) {
                if (this.photoData.page === 1 && maxPage !== 1) {
                    await this.container.requestPage(maxPage)
                }
                this.photoIndex = this.photoData.data.length - 1
            } else {
                this.photoIndex--
            }
            await this.showPhoto(this.photoIndex)

            nextBtn.enable()
            backBtn.enable()
            saveBtn.enable()
            deleteBtn.enable()
            discordBtn.enable()
        })
        const saveBtn = this.getElement('fto.save.button') as BitmapTextButton
        saveBtn.addEventListener('pointertap', () => {
            if (!this.#lastPhotoId) {
                return
            }
            saveBtn.disable()
            Client.shared.serverBroker
                .sendAck('edit_photo', {
                    photoId: this.#lastPhotoId,
                    caption: this.#caption.getValue()
                })
                .then((res) => {
                    console.log('saved', res)
                    saveBtn.enable()
                })
        })

        discordBtn.addEventListener('pointertap', () => {
            if (!this.#lastPhotoId) {
                return
            }
            discordBtn.disable()
            Client.shared.serverBroker
                .sendAck('photo_post', {
                    photoId: this.#lastPhotoId
                })
                .then((res) => {
                    if (res) {
                        Client.shared.helpers.alert({
                            title: 'Photo Submitted',
                            message:
                                'Your photo has been posted to the gallery - check it out in the Discord server screenshots channel!'
                        })
                    }

                    discordBtn.enable()
                })
        })

        publishBtn.disable()
        placeBtn.disable()

        viewAllBtn.addEventListener('pointertap', () => this.container.showThumbs())

        deleteBtn.addEventListener('pointertap', async () => {
            if (!this.#lastPhotoId) {
                return
            }
            deleteBtn.disable()
            saveBtn.disable()
            const index = this.photoIndex

            if (
                await Client.shared.helpers.confirm({
                    message: 'photocatalog_confirm_delete'
                })
            ) {
                Client.shared.serverBroker.sendAck('delete_photo', this.#lastPhotoId).then((res) => {
                    if (res) {
                        this.photoData.data.splice(index, 1)
                        this.photoData.total--
                        if (this.photoIndex !== 0) {
                            this.photoIndex--
                        }
                        this.showPhoto(this.photoIndex)
                        this.container.requestPage(this.photoData.page, true)
                    }
                    deleteBtn.enable()
                    saveBtn.enable()
                })
            } else {
                deleteBtn.enable()
                saveBtn.enable()
            }
        })

        this.photoData = await this.container.photoRequest

        this.showPhoto(0)
    }

    async showPhoto(index: number) {
        if (this.#lastTex) {
            if (this.#lastPhotoId) {
                // await Assets.unload('fto-' + this.#lastPhotoId);
                // delete Assets.resolver._assetMap['fto-' + this.#lastPhotoId];
            }
            // this.#lastTex.destroy(true);
            this.#lastTex = null
        }
        const photo = this.photoData.data[index]
        if (!photo) {
            this.#lastPhotoId = null
            this.#viewer.texture = Texture.EMPTY
            this.#caption.setValue('')
            this.#caption.setReadonly(true)
            return
        }
        this.#lastPhotoId = photo[0]

        this.#caption.setValue(photo[1])
        this.#caption.setReadonly(false)

        const res = await Client.shared.api.get('photo/' + photo[0])

        if (res) {
            let tex = Assets.get(`fto-${photo[0]}`)
            if (!tex) {
                Assets.add({ alias: `fto-${photo[0]}`, src: res.data })
                tex = await Assets.load(`fto-${photo[0]}`)
            }

            if (tex) {
                this.#lastTex = tex
                this.#viewer.texture = this.#lastTex
            }
        } else {
            this.#viewer.texture = Texture.EMPTY
        }
    }
}
