import type { FederatedEvent } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { getText } from '../../assets/ExternalConfigManager.js'
import { Client } from '../../Client.js'
import { Constants } from '../../Constants.js'
import { Fonts } from '../../Fonts.js'
import { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import { RadioButton } from '../buttons/RadioButton.js'
import { DOMText } from '../DOMText.js'
import { FieldG } from '../fields/FieldG.js'
import { RadioController } from '../RadioController.js'
import { TextColor } from '../VMKLText'
import type { HelpWindow } from './HelpWindow.js'
import { UIWindowView } from './UIWindowView.js'

export class CFHView extends UIWindowView<HelpWindow> {
    padding = 5
    nameField: DOMText
    bodyField: DOMText

    constructor() {
        super()

        const header = new BitmapText(Constants.HELP_HEADER.toUpperCase(), {
            fontFamily: 'Folio',
            fontSize: 12,
            align: 'left'
        })
        this.addChild(header)

        const reasonHeader = new BitmapText(Constants.HELP_REASON_HEADER, {
            ...Fonts.Foxley_16,
            align: 'left'
        })
        reasonHeader.y = 24
        this.addChild(reasonHeader)

        const r1 = new RadioButton(getText('hlp.problem1'), 1, 6)
        const r2 = new RadioButton(getText('hlp.problem2'), 2, 6)
        const r3 = new RadioButton(getText('hlp.problem3'), 3, 6)

        const reasonController = new RadioController([r1, r2, r3])

        reasonController.setValue(1)

        r1.y = 44
        r2.y = 62
        r3.y = 80

        r1.x = r2.x = r3.x = 5
        this.addChild(r1, r2, r3)

        const nameHeader = new BitmapText(Constants.HELP_NAME_PROMPT, {
            ...Fonts.Foxley_16,
            align: 'left',
            wordWrap: true
        })
        nameHeader.style.wordWrapWidth = 290
        nameHeader.y = r3.y + 26
        this.addChild(nameHeader)

        this.nameField = new DOMText({
            kind: 'field',
            className: 'help',
            id: 'cfh.name',
            maxlength: 32,
            fieldWidth: 270,
            fieldHeight: 18,
            fontSize: 16,
            fontColor: TextColor.black,
            initialValue: '',
            bgObject: new FieldG(275, 11),
            padLeft: 7,
            padTop: -1
        })
        this.nameField.y = nameHeader.y + 32
        this.addChild(this.nameField)

        const bodyHeader = new BitmapText(Constants.HELP_BODY_PROMPT, {
            ...Fonts.Foxley_16,
            align: 'left'
        })
        bodyHeader.style.wordWrapWidth = 290
        bodyHeader.y = this.nameField.y + 32
        this.addChild(bodyHeader)

        this.bodyField = new DOMText({
            kind: 'area',
            className: 'help',
            id: 'cfh.body',
            maxlength: 256,
            fieldWidth: 270,
            fieldHeight: 87,
            fontSize: 16,
            fontColor: TextColor.black,
            initialValue: '',
            bgObject: new FieldG(275, 80),
            padLeft: 7,
            padTop: 0
        })
        this.bodyField.y = bodyHeader.y + 15
        this.addChild(this.bodyField)

        const warning = new BitmapText(Constants.HELP_SUBMIT_WARNING, {
            ...Fonts.Foxley_16,
            align: 'left',
            wordWrap: true
        })
        warning.style.wordWrapWidth = 290
        warning.y = this.bodyField.y + this.bodyField.height + 6
        this.addChild(warning)

        const submitBtn = new BitmapTextButton(145, Constants.HELP_SUBMIT_BUTTON, 'c')
        submitBtn.y = 355
        this.addChild(submitBtn)
        // submitBtn.disable();
        submitBtn.addEventListener('pointerup', (e: FederatedEvent) => {
            const reportedUser = this.nameField.getValue()
            const report = this.bodyField.getValue()

            if (!report || !reasonController.getValue()) {
                Client.shared.helpers.alert({
                    title: 'Oops!',
                    message:
                        'To send a Call for Help, choose an option for "How can we help you?" and enter a description for "What happened?"'
                })
                return
            }

            Client.shared.serverBroker.send('cfh_request', {
                type: reasonController.getValue(),
                reportedUser,
                report
            })

            this.nameField.setValue('')
            this.bodyField.setValue('')
            reasonController.setValue(1)

            Client.shared.userInterface.removeWindow(this.parent)
        })
    }
}
