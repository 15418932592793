import { ESndGrp, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import type { Container } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { Client } from '../../../Client.js'
import { Fonts } from '../../../Fonts.js'
import { BitmapTextButton } from '../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../buttons/BitmapTextButtonType.js'
import { ContentBox } from '../../containers/ContentBox.js'
import { ScrollArea } from '../../containers/ScrollArea.js'
import { DOMText } from '../../DOMText.js'
import { FieldG } from '../../fields/FieldG.js'
import { ScaleField } from '../../fields/ScaleField.js'
import { UISoundLibrary } from '../../UISoundLibrary.js'
import { TextColor } from '../../VMKLText'
import { UIWindowView } from '../UIWindowView.js'
import type { GuestRoomsWindow } from './GuestRoomsWindow.js'
import { RoomField } from './RoomField.js'

export class GuestRoomListView extends UIWindowView<GuestRoomsWindow> {
    private scrollArea: ScrollArea
    private ownerTf: BitmapText
    private descTf: BitmapText
    private currentRoomSelection: RoomField = null
    private roomData: any[] = []
    private rooms: RoomField[]
    private enterButton: BitmapTextButton
    private readonly NAME_FIELD_WIDTH: number = 240
    private readonly NUM_FIELD_WIDTH: number = 72
    private readonly FIELD_X_SPACING: number = 2
    private readonly FIELD_Y_SPACING: number = 3
    private readonly FIELD_HEIGHT: number = 20
    private readonly INACTIVE_TINT: number = 0x265692
    private readonly ACTIVE_TINT: number = 0x028bbd
    private roomsContainer: Container
    private searchInput: DOMText

    constructor() {
        super()

        this.rooms = []

        this.searchInput = this.addChild(
            new DOMText({
                kind: 'field',
                className: '',
                id: 'room-search',
                maxlength: 40,
                fieldWidth: 290,
                fieldHeight: 18,
                fontSize: 16,
                fontColor: TextColor.black,
                initialValue: '',
                bgObject: new FieldG(290, 11),
                padLeft: 7,
                padTop: 2
            })
        )
        const searchBtn = this.addChild(new BitmapTextButton(96, 'SEARCH', BitmapTextButtonType.GOLD))
        searchBtn.position.set(this.searchInput.x + this.searchInput.width + 5, 0)
        searchBtn.addEventListener('pointertap', () => this.enumerateRooms())

        this.roomsContainer = Pooler.newContainer()

        this.scrollArea = new ScrollArea(385, 165, this.roomsContainer, 5, 5)
        this.scrollArea.x = 0
        this.scrollArea.y = searchBtn.position.y + 23 + 5
        this.addChild(this.scrollArea)

        const infoBox = new ContentBox(403, 155)
        infoBox.x = 0
        infoBox.y = this.scrollArea.y + this.scrollArea.height + 5
        this.addChild(infoBox)

        const ownerHead = new BitmapText('OWNER:', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        ownerHead.tint = 0xffffff
        ownerHead.x = 10
        ownerHead.y = 10
        infoBox.addChild(ownerHead)

        this.ownerTf = new BitmapText('', {
            ...Fonts.Foxley_16,
            wordWrapWidth: 325
        })
        this.ownerTf.tint = 0xffffff
        this.ownerTf.x = 57
        this.ownerTf.y = 7

        infoBox.addChild(this.ownerTf)

        const descHead = new BitmapText('DESCRIPTION:', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        descHead.tint = 0xffffff
        descHead.x = 10
        descHead.y = 29
        infoBox.addChild(descHead)

        this.descTf = new BitmapText('', {
            ...Fonts.Foxley_16,
            wordWrapWidth: 325
        })
        this.descTf.tint = 0xffffff
        this.descTf.x = 85
        this.descTf.y = 27

        infoBox.addChild(this.descTf)

        this.enterButton = new BitmapTextButton(120, 'ENTER ROOM', 'h')
        this.enterButton.x = 10
        this.enterButton.y = 140
        infoBox.addChild(this.enterButton)
        this.enterButton.addEventListener('pointerup', () => {
            if (this.currentRoomSelection !== null) {
                SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.MapRoomSelection)
                SoundManager.shared.stopAll(ESndGrp.Music)
                Client.shared.serverBroker.requestRoom(+this.currentRoomSelection.roomId)
            } else {
                console.error('Tried to enter room without room selection')
            }
        })
        this.enterButton.eventMode = 'auto'
        this.enterButton.alpha = 0.5
    }

    setRooms(
        rooms: {
            id: number
            roomName: string
            roomOwner: string
            roomDescription: string
            own: boolean
            peopleIn: number
            queue: number
            event: boolean
        }[]
    ): void {
        this.roomData = rooms

        this.enumerateRooms()
    }

    enumerateRooms(): void {
        this.roomsContainer.removeChildren()

        const h1 = new ScaleField(
            this.NAME_FIELD_WIDTH,
            this.FIELD_HEIGHT,
            this.INACTIVE_TINT,
            'Room Name',
            true,
            0xffffff,
            1,
            false
        )
        const h2 = new ScaleField(
            this.NUM_FIELD_WIDTH,
            this.FIELD_HEIGHT,
            this.INACTIVE_TINT,
            'People In',
            true,
            0xffffff,
            1,
            true
        )
        h2.x = this.NAME_FIELD_WIDTH + this.FIELD_X_SPACING
        const h3 = new ScaleField(
            this.NUM_FIELD_WIDTH,
            this.FIELD_HEIGHT,
            this.INACTIVE_TINT,
            'Queue',
            true,
            0xffffff,
            1,
            false
        )
        h3.x = this.NAME_FIELD_WIDTH + this.NUM_FIELD_WIDTH + 2 * this.FIELD_X_SPACING // 295;
        this.roomsContainer.addChild(h1, h2, h3)

        const filter = this.searchInput.getValue().trim().toLowerCase()

        const rooms = filter ? this.roomData.filter((r) => r.roomName.toLowerCase().includes(filter)) : this.roomData

        // Sort to place events at the top
        for (let i = 0; i < rooms.length; i++) {
            const room = rooms[i]
            if (room.event === true) {
                rooms.unshift(rooms.splice(i, 1)[0])
            }
        }

        rooms.forEach((room, index: number) => {
            const f1 = new ScaleField(
                this.NAME_FIELD_WIDTH,
                this.FIELD_HEIGHT,
                this.INACTIVE_TINT,
                room.roomName,
                false,
                0xffffff,
                1,
                false
            )
            const f2 = new ScaleField(
                this.NUM_FIELD_WIDTH,
                this.FIELD_HEIGHT,
                this.INACTIVE_TINT,
                room.peopleIn.toString(),
                false,
                0xffffff,
                1,
                true
            )
            f2.x = this.NAME_FIELD_WIDTH + this.FIELD_X_SPACING
            const f3 = new ScaleField(
                this.NUM_FIELD_WIDTH,
                this.FIELD_HEIGHT,
                this.INACTIVE_TINT,
                room.queue.toString() || '-',
                false,
                0xffffff,
                1,
                true
            )
            f3.x = this.NAME_FIELD_WIDTH + this.NUM_FIELD_WIDTH + 2 * this.FIELD_X_SPACING // 295;
            f1.y = f2.y = f3.y = (this.FIELD_HEIGHT + this.FIELD_Y_SPACING) * (index + 1)
            if (room.event === true) {
                const mikki = Pooler.newSprite('eventroom_icon')
                f1.addChild(mikki)
                mikki.x = this.NAME_FIELD_WIDTH - mikki.width - 5
            }
            const field = new RoomField(
                +room.id,
                f1,
                f2,
                f3,
                room.roomName,
                room.roomOwner,
                room.roomDescription,
                room.event,
                room.own
            )
            this.roomsContainer.addChild(f1, f2, f3)

            this.rooms.push(field)
        })

        this.scrollArea.resetScroll()

        // Add tap listeners
        for (const field of this.rooms) {
            field.nameField.eventMode = field.peopleField.eventMode = field.queueField.eventMode = 'static'
            field.nameField.cursor = field.peopleField.cursor = field.queueField.cursor = 'pointer'
            field.nameField.addEventListener('pointerup', () => {
                this.selectField(field)
            })
            field.peopleField.addEventListener('pointerup', () => {
                this.selectField(field)
            })
            field.queueField.addEventListener('pointerup', () => {
                this.selectField(field)
            })
        }
    }

    private selectField(field: RoomField): void {
        if (field.getLastTap() && new Date().getTime() - field.getLastTap().getTime() < 500) {
            if (this.currentRoomSelection !== null) {
                SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.MapRoomSelection)
                SoundManager.shared.stopAll(ESndGrp.Music)
                Client.shared.serverBroker.requestRoom(+this.currentRoomSelection.roomId)
            } else {
                console.error('Tried to enter room without room selection')
            }
            return
        }
        field.setLastTap(new Date())

        // Deselect all others
        this.rooms.forEach((f) => {
            f.nameField.setTint(this.INACTIVE_TINT)
            f.peopleField.setTint(this.INACTIVE_TINT)
            f.queueField.setTint(this.INACTIVE_TINT)
        })

        // Set active field
        field.nameField.setTint(this.ACTIVE_TINT)
        field.peopleField.setTint(this.ACTIVE_TINT)
        field.queueField.setTint(this.ACTIVE_TINT)
        this.currentRoomSelection = field
        this.ownerTf.text = field.owner ?? ''
        this.descTf.text = field.description ?? ''

        if (this.enterButton.eventMode !== 'static') {
            this.enterButton.alpha = 1
            this.enterButton.eventMode = 'static'
        }
    }

    update(): void {
        //
    }
}
