import { Pooler } from '@vmk-legacy/render-utils'
import type { FederatedEvent } from 'pixi.js'
import { BitmapText, Container, Graphics, Rectangle } from 'pixi.js'
import { Constants } from '../../Constants.js'
import { Fonts } from '../../Fonts.js'

export class DropDown extends Container {
    private mainElement: Container
    private mainBg: Graphics
    private selectedText: BitmapText
    private chosenVal: any
    private dropdownElement?: Container

    static expandedMenu?: DropDown

    private expanded = false

    constructor(
        private options: {
            label: string
            value: any
        }[] = [],
        unselected = '(choose)',
        preselect?: any
    ) {
        super()

        this.eventMode = 'static'

        this.mainElement = this.addChild(Pooler.newContainer())
        this.mainElement.cursor = 'pointer'
        this.mainBg = this.mainElement.addChild(new Graphics())
        this.selectedText = this.mainElement.addChild(new BitmapText(unselected, Fonts.Foxley_16))
        this.selectedText.position.set(4, 2)
        this.updateBg()
        this.mainElement.eventMode = 'static'
        this.mainElement.addEventListener('pointertap', this.toggleExpand)

        if (preselect) {
            this.setValue(preselect)
        }
    }

    updateBg(): void {
        this.mainBg.clear()
        this.mainBg.beginFill(Constants.UI_LOADER_BAR_COLOR)
        this.mainBg.drawRoundedRect(0, 0, this.selectedText.width + 8, this.selectedText.height + 4, 5)
        this.mainBg.endFill()
    }

    toggleExpand = (): void => {
        if (this.expanded) {
            this.expanded = false
            if (DropDown.expandedMenu === this) {
                DropDown.expandedMenu = null
            }

            if (this.dropdownElement) {
                this.removeChild(this.dropdownElement)
                this.dropdownElement.destroy()
                this.dropdownElement = null
            }
        } else {
            if (DropDown.expandedMenu !== this) {
                if (DropDown.expandedMenu?.expanded) {
                    DropDown.expandedMenu.toggleExpand()
                }
                DropDown.expandedMenu = this
            }
            this.expanded = true
            this.dropdownElement = this.addChild(Pooler.newContainer())
            this.dropdownElement.position.set(0, this.mainElement.height + 2)

            const bg = this.dropdownElement.addChild(new Graphics())
            let y = 0
            let maxWidth = 0
            let maxHeight = 0

            const optEls = []

            for (const opt of this.options) {
                const c = this.dropdownElement.addChild(new DDMenuOption(opt.label))
                c.addEventListener(
                    'pointerup',
                    (e: FederatedEvent) => {
                        e.stopPropagation()
                        this.setValue(opt.value)
                    },
                    { once: true }
                )
                c.position.y = y
                optEls.push(c)
                const height = (c.hitArea as Rectangle).height
                const width = (c.hitArea as Rectangle).width
                if (height > maxHeight) {
                    maxHeight = height
                }
                if (width > maxWidth) {
                    maxWidth = width
                }
                y += height
            }
            optEls.forEach((o) => (o.hitArea.width = maxWidth))
            bg.beginFill(Constants.TOOLBAR_BG_COLOR)
            bg.drawRoundedRect(0, 0, maxWidth, y + 2, 5)
            bg.endFill()
        }
    }

    getValue() {
        return this.chosenVal
    }

    setValue(value: any, propagate = true): void {
        if (this.chosenVal !== value) {
            this.chosenVal = value

            const opt = this.options.find((o) => o.value === value)

            if (opt) {
                this.selectedText.text = opt.label
                this.updateBg()

                if (propagate) {
                    this.emit('value-changed', value)
                }
            }
        }

        if (this.expanded) {
            this.toggleExpand()
        }
    }
}

class DDMenuOption extends Container {
    constructor(label: string) {
        super()
        this.eventMode = 'static'
        this.cursor = 'pointer'

        const text = new BitmapText(label, { ...Fonts.Foxley_16 })
        text.eventMode = 'auto'
        text.position.set(4, 2)
        this.addChild(text)

        this.addEventListener('pointerover', () => (text.tint = Constants.UI_OK_BTN_COLOR))
        this.addEventListener('pointerout', () => (text.tint = 0xffffff))

        this.hitArea = new Rectangle(0, 0, text.width + 8, text.height + 4)
    }
}
