import type { ItemDefUid, ItemOwnedTuple } from '@vmk-legacy/common-ts'
import { EItemType } from '@vmk-legacy/common-ts'
import { AvatarOutfit, ESndGrp, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import type { Container, DestroyOptions, FederatedEvent } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { Client } from '../../../Client.js'
import { Constants } from '../../../Constants.js'
import { DummyItem } from '../../../data/ItemStack.js'
import { EWindow } from '../../../enums.js'
import { Fonts } from '../../../Fonts.js'
import { AvatarVisual } from '../../../room/entities/AvatarVisual.js'
import type { TradeUpdatePayload } from '../../../server/messages/TradeBeginModule.js'
import { Helpers } from '../../../util/Helpers.js'
import { Validator } from '../../../util/Validator.js'
import { BitmapTextButton } from '../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../buttons/BitmapTextButtonType.js'
import { CheckBox } from '../../buttons/CheckBox.js'
import { ImageButton } from '../../buttons/ImageButton.js'
import { ScrollArea } from '../../containers/ScrollArea.js'
import { DOMText } from '../../DOMText.js'
import { FieldG } from '../../fields/FieldG.js'
import { PagedThumbnailBox } from '../../thumbs/PagedThumbnailBox.js'
import { UISoundLibrary } from '../../UISoundLibrary.js'
import { TextColor, VMKLText } from '../../VMKLText'
import { UIWindow } from '../UIWindow.js'
import { UIWindowTab } from '../UIWindowTab.js'
import { UIWindowType } from '../UIWindowType.js'
import type { UIWindowView } from '../UIWindowView.js'
import { TradeView } from './views/TradeView.js'

export class TradeWindow extends UIWindow {
    readonly kind = EWindow.Trading

    // id of partner trading with
    private ign: string
    private chatTf: DOMText

    isDraggable: boolean
    exitBtn: ImageButton
    isFocused = true

    removedInventoryItems: Map<number, DummyItem>

    private furnishingsView: TradeView
    private pinsView: TradeView
    private clothingView: TradeView
    private postersView: TradeView

    private chatText: VMKLText
    private chatScrollArea: ScrollArea

    private youOfferText: BitmapText
    private theyOfferText: BitmapText

    yourOfferContainer: PagedThumbnailBox
    theirOfferContainer: PagedThumbnailBox
    yourOfferController: Container
    theirOfferController: Container
    theirOffer: DummyItem[] = []
    yourOffer: DummyItem[] = []

    yourCheckbox: CheckBox
    theirCheckbox: CheckBox

    stopBtn: BitmapTextButton

    static readonly DEFAULT_HEIGHT: number = 438
    private partnerAvatar: AvatarVisual
    private myAvatar: AvatarVisual

    constructor() {
        super(TradeWindow.DEFAULT_HEIGHT, UIWindowType.TRADE)

        this.x = 141
        this.y = 45

        this.isDraggable = true

        this.removedInventoryItems = new Map<number, DummyItem>()
    }

    reset() {
        this.clothingView.reset()
        this.postersView.reset()
        this.furnishingsView.reset()
        this.pinsView.reset()
    }

    override async windowWasBuilt(): Promise<void> {
        this.exitBtn = new ImageButton('button.close.active', 'button.close.pressed')
        this.addChild(this.exitBtn)
        this.exitBtn.x = this.header.x + this.header.width - 18
        this.exitBtn.y = this.header.y + 2

        // -=-=-=-=- Begin Views & Tabs -=-=-=-=- //
        this.furnishingsView = new TradeView(this, EItemType.Furniture)
        const furnishingsTab = new UIWindowTab('Furnishings', this.furnishingsView)
        this.addChild(furnishingsTab)

        this.pinsView = new TradeView(this, EItemType.Pin)
        const pinsTab = new UIWindowTab('Pins', this.pinsView)
        this.addChild(pinsTab)

        this.clothingView = new TradeView(this, EItemType.Clothing)
        const clothingTab = new UIWindowTab('Clothing', this.clothingView)
        this.addChild(clothingTab)
        this.postersView = new TradeView(this, EItemType.Poster)
        const postersTab = new UIWindowTab('Posters', this.postersView)
        this.addChild(postersTab)

        this.views.push(this.furnishingsView, this.pinsView, this.clothingView, this.postersView)
        this.tabs.push(furnishingsTab, pinsTab, clothingTab, postersTab)
        // -=-=-=-=- End Views & Tabs -=-=-=-=- //

        const OFFER_Y = 245

        this.youOfferText = new BitmapText('You offer:', {
            ...Fonts.Foxley_16
        })
        this.youOfferText.x = 100
        this.youOfferText.y = OFFER_Y
        this.addChild(this.youOfferText)

        this.theyOfferText = new BitmapText('', {
            ...Fonts.Foxley_16
        })
        this.theyOfferText.y = OFFER_Y
        this.addChild(this.theyOfferText)

        // Your offer
        this.yourOfferContainer = new PagedThumbnailBox({
            rows: 2,
            cols: 4,
            items: [],
            spacing: 2,
            onItemTap: (item: DummyItem, preview: Container) => {
                // on tap
                this.yourOfferContainer.setSelected(item)
                this.theirOfferContainer.setSelected(null)
                ;(this.getCurrentView() as TradeView).getThumbContainer().setSelected(null)
                const tradeView = this.activeTab.view as TradeView
                tradeView.setPreviewImage(preview)

                let itemName = item.getName()
                if (item.stars > 0) {
                    itemName += ` (${item.stars} star${item.stars === 1 ? '' : 's'})`
                }
                tradeView.setItemName(itemName)
            },
            onItemDoubleTap: (item: DummyItem) => {
                // on double tap
                Client.shared.serverBroker.send('trade_offer_remove', item.id)
            },
            pageTurnSpacing: 64,
            pageTurnFormat: PagedThumbnailBox.FORMAT_BLANK
        })
        this.yourOfferContainer.position.set(61, 263)
        this.addChild(this.yourOfferContainer)
        this.yourOfferController = this.yourOfferContainer.getControllerContainer()
        this.yourOfferController.angle = 90
        this.yourOfferController.position.set(255, 263)
        this.addChild(this.yourOfferController)

        // Their offer
        this.theirOfferContainer = new PagedThumbnailBox({
            rows: 2,
            cols: 4,
            items: [],
            spacing: 2,
            onItemTap: (item: DummyItem, preview: Container) => {
                // on tap
                this.yourOfferContainer.setSelected(null)
                this.theirOfferContainer.setSelected(item)
                ;(this.getCurrentView() as TradeView).getThumbContainer().setSelected(null)
                const tradeView = this.activeTab.view as TradeView
                tradeView.setPreviewImage(preview)

                let itemName = item.getName()
                if (item.stars > 0) {
                    itemName += ` (${item.stars} star${item.stars === 1 ? '' : 's'})`
                }
                tradeView.setItemName(itemName)
            },
            onItemDoubleTap: null,
            pageTurnSpacing: 64,
            pageTurnFormat: PagedThumbnailBox.FORMAT_BLANK
        })
        this.theirOfferContainer.position.set(this.yourOfferContainer.x + 201, 263)
        this.addChild(this.theirOfferContainer)
        this.theirOfferController = this.theirOfferContainer.getControllerContainer()
        this.theirOfferController.angle = 90
        this.theirOfferController.position.set(456, 263)
        this.addChild(this.theirOfferController)

        this.chatText = new VMKLText({
            wordWrapWidth: 420,
            whiteSpace: 'normal'
        })
        this.chatScrollArea = new ScrollArea(425, 70, this.chatText)
        this.chatScrollArea.x = 32
        this.chatScrollArea.y = OFFER_Y + 137
        this.addChild(this.chatScrollArea)

        const sayText = new BitmapText('SAY', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        sayText.x = 32
        sayText.y = OFFER_Y + 233
        this.addChild(sayText)

        this.stopBtn = new BitmapTextButton(102, 'STOP TRADING', BitmapTextButtonType.RED)
        this.stopBtn.x = 380
        this.stopBtn.y = OFFER_Y + 228
        this.addChild(this.stopBtn)

        this.chatTf = new DOMText({
            kind: 'field',
            className: 'messenger',
            id: 'trade.chat',
            maxlength: 128,
            fieldWidth: 300,
            fieldHeight: 20,
            fontSize: 16,
            fontColor: TextColor.black,
            initialValue: '',
            bgObject: new FieldG(305, 6),
            padLeft: 5,
            padTop: -3
        })
        this.chatTf.x = 58
        this.chatTf.y = OFFER_Y + 230
        this.addChild(this.chatTf)

        this.chatTf.setSubmitHandler(() => {
            const input: string = this.chatTf.getValue()

            if (input.trim().length === 0 || !Validator.isSupportedChar(input)) {
                return
            }

            // Chat
            Client.shared.serverBroker.send('trade_chat', {
                message: input.toString()
            })
            this.chatTf.setValue('')
        })

        // loop over tabs... add event listeners, interactive(Children), set positions
        let currentX = 0
        this.tabs.forEach((t) => {
            t.view.x = 33
            t.view.y = 85
            this.addChild(t.view)
            t.eventMode = 'static'
            t.interactiveChildren = true
            t.cursor = 'pointer'

            t.y = 44 // static
            t.x = currentX + 28
            currentX += t.getWidth()

            t.setActive(false)

            t.addEventListener('pointerup', (e: FederatedEvent) => {
                SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.PopupTabChange)
                this.setActiveTab(t)
            })
        })

        this.yourCheckbox = new CheckBox('youAccept', 'You accept', false, false)
        this.yourCheckbox.x = 85
        this.yourCheckbox.y = 358
        this.addChild(this.yourCheckbox)
        this.yourCheckbox.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('trade_accept_change', {
                accept: !this.yourCheckbox.getActive()
            })
        })

        this.theirCheckbox = new CheckBox('theyAccept', this.ign + ' accepts', false, false)
        this.theirCheckbox.x = 282
        this.theirCheckbox.y = 358
        this.addChild(this.theirCheckbox)
        this.theirCheckbox.eventMode = 'auto'

        // set default tab as active
        this.setActiveTab(furnishingsTab)

        this.center()

        Client.shared.serverBroker.onEvent('trade_state', (payload: any) => {
            this.onTradeUpdate(payload)
        })

        Client.shared.serverBroker.onEvent('trade_added_self', (data: ItemOwnedTuple) => {
            const item = new DummyItem(...data)
            this.yourOffer.push(item)
            this.yourOfferContainer.addItems(item)
        })

        Client.shared.serverBroker.onEvent('trade_added_partner', (data: ItemOwnedTuple) => {
            const item = new DummyItem(...data)
            this.theirOffer.push(item)
            this.theirOfferContainer.addItems(item)
        })

        Client.shared.serverBroker.onEvent('trade_remove_self', (itemId: number) => {
            const index = this.yourOffer.findIndex((i) => i.id === itemId)
            if (index !== -1) {
                const [item] = this.yourOffer.splice(index, 1)

                this.yourOfferContainer.removeStack(item)
            }
        })

        Client.shared.serverBroker.onEvent('trade_remove_partner', (itemId: number) => {
            const index = this.theirOffer.findIndex((i) => i.id === itemId)
            if (index !== -1) {
                const [item] = this.theirOffer.splice(index, 1)

                this.theirOfferContainer.removeStack(item)
            }
        })

        Client.shared.serverBroker.onceEvent('trade_quit', () => {
            Client.shared.userInterface.removeWindow(this)
        })

        this.exitBtn.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('trade_quit')
        })

        this.stopBtn.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('trade_quit')
        })

        const myAvatar = new AvatarVisual(this.provider, 3)
        myAvatar.setUpdatedOutfit(Client.shared.selfRecord.getOutfit())
        myAvatar.x = 35
        myAvatar.y = 225
        myAvatar.pivot.y = -130
        this.addChild(myAvatar)
        this.myAvatar = myAvatar

        const partnerAvatar = new AvatarVisual(this.provider, 5)
        partnerAvatar.x = this.width - 33
        partnerAvatar.pivot.y = -130
        partnerAvatar.y = 225
        this.addChild(partnerAvatar)
        this.partnerAvatar = partnerAvatar

        this.sizeDidChange()

        this.on('added', () => {
            this.myAvatar.wave()
            this.partnerAvatar.wave()

            Client.shared.slowTicker.add(this.myAvatar.update, this as any)
            Client.shared.slowTicker.add(this.partnerAvatar.update, this as any)

            this.chatTf.refit(Client.shared.size)

            Helpers.delay(2000).then(() => {
                Client.shared.slowTicker.remove(this.myAvatar.update, this as any)
                Client.shared.slowTicker.remove(this.partnerAvatar.update, this as any)
            })
        })
    }

    override destroy(_options?: DestroyOptions | boolean): void {
        super.destroy(_options)

        Client.shared.userInterface.toolbar.inventoryBtn.enable()
        Client.shared.userInterface.toolbar.shopBtn.enable()
        Client.shared.userInterface.toolbar.avatarBtn.enable()
        Client.shared.userInterface.toolbar.questBtn.enable()

        Client.shared.serverBroker.offEvent('trade_added_self')
        Client.shared.serverBroker.offEvent('trade_added_partner')
        Client.shared.serverBroker.offEvent('trade_remove_self')
        Client.shared.serverBroker.offEvent('trade_remove_partner')
        Client.shared.serverBroker.offEvent('trade_state')
        Client.shared.serverBroker.offEvent('trade_quit')

        Client.shared.slowTicker.remove(this.myAvatar.update, this as any)
        Client.shared.slowTicker.remove(this.partnerAvatar.update, this as any)
    }

    onTradeUpdate(payload: TradeUpdatePayload): void {
        if ('ign' in payload) {
            this.setPartnerIgn(payload.ign)
        }
        if ('outfit' in payload) {
            this.setPartnerOutfit(payload.outfit)
        }
        if ('youAccept' in payload) {
            this.yourCheckbox.setActive(payload.youAccept)
        }
        if ('theyAccept' in payload) {
            this.theirCheckbox.setActive(payload.theyAccept)
        }
        if ('chat' in payload) {
            if (this.chatText.text !== payload.chat) {
                this.chatText.text = payload.chat
                this.chatScrollArea.setScroll(1)
            }
        }
        if ('myOffer' in payload) {
            const myDummyItems = payload.myOffer.map((i) => new DummyItem(...i))
            this.yourOffer.push(...myDummyItems)
            this.yourOfferContainer.addItems(...myDummyItems)
        }
        if ('theirOffer' in payload) {
            const theirDummyItems = payload.theirOffer.map((i) => new DummyItem(...i))
            this.theirOffer.push(...theirDummyItems)
            this.theirOfferContainer.addItems(...theirDummyItems)
        }
    }

    setPartnerIgn(ign: string): void {
        this.ign = ign
        this.theyOfferText.text = ign + ' offers:'
        this.theyOfferText.x = 250 + Math.round((172 - this.theyOfferText.width) / 2)
        if (ign.length > 12) {
            ign = 'Partner'
        }
        this.theirCheckbox.setText(ign + ' accepts')
    }

    setPartnerOutfit(outfit: (ItemDefUid | number)[]): void {
        this.waitToBeBuilt().then(() => {
            if (outfit) {
                this.partnerAvatar?.setUpdatedOutfit(AvatarOutfit.from(outfit))
            }
        })
    }

    center(): void {
        this.x = Math.round((Constants.SIZE[0] - this.width) / 2)
        this.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }

    getTheirOfferContainer(): PagedThumbnailBox {
        return this.theirOfferContainer
    }

    getYourOfferContainer(): PagedThumbnailBox {
        return this.yourOfferContainer
    }

    setVisible(visible: boolean) {
        super.setVisible(visible)
        if (visible) {
            Client.shared.userInterface.bringToFront(this)
            Client.shared.userInterface.toolbar.inventoryBtn.disable()
            Client.shared.userInterface.toolbar.shopBtn.disable()
            Client.shared.userInterface.toolbar.avatarBtn.disable()
            Client.shared.userInterface.toolbar.questBtn.disable()
        } else {
            Client.shared.userInterface.toolbar.inventoryBtn.enable()
            Client.shared.userInterface.toolbar.shopBtn.enable()
            Client.shared.userInterface.toolbar.avatarBtn.enable()
            Client.shared.userInterface.toolbar.questBtn.enable()
        }
        return this
    }

    getCurrentView(): UIWindowView {
        return this.activeTab.view as UIWindowView
    }
}
