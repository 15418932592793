import { Pooler } from '@vmk-legacy/render-utils'
import { AnimatedSprite, Container, Graphics, Texture } from 'pixi.js'
import type { TextKey } from '../../assets/ExternalConfigManager.js'
import { getText } from '../../assets/ExternalConfigManager.js'
import { Client } from '../../Client.js'
import { Constants } from '../../Constants.js'
import { EWindow, UILayer } from '../../enums.js'
import { PixiTextType, TextColor, VMKLFont, VMKLText } from '../VMKLText'
import type { IWindow } from '../windows/IWindow.js'

const WIDTH = 365
const HEIGHT = 140
const ANIMATION_SPEED = 0.18
const LOADING_BAR_PADDING_TOP = 28
const LOADING_BAR_PADDING_BOTTOM = 3

export class IndeterminateLoadingView extends Container implements IWindow {
    private statusText: VMKLText
    private waitText: VMKLText

    constructor(status: TextKey, wait: TextKey) {
        super()

        const g = new Graphics()
        g.roundRect(0, 0, WIDTH, HEIGHT, Constants.UI_BOX_CORNER_RADIUS)
        g.fill(Constants.UI_POPUP_BOX_BORDER_COLOR)
        g.roundRect(
            Constants.UI_BOX_BORDER_WIDTH,
            Constants.UI_BOX_BORDER_WIDTH,
            WIDTH - 2 * Constants.UI_BOX_BORDER_WIDTH,
            HEIGHT - 2 * Constants.UI_BOX_BORDER_WIDTH,
            Constants.UI_BOX_CORNER_RADIUS
        )
        g.fill(Constants.UI_POPUP_BOX_BG_COLOR)

        this.addChild(g)

        const loadingBarFrames: Texture[] = []
        for (let i = 1; i < 5; i++) {
            loadingBarFrames.push(Texture.from('Preloaqdingbar_anim_' + i))
        }
        const loadingAnim = new AnimatedSprite(loadingBarFrames)
        loadingAnim.x = Math.floor((WIDTH - loadingAnim.width) / 2)
        loadingAnim.y = Math.floor((HEIGHT - loadingAnim.height) / 2) - 8
        loadingAnim.animationSpeed = ANIMATION_SPEED
        loadingAnim.play()

        this.addChild(loadingAnim)

        const symbol = Pooler.newSprite('vmk_symbol')
        symbol.width = 37
        symbol.height = 34
        symbol.position.set(312, 92)
        this.addChild(symbol)

        this.statusText = new VMKLText({
            text: getText(status),
            type: PixiTextType.bitmapText,
            font: VMKLFont.foxley,
            bold: true,
            color: TextColor.white,
            size: 16
        })

        this.statusText.x = Math.floor((WIDTH - this.statusText.width) / 2)
        this.statusText.y =
            Math.floor(HEIGHT / 2 - loadingAnim.height / 2 - this.statusText.height) - LOADING_BAR_PADDING_TOP
        this.addChild(this.statusText)

        this.waitText = new VMKLText({
            text: getText(wait),
            type: PixiTextType.bitmapText,
            font: VMKLFont.foxley,
            color: TextColor.white,
            size: 16
        })
        this.waitText.x = Math.floor((WIDTH - this.waitText.width) / 2)
        this.waitText.y =
            Math.floor(HEIGHT / 2 - loadingAnim.height / 2 + this.waitText.height) + LOADING_BAR_PADDING_BOTTOM
        this.addChild(this.waitText)

        this.eventMode = 'static'

        this.refit()
    }

    refit() {
        this.x = Math.floor((Client.shared.size.width - this.width) / 2)
        this.y = Math.floor((Client.shared.size.height - this.height) / 2)
    }

    kind = EWindow.Other
    layer = UILayer.Loaders

    getWindowWidth(): number {
        return this.width
    }

    getWindowHeight(): number {
        return this.height
    }

    setVisible(visible: boolean): void {
        this.visible = visible
    }

    reset(): void {
        //
    }

    setStatusText(status: TextKey): void {
        this.statusText.text = getText(status)
        this.statusText.x = Math.floor((WIDTH - this.statusText.width) / 2)
    }

    setWaitText(wait: TextKey): void {
        this.waitText.text = getText(wait)
        this.waitText.x = Math.floor((WIDTH - this.waitText.width) / 2)
    }
}
