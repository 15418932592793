import { Pooler } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { BitmapText, Container, Texture } from 'pixi.js'
import { Client } from '../../../../../Client.js'
import { Constants } from '../../../../../Constants.js'
import { EWindow, UILayer } from '../../../../../enums.js'
import { Fonts } from '../../../../../Fonts.js'
import { BitmapTextButton } from '../../../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../../../buttons/BitmapTextButtonType.js'
import { ImageButton } from '../../../../buttons/ImageButton.js'
import { BasicBox } from '../../../../containers/BasicBox.js'
import { FieldH } from '../../../../fields/FieldH.js'
import type { IWindow } from '../../../IWindow.js'

export class MKBadgesPopup extends Container implements IWindow {
    readonly kind = EWindow.Other
    layer = UILayer.AlwaysOnTop
    header: Sprite
    isDraggable = true
    exitBtn: ImageButton

    possibleBadgeIds: number[] = [1, 3, 4, 5, 6, 7, 8]
    possibleBadgeMap: Map<number, Sprite>

    container: Container
    private title: string

    private totalWidth: number
    private totalHeight: number

    private currentBadgeIds: number[]
    private currentBadgeSprites: Sprite[]

    private containerContainer: Container

    constructor(id: number, ign: string, initBadges: number[]) {
        super()

        this.currentBadgeIds = initBadges

        this.title = `Edit ${ign}'s Badges`

        this.exitBtn = new ImageButton('button.closeb.active', 'button.closeb.pressed')

        this.containerContainer = Pooler.newContainer()
        this.container = Pooler.newContainer()

        this.header = Pooler.newSprite(Texture.EMPTY)

        const bg: BasicBox = new BasicBox(286, 134)
        this.container.addChild(bg)

        const titleBg: FieldH = new FieldH(279)

        this.container.addChild(titleBg)
        titleBg.x = 9
        titleBg.y = 13

        this.eventMode = 'static'

        const titleText = new BitmapText(this.title || '', {
            ...Fonts.FoxleyBold_16,
            align: 'left'
        })
        titleText.y = 14
        const titlewidth: number = titleText.width
        titleText.x = Math.round((bg.width - titlewidth) / 2)
        this.container.addChild(titleText)

        for (let i = 0; i < 2; i++) {
            const bg1 = Pooler.newSprite(Texture.from('thumbs.grid.b'))
            bg1.width = bg1.height = 32
            bg1.position.set(110 + i * 38, 40)
            this.container.addChild(bg1)
        }

        this.possibleBadgeMap = new Map<number, Sprite>()
        const offX: number = Math.round((307 - (36 * this.possibleBadgeIds.length - 20)) / 2) - 6
        for (let i = 0; i < this.possibleBadgeIds.length; i++) {
            const badgePreview = Pooler.newSprite(Texture.from(`badge_${this.possibleBadgeIds[i]}`))
            badgePreview.position.set(offX + 36 * i, 82)
            this.container.addChild(badgePreview)
            this.possibleBadgeMap.set(this.possibleBadgeIds[i], badgePreview)
        }

        const submitBtn = new BitmapTextButton(100, 'SAVE', BitmapTextButtonType.GREEN)
        submitBtn.position.set(43, 125)
        this.container.addChild(submitBtn)
        submitBtn.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('mk_change_badges', {
                id: id,
                badges: this.currentBadgeIds
            })
            Client.shared.userInterface.removeWindow(this)
        })

        const cancelBtn = new BitmapTextButton(100, 'CANCEL', BitmapTextButtonType.RED)
        cancelBtn.position.set(43 + 115, 125)
        this.container.addChild(cancelBtn)
        cancelBtn.addEventListener('pointerup', () => {
            this.currentBadgeIds = initBadges
            this.setBadges(this.currentBadgeIds)
            Client.shared.userInterface.removeWindow(this)
        })

        this.containerContainer.addChild(this.container)
        this.addChild(this.containerContainer)
        this.center()
        Client.shared.userInterface.register(this)

        Client.shared.userInterface.bringToFront(this)

        this.currentBadgeSprites = []
        this.setBadges(this.currentBadgeIds)
    }

    disableBadge(id: number) {
        const badge = this.possibleBadgeMap.get(id)
        if (!badge) {
            return
        }
        badge.alpha = 0.3
        badge.eventMode = 'auto'
    }

    addBadge(id: number) {
        if (this.currentBadgeIds.length >= 2) {
            return
        }
        if (this.currentBadgeIds.includes(id)) {
            return
        }

        this.currentBadgeIds.push(id)
        this.setBadges(this.currentBadgeIds)
    }

    removeBadge(id: number) {
        if (this.currentBadgeIds.length === 0) {
            return
        }
        if (!this.currentBadgeIds.includes(id)) {
            return
        }
        const index = this.currentBadgeIds.indexOf(id)
        this.currentBadgeIds.splice(index, 1)
        this.setBadges(this.currentBadgeIds)
    }

    setBadges(ids: number[]) {
        this.currentBadgeIds = ids

        this.currentBadgeSprites.forEach((sprite) => {
            this.container.removeChild(sprite)
        })

        // enable all badges for selection
        this.possibleBadgeMap.forEach((sprite, id) => {
            sprite.alpha = 1
            sprite.eventMode = 'static'
            sprite.cursor = 'pointer'
            sprite.cursor = 'pointer'

            sprite.addEventListener('pointerup', () => {
                this.addBadge(id)
            })
        })

        for (const id of ids) {
            const i = ids.indexOf(id)
            const sprite = Pooler.newSprite(Texture.from(`badge_${id}`))
            sprite.width = sprite.height = 28
            sprite.position.set(112 + i * 38, 42)
            this.container.addChild(sprite)
            this.disableBadge(id)
            this.currentBadgeSprites.push(sprite)
            sprite.eventMode = 'static'
            sprite.cursor = 'pointer'
            sprite.cursor = 'pointer'
            sprite.addEventListener('pointerup', () => {
                this.removeBadge(id)
            })
        }
    }

    onIgnore() {}

    onOk() {}

    center() {
        this.containerContainer.x = Math.round((Constants.SIZE[0] - this.width) / 2)
        this.containerContainer.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }

    tryRemove() {
        if (this.parent !== null) {
            this.parent.removeChild(this)
        }
    }

    getWidth(): number {
        return this.totalWidth
    }

    getHeight(): number {
        return this.totalHeight
    }

    setVisible(visible: boolean) {
        this.container.visible = visible
        this.containerContainer.visible = visible
        this.visible = visible
    }

    reset() {}
}
