import { EPerm } from '@vmk-legacy/common-ts'
import { ESndGrp, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import { ClientChat } from '@vmk-legacy/server-protos'
import { Filter } from 'bad-words'
import type { Container, FederatedEvent } from 'pixi.js'
import { Texture } from 'pixi.js'
import { getVar } from '../assets/ExternalConfigManager.js'
import { Client } from '../Client.js'
import { Constants } from '../Constants.js'
import { EWindow, UILayer } from '../enums.js'
import { PotcModal } from '../minigame/potc/PotcModal.js'
import { RegistrationView } from '../registration/RegistrationView.js'
import { MagicDefinitions } from '../room/entities/fx/MagicDefinitions.js'
import { WalkableRoomViewer } from '../room/renderer/WalkableRoomViewer.js'
import { Validator } from '../util/Validator.js'
import { View } from './layoutui/View.js'
import { Toolbar } from './Toolbar.js'
import { UISoundLibrary } from './UISoundLibrary.js'
import type { ISizeChanging } from './views/AlertView.js'
import { ResponsiveContainer } from './views/AlertView.js'
import { CameraViewfinder } from './windows/camera/CameraViewfinder.js'
import { CameraWindow } from './windows/camera/CameraWindow.js'
import { PhotoCatalog } from './windows/camera/PhotoCatalog.js'
import { CharacterWindow } from './windows/character/CharacterWindow.js'
import { EmoteWindow } from './windows/EmoteWindow.js'
import { FXWindow } from './windows/FXWindow.js'
import { HelpWindow } from './windows/HelpWindow.js'
import { InventoryWindow } from './windows/inventory/InventoryWindow.js'
import type { IWindow } from './windows/IWindow.js'
import { LanyardBox } from './windows/LanyardBox.js'
import { MessengerWindow } from './windows/messenger/MessengerWindow.js'
import { ModWindow } from './windows/mod/ModWindow.js'
import { RoomInfoBox } from './windows/RoomInfoBox.js'
import { GuestRoomsWindow } from './windows/rooms/GuestRoomsWindow.js'
import { SettingsWindow } from './windows/SettingsWindow.js'
import { ShopWindow } from './windows/shop/ShopWindow.js'
import { TradeWindow } from './windows/trade/TradeWindow.js'
import { UIWindow } from './windows/UIWindow.js'
import { VMKNavigator } from './windows/VMKNavigator.js'

const windowClasses = {
    [EWindow.GuestRooms]: GuestRoomsWindow,
    [EWindow.Inventory]: InventoryWindow,
    [EWindow.Messenger]: MessengerWindow,
    [EWindow.Shopping]: ShopWindow,
    [EWindow.Character]: CharacterWindow,
    [EWindow.Settings]: SettingsWindow,
    [EWindow.Help]: HelpWindow,
    [EWindow.Trading]: TradeWindow,
    [EWindow.RoomInfoBox]: RoomInfoBox,
    [EWindow.Lanyard]: LanyardBox,
    [EWindow.Mod]: ModWindow,
    [EWindow.Emotes]: EmoteWindow,
    [EWindow.Navigator]: VMKNavigator,
    [EWindow.Magic]: FXWindow,
    [EWindow.CamButtons]: CameraWindow,
    [EWindow.CamViewfinder]: CameraViewfinder,
    [EWindow.PhotoCatalog]: PhotoCatalog,
    [EWindow.PiratesModal]: PotcModal
}

type WinKinds = typeof windowClasses
type WindowObject = Container & IWindow & { kind: EWindow }
type WindowLayer = { children: WindowObject[] } & Container

export class UserInterface extends ResponsiveContainer {
    toolbar?: Toolbar

    shownWindows = new Map<keyof WinKinds, WindowObject>()
    layers = new Map<UILayer, WindowLayer>()

    protected static filter = new Filter({ placeHolder: '#' })
    #toolbarLayer: Container
    #delayedClosers = new Map<EWindow, number>()

    #dragData: any
    #lastX: number
    #lastY: number
    #dragX: number
    #dragY: number
    #dragging: Container & IWindow = null

    constructor() {
        super()

        UserInterface.filter.addWords('toot')
        UserInterface.filter.removeWords('willy', 'fart')

        this.zIndex = 10

        this.layers.set(UILayer.GameWindows, this.addChild(Pooler.newContainer()) as WindowLayer)
        this.#toolbarLayer = this.addChild(Pooler.newContainer())
        this.layers.set(UILayer.Loaders, this.addChild(Pooler.newContainer()) as WindowLayer)
        this.layers.set(UILayer.Legacy, this.addChild(Pooler.newContainer()) as WindowLayer)
        this.layers.set(UILayer.AlwaysOnTop, this.addChild(Pooler.newContainer()) as WindowLayer)
    }

    init(): void {
        this.toolbar = new Toolbar()
        this.#toolbarLayer.addChild(this.toolbar)

        if (Client.shared.selfRecord.isStaff()) {
            window.dispatchEvent(new UIEvent('resize'))
        }

        this.toolbar.chatbarInput.getElement().addEventListener('keydown', Client.shared.screenshotHotkey, {
            passive: false
        })
        this.toolbar.chatbarInput.getElement().addEventListener('keydown', this.#chatKeypress)

        Client.shared.stage.eventMode = 'static'
    }

    #chatKeypress = async (e: KeyboardEvent): Promise<void> => {
        if (e.code !== 'Enter' && e.code !== 'NumpadEnter' && e.keyCode !== 13) {
            return
        }
        const chatbar = this.toolbar.chatbarInput
        const chatEl = chatbar.getElement()
        const message = chatbar
            .getValue()
            .trim()
            .replace(/[\u2018\u2019]/g, "'")
            .replace(/[\u201C\u201D]/g, '"')

        if (message.length === 0 || !Validator.isSupportedChar(message)) {
            return
        }
        if (message.startsWith('/about')) {
            const data = `**Renderer:** ${Client.shared.renderer.name}
**Player:** ${Client.shared.selfRecord.getIgn()} #${Client.shared.selfRecord.getId()} 
**Client build:** ${Client.shared.buildHash} 
**Asset build:** ${Client.shared.assetLoader.buildVersion} 
**Window inner size:** ${window.innerWidth}x${window.innerHeight} 
**Device pixel ratio:** ${window.devicePixelRatio} 
**Body client size:** ${document.body.clientWidth}x${document.body.clientHeight} 
**Supports touch:** ${Client.shared.supportsTouch ? 'Yes' : 'No'} 
**Supports mouse:** ${Client.shared.supportsMouse ? 'Yes' : 'No'} 
**Renderer resolution:** ${Client.shared.renderer.resolution} 
**Renderer screen size:** ${Client.shared.renderer.screen.width}x${Client.shared.renderer.screen.height} 
**Renderer stage size:** ${Client.shared.stage.width}x${Client.shared.stage.height} 
**Renderer stage scale:** x - ${Client.shared.stage.scale.x} y - ${Client.shared.stage.scale.y} 
**Renderer stage offset:** ${Client.shared.stage.x}, ${Client.shared.stage.y}`
            chatbar.setValue('')
            prompt('Copy the following text into your bug report to assist with debugging.', data)
            return
        }

        if (
            Client.shared.selfRecord.can(EPerm.RoomsPublicFurni) &&
            Client.shared.roomViewer instanceof WalkableRoomViewer
        ) {
            if (message.startsWith('/furnish')) {
                Client.shared.roomViewer.furniController.setFurniMode(true)
                chatbar.setValue('')

                const inv = await this.getWin(EWindow.Inventory, true)
                if (inv instanceof InventoryWindow) {
                    inv.tabController.setActive('Furnishings')
                }

                return
            }
            if (message.startsWith('/dev')) {
                Client.shared.roomViewer.setDevMode(true)
                return
            }
            if (message.startsWith('/loadfurni')) {
                const input = document.createElement('input')
                input.type = 'file'
                input.onchange = () => {
                    const file = input.files[0]

                    const reader = new FileReader()
                    reader.readAsText(file, 'UTF-8')

                    reader.onload = (readerEvent) => {
                        const content = readerEvent.target.result // this is the content!
                        try {
                            const json = JSON.parse(content)
                            if (
                                typeof json !== 'object' ||
                                !Object.hasOwn(json, 'type') ||
                                json.type !== 'RoomFurniList'
                            ) {
                                Client.shared.helpers.alert('That is an invalid furni save file. Please try again.')
                                return
                            }
                            Client.shared.serverBroker.send('load_furni_save', json)
                        } catch (e) {
                            console.error(e)
                            Client.shared.helpers.alert(
                                'There was an error parsing the file. Make sure it is a supported format.'
                            )
                        }
                    }
                }

                input.click()
                return
            }
            if (message.startsWith('/trackonly')) {
                for (const entity of Client.shared.roomViewer.furniController.entities) {
                    if (!Client.shared.furniMapMgr.isTrack(entity.getItem().defUid)) {
                        for (const p of entity.getParts()) {
                            if (p['temphidden']) {
                                p.setAlpha(1)
                                p.eventMode = p['tempevmode']
                                delete p['temphidden']
                                delete p['tempevmode']
                            } else {
                                p['temphidden'] = true
                                p.setAlpha(0.15)
                                p['tempevmode'] = p.eventMode
                                p.eventMode = 'none'
                            }
                        }
                    }
                }
                return
            }

            if (message.startsWith('/regtest')) {
                Client.shared.regView = new RegistrationView(message.endsWith('rules'))
                await Client.shared.regView.loadAssets()

                Client.shared.regView.setVisible(true)
                Client.shared.userInterface.register(Client.shared.regView)
                Client.shared.loadingView.setVisible(false)

                return
            }
        }
        console.log('>> before profane ' + message)

        if (UserInterface.filter.isProfane(message)) {
            const clean = UserInterface.filter.clean(message)

            if (clean !== message) {
                chatEl.style.color = 'red'
                chatbar.setValue(clean)
                return
            }
        }
        console.log('>> before magic ' + message)

        if (message.endsWith('!')) {
            const magic = MagicDefinitions.getEffectForWord(message)

            if (magic) {
                const lanyard = Client.shared.selfRecord.getLanyard()
                const fxTypes = getVar('fxtypes')

                lanyardMagic: {
                    for (const i of lanyard) {
                        for (const type in fxTypes) {
                            if (type === magic && fxTypes[type].includes(i.defUid)) {
                                Client.shared.serverBroker.send('use_magic', i.id)
                                Client.shared.userInterface.closeWindow(EWindow.Magic)

                                break lanyardMagic
                            }
                        }
                    }
                }
            }
        }

        console.log('>> before emit ' + message)

        Client.shared.serverBroker.send(new ClientChat({ message: message }))

        chatbar.setValue('')
        chatEl.style.color = '#fff'
    }

    showToolbars(): void {
        Client.shared.userInterface.layers.forEach((layer) => (layer.visible = true))
        Client.shared.userInterface.toolbar?.chatbarInput?.show()
        this.toolbar?.show()
    }

    hideToolbars(): void {
        this.toolbar?.hide()
    }

    getObscuringToolbarHeight(): number {
        return this.getObscuringTopHeight() + this.getObscuringBottomHeight()
    }

    getObscuringTopHeight(): number {
        return Client.shared.runningMinigame?.getObscuringTopHeight() ?? 0
    }

    getObscuringBottomHeight(): number {
        return this.toolbar?.height || 0
    }

    override sizeDidChange(size): void {
        console.log('UserInterface size did change')
        this.layers.forEach((layer) => {
            layer.children.forEach((c) => {
                ;(c as ISizeChanging).refit?.(size)
            })
        })
        this.toolbar?.refit(size)
    }

    async showWindow(kind: EWindow): Promise<UIWindow> {
        return this.getWin(kind, true)
    }

    async getWin<WindowKind extends keyof WinKinds>(win: WindowKind | number, showing = false): Promise<UIWindow> {
        win = win as WindowKind
        const closer = this.#delayedClosers.get(win as EWindow)
        if (closer) {
            clearTimeout(closer)
            this.#delayedClosers.delete(win as EWindow)
        }
        let existing = this.shownWindows.get(win)

        if (!existing) {
            if (!showing) {
                return undefined
            }
            const classDef = windowClasses[win] as WinKinds[WindowKind]
            if (!classDef) {
                throw new Error('Tried to get window of kind ' + win + ', but it is not in the class map.')
            }
            existing = new classDef()

            if (existing instanceof UIWindow) {
                await existing._buildWindow()
            } else if (existing instanceof View) {
                existing.putIn(this.layers.get(existing.layer))
            }
            if (existing instanceof View) {
                this.register(existing.view)
            } else {
                this.register(existing)
            }

            if (typeof existing.playsOpenSound === 'undefined' || existing.playsOpenSound) {
                SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.OpenPopup)
            }
        }

        if (showing) {
            this.bringToFront(existing)

            existing.visible = true
        }

        return existing as InstanceType<WinKinds[WindowKind]>
    }

    center(window: UIWindow): void {
        window.position.set((Constants.SIZE[0] - window.width) / 2, (Constants.SIZE[1] - window.height) / 2)
    }

    closeWindows(inLayer: UILayer): void {
        const layer = this.layers.get(inLayer)
        if (layer) {
            layer.removeChildren().forEach((c: WindowObject) => {
                this.shownWindows.delete(c.kind as keyof WinKinds)
                c.destroy({ children: true })
            })
        }
    }

    closeWindow(window: EWindow, playSound = false): void {
        if (this.#delayedClosers.has(window)) {
            return
        }
        const shown = this.shownWindows.get(window as keyof WinKinds)

        if (shown) {
            const closeDelay = window === EWindow.Inventory ? 10000 : 1

            if (playSound) {
                SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.ClosePopup)
            }
            console.log('delaying close of ' + window + ' for ' + closeDelay)
            if (closeDelay > 1) {
                shown.visible = false
            } else {
                this.removeWindow(shown, false)
                return
            }
            const closer = setTimeout(() => this.removeWindow(shown, false), closeDelay)
            this.#delayedClosers.set(window, closer)
        }
    }

    toggleWindow(window: EWindow, playSound = false): void {
        const shown = this.shownWindows.get(window as keyof WinKinds)

        if (shown?.visible) {
            this.removeWindow(shown, playSound)
        } else {
            this.showWindow(window)
        }
    }

    removeWindow(window: WindowObject, playSound = false): void {
        if (window.kind) {
            this.shownWindows.delete(window.kind as keyof WinKinds)
        }
        this.layers.get(window.layer)?.removeChild(window)

        if (window['underlay'] && !window['underlay'].destroyed) {
            window['underlay'].destroy()
        }
        if (!window.destroyed) {
            window.destroy({ children: true })
        }

        if (playSound) {
            SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.ClosePopup)
        }

        if (window.layer) {
            this.focusTop(window.layer)
        }
        const closer = this.#delayedClosers.get(window.kind)
        if (closer) {
            clearTimeout(closer)
            this.#delayedClosers.delete(window.kind)
        }
    }

    register<T extends WindowObject>(window: T, underlay = false): T {
        const layer = this.layers.get(window.layer)
        if (!layer) {
            throw new Error('Cannot register window to invalid UI layer: ' + window.layer)
        }

        window.zIndex = layer.children.length

        if (underlay) {
            const bg = Pooler.newSprite(Texture.WHITE)
            bg.eventMode = 'static'
            bg.tint = 0x000000
            bg.width = Constants.SIZE[0]
            bg.height = Constants.SIZE[1]
            bg.alpha = 0.25
            layer.addChild(bg)
            window['underlay'] = bg
        }

        layer.addChild(window)

        if (window.kind !== EWindow.Other) {
            this.shownWindows.set(window.kind as keyof WinKinds, window)
        }

        window.eventMode = 'static'
        window.addEventListener('pointerdown', () => this.bringToFront(window))

        if (window.isDraggable) {
            window.eventMode = 'static'
            window.interactiveChildren = true
            window.cursor = 'draggable'
            window.addEventListener('pointerdown', this.#onDragStart)
            window.addEventListener('pointerup', this.#onDragEnd)
            window.addEventListener('pointerupoutside', this.#onDragEnd)
        }

        if (window instanceof UIWindow) {
            window.waitToBeBuilt().then(() => {
                window.exitBtn?.addEventListener('pointerup', () => {
                    console.log('window.exitBtn pressed a ')
                    if (window.kind && window.kind !== EWindow.Other) {
                        this.closeWindow(window.kind, true)
                    } else {
                        this.removeWindow(window, true)
                    }
                })
            })
        } else {
            window.exitBtn?.addEventListener('pointerup', () => {
                console.log('window.exitBtn pressed b ')
                this.removeWindow(window, true)
            })
        }

        return window
    }

    #onDragStart = (e: FederatedEvent): void => {
        if (e.target !== e.currentTarget || this.#dragging) {
            return
        }

        this.#dragData = e.data
        this.#lastX = e.data.pageX
        this.#lastY = e.data.pageY
        this.#dragging = e.currentTarget as Container & IWindow
        this.#dragX = this.#dragging.x
        this.#dragY = this.#dragging.y

        this.#dragging.cursor = 'dragging'
        Client.shared.canvas.classList.add('draggable')
        Client.shared.stage.addEventListener('pointermove', this.#onDragMove)
    }

    #onDragEnd = (e: FederatedEvent): void => {
        if (this.#dragging) {
            e.stopPropagation()
            e.originalEvent.stopPropagation()

            this.#dragging.cursor = 'draggable'
            Client.shared.canvas.classList.remove('draggable')

            this.#dragging = null
            this.#dragData = null
            this.#lastX = 0
            this.#lastY = 0
            Client.shared.stage.removeEventListener('pointermove', this.#onDragMove)
        }
    }

    #onDragMove = (e: FederatedEvent): void => {
        if (this.#dragging) {
            // if (!this.#dragging.transform) {
            //     return this.#onDragEnd(e)
            // }
            e.stopPropagation()
            e.originalEvent.stopPropagation()

            this.#dragX += (e.data.pageX - this.#lastX) / Client.shared.stage.scale.x
            this.#dragY += (e.data.pageY - this.#lastY) / Client.shared.stage.scale.y

            if (this.#dragging.dragBounds) {
                if (this.#dragX < 0) {
                    this.#dragX = 0
                } else if (this.#dragX > Constants.SIZE[0] - this.#dragging.width) {
                    this.#dragX = Constants.SIZE[0] - this.#dragging.width
                }
                if (this.#dragY < 0) {
                    this.#dragY = 0
                } else if (this.#dragY > Constants.SIZE[1] - this.#dragging.height) {
                    this.#dragY = Constants.SIZE[1] - this.#dragging.height
                }
            }
            this.#dragging.position.set(Math.floor(this.#dragX), Math.floor(this.#dragY))

            this.#lastX = e.data.pageX
            this.#lastY = e.data.pageY
        }
    }

    /**
     * Call this after removing or changing stack of a window to update z order and call focus on new top window.
     * @param {UILayer} layerNum
     */
    focusTop(layerNum: UILayer): void {
        const layer = this.layers.get(layerNum)
        if (!layer) {
            return
        }

        for (let i = 0; i < layer.children.length; i++) {
            layer.children[i].zIndex = i
        }
    }

    bringToFront(window: WindowObject): boolean {
        const layer = this.layers.get(window.layer)
        if (!layer) {
            return false
        }
        const index = layer.children.indexOf(window)
        if (index === -1) {
            this.register(window)
            return true
        }

        if (index !== layer.children.length - 1) {
            layer.children.splice(index, 1)
            layer.children.push(window)

            this.focusTop(window.layer)

            return true
        }
        return false
    }
}
