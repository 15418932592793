import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import type { RenderTexture, Sprite } from 'pixi.js'
import { Rectangle, Texture } from 'pixi.js'
import { Client } from '../../../Client.js'
import { Constants } from '../../../Constants.js'
import { EWindow } from '../../../enums.js'
import { UISoundLibrary } from '../../UISoundLibrary.js'
import LegacyWindow from '../LegacyWindow.js'
import { SavePhotoWindow } from './SavePhotoWindow.js'
import streamsaver from 'streamsaver'

export class CameraViewfinder extends LegacyWindow {
    override readonly kind = EWindow.CamViewfinder

    constructor() {
        super('vmk_photo_camera')

        this.isDraggable = true
        this.dragBounds = true
    }

    override async windowWasBuilt(): Promise<void> {
        const captureBtn = this.getElement('camera.takepicture.button')
        const filmCount = this.getElement('film')
        const reticule = this.getElement('camera.reticule')
        const picture = this.getElement('camera.picture') as Sprite

        picture.texture = Texture.EMPTY

        this.setField('film', `${Client.shared.selfRecord.getFilm()}`)

        if (Client.shared.selfRecord.getFilm()) {
            this.hide('camera.outoffilm')
            captureBtn.addEventListener('pointertap', () => this.takePicture())
        } else {
            this.hide('camera.takepicture.button')
        }

        Client.shared.userInterface.closeWindow(EWindow.PhotoCatalog)
    }

    getImage() {
        const picture = this.getElement('camera.picture') as Sprite

        const imageResolution = 2

        Client.shared.resizeFunc(undefined, Constants.SIZE[0], Constants.SIZE[1], imageResolution)
        Client.shared.renderer.render(Client.shared.stage)
        const stageAdjustedPoint = Client.shared.roomViewer.sprites.toLocal(picture.position, this)

        const region = new Rectangle(
            stageAdjustedPoint.x,// + Client.shared.roomViewer.sprites.pivot.x - Client.shared.roomViewer.pivot.x,
            stageAdjustedPoint.y,// + Client.shared.roomViewer.sprites.pivot.y - Client.shared.roomViewer.pivot.y,
            184,// * Client.shared.stage.scale.x,
            124// * Client.shared.stage.scale.y
        )

        const image = Client.shared.renderer.generateTexture({
            target: Client.shared.roomViewer.sprites,
            frame: region,
            resolution: imageResolution
        }) as RenderTexture

        picture.texture = image
        Client.shared.resizeFunc()

        return image
    }

    takePicture() {
        SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.Camera)

        const image = this.getImage()

        Client.shared.userInterface.removeWindow(this)
        Client.shared.userInterface.register(new SavePhotoWindow(image))
    }
}
