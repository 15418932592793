/**
 * Use a FakeContainer when you want to be able to control a group of sprites simultaneously,
 * but they must be able to interstack with other sprites outside the group.
 */
import {
    Container,
    type EventMode,
    type FederatedEvent,
    type FederatedEventTarget,
    Graphics,
    ObservablePoint,
    Sprite,
    Texture
} from 'pixi.js'

export type ManagedObject = (ManagedGraphics | ManagedSprite | ManagedSingleContainer) & FederatedEventTarget

export class FakeContainer {
    children: ManagedObject[] = []
    protected parent: Container
    private _zIndex = 0
    _onUpdate = () => {
        for (const c of this.children) {
            c.updatePosition()
        }
    }
    private _position: ObservablePoint = new ObservablePoint(this)
    private _alpha = 1
    private _events: Map<string, { handler: any }> = new Map()
    private _eventMode: EventMode = 'static'

    setParent(container: Container): void {
        this.parent = container

        if (this.children.length) {
            container.addChild(...this.children)
        }
    }

    addEventListener(event: string, handler: (e: FederatedEvent) => void): void {
        if (this._events.has(event)) {
            console.warn('Overriding an event handler on FakeContainer', this)
        }
        this._events.set(event, { handler })
        for (const c of this.children) {
            c.addEventListener(event, handler)
        }
    }

    off(event: string): void {
        const handler = this._events.get(event)

        if (handler) {
            this._events.delete(event)
            for (const c of this.children) {
                c.removeEventListener(event, handler.handler)
            }
        }
    }

    emit(event: string): void {
        if (!this.children.length) {
            return
        }
        const o = this._events.get(event)
        if (o) {
            o.handler({ currentTarget: this.children[0] })
        }
    }

    set alpha(val: number) {
        this._alpha = val
        for (const c of this.children) {
            c.alpha = val * ('selfAlpha' in c ? c.selfAlpha : 1)
        }
    }

    get alpha(): number {
        return this._alpha
    }

    setAlpha(val: number): void {
        this.alpha = val
    }

    getAlpha(): number {
        return this._alpha
    }

    set eventMode(val: EventMode) {
        if (this._eventMode !== val) {
            this._eventMode = val
            for (const c of this.children) {
                c.eventMode = val
            }
        }
    }

    get eventMode(): string {
        return this._eventMode
    }

    set position(val: ObservablePoint) {
        this._position = val
        this.positionUpdated()
    }

    get position(): ObservablePoint {
        return this._position
    }

    get zIndex() {
        return this._zIndex
    }

    set zIndex(val: number) {
        this._zIndex = val
        for (const c of this.children) {
            c.updateZIndex()
        }
    }

    addChild<T extends ManagedObject>(...children: T[]): T {
        if (children.length) {
            for (const c of children) {
                c.manager = this
                c.updatePosition()
                c.updateZIndex()
                c.alpha = this.alpha * ('selfAlpha' in c ? c.selfAlpha : 1)
                c.eventMode = this._eventMode
                this._events.forEach((o, event) => c.addEventListener(event, o.handler))
            }

            if (this.parent) {
                this.parent.addChild(...children)
            }

            this.children.push(...children)

            return children[0]
        }
    }

    destroy(
        options: {
            children?: boolean
            texture?: boolean
            baseTexture?: boolean
        } = {
            children: false,
            texture: false,
            baseTexture: false
        }
    ): void {
        for (const c of this.children) {
            if (!c.destroyed) {
                c.destroy(options)
            }
        }

        this.children = []
        this._events.clear()
        this.parent = null
    }
}

export class ManagedSprite extends Sprite {
    selfAlpha = 1

    constructor(
        texture: Texture,
        readonly index?: number
    ) {
        super(texture)
    }

    manager: FakeContainer

    _selfZIndex = 0

    updatePosition = () => {
        this.position.set(
            this._selfPosition.x + (this.manager?.position?.x || 0),
            this._selfPosition.y + (this.manager?.position?.y || 0)
        )
    }
    _selfPosition: ObservablePoint = new ObservablePoint({
        _onUpdate: point => this.updatePosition()
    })

    setZIndex(val: number) {
        this._selfZIndex = val

        this.updateZIndex()
    }

    setPosition(x: number, y: number) {
        this._selfPosition.set(x, y)
    }

    updateZIndex() {
        this.zIndex = this._selfZIndex + (this.manager?.zIndex || 0)
    }

    static from(source: string | Texture, index?: number): ManagedSprite {
        let tex: Texture
        try {
            tex = source instanceof Texture ? source : Texture.from(source)
        } catch (error) {
            tex = Texture.EMPTY
            console.log(error)
        }
        return new ManagedSprite(tex, index)
    }
}

export class ManagedSingleContainer extends Container {
    selfAlpha: number = 1

    constructor(
        child: Container,
        readonly index?: number
    ) {
        super()
        this.addChild(child)
    }

    manager: FakeContainer

    _selfZIndex = 0

    updatePosition = () => {
        this.position.set(
            this._selfPosition.x + (this.manager?.position?.x || 0),
            this._selfPosition.y + (this.manager?.position?.y || 0)
        )
    }
    _selfPosition: ObservablePoint = new ObservablePoint({ _onUpdate: () => this.updatePosition() })

    setZIndex(val: number) {
        this._selfZIndex = val

        this.updateZIndex()
    }

    setPosition(x: number, y: number) {
        this._selfPosition.set(x, y)
    }

    updateZIndex() {
        this.zIndex = this._selfZIndex + (this.manager?.zIndex || 0)
    }
}

export class ManagedGraphics extends Graphics {
    constructor(readonly index?: number) {
        super()
    }

    manager: FakeContainer

    _selfZIndex = 0
    _selfPosition: ObservablePoint = new ObservablePoint({ _onUpdate: () => this.updatePosition() })

    setZIndex(val: number) {
        this._selfZIndex = val

        this.updateZIndex()
    }

    setPosition(x: number, y: number) {
        this._selfPosition.set(x, y)
    }

    updateZIndex() {
        this.zIndex = this._selfZIndex + (this.manager?.zIndex || 0)
    }

    updatePosition() {
        this.position.set(
            this._selfPosition.x + (this.manager?.position?.x || 0),
            this._selfPosition.y + (this.manager?.position?.y || 0)
        )
    }
}
