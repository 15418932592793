import { Pooler } from '@vmk-legacy/render-utils'
import { ColorOverlayFilter } from 'pixi-filters/color-overlay'
import type { Sprite } from 'pixi.js'
import { Container, Rectangle, Text, Texture } from 'pixi.js'
import type { TextKey } from '../../assets/ExternalConfigManager.js'
import { getText } from '../../assets/ExternalConfigManager.js'
import { TextColor, VMKLFont, VMKLText } from '../VMKLText'
import { UIWindowView } from './UIWindowView.js'

export class UIWindowTab extends Container {
    id: string
    private text: string
    private active = false
    private w: number
    private h: number
    private left: Sprite
    private middle: Sprite
    private right: Sprite

    view?: Container
    private bg: Container
    private labelText: VMKLText

    constructor(text: TextKey, view?: Container) {
        super()

        if (view instanceof UIWindowView) {
            view.tab = this
        }

        this.id = text
        this.text = getText(text)
        this.view = view

        this.left = Pooler.newSprite('tab.bg.left')
        this.right = Pooler.newSprite('tab.bg.right')
        this.middle = Pooler.newSprite(Texture.WHITE)
        this.middle.tint = 0x28578b
        this.middle.height = 29

        this.left.roundPixels = true
        this.right.roundPixels = true
        this.middle.roundPixels = true

        const tabBarUnderHeight = 25

        this.middle.x = this.left.width

        this.labelText = new VMKLText({
            text: this.text.toUpperCase(),
            font: VMKLFont.folio,
            size: 13,
            color: TextColor.white,
            anchorY: 0.5
        })
        this.labelText.position.set(this.left.width - 1, tabBarUnderHeight / 2)

        this.middle.width = this.labelText.width - 2
        this.right.x = this.left.width + this.middle.width

        this.left.height += 1
        this.middle.height += 1
        this.right.height += 1

        this.bg = Pooler.newContainer()
        this.bg.visible = false
        this.bg.addChild(this.left, this.middle, this.right)

        this.addChild(this.bg, this.labelText)

        this.w = this.left.width + this.middle.width + this.right.width
        this.h = this.left.height

        this.hitArea = new Rectangle(0, 0, this.w, this.h)

        this.interactiveChildren = true
        this.eventMode = 'static'
        this.labelText.cursor = 'pointer'
        this.cursor = 'pointer'
        this.visible = true
    }

    setTint(tint: number): this {
        this.bg.filters = [new ColorOverlayFilter(tint)]

        if (tint === 0xffffff) {
            this.labelText.style.fill = 0x000000
        }

        return this
    }

    setName(name: string): this {
        this.name = name

        return this
    }

    setId(id: string): this {
        this.id = id

        return this
    }

    setActive(tabOpen = true): void {
        this.active = tabOpen

        this.bg.visible = tabOpen
        if (this.view) {
            this.view.visible = tabOpen
        }
    }

    getActive(): boolean {
        return this.active
    }

    getWidth(): number {
        return this.w
    }

    getHeight(): number {
        return this.h
    }

    getText(): string {
        return this.text
    }
}
