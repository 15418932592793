import { Pooler } from '@vmk-legacy/render-utils'
import { BitmapText, Sprite } from 'pixi.js'
import { Fonts } from '../../Fonts.js'

export class RadioButton extends Sprite {
    private activeState: Sprite
    private passiveState: Sprite
    private text: string
    private value: string | number

    private isActive = false

    constructor(text = '', value: string | number, paddingX = 4, type = 'a', font = Fonts.Foxley_16) {
        super()

        this.text = text
        this.value = value

        if ('a' !== type && 'b' !== type) {
            throw new Error('Invalid radio button type')
        }

        this.activeState =
            type === 'a' ? Pooler.newSprite('button.radiobutton.1') : Pooler.newSprite('button.radiobuttonb.1')
        this.passiveState = Pooler.newSprite('button.radiobutton.0')

        this.addChild(this.activeState, this.passiveState)

        this.setActive(false)

        this.eventMode = 'static'
        this.interactiveChildren = true
        this.activeState.eventMode = this.passiveState.eventMode = 'static'
        this.activeState.cursor = this.passiveState.cursor = 'pointer'

        if ('' !== text) {
            const t = new BitmapText(text, {
                ...font,
                align: 'left'
            })
            t.x = this.activeState.width + paddingX
            t.y = font.fontFamily === 'Foxley' ? 3 : 0
            this.addChild(t)
            t.eventMode = 'auto'
            t.interactiveChildren = false
        }
    }

    getText(): string {
        return this.text
    }

    getValue(): string | number {
        return this.value
    }

    setActive(active: boolean): void {
        this.isActive = active

        this.activeState.visible = active
        this.passiveState.visible = !active
    }

    getActive(): boolean {
        return this.isActive
    }
}
