/*
 * HTMLView.ts
 * VMK Legacy Client
 */

import type { Point } from 'pixi.js'
import { Client } from '../Client'
import { ScrollArea } from './containers/ScrollArea'
import { ResponsiveContainer } from './views/AlertView'

export interface ScrollHeightVending {
    get scrollableHeight(): number
}

export class HTMLView extends ResponsiveContainer implements ScrollHeightVending {
    private element?: HTMLDivElement
    private areaHeight: number
    private shouldBeVisible: boolean = false

    constructor(opts: {
        html: string,
        areaWidth: number,
        areaHeight: number
    }) {
        super()

        this.eventMode = 'none'
        this.hitArea = { contains(x, y) { return false } }

        const element = document.createElement('div')
        this.element = element
        this.areaHeight = opts.areaHeight

        element.className = 'html-view'
        element.style.color = '#000000'
        element.style.width = `${opts.areaWidth}px`
        element.style.height = `${opts.areaHeight}px`
        element.style.overflowX = 'auto'
        element.style.display = 'none'
        element.innerHTML = opts.html

        element.addEventListener('wheel', (e) => {
            if (this.parent instanceof ScrollArea) {
                this.parent.setScroll(this.getScrollPercent(), true, true)
            }
        }, { passive: false, capture: true })

        this.once('added', (p) => {
            document.getElementById('overlay').appendChild(element)
            this.sizeDidChange()
            console.log("htmlview added")
        })

        this.once('removed', () => {
            this.element?.remove()
            this.element = undefined
            console.log("htmlview removed")
        })

        this.once('destroyed', () => {
            this.element?.remove()
            this.element = undefined
            console.log("htmlview destroyed")
        })
    }

    onRender = () => {
        const newVisible = this.localDisplayStatus === 7 && this.globalDisplayStatus === 7
        if (newVisible !== this.shouldBeVisible) {
            this.shouldBeVisible = newVisible
            if (newVisible) {
                // displaying
                this.sizeDidChange()
                this.element.style.display = 'block'
                console.log("htmlview made visible")
            } else {
                this.element.style.display = 'none'
                console.log("htmlview made invisible")
            }
        }
    }

    setHTML(html: string): void {
        this.element.innerHTML = html
    }

    override sizeDidChange(): void {
        if (this.destroyed) {
            return
        }
        const gPos = Client.shared.stage.toLocal(this.getGlobalPosition())
        console.log('Adjusted position is ' + gPos)
        if (gPos) {
            this.#setGlobalPos(gPos)
        }
    }
    #setGlobalPos(coord: Point): void {
        this.element!.style.left = `${Math.round(coord.x)}px`
        this.element!.style.top = `${Math.round(coord.y)}px`
    }

    get scrollableHeight(): number {
        return this.element?.scrollHeight ?? 0
    }

    setScroll(percent: number): void {
        this.element!.scrollTop = (this.scrollableHeight - this.areaHeight) * percent
    }

    getScrollPercent(): number {
        return this.element!.scrollTop / (this.scrollableHeight - this.areaHeight)
    }

    // destroy(o): void {
    //     console.log('HTMLView.destroy', o)
    //     this.element?.remove()
    //     this.element = undefined
    // }
}
