import { BitmapText, Container, Graphics } from 'pixi.js'
import { Client } from '../../../Client.js'
import { Fonts } from '../../../Fonts.js'
import { BitmapTextButton } from '../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../buttons/BitmapTextButtonType.js'
import type { IGNView } from './views/IGNView.js'

export class IGNRequestContainer extends Container {
    constructor(
        readonly view: IGNView,
        boxWidth: number,
        boxHeight: number,
        oldIgn: string,
        newIgn: string,
        uid: number,
        warning = ''
    ) {
        super()

        const reportBG = new Graphics()
        reportBG.beginFill(0x255592)
        reportBG.drawRect(0, 0, boxWidth, boxHeight)
        reportBG.endFill()
        this.addChild(reportBG)

        const textOffsetX = 7
        const textOffsetY = 7

        const id = new BitmapText(oldIgn || '', {
            ...Fonts.FoxleyBold_16
        })
        id.x = textOffsetX
        id.y = textOffsetY
        this.addChild(id)

        const arrow = new BitmapText(' to ', {
            ...Fonts.Foxley_16
        })
        arrow.x = id.x + id.width
        arrow.y = id.y + 1
        this.addChild(arrow)

        const hasWarning = !!warning

        const user = new BitmapText(newIgn || '', {
            ...Fonts.FoxleyBold_16
        })
        user.tint = hasWarning ? 0xec201e : 0x00ff00
        user.x = arrow.x + arrow.width + 3
        user.y = id.y
        this.addChild(user)

        const date = new BitmapText(warning || '', Fonts.Foxley_16)
        date.x = id.x
        date.y = id.y + 16
        this.addChild(date)
        if (!hasWarning) {
            date.text = 'Available'
        }

        const accept = new BitmapTextButton(145, 'APPROVE', BitmapTextButtonType.GREEN)
        accept.position.set(10, date.y + 16)
        this.addChild(accept)
        accept.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('mk_ign_accept', {
                ign: newIgn,
                id: uid
            })
            accept.alpha = reject.alpha = 0.2
            accept.eventMode = reject.eventMode = 'auto'
        })

        const reject = new BitmapTextButton(142, 'REJECT', BitmapTextButtonType.RED)
        reject.position.set(170, date.y + 16)
        this.addChild(reject)
        reject.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('mk_ign_reject', {
                id: uid,
                ign: newIgn
            })
            accept.alpha = reject.alpha = 0.2
            accept.eventMode = reject.eventMode = 'auto'
        })
    }
}
