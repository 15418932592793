import { Pooler } from '@vmk-legacy/render-utils'
import type { FederatedPointerEvent, Sprite } from 'pixi.js'
import { Container } from 'pixi.js'
import { ImageButton } from '../buttons/ImageButton.js'

export class Scrollbar extends Container {
    private scrollbarHandle: ImageButton
    private scrollbarUp: ImageButton
    private scrollbarDown: ImageButton
    private scrollbarBGEnabled: Sprite
    private scrollbarBGDisabled: Sprite
    private scrollbarBGHeight: number
    percentScrolled = 0
    private callback?: (percent: number) => any
    private sUp: ImageButton
    private sDown: ImageButton

    constructor(scrollCallback?: (percent: number) => any) {
        super()
        const sX = 0
        const sY = 145

        this.sUp = new ImageButton('button.scroll.up.active', 'button.scroll.up.pressed', 'button.scroll.up.passive')
        this.sDown = new ImageButton(
            'button.scroll.down.active',
            'button.scroll.down.pressed',
            'button.scroll.down.passive'
        )
        this.sUp.x = this.sDown.x = sX
        this.sDown.y = sY - this.sDown.height
        this.scrollbarUp = this.sUp
        this.scrollbarDown = this.sDown
        this.addChild(this.sUp, this.sDown)

        const sBGActive = Pooler.newSprite('scrollbar.vertical.active')
        const sBGPassive = Pooler.newSprite('scrollbar.vertical.passive')
        this.addChild(sBGPassive, sBGActive)

        sBGActive.x = sBGPassive.x = sX
        sBGActive.y = sBGPassive.y = this.sUp.height
        sBGActive.height = sBGPassive.height = this.sDown.y - this.sUp.height

        const handle: ImageButton = new ImageButton(
            'button.scroll.lift.active',
            'button.scroll.lift.pressed',
            'button.scroll.lift.passive'
        )
        handle.x = sX
        handle.y = this.sUp.y + this.sUp.height
        this.addChild(handle)

        this.scrollbarBGEnabled = sBGActive
        this.scrollbarBGDisabled = sBGPassive
        this.scrollbarHandle = handle

        this.scrollbarBGHeight = sBGActive.height

        this.eventMode = this.scrollbarHandle.eventMode = 'static'
        this.interactiveChildren = true

        this.addEventListener('pointermove', (e: FederatedPointerEvent) => {
            this.updatePos(this.toLocal(e.global).y)
        })
        this.callback = scrollCallback
    }

    updatePos(pointerPos: number): void {
        let rY: number = pointerPos - 15

        if (rY - 15 < 0) {
            rY = 15
        }
        if (rY + 15 > this.scrollbarBGEnabled.height) {
            rY = this.scrollbarBGEnabled.height - 15
        }
        if (this.scrollbarHandle.isPressed()) {
            this.scrollbarHandle.y = rY
            const innerHeight = this.sDown.y - this.sUp.height
            const scroll0 = 15
            const scroll100: number = innerHeight - 2 * scroll0
            this.percentScrolled = (rY - scroll0) / scroll100
            if (this.callback !== null) {
                this.callback(this.percentScrolled)
            }
        }
    }

    setPercentScrolled(p: number): void {
        const innerHeight = this.sDown.y - this.sUp.height
        const scroll0 = 15

        this.scrollbarHandle.y =
            Math.max(15, scroll0 + Math.round((innerHeight - 15) * p) - Math.round(this.scrollbarHandle.height / 2))

        this.percentScrolled = p

        console.log('SETTING SCROLLBAR TO ' + this.scrollbarHandle.y)
    }
}
