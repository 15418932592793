import type { RenderTexture, Sprite } from 'pixi.js'
import { ColorMatrixFilter } from 'pixi.js'
import { Client } from '../../../Client.js'
import { EWindow } from '../../../enums.js'
import type { BitmapTextButton } from '../../buttons/BitmapTextButton.js'
import type { DOMText } from '../../DOMText.js'
import LegacyWindow from '../LegacyWindow.js'

export class SavePhotoWindow extends LegacyWindow {
    readonly kind = EWindow.Other

    private photoId: number
    private uploader: Promise<boolean>

    constructor(readonly image: RenderTexture) {
        super('vmk_photo_picturetitle')

        this.uploader = this.#beginUpload()
    }

    override async windowWasBuilt(): Promise<void> {
        const picture = this.getElement('camera.picture') as Sprite
        picture.texture = this.image

        const sepia = new ColorMatrixFilter()
        sepia.contrast(0.25, false)
        sepia.tint(0xffea37, true)
        sepia.sepia(true)
        picture.filters = [sepia]

        const button = this.getElement('camera.save.button') as BitmapTextButton

        if (!this.photoId) {
            button.disable()
            button.setText('Uploading')
        }

        button.addEventListener('pointertap', () => this.saveCaption())

        this.getElement<DOMText>('camera.caption.input').forceFocus()
    }

    #beginUpload() {
        return new Promise<boolean>(async (resolve, reject) => {
            const data = (await Client.shared.renderer.extract.base64({
                target: this.image,
                resolution: this.image.source.resolution,
                format: 'jpg',
                quality: 0.75
            })) as string

            const form = new FormData()
            form.append('token', Client.shared.selfRecord.getTkn())
            form.append('photo', data)

            Client.shared.api
                .post('uploadPhoto', form)
                .then((data: { photoId: number }) => {
                    Client.shared.serverBroker.sendAck('confirm_photo', data.photoId).then((success) => {
                        this.photoId = data.photoId
                        if (this.alreadyBuilt) {
                            const button = this.getElement('camera.save.button') as BitmapTextButton
                            button.enable()
                            button.setText('OK')
                        }
                        resolve(success)
                    })
                })
                .catch((error) => {
                    this.destroy()
                    Client.shared.helpers.alert(Array.isArray(error) ? error.join(' ') : String(error))
                    reject()
                })
        })
    }

    saveCaption() {
        const button = this.getElement('camera.save.button') as BitmapTextButton
        button.disable()

        const input = this.getElement('camera.caption.input') as DOMText

        Client.shared.serverBroker
            .sendAck('edit_photo', {
                photoId: this.photoId,
                caption: input.getValue()
            })
            .then((success) => {
                if (success) {
                    Client.shared.userInterface.showWindow(EWindow.PhotoCatalog)
                    Client.shared.userInterface.removeWindow(this)
                } else {
                    button.enable()
                }
            })
    }
}
