/*
 * VMKLText.ts
 * VMK Legacy Client
 */

import {
    BitmapText,
    Container,
    HTMLText,
    Text,
    type TextStyle,
    type TextOptions,
    type TextStyleFontWeight,
    type TextStyleTextBaseline,
    type TextStyleWhiteSpace
} from 'pixi.js'
import { Client } from '../Client'
import { ScrollHeightVending } from './HTMLView'

export enum VMKLFont {
    foxley,
    folio
}

export enum TextColor {
    black = 'black',
    white = 'white'
}

export function textColorToHex(color: TextColor): string {
    return color === TextColor.black ? '#000000' : '#ffffff'
}

export enum PixiTextType {
    text,
    bitmapText,
    htmlText
}

export function textStyle(type: PixiTextType, font: VMKLFont, color: TextColor, isBold = false) {
    const colorAsHex = textColorToHex(color)

    let fontFamily: string
    const fontWeight: TextStyleFontWeight = isBold ? 'bold' : 'normal'
    switch (type) {
        case PixiTextType.text:
        case PixiTextType.htmlText: {
            switch (font) {
                case VMKLFont.folio:
                    fontFamily = 'web-folio'
                    break
                case VMKLFont.foxley:
                    fontFamily = isBold ? 'web-foxley-bold' : 'web-foxley'
                    break
            }
            break
        }
        case PixiTextType.bitmapText: {
            // As of Pixi 8.5.0, BitmapText font fills are broken
            // So we install a black and white version
            switch (font) {
                case VMKLFont.folio:
                    fontFamily = `vmkl-folio-${color}`
                    break
                case VMKLFont.foxley:
                    fontFamily = isBold ? `vmkl-foxley-bold-${color}` : `vmkl-foxley-${color}`
                    break
            }
            break
        }
    }

    return {
        fontFamily,
        fontWeight,
        fill: colorAsHex
    }
}

export class VMKLText extends Container implements ScrollHeightVending {
    private pixiText: Text | HTMLText | BitmapText

    constructor(opts: {
        text?: string
        type?: PixiTextType
        font?: VMKLFont
        size?: number
        color?: TextColor
        bold?: boolean
        align?: 'left' | 'center' | 'right'

        anchorX?: number
        anchorY?: number

        letterSpacing?: number
        lineHeight?: number
        whiteSpace?: TextStyleWhiteSpace
        breakWords?: boolean
        wordWrapWidth?: number
        textBaseline?: TextStyleTextBaseline
    } = {}) {
        super()

        opts.type ??= PixiTextType.bitmapText
        opts.font ??= VMKLFont.foxley
        opts.color ??= TextColor.white
        opts.size ??= 16

        const pixiOptions: TextOptions = {
            text: opts.text ?? '',
            roundPixels: true,
            resolution: Client.shared.renderer.resolution,
            style: {
                ...textStyle(
                    opts.type, opts.font, opts.color, opts.bold
                ),
                fontSize: opts.size,
                align: opts.align ?? 'left',
                letterSpacing: opts.letterSpacing ?? 0,
                lineHeight: opts.lineHeight,
                whiteSpace: opts.whiteSpace ?? 'pre',
                breakWords: opts.breakWords,
                wordWrap: !!opts.wordWrapWidth, // enable wordWrap if a wrapWidth is passed
                wordWrapWidth: opts.wordWrapWidth,
                textBaseline: opts.textBaseline
            }
        }

        switch (opts.type) {
            case PixiTextType.bitmapText:
                this.pixiText = new BitmapText(pixiOptions)
                break
            case PixiTextType.text:
                this.pixiText = new Text(pixiOptions)
                break
            case PixiTextType.htmlText:
                this.pixiText = new HTMLText(pixiOptions)

                this.pixiText.style.addOverride(
                    'font-smoothing: none',
                    'text-rendering: optimizeSpeed',
                    '-webkit-font-smoothing: none'
                )
                break
        }

        if (typeof opts.anchorX === 'number') {
            this.pixiText.anchor.x = opts.anchorX
        }

        if (typeof opts.anchorY === 'number') {
            this.pixiText.anchor.y = opts.anchorY
        }

        this.addChild(this.pixiText)
    }

    set text(value: string) {
        this.pixiText.text = value
    }

    get text(): string {
        return this.pixiText.text
    }

    get style(): TextStyle {
        return this.pixiText.style
    }

    get scrollableHeight(): number {
        return this.pixiText.height
    }
}