import type { ICastProvides, SoundChannel } from '@vmk-legacy/render-utils'
import { ESndGrp, ScoreRunner, SoundChannelItem, SoundManager } from '@vmk-legacy/render-utils'
import type { DestroyOptions, Sprite } from 'pixi.js'
import { Texture } from 'pixi.js'
import { Client } from '../../Client.js'
import { UILayer } from '../../enums.js'
import LegacyWindow from '../../ui/windows/LegacyWindow.js'
import { Helpers } from '../../util/Helpers.js'
import type { MusicMixerDelegate } from './MusicMixerDelegate.js'

// id => "{cat}_{num}_{len}"
type Tuple<T, N extends number, R extends readonly T[] = []> = R['length'] extends N
    ? R
    : Tuple<T, N, readonly [T, ...R]>

export const _MixerSounds: Record<'street' | 'mono', Tuple<string, 42>> = {
    street: [
        '', // repeat
        'emptySnd',
        'bas_201_2',
        'bas_202_2',
        'bas_203_2',
        'bas_204_2',
        'bas_205_4',
        'bas_206_2',
        'bas_207_2',
        'bas_208_2',
        'drm_1_2',
        'drm_2_2',
        'drm_3_2',
        'drm_4_2',
        'drm_5_2',
        'drm_6_2',
        'drm_7_2',
        'drm_8_2',
        'fx_401_4',
        'fx_402_4',
        'fx_403_2',
        'fx_404_2',
        'fx_405_2',
        'fx_406_4',
        'fx_407_2',
        'fx_408_2',
        'gtr_101_2',
        'gtr_102_2',
        'gtr_103_2',
        'gtr_104_4',
        'gtr_105_4',
        'gtr_106_2',
        'gtr_107_2',
        'gtr_108_4',
        'snt_301_2',
        'snt_302_2',
        'snt_303_2',
        'snt_304_2',
        'snt_305_4',
        'snt_306_2',
        'snt_307_4',
        'snt_308_4'
    ],
    mono: [
        '',
        'emptySnd',
        'bas_201_2',
        'bas_202_4',
        'bas_203_2',
        'bas_204_2',
        'bas_205_2',
        'bas_206_2',
        'bas_207_4',
        'bas_208_2',
        'drm_1_2',
        'drm_2_2',
        'drm_3_2',
        'drm_4_2',
        'drm_5_2',
        'drm_6_4',
        'drm_7_4',
        'drm_8_2',
        'fx_401_2',
        'fx_402_2',
        'fx_403_2',
        'fx_404_2',
        'fx_405_2',
        'fx_406_2',
        'fx_407_2',
        'fx_408_4',
        'gtr_101_2',
        'gtr_102_4',
        'gtr_103_4',
        'gtr_104_4',
        'gtr_105_2',
        'gtr_106_2',
        'gtr_107_2',
        'gtr_108_4',
        'snt_301_4',
        'snt_302_4',
        'snt_303_2',
        'snt_304_4',
        'snt_305_4',
        'snt_306_2',
        'snt_307_4',
        'snt_308_2'
    ]
}

const MixerColors = {
    drm: 'red',
    bas: 'orange',
    gtr: 'green',
    snt: 'pink',
    fx: 'blue'
}

const NumTracks = 4
const NumSpots = 16

type NumTracks = 4
type NumSpots = 16

export interface MixerSong {
    id?: number
    name: string
    sounds: (keyof Tuple<number, 42>)[][]
}

type MixerSound = number

export class MusicMixer extends LegacyWindow {
    layer = UILayer.GameWindows
    protected casts
    isDraggable = false

    score: ScoreRunner

    protected placingSound?: MixerSound
    editingSong: MixerSong
    protected scoreItems: { [spot: number]: SoundChannelItem }[]
    protected sounds: Map<string, AudioBuffer>
    protected playhead?: Sprite
    private highlighted?: Sprite
    private playingPrev?: AudioBufferSourceNode

    constructor(
        readonly which: 'street' | 'mono' = 'street',
        readonly delegate?: MusicMixerDelegate
    ) {
        super('vmk_music_mixer')
        this.casts = ['minigame/music_mixer', 'minigame/music_mixer_samples_' + which]
    }

    get mixerSounds(): Record<number, string> {
        return _MixerSounds[this.which]
    }

    override castProvided(provides: ICastProvides): void {
        this.sounds = provides.sounds
    }

    override async windowWasBuilt(): Promise<void> {
        if (!this.delegate) {
            return
        }
        if (this.which === 'mono') {
            this.setBitmap('back', 'music.mixer.mono.bg')
        }
        this.getElement('help').addEventListener('pointerup', () =>
            Client.shared.helpers.alert({
                title: 'Help',
                message:
                    'Click on an sound to choose it, then click on the track on the slot where you want to place the instrument. Double-click on a slot to remove a sound.'
            })
        )
        this.playhead = this.getElement('track.pointer')
        this.getElements('track_*').forEach((spot: Sprite) => {
            spot.width = 37
            spot.height = 37
            spot.alpha = 1
            let tapTimer
            spot.addEventListener('pointerup', (e) => {
                const [_, track, spot] = e.currentTarget.name.match(/track_([1-4])_([0-9]{1,2})/)

                if (tapTimer) {
                    window.clearTimeout(tapTimer)
                    tapTimer = null
                    this.removeSoundAt(+track, +spot)
                    return
                }
                if (this.placingSound && this.editingSong.sounds[track - 1][spot - 1] === 1) {
                    this.setSoundAt(+track, +spot, this.placingSound, true)
                    return
                }
                tapTimer = window.setTimeout(() => {
                    tapTimer = null
                }, 300) // time for double click detection
            })
        })
        this.getElement('button.mixmanager').addEventListener('pointerup', () => this.delegate.showManager())
        this.getElement('button.savemusic').addEventListener('pointerup', () => this.saveTrack())
        this.getElement('button.playmusic').addEventListener('pointerup', () => this.score.playFromBeginning())
        this.getElement('button.stopmusic').addEventListener('pointerup', () => this.score.reset(1, true))
        this.getElement('button.clearmusic').addEventListener('pointerup', async () => {
            if (
                await Client.shared.helpers.confirm({
                    title: 'musicmix.dialog.clearall.title',
                    message: 'musicmix.dialog.clearall.text'
                })
            ) {
                this.newSong()
            }
        })
        this.getElement('button.exitgame').addEventListener('pointerup', async () => {
            if (
                await Client.shared.helpers.confirm({
                    title: 'musicmix.dialog.exit.confirm.title',
                    message: 'musicmix.dialog.exit.confirm.text'
                })
            ) {
                await this.delegate.teardown()
            }
        })
        this.getElements('button.mute.*').forEach((btn) =>
            btn.addEventListener('pointerup', (e) => {
                const index = +e.currentTarget.name.substr('button.mute.'.length, 1)
                const channel = <SoundChannel>this.score.channels[index - 1]
                const muting = channel.volume === 0
                if (muting) {
                    e.currentTarget.texture = Texture.from('button.audio.0')
                } else {
                    e.currentTarget.texture = Texture.from('button.audio.1')
                }
                channel.setVolume(muting ? 0 : 1)
            })
        )
        this.getElements('inst_*').forEach((instBtn: Sprite) => {
            instBtn.alpha = 0
            instBtn.addEventListener('pointerdown', (e) => (e.currentTarget.alpha = 1))
            instBtn.addEventListener('pointerup', (e) => {
                const [_, inst, num] = e.currentTarget.name.match(/inst_([a-z]{2,3})_([0-9]{1,3})/)
                if (this.highlighted) {
                    this.highlighted.alpha = 0
                    this.highlighted = null
                }
                e.currentTarget.alpha = 1

                this.placingSound = +Object.entries(this.mixerSounds).find(
                    (e) => e[1].match(new RegExp('^' + inst + '_' + num + '_[24]', 'g')) !== null
                )[0]
                if (this.placingSound) {
                    this.highlighted = e.currentTarget
                    SoundManager.shared.release(this.playingPrev)
                    this.playingPrev = null

                    const toPreview = this.sounds.get(this.getSoundInfo(this.placingSound).name)
                    if (toPreview) {
                        this.playingPrev = SoundManager.shared.play(ESndGrp.Always, toPreview)

                        Helpers.delay(toPreview.duration * 1000).then(() => (this.playingPrev = null))
                    }
                }
            })
        })

        this.newSong()
    }

    async saveTrack(): Promise<void> {
        if (!this.editingSong.sounds.some((track) => Math.max(...track) > 1)) {
            await Client.shared.helpers.alert('Put some sound samples on the tracks before saving your mix.')

            return
        }

        if (!this.editingSong.id) {
            const nameDialog = new LegacyWindow('vmk_musicmix', new LegacyWindow('vmk_musicmix_editname'))

            await Client.shared.userInterface.register(nameDialog, true).waitToBeBuilt()

            nameDialog.setField('musicmix.dialog.hd', 'musicmix.entername.hd')
            nameDialog.setField('music.mixer.entername', 'Untitled')

            nameDialog.getElement('musicmix.ok').addEventListener('pointerup', () => {
                this.editingSong.name = nameDialog.getElement('music.mixer.entername').getValue()

                if (this.editingSong.name.length < 3 || this.editingSong.name.length > 50) {
                    Client.shared.helpers.alert("Your mix's name must be between 3 and 50 characters long.")
                    return
                }

                Client.shared.serverBroker.sendAck('mix_save', this.editingSong).then((id: number) => {
                    if (id) {
                        this.editingSong.id = id
                        Client.shared.helpers.alert('Your song has been saved.')
                    }
                })

                Client.shared.userInterface.removeWindow(nameDialog)
            })

            nameDialog
                .getElement('musicmix.cancel')
                .addEventListener('pointerup', () => Client.shared.userInterface.removeWindow(nameDialog))

            return
        }

        Client.shared.serverBroker
            .sendAck('mix_save', this.editingSong)
            .then((id) => id && Client.shared.helpers.alert('Your song has been saved.'))
    }

    updatePlayhead(frame: number): void {
        this.playhead.pivot.x = -((frame / ScoreRunner.frameRate) * 40) / 2
    }

    newSong(): void {
        this.populateSong({
            name: 'Untitled',
            sounds: new Array(NumTracks).fill(null).map(() => new Array(NumSpots).fill(1))
        })
    }

    populateSong(song: MixerSong): void {
        this.score?.teardown()
        this.score = new ScoreRunner({ name: 'Mixer' })
        Client.shared.attachScore(this.score)
        this.score.setLastFrame((NumSpots - 1) * 2 * ScoreRunner.frameRate + 1)
        if (this.delegate) {
            this.score.addUpdateFunc((f) => this.updatePlayhead(f))
        }
        this.score.loops = false

        this.scoreItems = []

        for (let t = 0; t < NumTracks; t++) {
            this.score.addSoundChannel('trk' + (t + 1))

            this.scoreItems[t] = {}
        }

        this.editingSong = song

        for (const trackIndex in this.editingSong.sounds) {
            const track = this.editingSong.sounds[trackIndex]

            for (const spotIndex in track as MixerSound[]) {
                const sound = track[spotIndex]

                this.setSoundAt(+trackIndex + 1, +spotIndex + 1, sound, false)
            }
        }
    }

    protected getSoundInfo(sndNum: MixerSound) {
        if (sndNum === 0) {
            return {
                name: 'repeat',
                inst: null,
                instNum: 0,
                len: null,
                color: null
            }
        }
        if (sndNum === 1) {
            return {
                name: 'emptySnd',
                inst: null,
                instNum: 1,
                len: 2,
                color: null
            }
        }
        const sndName = this.mixerSounds[sndNum]

        const [inst, num, len] = sndName.split('_')
        const color = MixerColors[inst]

        return {
            name: sndName,
            inst,
            instNum: +num.substr(-1),
            len: +len,
            color
        }
    }

    protected getSoundInfoAt(track: number, spot: number) {
        const snd = this.editingSong.sounds[track - 1][spot - 1]

        return { snd, ...this.getSoundInfo(snd) }
    }

    protected removeSoundAt(track: number, spot: number): void {
        const channel = this.score.channels[track - 1]
        if (!channel) {
            console.log(`Missing score channel ${track - 1}`)
            return
        }
        const current = this.getSoundInfoAt(track, spot)
        console.log('Removing ' + track + ' - ' + spot + ': ' + current.snd + ' (' + current.len + ')')

        this.editingSong.sounds[track - 1][spot - 1] = 1
        const itemAtSpot = this.scoreItems[track - 1]?.[spot - 1]
        if (itemAtSpot) {
            channel.removeItem(itemAtSpot)
        }

        this.setBitmap(`track_${track}_${spot}`, null, [37, 37])

        if (current.snd === 0) {
            // second part of double width
            console.log('Removing ' + track + ' - ' + (spot - 1))

            this.editingSong.sounds[track - 1][spot - 2] = 1

            const lastSpot = this.scoreItems[track - 1]?.[spot - 2]
            if (lastSpot) {
                channel.removeItem(lastSpot)
            }

            this.setBitmap(`track_${track}_${spot - 1}`, null, [37, 37])
        } else if (current.len === 4) {
            console.log('Removing ' + track + ' - ' + (spot + 1))
            this.editingSong.sounds[track - 1][spot] = 1

            const nextSpot = this.scoreItems[track - 1]?.[spot]
            if (nextSpot) {
                channel.removeItem(nextSpot)
            }

            this.setBitmap(`track_${track}_${spot + 1}`, null, [37, 37])
        }
    }

    protected setSoundAt(track: number, spot: number, sndNum: MixerSound, newPlace = true): void {
        if (sndNum === 0) {
            // double length repeated
            return
        }
        const sound = this.getSoundInfo(sndNum)

        if (newPlace) {
            // if adding new sound
            this.removeSoundAt(track, spot)
        } else if (sndNum === 1) {
            this.setBitmap(`track_${track}_${spot}`, null, [37, 37])
            return
        }
        if (newPlace && sound.len === 4) {
            if (spot === NumSpots) {
                spot -= 1
            } else {
                this.removeSoundAt(track, spot + 1)
            }
        }
        const trackChan = this.score.channels[track - 1] as SoundChannel

        const startFrame = (spot - 1) * 2 * ScoreRunner.frameRate + 1
        const endFrame = startFrame + sound.len * ScoreRunner.frameRate

        this.scoreItems[track - 1][spot - 1] = trackChan.addItem(
            new SoundChannelItem(
                trackChan,
                {
                    asset: sound.name,
                    type: 'fx',
                    start: startFrame,
                    end: endFrame
                },
                this.sounds.get(sound.name)
            ),
            startFrame,
            endFrame
        )

        this.setBitmap(`track_${track}_${spot}`, `${sound.color}${sound.instNum}.big`)
        if (sound.len === 4) {
            this.setBitmap(`track_${track}_${spot + 1}`, `${sound.color}${sound.instNum}.big`)
        }

        if (newPlace) {
            this.editingSong.sounds[track - 1][spot - 1] = sndNum

            if (sound.len === 4) {
                this.editingSong.sounds[track - 1][spot] = 0
            }
        }
    }

    override destroy(_options?: DestroyOptions | boolean): void {
        this.score?.teardown()

        super.destroy(_options)
    }
}
