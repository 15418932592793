import { Pooler } from '@vmk-legacy/render-utils'
import { ClientEmote } from '@vmk-legacy/server-protos'
import type { Sprite } from 'pixi.js'
import { BitmapText, Container, Graphics } from 'pixi.js'
import { Client } from '../../Client.js'
import { EWindow, UILayer } from '../../enums.js'
import { Fonts } from '../../Fonts.js'
import { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../buttons/BitmapTextButtonType.js'
import { ImageButton } from '../buttons/ImageButton.js'
import { FieldH } from '../fields/FieldH.js'
import { PopupBox } from './PopupBox.js'
import { UIWindow } from './UIWindow.js'

export class EmoteWindow extends UIWindow {
    readonly kind = EWindow.Emotes
    layer = UILayer.GameWindows
    isDraggable = false
    exitBtn: ImageButton
    readonly BOX_WIDTH: number = 205
    readonly BOX_HEIGHT: number = 105
    readonly ROW_1_Y: number = -10
    readonly NAME_FIELD_X: number = 27
    readonly NAME_FIELD_WIDTH: number = 142
    private titleField: BitmapText
    private lastWave: number

    async _buildWindow(innerHeight: number) {
        this.x = 105
        this.y = 485 + Client.shared.userInterface.getObscuringTopHeight()
        this.isDraggable = false

        const background = Pooler.newSprite()

        const bgBox = new PopupBox(this.BOX_WIDTH, this.BOX_HEIGHT, false)
        background.addChild(bgBox)
        bgBox.y = -15

        this.addChild(background)

        const qButton: ImageButton = new ImageButton('button.helpb.active', 'button.helpb.pressed')
        qButton.x = 8
        qButton.y = this.ROW_1_Y
        this.addChild(qButton)

        const xButton: ImageButton = new ImageButton('button.closeb.active', 'button.closeb.pressed')
        xButton.x = this.BOX_WIDTH - 24
        xButton.y = this.ROW_1_Y
        this.addChild(xButton)
        xButton.addEventListener('pointerup', () => {
            this.setVisible(false)
        })

        this.exitBtn = xButton

        const f = new FieldH(this.NAME_FIELD_WIDTH)
        f.x = this.NAME_FIELD_X
        f.y = this.ROW_1_Y
        this.addChild(f)

        const emotes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

        let index = 0
        const boxes: EmoteBox[] = []
        for (let r = 0; r < 2; r++) {
            for (let c = 0; c < 8; c++) {
                index++
                const box = new EmoteBox()
                box.x = 8 + 24 * c
                box.y = 10 + 24 * r
                this.addChild(box)
                if (index <= emotes.length) {
                    box.setIcon(index)
                    box.eventMode = 'static'
                    box.addEventListener('pointerup', () => {
                        boxes.forEach((b) => {
                            b.setSelected(b === box)
                        })
                        const now = new Date().getTime()
                        if (now - box.lastTap < 500) {
                            Client.shared.serverBroker.send(
                                new ClientEmote({
                                    id: box.getId()
                                })
                            )
                        } else {
                            box.lastTap = now
                        }
                    })
                }
                boxes.push(box)
            }
        }

        this.titleField = new BitmapText('Emotes', {
            ...Fonts.FoxleyBold_16
        })
        const distX = Math.round((this.NAME_FIELD_WIDTH - this.titleField.width) / 2)
        this.titleField.x = 4 + this.NAME_FIELD_X + distX
        this.titleField.y = this.ROW_1_Y + 1
        this.addChild(this.titleField)

        const danceBtn = new BitmapTextButton(80, 'DANCE', BitmapTextButtonType.GREEN)
        this.addChild(danceBtn)
        danceBtn.x = 15
        danceBtn.y = 60
        danceBtn.addEventListener('pointerup', () => {
            const now = Date.now()
            if (now - 500 < this.lastWave) {
                return
            }
            this.lastWave = now
            Client.shared.serverBroker.send('dance')
        })

        const waveBtn = new BitmapTextButton(80, 'WAVE', BitmapTextButtonType.GREEN)
        this.addChild(waveBtn)
        waveBtn.x = 110
        waveBtn.y = 60

        waveBtn.addEventListener('pointerup', () => {
            const now = Date.now()
            if (now - 500 < this.lastWave) {
                return
            }
            this.lastWave = now
            Client.shared.serverBroker.send('wave')
        })

        if (this.alreadyBuilt) {
            this.addChild(this.exitBtn)
        } else {
            this.alreadyBuilt = true
            await this.windowWasBuilt()
        }
    }

    override async windowWasBuilt(): Promise<void> {
        return Promise.resolve(undefined)
    }
}

export class EmoteBox extends Container {
    private bg: Graphics
    private icon: Sprite
    private id: number

    lastTap: number

    constructor() {
        super()
        this.bg = this.addChild(new Graphics())
        this.setSelected(false)
    }

    setSelected(selected = true) {
        if (this.icon) {
            this.bg.beginFill(selected ? 0xfbcf0a : 0x50a2d4)
            this.bg.drawRect(0, 0, 22, 22)
            this.bg.endFill()

            this.bg.beginFill(0x285692)
            this.bg.drawRect(2, 2, 18, 18)
            this.bg.endFill()
        } else {
            this.bg.beginFill(0x285692)
            this.bg.drawRect(0, 0, 22, 22)
            this.bg.endFill()
        }
    }

    getId(): number {
        return this.id
    }

    setIcon(id: number) {
        const texture = 'emoticon_' + id
        if (this.icon) {
            this.removeChild(this.icon)
        }
        this.icon = Pooler.newSprite(texture)
        this.icon.position.set(2, 2)
        this.icon.width = 18
        this.icon.height = 18
        this.addChild(this.icon)
        this.setSelected(false)
        this.id = id
    }
}
