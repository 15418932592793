import { ESndGrp, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { Container, Texture } from 'pixi.js'
import type { JungleCruiseDelegate } from './JungleCruiseDelegate.js'

export class JungleCruiseAnimal extends Container {
    id: number
    private blocker: number
    private scared: number

    private entryFrame = 0
    private leaveFrame = 0
    private loopFrame = 0

    snapped = false
    private left = false

    private firstTick = true

    protected totalTicks = 0

    private entryFrames: string[]
    private leaveFrames: string[]
    private loopFrames: string[]

    private smokeFrames: string[] = ['smoke_1', 'smoke_2', 'smoke_3', 'smoke_4', 'smoke_5']
    private smokeFrame = 0
    private smokeSprite: Sprite

    private entrySprite: Sprite
    private loopSprite: Sprite
    private leaveSprite: Sprite

    private spawnTime: number

    private tickCount = 0

    state = 0

    private leaveCallback: () => void
    private sound: AudioBuffer

    constructor(
        readonly delegate: JungleCruiseDelegate,
        entry: any,
        leaveCallback?: () => void
    ) {
        super()
        this.sound = this.delegate.soundMap.get(entry.sound)

        this.leaveCallback = leaveCallback
        this.id = entry.ID
        this.blocker = entry.blocker
        this.scared = entry.scared
        this.entryFrames = entry['frames.entry']
        this.leaveFrames = entry['frames.leave']
        this.loopFrames = entry['frames.loop']

        const currentFrame = this.entryFrames[0]
        this.entrySprite = Pooler.newSprite(currentFrame)

        this.smokeSprite = Pooler.newSprite(this.smokeFrames[this.smokeFrame])

        this.loopSprite = Pooler.newSprite(this.loopFrames[0])
        this.leaveSprite = Pooler.newSprite(this.leaveFrames[0])

        this.addChild(this.entrySprite)
        this.addChild(this.smokeSprite)
        this.addChild(this.loopSprite)
        this.addChild(this.leaveSprite)

        this.loopSprite.visible = this.leaveSprite.visible = false
    }

    setSpawnTime(): void {
        this.spawnTime = new Date().getTime()
    }

    getSpawnTime(): number {
        return this.spawnTime
    }

    setLeaveCallback(cb: () => void): void {
        this.leaveCallback = cb
    }

    leave(): void {
        this.state = 2
    }

    tick(): void {
        this.totalTicks++
        if (this.firstTick) {
            this.firstTick = false
            this.playSound()
            this.setSpawnTime()
        }

        if (this.tickCount >= 2) {
            this.tickCount = 0

            switch (this.state) {
                case 0:
                    this.entryFrame++
                    this.smokeFrame++
                    break
                case 1:
                    this.loopFrame++
                    break
                case 2:
                    this.leaveFrame++
                    break
            }
            this.update()
        } else {
            this.tickCount++
        }
    }

    playSound(): void {
        SoundManager.shared.play(ESndGrp.SFX, this.sound)
    }

    update(): void {
        if (this.state === 0) {
            if (this.entryFrame >= this.entryFrames.length) {
                this.state = 1
                this.update()
            }

            const currentFrame = this.entryFrames[this.entryFrame]
            if (currentFrame) {
                this.entrySprite.texture = Texture.from(currentFrame)
                this.entrySprite.anchor.copyFrom(this.entrySprite.texture.defaultAnchor ?? { x: 0, y: 0 })
            }

            this.entrySprite.visible = true
            this.smokeSprite.visible = true
            this.loopSprite.visible = false
            this.leaveSprite.visible = false

            if (this.smokeFrame >= this.smokeFrames.length) {
                this.smokeSprite.visible = false
                return
            }

            const currentSmokeFrame = this.smokeFrames[this.smokeFrame]
            if (currentSmokeFrame) {
                this.smokeSprite.texture = Texture.from(currentSmokeFrame)
                this.smokeSprite.anchor.copyFrom(this.smokeSprite.texture.defaultAnchor ?? { x: 0, y: 0 })
            }
        } else if (this.state === 1) {
            if (this.loopFrame >= this.loopFrames.length) {
                this.loopFrame = 0
            }

            const currentFrame = this.loopFrames[this.loopFrame]
            if (currentFrame) {
                this.loopSprite.texture = Texture.from(currentFrame)
                this.loopSprite.anchor.copyFrom(this.loopSprite.texture.defaultAnchor ?? { x: 0, y: 0 })
            }

            this.entrySprite.visible = false
            this.smokeSprite.visible = false
            this.loopSprite.visible = true
            this.leaveSprite.visible = false
        } else if (this.state === 2) {
            if (this.leaveFrame >= this.leaveFrames.length) {
                this.entrySprite.visible = false
                this.smokeSprite.visible = false
                this.loopSprite.visible = false
                this.leaveSprite.visible = false

                if (!this.left) {
                    if (this.leaveCallback) {
                        this.leaveCallback()
                    }
                    this.left = true
                }
                return
            }

            const currentFrame = this.leaveFrames[this.leaveFrame]
            if (currentFrame) {
                this.leaveSprite.texture = Texture.from(currentFrame)
                this.leaveSprite.anchor.copyFrom(this.leaveSprite.texture.defaultAnchor ?? { x: 0, y: 0 })
            }

            this.entrySprite.visible = false
            this.smokeSprite.visible = false
            this.loopSprite.visible = false
            this.leaveSprite.visible = true
        }
    }
}
