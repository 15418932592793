import type { EItemType, ItemDefUid } from '@vmk-legacy/common-ts'
import { Pooler } from '@vmk-legacy/render-utils'
import Fuse from 'fuse.js'
import { BitmapText, Container, EventBoundary, FederatedPointerEvent } from 'pixi.js'
import { Client } from '../../../../../Client.js'
import { Constants } from '../../../../../Constants.js'
import { DummyItem } from '../../../../../data/ItemStack.js'
import { type EntityRoomIdentifier, EWindow, UILayer } from '../../../../../enums.js'
import { Fonts } from '../../../../../Fonts.js'
import { BitmapTextButton } from '../../../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../../../buttons/BitmapTextButtonType.js'
import { ImageButton } from '../../../../buttons/ImageButton.js'
import { BasicBox } from '../../../../containers/BasicBox.js'
import { DOMText } from '../../../../DOMText.js'
import { FieldG } from '../../../../fields/FieldG.js'
import { FieldH } from '../../../../fields/FieldH.js'
import { PagedThumbnailBox } from '../../../../thumbs/PagedThumbnailBox.js'
import { TextColor } from '../../../../VMKLText'
import type { IWindow } from '../../../IWindow.js'

export class MKGivePopup extends Container implements IWindow {
    readonly kind = EWindow.Other
    layer = UILayer.AlwaysOnTop
    private ign: string
    private id: number
    private itemToGive: DummyItem | null = null
    private itemPreviewContainer: PagedThumbnailBox
    private itemPreviewController: Container

    header: Container
    isDraggable = true
    exitBtn: ImageButton

    setVisible(visible: boolean) {
        this.container.visible = visible
        this.containerContainer.visible = visible
        this.visible = visible
    }

    reset() {}

    container: Container
    private title: string

    private totalWidth: number
    private totalHeight: number

    private titleTf: DOMText

    private containerContainer: Container

    static itemDefs: { defUid: ItemDefUid; name: string; type: EItemType }[]

    constructor(id: EntityRoomIdentifier | number, ign: string) {
        super()

        if (typeof id === 'string') {
            if (isNaN(+id)) {
                return
            }
            id = +id
        }

        this.id = id
        this.ign = ign

        this.title = `Give An Item to ${ign}`

        this.exitBtn = new ImageButton('button.closeb.active', 'button.closeb.pressed')

        this.containerContainer = Pooler.newContainer()
        this.container = Pooler.newContainer()

        const bg = new BasicBox(350, 290)
        this.container.addChild(bg)

        const titleBg = new FieldH(279 + 64)

        this.container.addChild(titleBg)
        titleBg.x = 9
        titleBg.y = 15

        this.eventMode = 'static'

        const titleText = new BitmapText(this.title || '', {
            ...Fonts.FoxleyBold_16,
            align: 'left'
        })
        titleText.y = 16
        const titlewidth: number = titleText.width
        titleText.x = Math.round((bg.width - titlewidth) / 2)
        this.container.addChild(titleText)

        this.header = titleBg

        const searchHeader = new BitmapText('Name:', {
            ...Fonts.FoxleyBold_16
        })
        searchHeader.position.set(15, 40)
        this.container.addChild(searchHeader)

        const fieldOffX = 8
        const tfWidth = 180

        this.titleTf = new DOMText({
            kind: 'field',
            className: 'mk',
            id: 'mk.alert.title',
            maxlength: 32,
            fieldWidth: tfWidth - 10,
            fieldHeight: 16,
            fontSize: 16,
            fontColor: TextColor.white,
            initialValue: '',
            bgObject: new FieldH(tfWidth),
            padLeft: 9,
            padTop: -3
        })
        this.titleTf.setSubmitHandler(() => {
            const event = new FederatedPointerEvent(new EventBoundary(searchButton))
            event.type = 'pointerup'
            event.target = searchButton
            searchButton.dispatchEvent(event)
        })
        this.titleTf.position.set(83 + fieldOffX, 38)
        this.container.addChild(this.titleTf)

        const searchButton = new BitmapTextButton(70, 'SEARCH', BitmapTextButtonType.LIGHT_BLUE)
        searchButton.position.set(this.titleTf.x + tfWidth + 15, this.titleTf.y - 2)
        this.container.addChild(searchButton)

        const thumbContainerX = 35
        const thumbContainerY = 70

        this.itemPreviewContainer = new PagedThumbnailBox({
            rows: 3,
            cols: 7,
            items: [],
            spacing: 1
        })
        this.itemPreviewContainer.position.set(thumbContainerX, thumbContainerY - 10)
        this.container.addChild(this.itemPreviewContainer)

        const itemNameText = new BitmapText('(No item selected)', {
            ...Fonts.Foxley_16,
            wordWrapWidth: 275,
            align: 'center'
        })
        itemNameText.x = Math.round((bg.width - itemNameText.width) / 2)
        itemNameText.y = 210
        this.container.addChild(itemNameText)

        searchButton.addEventListener('pointerup', () => {
            const term = this.titleTf.getValue().trim()
            if (!term.length) {
                return
            }

            if (!MKGivePopup.itemDefs) {
                console.log('Item definitions not loaded')
                return
            }

            const fuse = new Fuse(MKGivePopup.itemDefs, {
                includeScore: true,
                keys: ['name']
            })
            const result = fuse.search(term)
            const items: DummyItem[] = []

            result.forEach((rr) => {
                const item = new DummyItem(-1, rr.item.defUid, rr.item.type)
                items.push(item)
            })

            this.container.removeChild(this.itemPreviewContainer)?.destroy()
            this.container.removeChild(this.itemPreviewController)?.destroy()

            this.itemToGive = null
            this.itemPreviewContainer = new PagedThumbnailBox({
                rows: 3,
                cols: 7,
                items: items,
                spacing: 1,
                onItemTap: (item) => {
                    this.itemToGive = item
                    this.itemPreviewContainer.setSelected(item)
                    itemNameText.tint = 0xffffff
                    itemNameText.text = item.getName()
                    itemNameText.x = Math.round((bg.width - itemNameText.width) / 2)
                    giveItemButton.enable()
                },
                onItemDoubleTap: () => {},
                pageTurnSpacing: 310,
                pageTurnFormat: PagedThumbnailBox.FORMAT_BLANK
            })
            this.itemPreviewContainer.position.set(thumbContainerX, thumbContainerY)
            this.container.addChild(this.itemPreviewContainer)

            this.itemPreviewController = this.itemPreviewContainer.getControllerContainer()
            this.itemPreviewController.x = 12
            this.itemPreviewController.y = 135
            this.container.addChild(this.itemPreviewController)
        })

        const qty = new DOMText({
            kind: 'field',
            className: '',
            id: 'mk-give-qty',
            maxlength: 3,
            fieldWidth: 75,
            fieldHeight: 19,
            fontSize: 16,
            fontColor: TextColor.black,
            initialValue: 1,
            bgObject: new FieldG(70, 8),
            padLeft: 5,
            padTop: 0,
            noBreaks: false,
            enterHint: '',
            inputMode: 'numeric'
        })

        qty.position.set(175, 242)
        const qtyLabel = new BitmapText('Quantity:', {
            ...Fonts.Foxley_16
        })
        qtyLabel.position.set(120, 244)
        this.container.addChild(qty, qtyLabel)

        qty.getElement().setAttribute('min', '1')
        qty.getElement().setAttribute('max', '100')

        const giveItemButton = new BitmapTextButton(150, 'GIVE ITEM', BitmapTextButtonType.GREEN)
        giveItemButton.addEventListener('pointerup', async () => {
            const item = MKGivePopup.itemDefs.find((i) => i.defUid === this.itemToGive.defUid)
            if (!item) {
                return
            }
            const amount = +qty.getValue()

            Client.shared.serverBroker
                .sendAck('mk_give_item', {
                    userId: this.id,
                    defUid: item.defUid,
                    stars: 0,
                    qty: amount
                })
                .then((success) => {
                    if (success) {
                        itemNameText.tint = 0x49eb34
                        itemNameText.text = amount + ' x ' + item.name + ' successfully given to ' + ign + '.'
                        qty.setValue('1')
                        this.itemPreviewContainer.setSelected(null)
                        this.itemToGive = null
                        giveItemButton.disable()
                    } else {
                        itemNameText.tint = 0xeb6275
                        itemNameText.text = 'Server encountered error giving item.'
                    }
                    itemNameText.x = Math.round((bg.width - itemNameText.width) / 2)
                })
        })
        giveItemButton.position.set(30, 275)
        this.container.addChild(giveItemButton)
        giveItemButton.disable()

        const cancelButton = new BitmapTextButton(150, 'CLOSE', BitmapTextButtonType.RED)
        cancelButton.position.set(191, 275)
        this.container.addChild(cancelButton)
        cancelButton.once('pointerup', () => {
            Client.shared.userInterface.removeWindow(this)
        })

        this.containerContainer.addChild(this.container)
        this.addChild(this.containerContainer)
        this.center()

        this.on('added', () => {
            this.titleTf.forceFocus()
        })

        Client.shared.userInterface.bringToFront(this)
    }

    onIgnore() {}

    onOk() {}

    center() {
        this.containerContainer.x = Math.round((Constants.SIZE[0] - this.width) / 2)
        this.containerContainer.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }

    tryRemove() {
        if (this.parent !== null) {
            this.parent.removeChild(this)
        }
    }

    getWidth(): number {
        return this.totalWidth
    }

    getHeight(): number {
        return this.totalHeight
    }
}
