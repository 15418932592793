import { EItemType } from '@vmk-legacy/common-ts'
import { Pooler } from '@vmk-legacy/render-utils'
import type { Container, FederatedEvent, Sprite } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { getText } from '../../../../assets/ExternalConfigManager.js'
import { Client } from '../../../../Client.js'
import { Fonts } from '../../../../Fonts.js'
import { BitmapTextButton } from '../../../buttons/BitmapTextButton.js'
import { ScrollArea } from '../../../containers/ScrollArea.js'
import { Field } from '../../../fields/Field.js'
import { FieldShop } from '../../../fields/FieldShop.js'
import { AccordionList } from '../../../lists/AccordionList.js'
import { AccordionListItem } from '../../../lists/AccordionListItem.js'
import { PagedThumbnailBox } from '../../../thumbs/PagedThumbnailBox.js'
import { UIWindowView } from '../../UIWindowView.js'
import type { ShopCategoryParent } from '../model/ShopCategoryParent.js'
import type { ShopItem } from '../model/ShopItem.js'
import type { ShopWindow } from '../ShopWindow.js'

export class ShopView extends UIWindowView<ShopWindow> {
    padding = 3
    private categories: FieldShop[]
    private acc: AccordionList
    private logo: Sprite
    private allFields: FieldShop[]
    private previewBox: Sprite
    private itemText: BitmapText

    private previewContainer: Container

    private items: ShopItem[]
    private ptb: PagedThumbnailBox

    selectedItem: ShopItem = null
    private scrollArea: ScrollArea

    private priceNumText: BitmapText

    private rightContain: Container
    private buyBtn: BitmapTextButton

    private plusBtn: BitmapTextButton
    private minusBtn: BitmapTextButton
    private quantity = 1

    constructor() {
        super()

        this.eventMode = 'static'
        this.interactiveChildren = true

        this.categories = []
        this.allFields = []
        this.items = []

        this.rightContain = this.addChild(Pooler.newContainer())

        this.setLogo(6)

        this.itemText = new BitmapText('', {
            ...Fonts.Foxley_16,
            align: 'center',
            wordWrap: true,
            wordWrapWidth: 120
        })
        this.itemText.y = 43
        this.itemText.roundPixels = true
        this.setItemText('Select an item to preview!')
        this.rightContain.addChild(this.itemText)

        this.previewBox = Pooler.newSprite('previewbox.b')
        this.previewBox.x = 378
        this.previewBox.y = 90
        this.previewBox.scale.set(0.5)
        this.rightContain.addChild(this.previewBox)

        this.previewContainer = Pooler.newContainer()
        this.previewContainer.x = 381
        this.previewContainer.y = 90
        this.rightContain.addChild(this.previewContainer)

        this.ptb = new PagedThumbnailBox({
            rows: 5,
            cols: 3,
            items: [],
            spacing: 3,
            onItemTap: (item: ShopItem, preview: Container) => {
                this.select(item, preview)
            },
            onItemDoubleTap: (item: ShopItem, preview: Container) => {
                this.select(item, preview)
                this.onBuyPress()
            },
            pageTurnSpacing: 80,
            pageTurnFormat: PagedThumbnailBox.FORMAT_C,
            dimUntradeables: false,
            initialPage: undefined
        })
        this.ptb.y = 46
        this.ptb.x = 237
        this.addChild(this.ptb)

        const controller = this.ptb.getControllerContainer()
        controller.y = 285
        controller.x = this.ptb.x + 10
        this.addChild(controller)

        const priceBg = new Field('f', 119)
        priceBg.x = this.previewBox.x
        priceBg.y = 265
        this.rightContain.addChild(priceBg)

        this.buyBtn = new BitmapTextButton(76, 'BUY 1', 'g')
        this.buyBtn.x = this.previewBox.x + 26
        this.buyBtn.y = priceBg.y + priceBg.height + 4
        this.buyBtn.disable()
        this.buyBtn.addEventListener('pointerup', () => {
            this.onBuyPress()
        })
        this.minusBtn = new BitmapTextButton(22, '-', 'g')
        this.minusBtn.x = this.previewBox.x
        this.minusBtn.y = this.buyBtn.y
        this.minusBtn.disable()
        this.minusBtn.addEventListener('pointerup', () => {
            if (this.quantity <= 2) {
                this.minusBtn.disable()
                this.quantity = 1
                this.buyBtn.setText('BUY 1')
                return
            }
            this.quantity--
            this.buyBtn.setText('BUY ' + this.quantity)
        })
        this.plusBtn = new BitmapTextButton(22, '+', 'g')
        this.plusBtn.x = this.buyBtn.x + 80
        this.plusBtn.y = this.buyBtn.y
        this.plusBtn.disable()
        this.plusBtn.addEventListener('pointerup', () => {
            if (this.quantity >= 100) {
                return
            }
            this.quantity++
            if (this.quantity === 100) {
                this.plusBtn.disable()
            }
            this.buyBtn.setText('BUY ' + this.quantity)
            if (this.quantity > 1) {
                this.minusBtn.enable()
            }
        })

        this.rightContain.addChild(this.buyBtn, this.minusBtn, this.plusBtn)

        this.priceNumText = new BitmapText('? Credit', {
            ...Fonts.Foxley_16
        })
        this.priceNumText.y = priceBg.y + 5
        this.rightContain.addChild(this.priceNumText)

        this.priceNumText.visible = false
    }

    private setPriceText(price: number) {
        this.priceNumText.text = price + ' Credit'
        this.priceNumText.x = 5 + this.previewBox.x + Math.round((122 - this.priceNumText.width) / 2)
        this.priceNumText.visible = true
    }

    private setItems(items: ShopItem[]) {
        this.ptb.setItems(...items)
    }

    private async onBuyPress() {
        if (this.selectedItem) {
            this.buyBtn.disable()
            this.minusBtn.disable()
            this.plusBtn.disable()

            let itemName = getText(
                (this.selectedItem.getType() === EItemType.Pack ? 'pack.' : 'item.') + this.selectedItem.getDefUid()
            )

            if (itemName.includes('Teleporter')) {
                itemName += ' (Pair)'
            }

            if (
                await Client.shared.helpers.confirm(
                    `Are you sure you want to buy ${this.quantity} of:\n${itemName}\n\nTotal cost: ${this.quantity * this.selectedItem.getPrice()} credit`
                )
            ) {
                Client.shared.serverBroker
                    .sendAck('buy_request', {
                        catalogId: this.selectedItem.getCatalogId(),
                        qty: this.quantity
                    })
                    .then(() => {
                        this.quantity = 1

                        this.buyBtn.setText('BUY 1')

                        this.minusBtn.disable()
                        this.buyBtn.enable()
                        this.plusBtn.enable()
                    })
            } else {
                if (this.quantity > 1) {
                    this.minusBtn.enable()
                } else {
                    this.minusBtn.disable()
                }

                this.buyBtn.enable()

                if (this.quantity < 100) {
                    this.plusBtn.enable()
                } else {
                    this.plusBtn.disable()
                }
            }
        }
    }

    private setItemText(text: string) {
        this.itemText.text = text
        this.itemText.x = 380 + Math.round((120 - this.itemText.width) / 2)

        if (this.itemText.height > 38) {
            this.itemText.y = 34
        } else {
            this.itemText.y = 43
        }
    }

    private setLogo(id: number) {
        if (this.logo !== null) {
            this.rightContain.removeChild(this.logo)
        }
        this.logo = Pooler.newSprite('logo.' + id)
        this.logo.x = 298
        this.rightContain.addChild(this.logo)
    }

    private addCategoryWithChildren(
        categoryName: string,
        categoryLogo: number,
        categoryItems: ShopItem[],
        children: FieldShop[]
    ): AccordionListItem {
        const catItem: AccordionListItem = new AccordionListItem()

        // Create a field and add it to category item
        const catField = new FieldShop(categoryName, true, categoryLogo, categoryItems)

        catField.eventMode = 'static'
        catItem.addChild(catField)

        this.allFields.push(catField)
        children.forEach((child) => {
            this.allFields.push(child)
        })

        this.categories.push(catField)

        this.acc.setExpanded(catItem, true)

        catField.addEventListener('pointerup', (e: FederatedEvent) => {
            this.clearFields()
            catField.setSelected(!catField.isSelected())
            this.ptb.setPage(1)
            this.setItems(catField.getItems())
            this.setLogo(catField.getLogoId())
        })

        children.forEach((childField) => {
            const childItem = new AccordionListItem()
            childField.eventMode = 'static'
            childItem.eventMode = 'static'
            childItem.addEventListener('pointerup', (e: FederatedEvent) => {
                this.categories.forEach((category: FieldShop) => {
                    category.setSelected(false)
                })
                this.acc.clearSelection()
                childField.setSelected(!childField.isSelected())
                this.setItems(childField.getItems())
                this.setLogo(childField.getLogoId())
            })
            childItem.addChild(childField)
            catItem.addChild(childItem)
        })
        console.log(catItem)
        return catItem
    }

    private clearFields() {
        this.allFields.forEach((field) => {
            field.setSelected(false)
        })
    }

    private setPreviewScale(preview: Container | Sprite) {
        if (preview['scaleSet']) {
            return
        }
        preview['scaleSet'] = true
        const bgWidth = 125
        const bgHeight = 170
        preview.cacheAsBitmap = true
        const sA = 1
        const pA: number = preview.width / preview.height
        let sF: number
        if (sA > pA) {
            sF = bgWidth / preview.height
        } else {
            sF = bgWidth / preview.width
        }
        sF = sF * 0.9
        preview.scale.set(sF)
        if (preview.getLocalBounds() !== null) {
            preview.x += Math.round(Math.abs(preview.getLocalBounds().x) * sF)
            preview.y += Math.round(Math.abs(preview.getLocalBounds().y) * sF)
        } else {
            console.error('Local bounds null')
        }
        preview.x += Math.floor((bgWidth - preview.width) / 2)
        preview.y += Math.floor((bgHeight - preview.height) / 2)
    }

    private select(item: ShopItem, preview: Container) {
        this.ptb.setSelected(item)

        this.quantity = 1
        this.buyBtn.setText('BUY 1')
        this.minusBtn.disable()
        this.plusBtn.enable()
        this.buyBtn.enable()
        this.previewContainer.removeChildren()
        this.setPreviewScale(preview)
        this.previewContainer.addChild(preview)
        this.selectedItem = item
        this.setItemText(item.getName())
        this.setPriceText(item.getPrice())
    }

    setShops(shops: ShopCategoryParent[]) {
        this.scrollArea?.removeChildren()
        this.acc?.destroy()
        this.removeChild(this.scrollArea)
        this.scrollArea = new ScrollArea(204, 290, null, 6, 6)
        if (shops.length) {
            this.acc = new AccordionList(this.scrollArea)

            // Add content to accordion
            shops.forEach((shop) => {
                const childFields: FieldShop[] = []
                shop.getCategories().forEach((category) => {
                    const field = new FieldShop(category.getName(), false, category.getLogoId(), category.getItems())

                    childFields.push(field)
                })
                this.acc.add(
                    this.addCategoryWithChildren(shop.getName(), shop.getLogoId(), shop.getItems(), childFields)
                )
            })

            this.scrollArea.setContent(this.acc)

            this.rightContain.visible = true
        } else {
            const text = new BitmapText(getText('cat.visitshops'), {
                ...Fonts.Foxley_16,
                wordWrap: true,
                wordWrapWidth: 198
            })

            this.scrollArea.setContent(text)
            this.rightContain.visible = false
        }
        this.addChildAt(this.scrollArea, 0)
    }
}
