import { EPerm } from '@vmk-legacy/common-ts'
import { ESndGrp, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import type { Container, Sprite } from 'pixi.js'
import { Graphics } from 'pixi.js'
import posthog from 'posthog-js'
import { Client } from '../Client.js'
import { Constants } from '../Constants.js'
import { EWindow } from '../enums.js'
import { HighlightButton } from './buttons/HighlightButton.js'
import { DOMText } from './DOMText.js'
import { ResponsiveContainer } from './views/AlertView.js'
import { TextColor } from './VMKLText'

export class Toolbar extends ResponsiveContainer {
    private bg: Graphics
    infoBtn: HighlightButton
    navBtn: HighlightButton
    inventoryBtn: HighlightButton
    msgBtn: HighlightButton
    shopBtn: HighlightButton
    questBtn: HighlightButton
    emoteBtn: HighlightButton
    magicBtn: HighlightButton
    camBtn: HighlightButton
    avatarBtn: HighlightButton
    settingsBtn: HighlightButton
    helpBtn: HighlightButton
    exitBtn: HighlightButton

    buttons: HighlightButton[]

    private chatContain: Container
    private chatbarBGLeft: Sprite
    chatbarBGMiddle: Sprite
    private chatbarBGRight: Sprite

    chatbarInput: DOMText

    private fixedContainer: Container
    private btnContain: Container

    constructor() {
        super()

        this.bg = new Graphics()
        this.addChild(this.bg)

        const btnContain = this.addChild(Pooler.newContainer())

        this.infoBtn = new HighlightButton('button.info.active', 'Info')
        this.infoBtn.disable()

        btnContain.addChild(this.infoBtn)
        this.infoBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.toggleWindow(EWindow.RoomInfoBox, true)
            posthog.capture('toolbar_pressed', { button: 'info' })
        })

        this.navBtn = new HighlightButton('button.navigator.active', 'Map')
        btnContain.addChild(this.navBtn)
        this.navBtn.eventMode = 'static'
        this.navBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.toggleWindow(EWindow.Navigator, true)
            posthog.capture('toolbar_pressed', { button: 'nav' })
        })

        this.inventoryBtn = new HighlightButton('button.inventory.active', 'Stuff')
        this.inventoryBtn.disable()
        btnContain.addChild(this.inventoryBtn)

        this.inventoryBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.toggleWindow(EWindow.Inventory, true)
            posthog.capture('toolbar_pressed', { button: 'inv' })
        })

        this.msgBtn = new HighlightButton('button.messenger.active', 'Mail')
        btnContain.addChild(this.msgBtn)

        this.msgBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.toggleWindow(EWindow.Messenger, true)
            posthog.capture('toolbar_pressed', { button: 'msg' })
        })

        this.shopBtn = new HighlightButton('button.shop.active', 'Shop')
        this.shopBtn.disable()
        btnContain.addChild(this.shopBtn)
        this.shopBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.toggleWindow(EWindow.Shopping, true)
            posthog.capture('toolbar_pressed', { button: 'shop' })
        })

        this.questBtn = new HighlightButton('button.quest.active', 'Quests')
        this.questBtn.disable()
        btnContain.addChild(this.questBtn)

        this.emoteBtn = new HighlightButton('button.emoticons.active', 'Emotes')
        this.emoteBtn.disable()
        this.emoteBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.toggleWindow(EWindow.Emotes, true)
            posthog.capture('toolbar_pressed', { button: 'emote' })
        })
        btnContain.addChild(this.emoteBtn)

        this.magicBtn = new HighlightButton('button.magics.active', 'Magic')
        this.magicBtn.disable()
        this.magicBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.toggleWindow(EWindow.Magic, true)
            posthog.capture('toolbar_pressed', { button: 'magic' })
        })
        btnContain.addChild(this.magicBtn)

        this.camBtn = new HighlightButton('button.camera.active', 'Camera')
        this.camBtn.disable()
        this.camBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.toggleWindow(EWindow.CamButtons, true)
            posthog.capture('toolbar_pressed', { button: 'cam' })
        })
        btnContain.addChild(this.camBtn)

        this.avatarBtn = new HighlightButton('button.avatar.active', 'Character')
        this.avatarBtn.disable()
        btnContain.addChild(this.avatarBtn)
        this.avatarBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.toggleWindow(EWindow.Character, true)
            posthog.capture('toolbar_pressed', { button: 'char' })
        })

        this.settingsBtn = new HighlightButton('button.settings.active', 'Settings')
        this.settingsBtn.disable()
        btnContain.addChild(this.settingsBtn)
        this.settingsBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.toggleWindow(EWindow.Settings, true)
            posthog.capture('toolbar_pressed', { button: 'set' })
        })

        this.helpBtn = new HighlightButton('button.chatbarhelp.active', 'Help')
        btnContain.addChild(this.helpBtn)
        this.helpBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.toggleWindow(EWindow.Help, true)
            posthog.capture('toolbar_pressed', { button: 'help' })
        })

        this.btnContain = btnContain
        this.updateExitButton()

        this.chatContain = Pooler.newContainer()
        this.chatContain.x = 224

        this.chatbarBGLeft = Pooler.newSprite('chatfield.left')
        this.chatbarBGLeft.x = 0
        this.chatContain.addChild(this.chatbarBGLeft)

        this.chatbarBGMiddle = Pooler.newSprite('chatfield.middle')
        this.chatbarBGMiddle.scale.x = 367

        this.chatbarInput = new DOMText({
            kind: 'field',
            className: 'chat',
            id: 'chatbar',
            maxlength: Client.shared.selfRecord.can(EPerm.MKAccessClient) ? 255 : 90,
            fieldWidth: 367,
            fieldHeight: 16,
            fontSize: 16,
            fontColor: TextColor.white,
            initialValue: '',
            bgObject: this.chatbarBGMiddle,
            padLeft: 0,
            padTop: 5,
            noBreaks: false,
            enterHint: 'send',
            accHint: 'Chat'
        })

        this.chatbarInput.getElement().autocomplete = 'off'
        this.chatbarInput.getElement().spellcheck = true
        this.chatbarInput.x = 5
        this.chatContain.addChild(this.chatbarInput)

        this.chatbarBGRight = Pooler.newSprite('chatfield.right')
        this.chatbarBGRight.x = 372
        this.chatContain.addChild(this.chatbarBGRight)

        this.infoBtn.x = 0
        this.navBtn.x = 32
        this.inventoryBtn.x = 64
        this.msgBtn.x = 96
        this.shopBtn.x = 128
        this.questBtn.x = 160
        this.emoteBtn.x = 186
        this.magicBtn.x = 602
        this.camBtn.x = 642
        this.avatarBtn.x = 674
        this.settingsBtn.x = 706
        this.helpBtn.x = 738
        this.fixedContainer = btnContain

        btnContain.addChild(this.chatContain)

        this.buttons = [
            this.infoBtn,
            this.navBtn,
            this.inventoryBtn,
            this.msgBtn,
            this.shopBtn,
            this.questBtn,
            this.emoteBtn,
            this.magicBtn,
            this.camBtn,
            this.avatarBtn,
            this.settingsBtn,
            this.helpBtn,
            this.exitBtn
        ]

        this.sizeDidChange(Client.shared.size)
    }

    updateExitButton(): void {
        if (this.exitBtn) {
            this.buttons?.splice(this.buttons?.indexOf(this.exitBtn), 1)
            this.exitBtn.destroy()
        }
        if (Client.shared.selfRecord.can(EPerm.MKAccessClient)) {
            this.exitBtn = new HighlightButton('button.mk.active', 'MK')
            this.exitBtn.addEventListener('pointerup', () =>
                Client.shared.userInterface.toggleWindow(EWindow.Mod, true)
            )
        } else {
            this.exitBtn = new HighlightButton('button.exit.active', 'Exit')
            this.exitBtn.addEventListener('pointerup', () => {
                Client.shared.interceptLogout()
                posthog.capture('toolbar_pressed', { button: 'exit' })
            })
        }

        this.btnContain.addChild(this.exitBtn)
        this.exitBtn.x = 770

        this.buttons?.push(this.exitBtn)
    }

    override sizeDidChange(size): void {
        this.position.set(0, size.safeArea.maxY - 28)

        this.fixedContainer.position.set(size.width / 2 - this.fixedContainer.width / 2, 0)

        this.bg.clear()
        this.bg.beginFill(Constants.TOOLBAR_BG_COLOR)
        this.bg.drawRect(0, 0, size.width, size.bounds.height - this.y)
        this.bg.endFill()
    }

    stopInfoGlow(): void {
        this.infoBtn.stopGlow()
    }

    infoButtonGlow(): void {
        this.infoBtn.beginGlow()
    }

    stopMessageGlow(): void {
        this.msgBtn.stopGlow()
    }

    messageButtonGlow(): void {
        this.msgBtn.beginGlow()
    }

    enableChatbar(): void {
        this.chatbarInput.getElement().style.display = 'inline-block'
        this.chatbarInput.show()
        this.chatbarInput.setReadonly(false)
    }

    disableChatbar(): void {
        this.chatbarInput.getElement().style.display = 'none'
        this.chatbarInput.setValue('')
        this.chatbarInput.setReadonly(true)
    }

    disableAllButtons(): void {
        this.buttons.forEach((button) => {
            button.disable()
        })
        if (Client.shared.selfRecord.can(EPerm.MKAccessClient)) {
            this.exitBtn.enable()
        }
    }

    show(): void {
        this.visible = true
    }

    hide(): void {
        this.visible = false

        SoundManager.shared.stopAll(ESndGrp.Music)
    }

    enableAll(): void {
        this.show()
        this.disableAll()

        if (Client.shared.isSpectating) {
            return
        }
        this.buttons.forEach((button) => {
            button.enable()
        })

        if (Client.shared.selfRecord.getFilm() === null) {
            this.camBtn.disable()
        }
        this.questBtn.disable()
    }

    enableMessages(): void {
        this.msgBtn.enable()
    }

    enableSettings(): void {
        this.settingsBtn.enable()
    }

    enableHelp(): void {
        this.helpBtn.enable()
    }

    enableMapButtons(): void {
        this.show()
        this.disableAll()
        if (Client.shared.isSpectating) {
            return
        }
        this.enableMessages()
        this.enableSettings()
        this.enableHelp()
        if (Client.shared.selfRecord.can(EPerm.MKAccessClient)) {
            this.magicBtn.enable()
        }

        if (Client.shared.roomViewer?.hasBeenRevealed) {
            this.navBtn.enable()
        } else {
            this.navBtn.disable()
        }
    }

    disableAll(): void {
        this.disableAllButtons()
    }
}
