import { Pooler } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { BitmapText, Container } from 'pixi.js'
import { Client } from '../../../../../Client.js'
import { Constants } from '../../../../../Constants.js'
import { EWindow, UILayer } from '../../../../../enums.js'
import { Fonts } from '../../../../../Fonts.js'
import { BitmapTextButton } from '../../../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../../../buttons/BitmapTextButtonType.js'
import { ImageButton } from '../../../../buttons/ImageButton.js'
import { BasicBox } from '../../../../containers/BasicBox.js'
import { DOMText } from '../../../../DOMText.js'
import { FieldH } from '../../../../fields/FieldH.js'
import { TextColor } from '../../../../VMKLText'
import type { IWindow } from '../../../IWindow.js'
import { Separator } from '../../../Separator.js'

export class MKChangeIgnPopup extends Container implements IWindow {
    readonly kind = EWindow.Other
    layer = UILayer.AlwaysOnTop
    private ign: string
    private id: number

    header: Sprite
    isDraggable = true
    exitBtn: ImageButton

    setVisible(visible: boolean) {
        this.container.visible = visible
        this.containerContainer.visible = visible
        this.visible = visible
    }

    reset() {}

    container: Container
    private title: string
    private body: string // \n for newline

    private totalWidth: number
    private totalHeight: number

    private reasonTf: DOMText

    private containerContainer: Container

    constructor(id: number, ign: string) {
        super()

        this.id = id
        this.ign = ign

        this.title = `Edit ${ign}'s IGN`
        this.body = `Enter a new IGN for ${ign} (uid ${id}) below`

        this.exitBtn = new ImageButton('button.closeb.active', 'button.closeb.pressed')

        this.containerContainer = Pooler.newContainer()
        this.container = Pooler.newContainer()

        const bg: BasicBox = new BasicBox(286, 134)
        this.container.addChild(bg)

        const titleBg: FieldH = new FieldH(279)

        this.container.addChild(titleBg)
        titleBg.x = 9
        titleBg.y = 13

        this.eventMode = 'static'

        const titleText = new BitmapText(this.title || '', {
            ...Fonts.FoxleyBold_16,
            align: 'left'
        })
        titleText.y = 14
        const titlewidth: number = titleText.width
        titleText.x = Math.round((bg.width - titlewidth) / 2)
        this.container.addChild(titleText)

        const bodyText = new BitmapText(this.body || '', {
            ...Fonts.Foxley_16
        })

        bodyText.x = Math.round((bg.width - bodyText.width) / 2)
        bodyText.y = 38

        this.container.addChild(bodyText)
        this.header = Pooler.newSprite()

        const separator = new Separator(277)
        separator.position.set(15, 54)
        this.container.addChild(separator)

        const offY = -14
        const reason = new BitmapText('NEW IGN:', {
            ...Fonts.FoxleyBold_16
        })
        reason.position.set(15, 82 + offY)
        this.container.addChild(reason)
        this.reasonTf = new DOMText({
            kind: 'field',
            className: 'mk',
            id: 'mk.ign',
            maxlength: 32,
            fieldWidth: 209,
            fieldHeight: 16,
            fontSize: 16,
            fontColor: TextColor.white,
            initialValue: '',
            bgObject: new FieldH(209),
            padLeft: 9,
            padTop: -3
        })

        this.reasonTf.position.set(60 + 8 + 8, 82 + offY)
        this.container.addChild(this.reasonTf)

        const verifyText = new BitmapText('Verify the information above before submitting.', {
            ...Fonts.Foxley_16
        })
        verifyText.tint = 0xff0000
        verifyText.align = 'center'
        verifyText.style.wordWrapWidth = 280
        verifyText.x = Math.round((bg.width - verifyText.width) / 2)
        verifyText.y = 106
        this.container.addChild(verifyText)

        const submitBtn = new BitmapTextButton(100, 'SUBMIT', BitmapTextButtonType.GREEN)
        submitBtn.position.set(43, 125)
        this.container.addChild(submitBtn)
        submitBtn.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('mk_change_ign', {
                id: id,
                ign: this.reasonTf.getValue().trim()
            })
            Client.shared.userInterface.removeWindow(this)
        })

        const cancelBtn = new BitmapTextButton(100, 'CANCEL', BitmapTextButtonType.RED)
        cancelBtn.position.set(43 + 115, 125)
        this.container.addChild(cancelBtn)
        cancelBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.removeWindow(this)
        })

        this.containerContainer.addChild(this.container)
        this.addChild(this.containerContainer)
        this.center()
        Client.shared.userInterface.register(this)

        Client.shared.userInterface.bringToFront(this)
    }

    onIgnore() {}

    onOk() {}

    center() {
        this.containerContainer.x = Math.round((Constants.SIZE[0] - this.width) / 2)
        this.containerContainer.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }

    tryRemove() {
        if (this.parent !== null) {
            this.parent.removeChild(this)
        }
    }

    getWidth(): number {
        return this.totalWidth
    }

    getHeight(): number {
        return this.totalHeight
    }
}
