import { BitmapText, Container } from 'pixi.js'
import { Constants } from '../../Constants.js'
import { UILayer } from '../../enums.js'
import { Fonts } from '../../Fonts.js'
import { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../buttons/BitmapTextButtonType.js'
import { BasicBox } from '../containers/BasicBox.js'
import { FieldH } from '../fields/FieldH.js'

export class RoomPurchaseWindow extends Container {
    layer = UILayer.GameWindows

    continueButton: BitmapTextButton
    enterButton: BitmapTextButton

    constructor(roomName: string, roomOwner: string, roomDesc: string) {
        super()

        const bg: BasicBox = new BasicBox(260, 300)
        this.addChild(bg)

        const titleBg: FieldH = new FieldH(260 - 12)
        this.addChild(titleBg)
        titleBg.x = titleBg.y = 12

        this.eventMode = 'static'

        const titleText = new BitmapText('Room created', Fonts.FoxleyBold_16)
        const titlewidth = titleText.width
        titleText.y = 13
        titleText.x = Math.round((bg.width - titlewidth) / 2)
        this.addChild(titleText)

        const congratsText = new BitmapText('Congratulations!', {
            ...Fonts.FoxleyBold_16
        })
        congratsText.x = 12
        congratsText.y = 38
        this.addChild(congratsText)

        const bodyText = new BitmapText('You just purchased a room - now go furnish it!', {
            ...Fonts.Foxley_16
        })
        bodyText.style.wordWrapWidth = 255
        bodyText.roundPixels = true
        bodyText.x = 12
        bodyText.y = 38 + 16
        this.addChild(bodyText)

        const roomInfo = new BitmapText('ROOM INFO:', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        roomInfo.x = 12
        roomInfo.y = 38 + 54
        this.addChild(roomInfo)

        const nameField = new BitmapText('Name:', {
            ...Fonts.FoxleyBold_16
        })
        nameField.x = 12
        nameField.y = 38 + 72
        this.addChild(nameField)
        const nameTxt = new BitmapText(roomName, {
            ...Fonts.Foxley_16
        })
        nameTxt.style.wordWrapWidth = 213
        nameTxt.roundPixels = true
        nameTxt.x = 12 + 46
        nameTxt.y = 38 + 74
        this.addChild(nameTxt)

        const ownerField = new BitmapText('Owner:', {
            ...Fonts.FoxleyBold_16
        })
        ownerField.x = 12
        ownerField.y = 38 + 105
        this.addChild(ownerField)
        const ownerTxt = new BitmapText(roomOwner, {
            ...Fonts.Foxley_16
        })
        ownerTxt.x = 12 + 51
        ownerTxt.y = 38 + 107
        this.addChild(ownerTxt)

        const descField = new BitmapText('Description:', {
            ...Fonts.FoxleyBold_16
        })
        descField.x = 12
        descField.y = 38 + 126
        this.addChild(descField)
        const descTxt = new BitmapText(roomDesc, {
            ...Fonts.Foxley_16
        })
        descTxt.x = 12
        descTxt.y = 38 + 144
        descTxt.style.wordWrapWidth = 256
        descTxt.roundPixels = true
        this.addChild(descTxt)

        this.continueButton = new BitmapTextButton(120, 'CONTINUE', BitmapTextButtonType.TEAL)
        this.continueButton.x = 10
        this.continueButton.y = 290
        this.addChild(this.continueButton)

        this.enterButton = new BitmapTextButton(120, 'ENTER ROOM', BitmapTextButtonType.TEAL)
        this.enterButton.x = 10 + 132
        this.enterButton.y = 290
        this.addChild(this.enterButton)
    }

    center() {
        this.x = Math.round((Constants.SIZE[0] - this.width) / 2)
        this.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }

    tryRemove() {
        if (this.parent !== null) {
            this.parent.removeChild(this)
        }
    }
}
