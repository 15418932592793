import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import { AnimatedSprite } from 'pixi.js'
import { Client } from '../../Client.js'
import type { EntityRoomIdentifier } from '../../enums.js'
import type { AvatarEntity } from '../../room/entities/AvatarEntity.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { UISoundLibrary } from '../../ui/UISoundLibrary.js'
import { Helpers } from '../../util/Helpers.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export const TeleportFrames: string[] = []
for (let i = 1; i <= 16; i++) {
    TeleportFrames.push('teleport_' + i)
}

export class TeleportedModule extends ServerEvent {
    static readonly type = MessageType.TELEPORTED

    async handle(data: {
        entityId: EntityRoomIdentifier
        ref?: number
        dir?: number
        skipAnim?: boolean
    }): Promise<void> {
        console.log('teleported ', data)
        const room = Client.shared.roomViewer
        if (room instanceof WalkableRoomViewer) {
            const avatar = room.getEntityByRef(data.entityId) as AvatarEntity
            if (!avatar) {
                return
            }

            avatar.getVisual().stopWalking()

            if (data.ref && (data.skipAnim || avatar.isInvisible())) {
                avatar.setDirection(+data.dir)
                avatar.setTileRef(+data.ref)
                avatar.jumpToPosition()
                return
            }
            SoundManager.shared.play(ESndGrp.SFX, UISoundLibrary.Teleport2)

            let teleport: AnimatedSprite | undefined
            if (avatar.tile) {
                teleport = AnimatedSprite.fromFrames(TeleportFrames)
                teleport.blendMode = 'screen'
                teleport.pivot.set(35, 136)
                teleport.animationSpeed = 0.15
                teleport.loop = false

                teleport.position.set(avatar.tile.x, avatar.tile.y - 25)
                room.sprites.addChild(teleport)
                teleport.play()
            }

            gsap.to(avatar.getVisual(), {
                alpha: 0,
                duration: 1,
                delay: 1,
                onComplete: () => {
                    if (data.ref && typeof data.dir === 'number') {
                        let teleport2: AnimatedSprite | undefined
                        const destTile = room.tiles.get(data.ref)
                        if (destTile) {
                            teleport2 = AnimatedSprite.fromFrames(TeleportFrames)
                            teleport2.blendMode = 'screen'
                            teleport2.pivot.set(35, 136)
                            teleport2.animationSpeed = 0.15
                            teleport2.loop = false
                            teleport2.position.set(destTile.x, destTile.y - 25)
                            room.sprites.addChild(teleport2)
                            teleport2.play()
                        }
                        Helpers.delay(1000).then(() => {
                            SoundManager.shared.play(ESndGrp.SFX, UISoundLibrary.Teleport4)
                            avatar.setDirection(+data.dir)
                            avatar.setTileRef(+data.ref)
                            avatar.jumpToPosition()
                            gsap.to(avatar.getVisual(), {
                                alpha: 1,
                                duration: 1,
                                onComplete() {
                                    teleport?.destroy()
                                    teleport2?.destroy()
                                }
                            })
                        })
                    }
                }
            })
        }
    }
}
