import { AvatarOutfit } from '@vmk-legacy/render-utils'
import { Client } from '../../Client.js'
import { DummyItem, ItemStack } from '../../data/ItemStack.js'
import { EWindow } from '../../enums.js'
import type { CharacterWindow } from '../../ui/windows/character/CharacterWindow.js'
import type { InventoryWindow } from '../../ui/windows/inventory/InventoryWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class InventoryModule extends ServerEvent {
    static readonly type = MessageType.INVENTORY

    async handle(inventory: {
        credits: number
        lanyard: any
        badges: number[]
        outfit: (string | number)[]
        stacks: any[][]
    }) {
        if ('credits' in inventory) {
            Client.shared.selfRecord.setCredits(inventory.credits)
        }
        if ('lanyard' in inventory) {
            Client.shared.selfRecord.setLanyard(inventory.lanyard.map((d) => new DummyItem(...d)))
        }
        if ('badges' in inventory) {
            Client.shared.selfRecord.setBadges(inventory.badges)
        }
        if ('outfit' in inventory) {
            Client.shared.selfRecord.setOutfit(AvatarOutfit.from(inventory.outfit))
        }

        if ('stacks' in inventory) {
            const charWin = (await Client.shared.userInterface?.getWin(EWindow.Character)) as CharacterWindow
            if (charWin) {
                charWin.inventoryUpdated()
            }
            Client.shared.selfRecord.getInventory().clear()
            console.log('inventory', inventory.stacks)
            for (const stack of inventory.stacks) {
                const obj = new ItemStack(...stack)
                Client.shared.selfRecord.getInventory().set(obj.key, obj)
            }
        }

        if ('stacks' in inventory || 'lanyard' in inventory) {
            const inv = (await Client.shared.userInterface?.getWin(EWindow.Inventory)) as InventoryWindow
            if (inv) {
                if ('stacks' in inventory) {
                    inv.getClothingView().update()
                    inv.getFurnishingsView().update()
                }
                if ('lanyard' in inventory) {
                    inv.getPinsView().update()
                }
            }
        }
    }
}
