import { EPerm } from '@vmk-legacy/common-ts'
import { ClientChat } from '@vmk-legacy/server-protos'
import type { FederatedEvent, FederatedPointerEvent } from 'pixi.js'
import { Point, Polygon, Sprite, Texture } from 'pixi.js'
import { Client } from '../../../Client.js'
import type { WalkableEntity } from '../../entities/WalkableEntity.js'
import { RoomTileType } from '../../RoomTile.js'
import type { Point3D } from '../3DUtils.js'
import type { WalkableRoomViewer } from '../WalkableRoomViewer.js'
import type { Floor } from './Floor.js'
import type { FurniEntity } from './FurniEntity.js'
import type { FurniEntityPart } from './FurniEntityPart.js'

export class TileEntity extends Sprite {
    private mapX: number
    private mapY: number

    initialTileHgt: number
    totalHeight: number

    itemParts: FurniEntityPart[]

    private size: number
    initialZ: number
    type: RoomTileType
    private occupants: WalkableEntity[] = []
    isSeat = false

    static tileTextures = new Map<string, Texture>()

    constructor(
        readonly room: WalkableRoomViewer,
        tilesize: number,
        hiliter: RoomTileType,
        clickCallback: (e: FederatedEvent) => any,
        public ref: number,
        hoverOverCallback?: (e: FederatedEvent) => any,
        hoverOutCallback?: (e: FederatedEvent) => any,
        public point: Point3D,
        private floor: Floor
    ) {
        super()

        this.type = hiliter

        this.zIndex = point.screenStack + 1 // also had floor z added?
        this.initialZ = point.screenStack + 1

        this.size = tilesize
        this.itemParts = []

        this.initialTileHgt = 0
        this.totalHeight = 0

        this.updateHiliter()

        this.eventMode = 'static'
        this.alpha = 0

        this.addEventListener('pointerup', this.clickHandler)
        if (hoverOutCallback) {
            this.addEventListener('pointerout', hoverOutCallback)
        }
        if (hoverOverCallback) {
            this.addEventListener('pointermove', hoverOverCallback)
        }
        this.hitArea = new Polygon([
            new Point(-tilesize, 0),
            new Point(0, -tilesize / 2),
            new Point(tilesize, 0),
            new Point(0, tilesize / 2)
        ])
    }

    updatePosition(): void {
        const compound = this.floor.point.plus(this.point.get3DX(), this.point.get3DY(), this.point.get3DZ())

        this.position.set(compound.screenX, compound.screenY - this.initialTileHgt)

        this.initialZ = compound.screenStack + 1
        this.updateHiliter()

        this.itemParts.forEach((part) => {
            part.updatePosition()
        })
    }

    setTileTex(spriteName: string): void {
        if (TileEntity.tileTextures.has(spriteName)) {
            this.texture = TileEntity.tileTextures.get(spriteName)
        } else {
            TileEntity.tileTextures.set(spriteName, (this.texture = Texture.from(spriteName)))
        }
        this.anchor.copyFrom(this.texture.defaultAnchor ?? { x: 0, y: 0 })
    }

    getEntities() {
        return this.occupants
    }

    // do not name "click", pixi will auto attach it
    clickHandler = (e?: FederatedPointerEvent): void => {
        e?.stopImmediatePropagation()
        // if it was a touch, unhilite the tile
        if (e?.originalEvent.type.indexOf('touch') === 0 && this.room.hilitedTile) {
            this.room.hilitedTile.alpha = 0
            this.room.hilitedTile = null
        }
        if (
            !this.room.furniController.isMoving() &&
            Client.shared.selfRecord.can(EPerm.MKAccessClient) &&
            e?.nativeEvent.shiftKey
        ) {
            Client.shared.serverBroker.send(new ClientChat({ message: '/teleport ' + this.ref }))

            return
        }

        if (this.room.furniController.isMoving()) {
            this.room.furniController.placeFurni(this)

            return
        }

        if (e) {
            // only send event to item if it wasnt programatically called
            const part = this.getHighestPart()

            if (part) {
                part.emit('pointerup')

                return
            }
        }

        this.walkHere()
    }

    walkHere(): void {
        Client.shared.serverBroker.send('walk_request', {
            ref: this.ref,
            room: this.room.instanceId
        })
    }

    getNextIndex(): number {
        return this.itemParts.length
    }

    canStackMore(ignoreItem?: FurniEntity): boolean {
        const highest = this.getHighestPart(true, ignoreItem)

        return !highest || highest.getBlock().stk
    }

    addOccupant(entity: WalkableEntity): void {
        entity.getTile()?.removeOccupant(entity)

        if (!this.occupants.includes(entity)) {
            this.occupants.push(entity)
            entity.setTile(this)
        }
    }

    removeOccupant(entity: WalkableEntity): void {
        const index = this.occupants.indexOf(entity)
        if (index !== -1) {
            this.occupants.splice(index, 1)
        }
    }

    addPart(part: FurniEntityPart): void {
        this.itemParts.push(part)

        this.totalHeight += part.getHeight()
        this.updateHiliter()

        if (!part.placing) {
            for (const e of this.occupants) {
                e.repairStacked()
            }
        }
    }

    removePart(part: FurniEntityPart): void {
        const index = this.itemParts.indexOf(part)

        if (index !== -1) {
            this.itemParts.splice(index, 1)

            this.totalHeight -= part.getHeight()

            for (const e of this.occupants) {
                e.repairStacked()
            }
        }

        this.updateHiliter()
    }

    getHighestPart(ignoreCarpet = false, ignoreItem?: FurniEntity): FurniEntityPart {
        if (!this.itemParts.length) {
            return undefined
        }
        if (ignoreCarpet) {
            const parts = this.itemParts.filter((p) => p.getBlock().hgt > 0 && p.getFurni() !== ignoreItem)

            return parts[parts.length - 1]
        }

        return this.itemParts[this.itemParts.length - 1]
    }

    updateHiliter(ignoreFurni = false): void {
        if (this.destroyed) {
            return
        }
        this.isSeat = false
        const part = this.getHighestPart()

        if (part?.placing) {
            this.texture = Texture.EMPTY
            this.anchor.set(0, 0)
            this.zIndex = part.zIndex - 1

            return
        }

        if (ignoreFurni || !part || part.placing) {
            let spriteName: string
            switch (this.type) {
                case RoomTileType.TILE:
                    spriteName = `room_hiliter_${this.size}`
                    break
                case RoomTileType.EXIT:
                    spriteName = `exit_hiliter_${this.size}`
                    break
                case RoomTileType.QUEUE:
                    spriteName = `queue_hiliter_${this.size}`
                    if (this.size === 16) {
                        // no 16px queue sprite
                        spriteName = `teleport_hiliter_${this.size}`
                    }
                    break
                case RoomTileType.SIT:
                    this.isSeat = true
                    spriteName = `sit_hiliter_${this.size}`
                    break
                case RoomTileType.TELEPORT:
                case RoomTileType.UNKNOWN:
                default:
                    spriteName = `teleport_hiliter_${this.size}`
                    break
            }

            this.setTileTex(spriteName)
            this.pivot.set(0)
            this.zIndex = this.initialZ
            this.visible = true

            return
        }
        if (part.getBlock().sit) {
            this.isSeat = true
            if (this.room.hideSit) {
                this.texture = Texture.EMPTY
                this.visible = true
                this.pivot.set(0, this.totalHeight)

                return
            }
            this.setTileTex(`sit_hiliter_${this.size}`)
        } else if (part.getBlock().wlk) {
            if (part.getBlock().act === 1) {
                this.setTileTex(`teleport_hiliter_${this.size}`)
            } else {
                this.setTileTex(`room_hiliter_${this.size}`)
            }
        } else {
            this.texture = Texture.EMPTY
            this.visible = false
            this.pivot.set(0, this.totalHeight)

            return
        }
        this.visible = true

        // place the tile hiliter above the top item
        this.zIndex = part.zIndex + 10
        this.anchor.copyFrom(this.texture.defaultAnchor ?? { x: 0, y: 0 })

        this.pivot.set(0, this.totalHeight)
    }

    getInitialHeight(): number {
        return this.initialTileHgt
    }

    getTotalHeight(excluding?: FurniEntity): number {
        if (excluding) {
            const part = this.itemParts.find((p) => p.getFurni() === excluding)

            if (part) {
                return this.totalHeight - part.getHeight()
            }
        }

        return this.totalHeight
    }

    setMapX(x: number): void {
        this.mapX = x
    }

    setMapY(y: number): void {
        this.mapY = y
    }

    getMapX(): number {
        return this.mapX
    }

    getMapY(): number {
        return this.mapY
    }

    getFloor(): Floor {
        return this.floor
    }

    setEnabled(enabled: boolean): void {
        this.eventMode = enabled ? 'static' : 'auto'

        if (!enabled) {
            this.emit('pointerout')
        }
    }

    override destroy(): void {
        for (const p of this.itemParts) {
            p.destroy()
        }
        this.itemParts = []
    }
}
