import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import type { Container, DestroyOptions } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { getText } from '../../../assets/ExternalConfigManager.js'
import { Client } from '../../../Client.js'
import { EWindow, UILayer } from '../../../enums.js'
import { Fonts } from '../../../Fonts.js'
import { BitmapTextButton } from '../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../buttons/BitmapTextButtonType.js'
import { BasicBox } from '../../containers/BasicBox.js'
import { FieldH } from '../../fields/FieldH.js'
import { UISoundLibrary } from '../../UISoundLibrary.js'
import type { IWindow } from '../IWindow.js'

export class VMKPassWindow extends BasicBox implements IWindow {
    readonly kind = EWindow.Other
    layer = UILayer.AlwaysOnTop
    private queuePlaceTxt: BitmapText
    private enterBtn: BitmapTextButton
    private leaveBtn: BitmapTextButton

    header: Container
    isDraggable = true
    private roomNameTxt: BitmapText

    setVisible(visible: boolean) {
        this.visible = visible
    }

    reset() {}

    private title: string

    private totalWidth: number
    private totalHeight: number

    private queuePlace: number

    static instance: VMKPassWindow

    constructor(roomName: string, queuePlace: number) {
        super(230, 94)
        VMKPassWindow.instance?.destroy()
        VMKPassWindow.instance = this

        this.cacheAsBitmap = false
        this.title = 'VMK PASS'
        this.queuePlace = queuePlace

        this.position.set(265, 228)

        this.header = new FieldH(230 - 10)

        this.addChild(this.header)
        this.header.x = 10
        this.header.y = 10

        this.interactiveChildren = true

        const titleText = new BitmapText(this.title || '', {
            ...Fonts.FoxleyBold_16,
            align: 'left',
            wordWrapWidth: this.innerWidth
        })
        titleText.y = 10
        const titlewidth: number = titleText.width
        titleText.x = Math.round((this.width - titlewidth) / 2)
        this.addChild(titleText)

        this.queuePlaceTxt = new BitmapText(
            getText('queue.info1') + ' ' + (queuePlace || 0) + ' ' + getText('queue.info2'),
            {
                ...Fonts.Foxley_16,
                align: 'left'
            }
        )
        this.queuePlaceTxt.x = Math.floor((this.width - this.queuePlaceTxt.width) / 2)
        this.queuePlaceTxt.y = 31
        this.addChild(this.queuePlaceTxt)

        this.roomNameTxt = new BitmapText(roomName, {
            ...Fonts.FoxleyBold_16,
            align: 'left',
            wordWrapWidth: this.innerWidth
        })
        this.roomNameTxt.x = Math.floor((this.width - this.roomNameTxt.width) / 2)
        this.roomNameTxt.y = 46
        this.addChild(this.roomNameTxt)

        this.leaveBtn = new BitmapTextButton(100, 'fastpass.leave', BitmapTextButtonType.RED)
        this.leaveBtn.position.set(133, 85)
        this.leaveBtn.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('queue_leave')
            this.destroy({ children: true })
        })
        this.addChild(this.leaveBtn)

        this.enterBtn = new BitmapTextButton(100, 'AUTO ENTRY', BitmapTextButtonType.GREEN)
        this.enterBtn.position.set(10, 85)
        this.enterBtn.disable()
        this.addChild(this.enterBtn)

        Client.shared.userInterface.register<VMKPassWindow>(this)

        this.setQueuePlace(queuePlace)
    }

    override destroy(_options?: DestroyOptions | boolean): void {
        super.destroy(_options)

        if (VMKPassWindow.instance === this) {
            VMKPassWindow.instance = null
        }

        gsap.killTweensOf(this)
    }

    getWidth(): number {
        return this.totalWidth
    }

    getHeight(): number {
        return this.totalHeight
    }

    setDestination(destination: string): void {
        this.roomNameTxt.text = destination
    }

    setQueuePlace(place: number): void {
        const oldPlace = this.queuePlace
        this.queuePlace = place

        if (place === 1) {
            this.queuePlaceTxt.text = "You're next in line for"
            if (oldPlace !== place) {
                SoundManager.shared.play(ESndGrp.SFX, UISoundLibrary.TurnstileEntrance)
            }
        } else {
            this.leaveBtn.enable()
            this.queuePlaceTxt.text = 'You are number ' + place + ' in queue for'
        }

        this.queuePlaceTxt.x = Math.floor((230 - this.queuePlaceTxt.width) / 2)
    }
}
