import { BitmapText } from 'pixi.js'
import { Client } from '../../../Client.js'
import { Constants } from '../../../Constants.js'
import { Fonts } from '../../../Fonts.js'
import { BasicBox } from '../../containers/BasicBox.js'
import { DOMText } from '../../DOMText.js'
import { FieldG } from '../../fields/FieldG.js'
import { ResponsiveContainer } from '../../views/AlertView.js'
import { TextColor } from '../../VMKLText'

export class DevSpriteMover extends ResponsiveContainer {
    spriteName?: string
    infoTxt: BitmapText

    private coord3DX: DOMText
    private coord3DY: DOMText
    private coord3DZ: DOMText

    constructor() {
        super()

        this.alpha = 0.5

        const alertBoxWidth = 260
        const bg = new BasicBox(alertBoxWidth, 130)
        this.addChild(bg)

        this.infoTxt = this.addChild(
            new BitmapText('Click a sprite in the room to begin adjusting it.', {
                ...Fonts.Foxley_16,
                wordWrapWidth: 270
            })
        )
        this.infoTxt.position.set(10, 10)

        this.coord3DX = this.addChild(
            new DOMText({
                kind: 'field',
                className: 'mk',
                id: '',
                maxlength: 0,
                fieldWidth: 50,
                fieldHeight: 16,
                fontSize: 16,
                fontColor: TextColor.black,
                initialValue: '',
                bgObject: new FieldG(50, 10),
                padLeft: 5,
                padTop: 0,
                noBreaks: true,
                enterHint: '',
                inputMode: 'numeric'
            })
        )
        this.coord3DX.position.set(50, 100)
        this.coord3DX.getElement().addEventListener('input', () => this.applyNewCoord())

        this.coord3DY = this.addChild(
            new DOMText({
                kind: 'field',
                className: 'mk',
                id: '',
                maxlength: 0,
                fieldWidth: 50,
                fieldHeight: 16,
                fontSize: 16,
                fontColor: TextColor.black,
                initialValue: '',
                bgObject: new FieldG(50, 10),
                padLeft: 5,
                padTop: 0,
                noBreaks: true,
                enterHint: '',
                inputMode: 'numeric'
            })
        )
        this.coord3DY.position.set(110, 100)
        this.coord3DY.getElement().addEventListener('input', () => this.applyNewCoord())

        this.coord3DZ = this.addChild(
            new DOMText({
                kind: 'field',
                className: 'mk',
                id: '',
                maxlength: 0,
                fieldWidth: 50,
                fieldHeight: 16,
                fontSize: 16,
                fontColor: TextColor.black,
                initialValue: '',
                bgObject: new FieldG(50, 10),
                padLeft: 5,
                padTop: 0,
                noBreaks: true,
                enterHint: '',
                inputMode: 'numeric'
            })
        )
        this.coord3DZ.position.set(170, 100)
        this.coord3DZ.getElement().addEventListener('input', () => this.applyNewCoord())

        this.eventMode = 'static'
    }

    tryRemove(): void {
        this.parent?.removeChild(this)
    }

    setSprite(name: string): void {
        console.log('dev sprite mover set to ' + name)
        this.spriteName = name

        this.updateSpriteInfo()
    }

    updateSpriteInfo(): void {
        const room = Client.shared.roomViewer
        if (this.spriteName && room) {
            const point = room.spritePoints[this.spriteName]

            if (point) {
                this.infoTxt.text = `Sprite: ${this.spriteName}\n\n3D position: ${point.get3DX() + ' ,' + point.get3DY() + ', ' + point.get3DZ()}\n2D position: ${point.screenX + ', ' + point.screenY + ', ' + point.screenStack}`

                this.coord3DX.setValue('' + point.get3DX())
                this.coord3DY.setValue('' + point.get3DY())
                this.coord3DZ.setValue('' + point.get3DZ())

                return
            }
        }

        this.infoTxt.text = 'Click a sprite in the room to begin adjusting it.'
        this.coord3DX.setValue('')
        this.coord3DY.setValue('')
        this.coord3DZ.setValue('')
    }

    applyNewCoord(): void {
        const room = Client.shared.roomViewer
        if (this.spriteName && room) {
            const point = room.spritePoints[this.spriteName]

            if (point) {
                point.set3DX(+this.coord3DX.getValue())
                point.set3DY(+this.coord3DY.getValue())
                point.set3DZ(+this.coord3DZ.getValue())

                point.applyTo(room.sprites.getChildByName(this.spriteName))

                Client.shared.roomViewer.sprites.sortChildren()

                this.updateSpriteInfo()
            }
        }
    }

    override sizeDidChange(): void {
        this.x = Math.round((Constants.SIZE[0] - this.width) / 2) + 200
        this.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }
}
