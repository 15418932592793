import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import type { DestroyOptions } from 'pixi.js'
import { Container } from 'pixi.js'
import { UISoundLibrary } from './UISoundLibrary.js'
import type LegacyWindow from './windows/LegacyWindow.js'
import { UIWindowTab } from './windows/UIWindowTab.js'
import type { UIWindowView } from './windows/UIWindowView.js'

export class TabController extends Container {
    private tabs: UIWindowTab[] = []
    private activeTab: UIWindowTab
    private views: (LegacyWindow | UIWindowView)[] = []

    constructor(
        readonly window: LegacyWindow,
        readonly inset?: number[]
    ) {
        super()
    }

    addTab(t: string | UIWindowTab, view: LegacyWindow | UIWindowView, setActive?: boolean): UIWindowTab {
        const tab = typeof t === 'string' ? new UIWindowTab(t) : t

        tab.visible = true
        view.visible = false

        const lastTab = this.tabs[this.tabs.length - 1]

        tab.x = lastTab ? lastTab.x + lastTab.getWidth() : 0
        tab.y = 0
        tab.view = view

        this.tabs.push(tab)
        this.views.push(view)

        this.addChild(tab)

        tab.addEventListener('pointerup', () => this.setActive(tab))

        if (setActive || (typeof setActive === 'undefined' && !this.activeTab)) {
            this.setActive(tab)
        }

        return tab
    }

    setActive(tabOrString: UIWindowTab | string): void {
        const index = typeof tabOrString === 'string' ? this.tabs.findIndex((t) => t.getText() === tabOrString) : this.tabs.indexOf(tabOrString)
        const tab = this.tabs[index]
        if (!tab) {
            return
        }
        if (this.activeTab && !tab.getActive()) {
            SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.MapTabChange)
        }
        this.activeTab?.setActive(false)

        if (this.activeTab && this.activeTab !== tab) {
            this.emit('change', tab)
        }

        this.activeTab = tab
        tab.setActive(true)
        if (this.inset) {
            this.views[index].pivot.set(-this.inset[0], -this.inset[1])
        }

        this.window.embed(this.views[index])
    }

    getActive(): UIWindowTab {
        return this.activeTab
    }

    override destroy(_options?: DestroyOptions | boolean): void {
        super.destroy(_options)

        for (const view of this.views) {
            view.destroy()
        }
    }
}
