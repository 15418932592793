import { Pooler } from '@vmk-legacy/render-utils'
import type { Container } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { getText } from '../../../../assets/ExternalConfigManager.js'
import { Client } from '../../../../Client.js'
import { Fonts } from '../../../../Fonts.js'
import { BitmapTextButton } from '../../../buttons/BitmapTextButton.js'
import { ImageButton } from '../../../buttons/ImageButton.js'
import { UIContentBG } from '../../../containers/UIContentBG.js'
import { Field } from '../../../fields/Field.js'
import { Separator } from '../../Separator.js'
import { UIWindowView } from '../../UIWindowView.js'
import type { ShopItem } from '../model/ShopItem.js'
import { ShopRoom } from '../model/ShopRoom.js'
import type { ShopWindow } from '../ShopWindow.js'

export class RoomsView extends UIWindowView<ShopWindow> {
    padding = 3

    readonly #priceNumText: BitmapText
    readonly #yourCreditNumText: BitmapText
    readonly #roomNameText: BitmapText
    readonly #roomDescText: BitmapText
    readonly #buyBtn: BitmapTextButton
    readonly #previousBtn: ImageButton
    readonly #nextBtn: ImageButton
    readonly #roomInfoSeparator: Separator

    roomsForSale: ShopItem[]

    private readonly PREV_NEXT_BTN_Y: number = 272
    private readonly PRICE_CREDITS_Y: number = this.PREV_NEXT_BTN_Y + 21
    private readonly ROOM_NAME_X: number = 330
    private readonly ROOM_NAME_MAX_WIDTH: number = 170

    private rooms: ShopRoom[] = []
    private currentRoom: ShopRoom
    private roomPreviewContainer: Container

    constructor() {
        super()

        const roomPreviewBg = new UIContentBG(300, 230)
        roomPreviewBg.y = 20
        this.addChild(roomPreviewBg)

        const scrollBeforeBuyText = new BitmapText('Scroll through all the styles before you buy', {
            ...Fonts.Foxley_16
        })
        scrollBeforeBuyText.x = 36
        scrollBeforeBuyText.y = 4
        this.addChild(scrollBeforeBuyText)

        const previousText = new BitmapText('PREVIOUS', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        previousText.x = 20
        previousText.y = this.PREV_NEXT_BTN_Y + 2
        this.addChild(previousText)

        const nextText = new BitmapText('NEXT', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        nextText.x = 273
        nextText.y = this.PREV_NEXT_BTN_Y + 2
        this.addChild(nextText)

        this.#previousBtn = new ImageButton('char.button.left.active', 'char.button.left.pressed', '', '')
        this.addChild(this.#previousBtn)
        this.#previousBtn.enable()
        this.#previousBtn.y = this.PREV_NEXT_BTN_Y
        this.#previousBtn.addEventListener('pointerup', () => {
            this.onPreviousRoom()
        })

        this.#nextBtn = new ImageButton('char.button.right.active', 'char.button.right.pressed', '', '')
        this.addChild(this.#nextBtn)
        this.#nextBtn.enable()
        this.#nextBtn.x = 305
        this.#nextBtn.y = this.PREV_NEXT_BTN_Y
        this.#nextBtn.addEventListener('pointerup', () => {
            this.onNextRoom()
        })

        const priceText = new BitmapText('PRICE', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        priceText.x = 10
        priceText.y = this.PRICE_CREDITS_Y + 3
        this.addChild(priceText)

        const priceBg = new Field('f', 80)
        priceBg.y = this.PRICE_CREDITS_Y - 3
        priceBg.x = priceText.x + 35
        this.addChild(priceBg)

        const yourCreditText = new BitmapText('YOUR CREDIT', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        yourCreditText.x = priceText.x + 140
        yourCreditText.y = this.PRICE_CREDITS_Y + 3
        this.addChild(yourCreditText)

        // const yourCreditBg = new Field("h", 80);
        const yourCreditBg = new Field('b', 80)
        yourCreditBg.y = this.PRICE_CREDITS_Y - 3
        yourCreditBg.x = yourCreditText.x + 73
        this.addChild(yourCreditBg)

        this.#priceNumText = new BitmapText('', {
            ...Fonts.Foxley_16
        })
        this.#priceNumText.y = this.PRICE_CREDITS_Y + 2
        this.addChild(this.#priceNumText)

        this.#yourCreditNumText = new BitmapText('', {
            ...Fonts.Foxley_16
        })
        this.#yourCreditNumText.y = this.PRICE_CREDITS_Y + 2
        this.addChild(this.#yourCreditNumText)

        this.#roomNameText = new BitmapText('', {
            ...Fonts.Foxley_16,
            wordWrap: true,
            wordWrapWidth: this.ROOM_NAME_MAX_WIDTH,
            align: 'center'
        })
        this.#roomNameText.x = this.ROOM_NAME_X
        this.#roomNameText.y = 40
        this.addChild(this.#roomNameText)

        this.#roomInfoSeparator = new Separator(this.ROOM_NAME_MAX_WIDTH)
        this.#roomInfoSeparator.x = this.ROOM_NAME_X
        this.#roomInfoSeparator.y = 40
        this.addChild(this.#roomInfoSeparator)

        this.#roomDescText = new BitmapText('', {
            ...Fonts.Foxley_16,
            wordWrap: true
        })
        this.#roomDescText.x = this.ROOM_NAME_X
        this.#roomDescText.y = 72
        this.#roomDescText.style.wordWrapWidth = this.ROOM_NAME_MAX_WIDTH
        this.addChild(this.#roomDescText)

        this.roomPreviewContainer = Pooler.newContainer()
        this.roomPreviewContainer.x = 6
        this.roomPreviewContainer.y = 29
        this.addChild(this.roomPreviewContainer)

        this.setPriceNum(0)
        this.setRoomNameText('')
        this.setRoomDescText('')
        this.setRoomsForSale([])

        this.#buyBtn = new BitmapTextButton(60, 'BUY', 'g')
        this.#buyBtn.x = 380
        this.#buyBtn.y = this.PRICE_CREDITS_Y - 5
        this.addChild(this.#buyBtn)

        this.#buyBtn.addEventListener('pointerup', async () => {
            if (
                await Client.shared.helpers.confirm(
                    `Are you sure you want to buy ${this.currentRoom.getName()} for ${this.currentRoom.getPrice()} credits?`
                )
            ) {
                Client.shared.serverBroker.send('buy_request', {
                    catalogId: this.currentRoom.getCatalogId()
                })
            }
        })

        this.once('added', () => this.setYourCreditNum(Client.shared.selfRecord.getCredits()))

        this.update()
    }

    updateCredits() {
        this.setYourCreditNum(Client.shared.selfRecord.getCredits())
    }

    setPriceNum(price: number) {
        this.#priceNumText.text = price.toString()
        this.#priceNumText.x = 89 - Math.round(this.#priceNumText.width / 2)
    }

    setYourCreditNum(yourCredit: number) {
        if (yourCredit == null) {
            return
        }
        this.#yourCreditNumText.text = yourCredit.toString()
        this.#yourCreditNumText.x = 268 - Math.round(this.#yourCreditNumText.width / 2)
    }

    setRoomNameText(name: string) {
        this.#roomNameText.text = name
        this.#roomNameText.x =
            this.ROOM_NAME_X + Math.round((this.ROOM_NAME_MAX_WIDTH - this.#roomNameText.width) / 2)
        this.#roomInfoSeparator.y = this.#roomNameText.y + this.#roomNameText.height + 5
    }

    setRoomDescText(desc: string) {
        this.#roomDescText.text = desc
        this.#roomDescText.y = this.#roomInfoSeparator.y + 11
    }

    setRoomsForSale(rooms: ShopItem[]) {
        if (!rooms.length) {
            return
        }
        Client.shared.assetLoader.loadCasts(['items/room_thumbs'], { retains: this.parent.provider }).then(() => {
            this.roomsForSale = rooms
            this.rooms = []
            for (const roomItem of this.roomsForSale) {
                try {
                    const spaceId = roomItem.getDefUid()
                    const price = roomItem.getPrice()

                    const prodId =
                        {
                            1: 5003,
                            12: 5002,
                            17: 5004,
                            50: 5006,
                            51: 5007,
                            52: 5005,
                            53: 5008,
                            54: 5009,
                            67: 5012,
                            68: 5013,
                            69: 5014,
                            70: 5015,
                            71: 5016,
                            87: 5021,
                            86: 5020
                        }[spaceId] || spaceId
                    const name = getText('roomdeschd.' + prodId)
                    const desc = getText('roomdesctxt.' + prodId)
                    const preview = Pooler.newSprite('rooms.bg.' + prodId)
                    const room = new ShopRoom(roomItem.getCatalogId(), name, desc, price, preview)
                    this.rooms.push(room)
                } catch (ignored) {
                    console.log(ignored)
                    //
                }
            }
            this.update()
        })
    }

    update() {
        this.setYourCreditNum(Client.shared.selfRecord.getCredits() || 0)

        if (this.rooms.length === 0) {
            this.roomPreviewContainer.removeChildren()
            this.setRoomNameText('No rooms for sale')
            this.setRoomDescText('Watch the newsletter for information on when new rooms will become available!')
            this.setPriceNum(0)
            return
        }

        if (this.currentRoom == null) {
            this.currentRoom = this.rooms[0]
        }

        this.roomPreviewContainer.removeChildren()
        this.roomPreviewContainer.addChild(this.currentRoom.getPreview())
        this.setRoomNameText(this.currentRoom.getName())
        this.setRoomDescText(this.currentRoom.getDesc())
        this.setPriceNum(this.currentRoom.getPrice())
    }

    onPreviousRoom() {
        const currentRoomIndex: number = this.rooms.indexOf(this.currentRoom)
        let newIndex: number
        if (currentRoomIndex === 0) {
            newIndex = this.rooms.length - 1
        } else {
            newIndex = currentRoomIndex - 1
        }
        this.currentRoom = this.rooms[newIndex]
        this.update()
    }

    onNextRoom() {
        const currentRoomIndex: number = this.rooms.indexOf(this.currentRoom)
        let newIndex: number
        if (currentRoomIndex === this.rooms.length - 1) {
            newIndex = 0
        } else {
            newIndex = currentRoomIndex + 1
        }
        this.currentRoom = this.rooms[newIndex]
        this.update()
    }
}
