import { BitmapText, Container, Graphics } from 'pixi.js'
import { Fonts } from '../../../Fonts.js'

export class ChatLogContainer extends Container {
    constructor(
        boxWidth: number,
        boxHeight: number,
        ign: string,
        roomName: string,
        message: string,
        timeStamp: string
    ) {
        super()

        const messageBG = new Graphics()
        messageBG.beginFill(0x255592)
        messageBG.drawRect(0, 0, boxWidth, boxHeight)
        messageBG.endFill()
        this.addChild(messageBG)

        const textOffsetX = 7
        const textOffsetY = 3

        const user = new BitmapText(ign + ':  ', {
            ...Fonts.FoxleyBold_16
        })
        user.x = textOffsetX
        user.y = textOffsetY
        this.addChild(user)

        const msg = new BitmapText(message, {
            ...Fonts.Foxley_16
        })
        msg.x = user.x + user.width
        msg.y = user.y + 1
        this.addChild(msg)

        const rName = new BitmapText(roomName, {
            ...Fonts.FoxleyBold_16,
            tint: 0xff8c00
        })
        rName.x = textOffsetX
        rName.y = 20
        this.addChild(rName)

        const time = new BitmapText(timeStamp.split('T')[0] + ' ' + timeStamp.split('T')[1].slice(0, 8), {
            ...Fonts.Foxley_16
        })
        time.x = rName.x + rName.width + 10
        time.y = 20
        this.addChild(time)
    }
}
