import { EPerm } from '@vmk-legacy/common-ts'
import type { FederatedEvent } from 'pixi.js'
import { BitmapText, Container, Graphics, Rectangle } from 'pixi.js'
import { Client } from '../Client.js'
import { Constants } from '../Constants.js'
import { EWindow } from '../enums.js'
import { Fonts } from '../Fonts.js'
import { AvatarVisual } from '../room/entities/AvatarVisual.js'
import type { WalkableEntity } from '../room/entities/WalkableEntity.js'
import { FurniEntity } from '../room/renderer/types/FurniEntity.js'
import { TileEntity } from '../room/renderer/types/TileEntity.js'
import { WalkableRoomViewer } from '../room/renderer/WalkableRoomViewer.js'
import type { ISizeChanging } from './views/AlertView.js'
import type { ModWindow } from './windows/mod/ModWindow.js'
import { TrackDataEditor } from './windows/mod/TrackDataEditor.js'
import { MKGivePopup } from './windows/mod/views/popups/MKGivePopup.js'
import type { UsersView } from './windows/mod/views/UsersView.js'

export class ContextMenu extends Container implements ISizeChanging {
    options: MenuOption[] = []

    constructor() {
        super()
        this.eventMode = 'static'
    }

    populateFor(...entities: (AvatarVisual | FurniEntity | TileEntity)[]): void {
        this.options = [new Title()]

        for (const obj of entities) {
            if (!obj) {
                continue
            }
            if (obj instanceof TileEntity) {
                if (obj.isSeat) {
                    this.options.push(new SitHere(obj))
                } else {
                    this.options.push(new WalkHere(obj))
                }
            } else if (obj instanceof AvatarVisual) {
                this.options.push(new TradeUser(obj.factor))
                if (Client.shared.selfRecord.can(EPerm.MKAccessClient)) {
                    this.options.push(new LookupUser(obj.factor))
                    this.options.push(new KickUser(obj.factor))
                    if (Client.shared.selfRecord.can(EPerm.PlayersInventoryAddition)) {
                        this.options.push(new StaffGiveUser(obj.factor))
                    }
                }
            } else if (obj instanceof FurniEntity) {
                if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
                    if (Client.shared.roomViewer.canFurnish()) {
                        if (obj.type === 'furni') {
                            this.options.push(new RotateFurni(obj))
                        }
                        this.options.push(new MoveFurni(obj))
                        this.options.push(new RemoveFurni(obj))

                        if (Client.shared.selfRecord.can(EPerm.MKAccessClient) && obj.isTrack()) {
                            this.options.push(new EditTrackData(obj))
                        }
                    }
                }
            }
        }

        this.removeChildren()

        const bg = this.addChild(new Graphics())
        let y = 0
        let maxWidth = 0
        let maxHeight = 0

        let hasWalk = false

        for (const opt of this.options) {
            if (opt instanceof WalkHere) {
                if (hasWalk) {
                    continue
                }
                hasWalk = true
            }
            const c = this.addChild(opt)
            c.addEventListener('pointerup', this.didChoose, { once: true })
            c.position.y = y
            const height = (c.hitArea as Rectangle).height
            const width = (c.hitArea as Rectangle).width
            if (height > maxHeight) {
                maxHeight = height
            }
            if (width > maxWidth) {
                maxWidth = width
            }
            y += height
        }
        this.options.forEach((o) => (o.hitArea.width = maxWidth))
        bg.beginFill(Constants.TOOLBAR_BG_COLOR, 0.9)
        bg.drawRoundedRect(0, 0, maxWidth, y + 2, 5)
        bg.endFill()
    }

    private didChoose = (e: FederatedEvent): void => {
        e.stopPropagation()
        const option = e.currentTarget as MenuOption
        console.log(option)
        option.trigger()
        this.parent.destroy()
    }

    refit(): void {
        //
    }

    refitChildren(): void {
        //
    }

    sizeDidChange(): void {
        //
    }
}

export abstract class MenuOption extends Container {
    abstract trigger(): void

    constructor(label: string) {
        super()
        this.eventMode = 'static'
        this.cursor = 'pointer'

        const text = new BitmapText(label, { ...Fonts.Foxley_16 })
        text.eventMode = 'auto'
        text.position.set(4, 5)
        this.addChild(text)

        this.addEventListener('pointerover', () => (text.tint = Constants.UI_OK_BTN_COLOR))
        this.addEventListener('pointerout', () => (text.tint = 0xffffff))

        this.hitArea = new Rectangle(0, 0, text.width + 8, text.height + 4)
    }
}

export class Title extends Container {
    constructor() {
        super()
        this.eventMode = 'auto'

        const text = new BitmapText('Choose an option', {
            ...Fonts.FoxleyBold_16
        })
        text.eventMode = 'auto'
        text.position.set(4, 5)
        this.addChild(text)

        this.hitArea = new Rectangle(0, 0, text.width + 8, text.height + 4)
    }

    trigger(): void {
        //
    }
}

export class SitHere extends MenuOption {
    constructor(private tile: TileEntity) {
        super('Sit here')
    }

    trigger(): void {
        this.tile.walkHere()
    }
}

export class WalkHere extends MenuOption {
    constructor(private tile: TileEntity) {
        super('Walk here')
    }

    trigger(): void {
        this.tile.walkHere()
    }
}

export class TradeUser extends MenuOption {
    constructor(private entity: WalkableEntity) {
        super('Trade with ' + entity.getName())
    }

    trigger(): void {
        Client.shared.serverBroker.send('trade_request', {
            id: this.entity.getRoomIdentifier()
        })
    }
}

export class KickUser extends MenuOption {
    constructor(private entity: WalkableEntity) {
        super('[Staff] Kick ' + entity.getName())
    }

    trigger(): void {
        Client.shared.serverBroker.send('mk_boot', {
            id: this.entity.getRoomIdentifier()
        })
    }
}

export class LookupUser extends MenuOption {
    constructor(private entity: WalkableEntity) {
        super('[Staff] Lookup ' + entity.getName())
    }

    trigger(): void {
        Client.shared.userInterface.getWin(EWindow.Mod, true).then((modWin: ModWindow) => {
            if (modWin) {
                modWin.setActiveTab(modWin.usersTab)
                ;(modWin.usersTab.view as UsersView).nameField.getElement().placeholder = this.entity.getName()
                ;(modWin.usersTab.view as UsersView).setSearching(true)
                Client.shared.serverBroker.send('mk_search', {
                    ign: this.entity.getName()
                })
            }
        })
    }
}
export class StaffGiveUser extends MenuOption {
    constructor(private entity: WalkableEntity) {
        super('[Staff] Give ' + entity.getName())
    }

    trigger(): void {
        new MKGivePopup(this.entity.getRoomIdentifier(), this.entity.getName())
    }
}

export class RotateFurni extends MenuOption {
    constructor(private furni: FurniEntity) {
        super('Rotate ' + furni.getItem().getName())
    }

    trigger(): void {
        this.furni.rotate()
    }
}

export class MoveFurni extends MenuOption {
    constructor(private furni: FurniEntity) {
        super('Move ' + furni.getItem().getName())
    }

    trigger(): void {
        this.furni.controller.setActiveFurniEntity(this.furni)
        this.furni.controller.setMoveMode(true)
    }
}

export class RemoveFurni extends MenuOption {
    constructor(private furni: FurniEntity) {
        super('Take away ' + furni.getItem().getName())
    }

    trigger(): void {
        this.furni.removeFromRoom()
    }
}

export class EditTrackData extends MenuOption {
    constructor(private furni: FurniEntity) {
        super('[Staff] Edit track piece data')
    }

    trigger(): void {
        this.furni.pulseOverlay()
        Client.shared.userInterface.register(new TrackDataEditor(this.furni))
    }
}
