import { TintColor } from '@vmk-legacy/common-ts'
import { Container, Graphics, Point } from 'pixi.js'
import { Constants } from '../Constants.js'
import { PixiTextType, TextColor, VMKLFont, VMKLText } from '../ui/VMKLText'

export class ChatBubble extends Container {
    xAnchor = 0.5

    constructor(
        readonly sender: string,
        private message: string,
        readonly colorId: string | number = 182,
        readonly spikeLeft = false,
        readonly bgAlpha = 1
    ) {
        super()
    }

    setMessage(message: string): void {
        this.message = message
        this.removeChildren()
        this.load()
    }

    async load(): Promise<void> {
        const vertPadding = 2
        const senderText = new VMKLText({
            text: this.sender ? `${this.sender}:` : '',
            type: PixiTextType.bitmapText,
            font: VMKLFont.foxley,
            color: TextColor.black,
            size: 16,
            textBaseline: 'alphabetic',
            bold: true
        })

        senderText.position.set(6, 2)

        const messageText = new VMKLText({
            text: this.message,
            type: PixiTextType.text,
            font: VMKLFont.foxley,
            color: TextColor.black,
            size: 16,
            breakWords: true,
            textBaseline: 'alphabetic',
            wordWrapWidth: Constants.SIZE[0] - 25 - senderText.width
        })
        this.addChild(senderText)
        this.addChild(messageText)

        messageText.position.set(senderText.x + Math.round(senderText.width) + 6, vertPadding)

        const totalTextWidth = senderText.width + messageText.width + 6
        const tint = TintColor[this.colorId] || this.colorId

        const bubbleOuterWidth = totalTextWidth + 18
        const textHeight = Math.max(senderText.height, messageText.height)

        const whiteBack = new Graphics()
            .roundRect(0, 0, bubbleOuterWidth, senderText.y + textHeight + vertPadding * 2, 6)
            .fill(0xefefef)
            .stroke({
                width: 2,
                color: tint,
                cap: 'round',
                join: 'round',
                alignment: 1
            })
        this.addChildAt(whiteBack, 0)

        const center = this.spikeLeft ? 10 : Math.round(bubbleOuterWidth / 2)
        const spikeBg = new Graphics()
        spikeBg.poly([new Point(0, 0), new Point(0, 8), new Point(9, 0)])
        spikeBg.fill(tint)
        this.addChild(spikeBg)
        spikeBg.y = textHeight + vertPadding * 2
        spikeBg.x = center - 4

        const spikeInner = new Graphics()
        spikeInner.poly([new Point(2, 0), new Point(2, 4), new Point(6, 0)])
        spikeInner.fill(0xefefef)
        this.addChild(spikeInner)
        spikeInner.y = textHeight + vertPadding * 2
        spikeInner.x = center - 4
    }
}
