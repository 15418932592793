import { EPerm } from '@vmk-legacy/common-ts'
import { Pooler } from '@vmk-legacy/render-utils'
import type { Container } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { Client } from '../../../../Client.js'
import { Fonts } from '../../../../Fonts.js'
import { ScrollArea } from '../../../containers/ScrollArea.js'
import { UIWindowView } from '../../UIWindowView.js'
import { IGNRequestContainer } from '../IGNRequestContainer.js'
import type { ModWindow } from '../ModWindow.js'

export class IGNView extends UIWindowView<ModWindow> {
    padding = 5

    private requestsContainer: Container
    private scrollArea: ScrollArea

    constructor() {
        super()

        this.requestsContainer = Pooler.newContainer()

        // const box = new ContentBox(340, 330);
        // this.addChild(box);
        // box.y = 18;

        // this.addChild(this.requestsContainer);
        // this.requestsContainer.position.set(10, 28);

        const desc = new BitmapText('Accept or reject requested in-game names below', Fonts.Foxley_16)
        this.addChild(desc)
        desc.x = Math.round((360 - desc.width) / 2)

        const guidelinesTitle = new BitmapText('Guidelines for In-Game Names'.toUpperCase(), {
            fontFamily: 'Folio',
            fontSize: 14
        })
        this.addChild(guidelinesTitle)
        guidelinesTitle.x = 375

        const guidelineList = [
            'Must be at least 2 characters long',
            'Can only contain letters, numbers, periods, underscores, and dashes',
            'Must include more letters than special characters/numbers',
            'No staff/reserved prefixes (original or VMK past/present... eg LC_, VMK_, GR_)',
            'No prohibited language or dictionary dancing',
            'No innuendos',
            'No impersonating Disney characters (reserved for staff)',
            'Use your best judgement!'
        ]

        const bulletChar = '•'
        const guidelinesText = ` ${bulletChar} ${guidelineList.join(`\n ${bulletChar} `)}`

        const guidelines = new BitmapText(guidelinesText, {
            ...Fonts.Foxley_16,
            wordWrap: true
        })
        guidelines.x = guidelinesTitle.x
        guidelines.y = 28
        guidelines.style.wordWrapWidth = 300
        this.addChild(guidelines)

        this.once('added', () => {
            if (Client.shared.selfRecord.can(EPerm.PlayersIGNApproval)) {
                Client.shared.serverBroker.offEvent('mk_igns')
                Client.shared.serverBroker.onEvent('mk_igns', (data) => {
                    if (this.destroyed) {
                        return
                    }
                    this.setRequests(data.igns)
                })
                Client.shared.serverBroker.send('mk_igns_request')
            }
        })
    }

    setRequests(requests: any) {
        this.requestsContainer.removeChildren()
        for (let i = 0; i < requests.length; i++) {
            // if (requests.length <= i) break;

            const req = requests[i]

            let warning = ''
            if (req.hasOwnProperty('warning') && req['warning'] === true) {
                warning = req['warningReason']
            }
            const h = 66
            const reqBox: IGNRequestContainer = new IGNRequestContainer(
                this,
                338,
                h,
                req.ign,
                req.requested_ign,
                req.id,
                warning
            )
            reqBox.position.set(0, (h + 6) * i)
            this.requestsContainer.addChild(reqBox)
        }
        if (this.scrollArea) {
            this.removeChild(this.scrollArea)
        }
        this.scrollArea = new ScrollArea(330, 330, this.requestsContainer)
        this.addChild(this.scrollArea)
        this.scrollArea.y = 17
    }

    update() {}
}
