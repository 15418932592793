import type {
    EMagicEffectTypes,
    ERoomWhoEnters,
    IRoomLayout,
    IRoomLayoutElem,
    IRoomLayoutElemFloor,
    IRoomLayoutElemTile,
    IRoomLayoutElemWall,
    ItemOwnedTuple
} from '@vmk-legacy/common-ts'
import { EPerm } from '@vmk-legacy/common-ts'
import type { AvatarOutfit } from '@vmk-legacy/render-utils'
import { ESndGrp, MagicEffect, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import type { Container, FederatedEvent, FederatedPointerEvent, Sprite, VideoResource } from 'pixi.js'
import { AnimatedSprite, Assets, Texture } from 'pixi.js'
import { Client } from '../../Client.js'
import Config from '../../Config.js'
import { Constants } from '../../Constants.js'
import { DummyItem } from '../../data/ItemStack.js'
import type { EntityRoomIdentifier } from '../../enums.js'
import { TeleportFrames } from '../../server/messages/TeleportedModule.js'
import { ContextMenu } from '../../ui/ContextMenu.js'
import { UISoundLibrary } from '../../ui/UISoundLibrary.js'
import { AlertView } from '../../ui/views/AlertView.js'
import { RoomInfoBox } from '../../ui/windows/RoomInfoBox.js'
import { RoomInspector } from '../../ui/windows/RoomInspector.js'
import { Helpers } from '../../util/Helpers.js'
import type { Animation } from '../Animation.js'
import { AvatarEntity } from '../entities/AvatarEntity.js'
import type { AvatarVisual } from '../entities/AvatarVisual.js'
import { MagicDefinitions } from '../entities/fx/MagicDefinitions.js'
import { InvisibleNPCEntity } from '../entities/InvisibleNPCEntity.js'
import { NPCEntity } from '../entities/NPCEntity.js'
import type { NPCVisual } from '../entities/NPCVisual.js'
import type { WalkableEntity } from '../entities/WalkableEntity.js'
import type { RoomItem } from '../RoomItem.js'
import type { RoomEntity, RoomEntityVisualizer } from '../RoomObject.js'
import { ERoomObjType } from '../RoomObject.js'
import { RoomTileType } from '../RoomTile.js'
import { ServerControlledShow } from '../shows/ServerControlledShow.js'
import { Plane3D, Point3D } from './3DUtils.js'
import FurniController from './FurniController.js'
import { RoomViewer } from './RoomViewer.js'
import { Floor } from './types/Floor.js'
import type { RoomVisualSpriteTex } from './types/RoomVisualSpriteTex.js'
import { TileEntity } from './types/TileEntity.js'
import { Wall } from './types/Wall.js'

export class WalkableRoomViewer extends RoomViewer {
    instanceId: number
    spaceId: number
    protected variation?: string
    private tileTypes: { [p: string]: number[] }

    floors: Floor[] = []
    furniController = new FurniController(this)
    ownRoom: boolean
    private itemQueue: RoomItem[]

    walls = new Map<number, Wall>()
    tiles = new Map<number, TileEntity>()

    private disabledRefs: number[] = []

    private mickeyMap = new Map<string, string>()
    namedTiles = new Map<string, TileEntity>()
    protected override entities: WalkableEntity<AvatarVisual | NPCVisual>[] = []

    whoEnters?: ERoomWhoEnters

    hideSit = false

    hilitedTile: TileEntity | null = null
    pointer: Sprite = null

    private inspector?: RoomInspector

    serverShow?: ServerControlledShow

    init(
        ownRoom = false,
        instanceId = -1,
        spaceId = -1,
        variation: string | null,
        disabledRefs: number[] = [],
        tileTypes: { [type: string]: number[] } = {}
    ): void {
        console.log('RoomViewer instance: ' + instanceId + ' space: ' + spaceId + ' variation: ' + variation)

        this.spaceId = spaceId
        this.variation = variation
        this.disabledRefs = disabledRefs
        this.tileTypes = tileTypes

        this.visible = false

        if (spaceId === null || instanceId === -1) {
            return
        }

        this.instanceId = instanceId
        this.ownRoom = ownRoom
        this.hasBeenRevealed = false

        this.visualSprites = new Map<string, RoomVisualSpriteTex>()
        this.floors = []
        this.tileSize = 32
        this.animPlaceholders = new Map<string, Animation>()
        this.animationMap = new Map<string, Animation>()
        this.mickeyMap = new Map<string, string>()

        this.tiles = new Map<number, TileEntity>()
        this.itemQueue = []

        this.layoutIdentifier = Client.shared.roomInfoMgr.getInfo(spaceId)?.layoutId

        this.furniController.init()

        this.addEventListener('pointerup', () => {
            if (this.hilitedTile) {
                console.log('global room unhilite')
                this.hilitedTile.alpha = 0
                this.hilitedTile = null
            }
        })

        this.showInspector()
        this.sendDebugData()
    }

    showInspector(toggle = false): void {
        if (
            (!this.inspector || this.inspector.destroyed || toggle) &&
            Client.shared.selfRecord.can(EPerm.EventsManage)
        ) {
            if (toggle && this.inspector && !this.inspector.destroyed) {
                this.inspector.visible = !this.inspector.visible
                if (this.inspector.visible) {
                    this.inspector.highlightPiecesWithData()
                } else {
                    this.inspector.unhighlight()
                }

                return
            }
            this.inspector = Client.shared.userInterface.register(new RoomInspector())

            this.inspector.position.set(Constants.SIZE[0] - this.inspector.width - 5, 5)
        }
    }

    override getDebugData(): Record<string, string | number | object> {
        return {
            'Instance ID': this.instanceId,
            'Instance owner': RoomInfoBox.currentData?.roomOwner,
            'Instance name': RoomInfoBox.currentData?.roomName,
            'Instance desc': RoomInfoBox.currentData?.roomDesc,
            'Instance privacy': this.whoEnters,
            'Model base name': this.layoutIdentifier,
            'Space def ID': this.spaceId,
            'Model tile size': this.tileSize,
            'Model casts': this.getCastNames(),
            'Own room': this.ownRoom ? 'Yes' : 'No',
            'Hide seats': this.hideSit ? 'Yes' : 'No',
            Mickeys: this.mickeyMap.size,
            Floors: this.floors.length,
            Tiles: this.tiles.size,
            Walls: this.walls.size,
            Objects: this.entities.length
        }
    }

    getSelf(): RoomEntity<RoomEntityVisualizer> {
        return this.getEntityByRef(Client.shared.selfRecord.getRoomIdentifier())
    }

    override async reveal(): Promise<void> {
        const players = this.getEntitiesByType(ERoomObjType.Avatar)

        for (const o of players) {
            ;(o.visual as AvatarVisual | NPCVisual).setTileSize(this.tileSize)
        }

        Client.shared.stage.off('axis_move', this.handleJoystickMove)
        Client.shared.stage.off('game_btn', this.handleJoystickGameBtn)
        Client.shared.stage.on('axis_move', this.handleJoystickMove)
        Client.shared.stage.on('game_btn', this.handleJoystickGameBtn)

        this.sendDebugData()

        await super.reveal()

        this.serverShow?.roomIsReady()
    }

    private handleJoystickGameBtn = (): void => {
        Client.shared.serverBroker.send('wave')
    }

    private handleJoystickMove = (e): void => {
        Client.shared.serverBroker.send('walk_request', {
            joy: e.detail.gamepad.axes.slice(0, 2),
            room: this.instanceId
        })
    }

    async transitionVariation(variation = 'default'): Promise<void> {
        console.log('Transitioning to layout variation: ' + variation)

        this.variation = variation

        const oldSprites = Array.from(this.roomSprites, (value) => value) as Container[]

        this.loadedSounds?.clear()

        this.roomSprites.clear()
        this.visualSprites?.clear()

        this.animationMap.forEach((a) => {
            console.log('Stopping ' + a.score.name)
            Client.shared.detachScore(a.score)
            a.score.pause()
        })
        this.animationMap.clear()

        this.filmLoops = {}

        this.furniController.teardown()
        Client.shared.soundScore.teardown()

        await this.loadAssets(true)

        for (const sprite of this.roomSprites) {
            const destAlpha = sprite.alpha
            sprite.alpha = 0
            sprite.visible = true
            gsap.to(sprite, {
                duration: 2,
                alpha: destAlpha
            })
        }

        await Helpers.delay(1000)

        gsap.to(oldSprites, {
            duration: 1,
            alpha: 0,
            onComplete: () => {
                for (const s of oldSprites) {
                    s.destroy({
                        children: true,
                        texture: false
                    })
                }
                this.startAnimating(false)
            }
        })
        Client.shared.soundScore.playFromBeginning()
    }

    getRoomId(): number {
        return this.instanceId
    }

    getCastNames(): string[] {
        const info = Client.shared.roomInfoMgr.getInfo(this.spaceId)
        if (!info) {
            throw new Error('Unable to get data from roomInfoMgr for space #' + this.spaceId)
        }
        const folders = [...info.folders]

        if (!folders) {
            return []
        }

        if (this.variation && this.variation !== 'default') {
            for (let i = 0; i < folders.length; i++) {
                if (folders[i] === 'spaces/' + info.layoutId) {
                    folders[i] = folders[i] + '.' + this.variation
                } else if (folders[i] === 'sound/snd_' + info.layoutId) {
                    folders[i] = folders[i] + '.' + this.variation
                }
            }
        }

        return folders
    }

    override async teardown(): Promise<void> {
        const billboards = Client.shared.billboardMgr.getForRoom(this.spaceId)
        for (const board of billboards) {
            console.log('Tearing down billboard at ' + board.sprite_node)
            const node = this.sprites.getChildByName(board.sprite_node) as Sprite
            if (node) {
                node.texture?.destroy(true)
            }
        }

        await super.teardown()

        if (this.inspector) {
            Client.shared.userInterface.removeWindow(this.inspector)
            this.inspector = null
        }

        this.instanceId = null
        this.spaceId = null

        this.serverShow?.teardown()
        this.serverShow = undefined

        Client.shared.stage.off('game_btn', this.handleJoystickGameBtn)
        Client.shared.stage.off('axis_move', this.handleJoystickMove)

        if (this.floors) {
            for (const f of this.floors) {
                f.destroy()
            }
            this.floors = []
        }

        this.namedTiles?.clear()
        this.tiles?.forEach((t) => t.destroy())
        this.tiles?.clear()
        this.tileTypes = {}

        this.itemQueue = []
        this.furniController.teardown()

        this.hasBeenRevealed = false
    }

    private setFloor(name: string | undefined, tiles: IRoomLayoutElemTile[], x: number, y: number, z: number): void {
        console.log('Adding floor ' + name)
        const floor = new Floor(name, new Point3D(x, y, z))

        for (const tile of tiles) {
            let tileType
            if (this.tileTypes.exit?.includes(tile.ref)) {
                tileType = RoomTileType.EXIT
            } else if (this.tileTypes.sit?.includes(tile.ref)) {
                tileType = RoomTileType.SIT
            } else {
                if (tile.type === 'exit') {
                    tileType = RoomTileType.EXIT
                } else if (tile.type === 'tile') {
                    if (tile.name) {
                        const tileName = tile.name

                        if (tileName.includes('sit')) {
                            tileType = RoomTileType.SIT
                        } else if (tileName.includes('pan') || (tileName.includes('alice') && this.tileSize !== 16)) {
                            tileType = RoomTileType.QUEUE
                        } else if (tileName.includes('queue') || tileName.includes('gueue')) {
                            tileType = RoomTileType.QUEUE
                        }
                    } else {
                        tileType = RoomTileType.TILE
                    }
                } else {
                    tileType = RoomTileType.UNKNOWN
                }
            }

            const tileEntity = new TileEntity(
                this,
                this.tileSize,
                tileType,
                null,
                tile.ref,
                this.tileOverHandler,
                this.tileOutHandler,
                new Point3D(tile.x, tile.y, tile.z),
                floor
            )

            tileEntity.addEventListener('pointerdown', this.onPointerDown)

            if (tile.name) {
                this.namedTiles.set(tile.name, tileEntity)
            }

            const tileHgt = tile.hgt || 0

            tileEntity.initialTileHgt = tileHgt
            tileEntity.totalHeight = tileHgt
            tileEntity.updatePosition()

            if (this.disabledRefs.indexOf(tile.ref) !== -1) {
                tileEntity.setEnabled(false)
            }

            this.tiles.set(tile.ref, tileEntity)

            const mapX = Math.round(tile.x / this.tileSize)
            const mapY = Math.round(tile.y / this.tileSize)

            tileEntity.setMapX(mapX)
            tileEntity.setMapY(mapY)

            floor.addTile(tileEntity)

            this.sprites.addChild(tileEntity)
        }

        this.floors.push(floor)
    }

    tileOverHandler = (e: FederatedEvent): void => {
        const tile = (e?.currentTarget || e?.target) as TileEntity
        if (!tile) {
            console.log('not a tile', tile, e)

            return
        }

        if (this.furniController.isMoving()) {
            this.furniController.onTileOver(tile)
        }
        if (this.hilitedTile) {
            this.hilitedTile.alpha = 0
        }
        this.hilitedTile = tile
        if (Client.shared.isSpectating) {
            return
        }
        tile.alpha = 1
    }

    tileOutHandler = (e: FederatedEvent): void => {
        const tile = (e?.currentTarget || e?.target) as TileEntity
        if (tile) {
            if (this.hilitedTile === tile) {
                this.hilitedTile = null
            }
            tile.alpha = 0
        }
    }

    override async handleModelNode(node: IRoomLayoutElem): Promise<void> {
        if (node.type === 'floor') {
            const floor = node as IRoomLayoutElemFloor

            this.setFloor(floor.name, floor.elems as IRoomLayoutElemTile[], floor.x, floor.y, floor.z)
        } else if (node.type === 'wall') {
            const wall = node as IRoomLayoutElemWall

            this.setWall(
                wall.name,
                wall.ref,
                node.elems,
                wall.dir,
                wall.x,
                wall.y,
                wall.z ?? 0,
                wall.xd ?? 0,
                wall.yd ?? 0,
                wall.zd ?? 0
            )
        }
    }

    override async receiveExtraData(extraData: any): Promise<void> {
        await super.receiveExtraData(extraData)

        if (extraData) {
            this.hideSit = !!extraData.hideSit

            if (extraData.show) {
                if (this.serverShow) {
                    console.log('Not replacing existing show controller!')
                    return
                }
                console.log(' adding show !')

                this.serverShow = new ServerControlledShow(this, extraData.show)
                await this.serverShow.loadShow()
            }
        }
    }

    protected override async startAnimating(playSound = true): Promise<void> {
        const queue = this.itemQueue.slice().sort((a, b) => a.index - b.index)

        for (const item of queue) {
            await this.furniController.addItem(item)
        }
        this.itemQueue = []
        this.sendDebugData()

        return super.startAnimating(playSound)
    }

    async setBillboards(transition = false): Promise<void> {
        const billboards = Client.shared.billboardMgr.getForRoom(this.spaceId)

        for (const board of billboards) {
            const node = this.sprites.getChildByName(board.sprite_node) as Sprite
            if (!node) {
                console.log('could not find board ' + board.sprite_node)
                continue
            }

            let imageUrl = board.image_url

            if (imageUrl.startsWith('asset:')) {
                imageUrl = Config.environment.assetRoot + '/' + imageUrl.replace('asset:', '')
            }

            try {
                const tex = await Assets.load(imageUrl)
                if (imageUrl.endsWith('mp4')) {
                    const vidRes = tex.baseTexture.resource as VideoResource
                    const vid = vidRes.source as HTMLVideoElement
                    vid.currentTime = 0
                    vid.loop = false
                    vid.playsInline = true
                    vid.play()
                    Client.shared.soundScore.pause()
                } else if (transition) {
                    Client.shared.soundScore.resume()
                }
                if (transition && !imageUrl.endsWith('mp4')) {
                    const temp = Pooler.newSprite(tex)
                    temp.position.copyFrom(node.position)
                    temp.anchor.copyFrom(node.anchor)
                    temp.pivot.copyFrom(node.pivot)
                    temp.scale.copyFrom(node.scale)
                    temp.zIndex = node.zIndex + 1
                    temp.alpha = 0
                    this.sprites.addChild(temp)
                    this.sprites.sortChildren()
                    gsap.to(temp, {
                        duration: 1,
                        alpha: 1,
                        onComplete: () => {
                            node.texture = tex
                            this.sprites.removeChild(temp)?.destroy()
                        }
                    })
                } else {
                    node.texture = tex
                }
            } catch (err) {
                console.error('Unable to load billboard image', err)
                await Assets.unload(imageUrl)
            }

            if (board.click_node) {
                const clickNode = this.sprites.getChildByName(board.click_node) as Sprite
                if (clickNode) {
                    if (board.open_url) {
                        clickNode.eventMode = 'static'
                        clickNode.addEventListener('pointerup', () => {
                            if (board.open_url.startsWith('emit:')) {
                                Client.shared.serverBroker.send(board.open_url.replace('emit:', ''), {})
                            } else {
                                window.open(board.open_url)
                            }
                        })
                        clickNode.cursor = 'pointer'
                    } else if (clickNode.eventMode === 'static') {
                        clickNode.eventMode = 'auto'
                        clickNode.off('pointerup')
                        clickNode.cursor = 'auto'
                    }
                }
            }
        }
    }

    async addToItemQueue(item: RoomItem): Promise<void> {
        console.log('add to item queue', item)
        if (this.hasBeenRevealed) {
            await this.furniController.addItem(item, false, false)
        } else {
            this.itemQueue.push(item)
        }
    }

    addNPC(
        id: EntityRoomIdentifier,
        tileRef: number,
        direction: number,
        actions: any,
        ign: string,
        invisible?: boolean
    ): WalkableEntity<any> {
        let factor: WalkableEntity<any>
        if (invisible) {
            factor = new InvisibleNPCEntity(this, id, ign)
        } else {
            factor = new NPCEntity(this, id, ign, tileRef, direction, actions)
        }

        this.addEntity(factor)

        factor.jumpToPosition()

        return factor
    }

    addAvatar(
        id: EntityRoomIdentifier,
        outfit: AvatarOutfit,
        tileRef: number,
        direction: number,
        actions: any,
        ign: string,
        lanyard: ItemOwnedTuple[],
        badges: number[],
        signature: string,
        entryAnim?: (number | string)[],
        invisible?: boolean,
        trading?: boolean,
        effect?: EMagicEffectTypes,
        fxParam?: any,
        pronouns?: any,
        emote?: number
    ): AvatarEntity {
        this.removeEntityByRef(id)

        const entity = new AvatarEntity(
            this,
            id,
            outfit,
            tileRef,
            direction,
            actions,
            ign,
            lanyard.map((l) => new DummyItem(...l)),
            badges,
            signature,
            entryAnim,
            pronouns
        )

        if (trading) {
            emote = 1000
        }

        this.addEntity(entity)

        if (invisible) {
            entity.setInvisible(true)
        }
        if (emote) {
            entity.setEmote(emote)
        }

        entity.jumpToPosition()

        if (entity.entryAnim) {
            console.log('Doing entry anim: ', entity.entryAnim)
            if (typeof entity.entryAnim[0] === 'number') {
                entity.setTileRef(+entity.entryAnim[0])
                entity.jumpToPosition()
            }

            for (const step of entity.entryAnim) {
                if (typeof step === 'number') {
                    entity.startWalkingPath([step])
                } else if (String(step).endsWith('.ani')) {
                    this.animationMap.get(step)?.score.playFromBeginning(true)
                } else if (String(step).startsWith('vmk_anim')) {
                    const anim = this.animationMap.get(step)
                    if (anim) {
                        anim.score.playFromBeginning(true)
                    } else {
                        console.log('Unable to play entry anim step: ' + step)
                    }
                } else if (step === 'tele') {
                    entity.getVisual().alpha = 0
                    const tile = entity.getTile() as TileEntity
                    const teleport2 = AnimatedSprite.fromFrames(TeleportFrames)
                    teleport2.blendMode = 'screen'
                    teleport2.pivot.set(35, 136)
                    teleport2.animationSpeed = 0.15
                    teleport2.loop = false
                    teleport2.position.set(tile.x, tile.y - 25)
                    this.sprites.addChild(teleport2)
                    teleport2.play()

                    SoundManager.shared.play(ESndGrp.SFX, UISoundLibrary.Teleport4)
                    gsap.to(entity.getVisual(), {
                        alpha: 1,
                        delay: 0.5,
                        duration: 0.5,
                        onComplete() {
                            teleport2?.destroy()
                        }
                    })
                }
            }
        }
        if (effect) {
            const magicDef = MagicDefinitions.defs[effect]
            if (magicDef) {
                entity
                    .getVisual()
                    .setMagic(
                        new MagicEffect(
                            Client.shared.essentialAssets,
                            Client.shared.figuresMgr,
                            entity.getVisual(),
                            magicDef,
                            false,
                            fxParam
                        )
                    )
            }
        }

        return entity
    }

    setDisabledRefs(refs: number[]): void {
        for (const ref of this.disabledRefs) {
            this.tiles.get(ref)?.setEnabled(true)
        }
        for (const ref of refs) {
            this.tiles.get(ref)?.setEnabled(false)
        }
        this.disabledRefs = refs
    }

    override async enumerateScene(roomModel: IRoomLayout, hideSprites = false): Promise<void> {
        await super.enumerateScene(roomModel, hideSprites)

        await this.setBillboards()
    }

    override didBecomeHidden(): void {
        for (const o of this.entities) {
            gsap.killTweensOf(o.visual)
        }
    }

    private setWall(
        name: string,
        ref: number,
        holes: any[],
        dir: number,
        x: number,
        y: number,
        z: number,
        xd: number,
        yd: number,
        zd: number
    ): void {
        console.log('Creating new wall: ' + name + ' ' + ref + ' dir: ' + dir, {
            x,
            y,
            z,
            xd,
            yd,
            zd
        })
        const wall = new Wall(this, ref, new Plane3D(x, y, z, xd, yd, zd, dir))

        this.walls.set(ref, wall)

        wall.setParent(this.sprites)
    }

    private onPointerDown = (e: FederatedPointerEvent): void => {
        if (e && e.data.button === 2) {
            e.stopPropagation()
            const menu = new ContextMenu()
            const tile = e.currentTarget as TileEntity
            const items: any[] = [tile]
            tile.itemParts.forEach((p) => items.push(p.furni))
            menu.populateFor(...items)
            menu.position.copyFrom(Client.shared.viewport.toLocal(e.global))

            if (menu.y + menu.height > Constants.SIZE[1] - 27) {
                menu.y -= menu.height
            }
            if (menu.x + menu.width > Constants.SIZE[0]) {
                menu.x -= menu.width
            }
            Client.shared.stage.addChild(new AlertView(menu, 0, true))
        }
    }

    canFurnish(): boolean {
        return this.ownRoom || Client.shared.selfRecord.can(EPerm.RoomsGuestFurni)
    }
}
