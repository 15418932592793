import { Client } from '../../Client.js'
import type { EntityRoomIdentifier } from '../../enums.js'
import type { AvatarEntity } from '../../room/entities/AvatarEntity.js'
import type { AvatarVisual } from '../../room/entities/AvatarVisual.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class EntityBodyStateModule extends ServerEvent {
    static readonly type = MessageType.ENTITY_BODY_STATE

    async handle(data: any): Promise<void> {
        const entityId: EntityRoomIdentifier = data.entity
        const actions: any = data.actions
        const direction: number = data.direction

        if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
            const object = Client.shared.roomViewer.getEntityByRef(entityId) as AvatarEntity
            if (!object) {
                return
            }
            const avatarVisuals: AvatarVisual[] = [object.getVisual()]
            object.setDirection(direction)
            for (const avatar of avatarVisuals) {
                avatar.applyServerActions(actions)
            }
        } else {
            console.error('Entity not found: ' + entityId)
        }
    }
}
