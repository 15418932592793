const allTexts = {
    // while we could import these synchronously, this automatically makes webpack split the files
    ...(await import('../lang/en/init.json')),
    ...(await import('../lang/en/original.json'))
}

export type TextKey = keyof typeof allTexts | string

export class ExternalConfigManager {
    private texts = new Map<TextKey, string>()
    private variables = new Map<string, string>()

    constructor() {
        for (const k in allTexts) {
            this.setText(k, allTexts[k])
        }
    }

    getText(key: TextKey, fallback?: string): string {
        const entry = this.texts.get(key)

        if (typeof entry === 'string') {
            return entry.replace(/\\r/g, '\n')
        }

        if (typeof fallback === 'string') {
            return fallback
        }

        return key ?? ''
    }

    setText(key: string, value: string): void {
        this.texts.set(key, value)
    }

    setVariable(variableName: string, variable: any): void {
        if (this.variables.has(variableName)) {
            console.warn('Overwriting existing value for variable: ' + variableName)
        }
        this.variables.set(variableName, variable)
    }

    getVariable(variableName: string): any {
        return this.variables.get(variableName)
    }

    private static _instance: ExternalConfigManager

    static get instance(): ExternalConfigManager {
        return this._instance || (this._instance = new ExternalConfigManager())
    }
}

export function getText(key: TextKey, fallback?: string): string {
    return ExternalConfigManager.instance.getText(key, fallback)
}

export function getVar(key: string): any {
    return ExternalConfigManager.instance.getVariable(key)
}
