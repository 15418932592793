import { Pooler } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import type { DestroyOptions, Sprite } from 'pixi.js'
import { Assets, BitmapText, Graphics } from 'pixi.js'
import posthog from 'posthog-js'
import type { TextKey } from '../../assets/ExternalConfigManager.js'
import { getText } from '../../assets/ExternalConfigManager.js'
import { Client } from '../../Client.js'
import Config from '../../Config.js'
import { Fonts } from '../../Fonts.js'
import { Helpers } from '../../util/Helpers.js'
import { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import { BasicBox } from '../containers/BasicBox.js'
import { DOMText } from '../DOMText.js'
import { FieldG } from '../fields/FieldG.js'
import { FieldH } from '../fields/FieldH.js'
import type { SizeInfo } from '../views/AlertView.js'
import { ResponsiveContainer } from '../views/AlertView.js'
import { TextColor, VMKLText } from '../VMKLText'

export class LoginWindow extends ResponsiveContainer {
    loginBtn: BitmapTextButton
    private title: string
    private loginTF: DOMText
    private passTF: DOMText

    static lastEmail = ''
    private infoText: BitmapText
    private forgotBtn: BitmapTextButton

    private tweens = []

    constructor(prefillInfo: TextKey = '') {
        super()
        this.title = 'Log in to VMK Legacy'

        const logoContain = Pooler.newContainer()
        logoContain.alpha = 0
        const vmklLogo = Pooler.newSprite() as Sprite
        vmklLogo.width = 400
        vmklLogo.height = 57.5
        vmklLogo.x = (285 - 172) / 2
        const logoMask = new Graphics().beginFill(0xffffff).drawRect(0, 0, 172, 57.5).endFill()

        this.addChild(logoContain)
        logoContain.addChild(vmklLogo)
        vmklLogo.mask = logoMask
        vmklLogo.addChild(logoMask)

        const path = location.pathname
        const pathWithoutFile = path.substring(0, path.lastIndexOf('/'))

        Assets.load(`${location.origin}/${pathWithoutFile}/images/vmkl-wordmark@2x.png`).then((tex) => {
            if (this.destroyed) {
                return
            }
            vmklLogo.texture = tex
            this.tweens.push(
                gsap.to(logoContain, {
                    alpha: 1,
                    duration: 0.3
                })
            )

            Helpers.delay(1000).then(() => {
                if (this.destroyed) {
                    return
                }
                this.tweens.push(
                    gsap.to(logoMask, {
                        width: vmklLogo.width,
                        duration: 0.5,
                        ease: 'power2.out'
                    })
                )
                this.tweens.push(
                    gsap.to(vmklLogo, {
                        x: (285 - vmklLogo.width) / 2,
                        duration: 0.5,
                        ease: 'power2.out'
                    })
                )
            })
        })

        const alertBoxWidth = 285
        const bg = this.addChild(Pooler.newContainer())
        const box = new BasicBox(alertBoxWidth, 165)
        bg.addChild(box)
        bg.y = vmklLogo.height + 30

        const titleBg = new FieldH(alertBoxWidth - 12)
        bg.addChild(titleBg)
        titleBg.x = titleBg.y = 12

        this.eventMode = 'auto'

        const titleText = new VMKLText({ text: this.title || '', bold: true })
        titleText.y = 13
        const titlewidth: number = titleText.width
        titleText.x = Math.round((bg.width - titlewidth) / 2)
        bg.addChild(titleText)

        this.infoText = new BitmapText(getText(prefillInfo), {
            ...Fonts.Foxley_16,
            align: 'center',
            wordWrap: true,
            wordWrapWidth: 250
        })
        bg.addChild(this.infoText)
        this.infoText.anchor.set(0.5, 0)
        this.infoText.position.set(150, box.height + 20)

        const loginLabel = new VMKLText({ text: 'Email Address', bold: true })
        loginLabel.x = 12
        loginLabel.y = 38
        bg.addChild(loginLabel)

        const loginTF = new DOMText({
            kind: 'field',
            className: 'login',
            id: 'login.name',
            maxlength: 32,
            fieldWidth: 270,
            fieldHeight: 23,
            fontSize: 16,
            fontColor: TextColor.black,
            initialValue: LoginWindow.lastEmail,
            bgObject: new FieldG(272, 15),
            padLeft: 7,
            padTop: -1,
            noBreaks: false,
            enterHint: 'next',
            inputMode: 'email'
        })

        bg.addChild(loginTF)
        loginTF.x = loginLabel.x
        loginTF.y = loginLabel.y + 20
        this.loginTF = loginTF

        const passLabel = new VMKLText({ text: 'Password', bold: true })
        passLabel.x = loginLabel.x
        passLabel.y = loginTF.y + 34
        bg.addChild(passLabel)

        const passTF = new DOMText({
            kind: 'field',
            className: 'login',
            id: 'login.pass',
            maxlength: 120,
            fieldWidth: 270,
            fieldHeight: 23,
            fontSize: 16,
            fontColor: TextColor.black,
            initialValue: '',
            bgObject: new FieldG(272, 15),
            padLeft: 7,
            padTop: -1,
            noBreaks: false,
            enterHint: 'go'
        })

        bg.addChild(passTF)
        passTF.x = passLabel.x
        passTF.y = passLabel.y + 20
        this.passTF = passTF

        this.loginBtn = new BitmapTextButton(100, 'Log in', 'd')
        bg.addChild(this.loginBtn)
        this.loginBtn.x = this.passTF.x + this.passTF.width - this.loginBtn.width
        this.loginBtn.y = 150

        this.loginBtn.addEventListener('pointerup', () => this.login())

        this.forgotBtn = new BitmapTextButton(60, 'Forgot?', 'i')
        bg.addChild(this.forgotBtn)
        this.forgotBtn.x = 13
        this.forgotBtn.y = this.loginBtn.y

        this.forgotBtn.addEventListener('pointerup', () => {
            window.open(Config.environment.resetUrl)
        })

        if (Client.shared.startArgs?.has('discord')) {
            bg.visible = false
            return
        }

        loginTF.getElement().addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13) {
                // on enter key press
                if (passTF.getValue()) {
                    this.login()
                } else {
                    passTF.getElement().focus()
                }
            }
        })
        passTF.getElement().addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13) {
                // on enter key press
                if (loginTF.getValue()) {
                    this.login()
                } else {
                    loginTF.getElement().focus()
                }
            }
        })
        this.once('added', () => {
            loginTF.show()
            passTF.show()

            loginTF.forceFocus()

            if (Client.shared.startArgs?.get('e')) {
                loginTF.setValue(Client.shared.startArgs.get('e'))
                Client.shared.startArgs?.delete('e')

                if (Client.shared.startArgs.get('p')) {
                    passTF.setValue(Client.shared.startArgs.get('p'))
                    Client.shared.startArgs?.delete('p')

                    this.login()
                }
            }
        })
    }

    login() {
        this.loginTF.setReadonly(true)
        this.passTF.setReadonly(true)
        this.loginBtn.disable()

        this.infoText.text = 'Logging in...'

        const email = this.loginTF.getValue()
        const pass = this.passTF.getValue()

        LoginWindow.lastEmail = email

        Client.shared.api
            .login(email, pass, true)
            .then((data) => {
                posthog.identify('player:' + data.user.id, {
                    ign: data.user.ign
                })
                console.log('success', data)
                Client.shared.loadingView.setStatusText('Logging in ' + data.user.ign)
                Client.shared.loadingView.setVisible(true)
                this.parent.destroy({ children: true })

                Client.shared.authenticate(data.apiToken)
            })
            .catch((err) => {
                posthog.reset()
                console.log('error', err)

                this.infoText.text = Array.isArray(err) ? err.join('\n') : 'Unable to log you in. Please try again.'

                this.retryLogin()
            })
    }

    setInfoText(text: string): this {
        console.log('>> login set info to ' + text)
        this.infoText.text = text

        return this
    }

    retryLogin(): this {
        this.loginTF.setReadonly(false)
        this.passTF.setReadonly(false)
        this.loginBtn.enable()

        if (this.loginTF.getValue() === '') {
            this.loginTF.forceFocus()
        } else {
            this.passTF.forceFocus()
        }
        return this
    }

    override sizeDidChange(size: SizeInfo): void {
        console.log('centering login')

        size.safeAreaCenter(this, 285)

        this.loginTF.refit(size)
        this.passTF.refit(size)
    }

    override destroy(_options?: DestroyOptions | boolean) {
        super.destroy(_options)

        gsap.killTweensOf(this.tweens)
    }
}
