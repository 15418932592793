import { Pooler } from '@vmk-legacy/render-utils'
import type { Container, FederatedEvent } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { Constants } from '../../Constants.js'
import { Fonts } from '../../Fonts.js'
import { Converter } from '../../util/Converter.js'
import { ScrollArea } from '../containers/ScrollArea.js'
import { FieldHlp } from '../fields/FieldHlp.js'
import { FieldHlp2 } from '../fields/FieldHlp2.js'
import { AccordionList } from '../lists/AccordionList.js'
import { AccordionListItem } from '../lists/AccordionListItem.js'
import type { HelpWindow } from './HelpWindow.js'
import { UIWindowView } from './UIWindowView.js'

export class GuideView extends UIWindowView<HelpWindow> {
    static helps: any

    constructor() {
        super()
        const guideTexts: any = GuideView.helps
        const qContainer: ScrollArea = new ScrollArea(268, 120)

        const acc = new AccordionList(qContainer)

        // TODO: make this a utility?
        // helper to speed up adjusting accordion hierarchy
        function addCategoryWithChildren(categoryName: string, children: FieldHlp2[]): AccordionListItem {
            const catItem = new AccordionListItem()

            // Create a field and add it to category item
            const catField = new FieldHlp(categoryName)
            catItem.addChild(catField)

            catField.addEventListener('pointerup', (e: FederatedEvent) => {
                acc.setExpanded(catItem, !catField.isSelected())
                catField.setSelected(!catField.isSelected())
            })

            for (const childField of children) {
                const childItem = new AccordionListItem()

                childField.eventMode = 'static'
                childItem.eventMode = 'static'

                childItem.addEventListener('pointertap', (e: FederatedEvent) => {
                    acc.clearSelection()
                    childField.setSelected(!childField.isSelected())
                    const category = Converter.toSlug(catField.getText())
                    const id = Converter.toSlug(childField.getText())
                    if (guideTexts[category]?.[id]?.a) {
                        aText.text = guideTexts[category][id].a
                    } else {
                        aText.text = Constants.HELP_GUIDE_ENTRY_NOT_FOUND
                    }
                    aContainer.resetScroll()
                })
                childItem.addChild(childField)
                catItem.addChild(childItem)
            }
            return catItem
        }

        acc.add(
            addCategoryWithChildren('About VMK', [
                new FieldHlp2('What Is VMK?'),
                new FieldHlp2('Help & Safety'),
                new FieldHlp2('Game Staff'),
                new FieldHlp2('Community Leaders'),
                new FieldHlp2('Game Values')
            ])
        )
        acc.add(
            addCategoryWithChildren('Your Character', [
                new FieldHlp2('Your Guest Name'),
                new FieldHlp2('Your Signature'),
                new FieldHlp2('Wearing Pins'),
                new FieldHlp2('Badges')
            ])
        )
        acc.add(
            addCategoryWithChildren('Moving Around', [
                new FieldHlp2('Walking Around'),
                new FieldHlp2('Using The Map'),
                new FieldHlp2('Monorail & Teleporters'),
                new FieldHlp2('Following a Friend')
            ])
        )
        acc.add(
            addCategoryWithChildren('Guest Rooms', [
                new FieldHlp2('Buying a Room'),
                new FieldHlp2('Furnishing a Room'),
                new FieldHlp2('Changing Room Info'),
                new FieldHlp2('Teleporters')
            ])
        )
        acc.add(
            addCategoryWithChildren('Chat & Friends', [
                new FieldHlp2('Chat'),
                new FieldHlp2('Allowed Words'),
                new FieldHlp2('Emotes & Magic'),
                new FieldHlp2('Meeting People'),
                new FieldHlp2('Mail'),
                new FieldHlp2('Peace & Quiet'),
                new FieldHlp2('Call For Help')
            ])
        )
        acc.add(
            addCategoryWithChildren('Credits & Stuff', [
                new FieldHlp2('Credits'),
                new FieldHlp2('Codes'),
                new FieldHlp2('Shopping'),
                new FieldHlp2('Refunds'),
                new FieldHlp2('Trading'),
                new FieldHlp2('Furnishings'),
                new FieldHlp2('Clothing'),
                new FieldHlp2('Pins'),
                new FieldHlp2('Posters'),
                new FieldHlp2('Rooms'),
                new FieldHlp2('Camera')
            ])
        )
        acc.add(addCategoryWithChildren('Secret Codes', [new FieldHlp2('Forum Events')]))
        acc.add(
            addCategoryWithChildren('Playing Games', [
                new FieldHlp2('Pirates of the Caribbean'),
                new FieldHlp2('Jungle Cruise Photo Safari'),
                new FieldHlp2('Castle Fireworks'),
                new FieldHlp2('Street Party Music'),
                new FieldHlp2('High Scores'),
                new FieldHlp2('Guest Room Gaming')
            ])
        )
        acc.add(
            addCategoryWithChildren('Exploring The Kingdom', [
                new FieldHlp2('Hidden Mickey Quest'),
                new FieldHlp2('Finding Disney Characters'),
                new FieldHlp2('Taking Photos'),
                new FieldHlp2('Visiting Shops'),
                new FieldHlp2('Finding the Monorail'),
                new FieldHlp2('Sampling Magic')
            ])
        )
        acc.add(
            addCategoryWithChildren('Music & Sound', [
                new FieldHlp2('Sound Preferences'),
                new FieldHlp2('Mixing Your Own Music'),
                new FieldHlp2('Playing Your Own Music')
            ])
        )

        qContainer.setContent(acc)
        this.addChild(qContainer)

        const answersHeader = new BitmapText('ANSWERS', {
            fontFamily: 'Folio',
            fontSize: 12,
            align: 'left'
        })
        this.addChild(answersHeader)
        answersHeader.x = 118
        answersHeader.y = 151

        const aText = new BitmapText('Select a category from the list above to learn more!', {
            ...Fonts.Foxley_16,
            wordWrap: true
        })
        const aContainer = new ScrollArea(268, 190, aText)
        setTimeout(() => {
            aText.style.wordWrapWidth = aContainer.getMaskRect().width - 10
        }, 0)
        this.addChild(aContainer)
        aContainer.y = 170
    }
}
