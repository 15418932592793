import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import type { FederatedEvent } from 'pixi.js'
import { Client } from '../../Client.js'
import { EWindow } from '../../enums.js'
import { PrefTemp } from '../../SessionPrefs.js'
import { HighlightButton } from '../buttons/HighlightButton'
import type { ImageButton } from '../buttons/ImageButton.js'
import { TabController } from '../TabController.js'
import { UISoundLibrary } from '../UISoundLibrary.js'
import LegacyWindow from './LegacyWindow.js'
import type { UIWindowTab } from './UIWindowTab.js'

export enum NavArea {
    parkmap = 'parkmap',
    whatsnew = 'whatsnew',
    adventureland = 'adventureland',
    frontierland = 'frontierland',
    fantasyland = 'fantasyland',
    tomorrowland = 'tomorrowland',
    mainstreet = 'mainstreet',
    neworleans = 'neworleans'
}

export class VMKNavigator extends LegacyWindow {
    override playsOpenSound = false
    private tabController: TabController
    override isDraggable = false
    override kind = EWindow.Navigator

    private mapPages: NavArea[] = [
        NavArea.parkmap,
        NavArea.adventureland,
        NavArea.frontierland,
        NavArea.fantasyland,
        NavArea.tomorrowland,
        NavArea.mainstreet,
        NavArea.neworleans
    ]

    override exitBtn: ImageButton

    protected casts = ['navigator/navigator']

    constructor() {
        super('vmk_nav')
    }

    override async windowWasBuilt(): Promise<void> {
        SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.OpenMap)

        this.eventMode = 'static'
        this.interactiveChildren = true

        this.exitBtn = this.getElement('close')
        this.exitBtn.addEventListener('pointerup', () => {
            this.destroy()
        })

        this.tabController = this.addChild(new TabController(this))
        this.tabController.position.set(25, 5)
        this.tabController.on('change', (newTab: UIWindowTab) => {
            Client.shared.prefs.setTemp(PrefTemp.NavTab, newTab.id.substr('map.tab.'.length) as NavArea)
        })

        const tints = {
            [NavArea.parkmap]: 0x0070b7,
            [NavArea.adventureland]: 0x80c045,
            [NavArea.frontierland]: 0x822713,
            [NavArea.fantasyland]: 0x855fa7,
            [NavArea.tomorrowland]: 0x0f080f,
            [NavArea.mainstreet]: 0x995aa1,
            [NavArea.neworleans]: 0x401857
        }

        const activeTab = Client.shared.prefs.getTemp(PrefTemp.NavTab)

        for (const area of this.mapPages) {
            const page = new LegacyWindow(`vmk_nav_${area}`)
            page.casts = this.casts

            this.tabController
                .addTab(`map.tab.${area}`, page, area === activeTab)
                .setTint(tints[area])
                .setName(area)

            page.waitToBeBuilt().then(() => {
                page.hide('*advancedmode*')
                for (const e of page.getElements('nav.*', 'sprite')) {
                    const room = e.label.substring(4).replace('.active', '')
                    if (area === NavArea.parkmap) {
                        if (room === 'guestrooms') {
                            e.addEventListener('pointerup', (e) => {
                                Client.shared.userInterface.getWin(EWindow.GuestRooms, true)
                            })
                        } else {
                            const t = this.tabController.getChildByName(room) as UIWindowTab
                            e.addEventListener('pointerup', (e: FederatedEvent) => {
                                this.tabController.setActive(t)
                                e.originalEvent.preventDefault()
                            })
                        }
                    } else {
                        e.addEventListener('pointerup', () => {
                            const directId = `${area}.${room}`
                            Client.shared.serverBroker.requestRoom(directId)
                            SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.MapRoomSelection)
                        })
                    }
                    e.eventMode = 'static'
                    e.cursor = 'pointer'

                    if (e.label.endsWith('active')) {
                        e.blendMode = 'add'
                        e.visible = false
                        e.addEventListener('pointerover', () => {
                            e.visible = true
                        })
                        e.addEventListener('pointerout', () => {
                            e.visible = false
                        })
                    } else {
                        e.addEventListener('pointerover', () => {
                            page.getElement(`${e.label}.active`).visible = true
                        })
                        e.addEventListener('pointerout', () => {
                            page.getElement(`${e.label}.active`).visible = false
                        })
                    }
                }
                if (area === NavArea.parkmap) {
                    const names = [
                        {
                            name: '"it\'s a small world" Imports',
                            id: 26
                        },
                        {
                            name: 'VMK GALLERY',
                            id: 52
                        },
                        {
                            name: 'SPLASH MOUNTAIN',
                            id: 13
                        },
                        {
                            name: 'VMK ESPLANADE',
                            id: 50
                        }
                    ]
                    for (let i = 0; i < 4; i++) {
                        page.setField(`nav.shortcut_${i + 1}`, names[i].name.toUpperCase()).addEventListener(
                            'pointerup',
                            () => {
                                SoundManager.shared.stopAll(ESndGrp.Music)
                                SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.MapRoomSelection)
                                Client.shared.serverBroker.send('room_join_request', {
                                    roomId: names[i].id.toString()
                                })
                            }
                        )
                    }
                }
            })
        }

        this.hide('nav.tab.*')
        const guestRoomsButton = new HighlightButton('button.guestrooms.active')
        this.replaceElement('nav.guestrooms.button', guestRoomsButton)

        guestRoomsButton.addEventListener('pointerup', () =>
            Client.shared.userInterface.getWin(EWindow.GuestRooms, true)
        )
    }
}
