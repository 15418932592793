import { BitmapText } from 'pixi.js'
import { Client } from '../../../Client.js'
import { Constants } from '../../../Constants.js'
import { EWindow } from '../../../enums.js'
import { Fonts } from '../../../Fonts.js'
import type { FurniEntity } from '../../../room/renderer/types/FurniEntity.js'
import { BitmapTextButton } from '../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../buttons/BitmapTextButtonType.js'
import { CheckBox } from '../../buttons/CheckBox.js'
import { CheckBoxController } from '../../CheckBoxController.js'
import { BasicBox } from '../../containers/BasicBox.js'
import { DOMText } from '../../DOMText.js'
import { FieldG } from '../../fields/FieldG.js'
import { TextColor } from '../../VMKLText'
import { UIWindow } from '../UIWindow.js'

export class TrackDataEditor extends UIWindow {
    kind: EWindow = EWindow.Other

    windowWasBuilt(): Promise<void> {
        return Promise.resolve()
    }

    infoTxt: BitmapText
    private prizePackId: DOMText
    private alert: DOMText

    constructor(readonly trackPiece: FurniEntity) {
        super()
    }

    async _buildWindow(): Promise<void> {
        const inspector = this.trackPiece.controller.room.inspector
        const existing = inspector.furniData[this.trackPiece.getItem().itemId]

        this.isDraggable = true

        this.alpha = 0.75

        const bg = new BasicBox(260, 175)
        this.addChild(bg)

        this.infoTxt = this.addChild(
            new BitmapText('Editing data for ' + this.trackPiece.getItem().getName(), {
                ...Fonts.FoxleyBold_16,
                wordWrapWidth: 265
            })
        )
        this.infoTxt.position.set(10, 10)

        const prizeLbl = this.addChild(
            new BitmapText('Gives Pack #', {
                ...Fonts.Foxley_16,
                wordWrapWidth: 270
            })
        )
        prizeLbl.position.set(10, 50)

        this.prizePackId = this.addChild(
            new DOMText({
                kind: 'field',
                className: 'mk',
                id: '',
                maxlength: 0,
                fieldWidth: 55,
                fieldHeight: 16,
                fontSize: 16,
                fontColor: TextColor.black,
                initialValue: existing?.prizePack || '',
                bgObject: new FieldG(55, 10),
                padLeft: 5,
                padTop: 0,
                noBreaks: true,
                enterHint: '',
                inputMode: 'numeric'
            })
        )
        this.prizePackId.position.set(prizeLbl.x + prizeLbl.width + 5, 45)

        const hideCheck = this.addChild(new CheckBox('hide', 'Hide to Players'))
        hideCheck.setActive(existing?.hide || false)
        hideCheck.position.set(160, 40)

        new CheckBoxController([hideCheck])

        const exitChck = this.addChild(new CheckBox('exit', 'Exits to Park'))
        exitChck.setActive(existing?.exit || false)
        exitChck.position.set(160, 55)

        new CheckBoxController([exitChck])

        const alertLbl = this.addChild(
            new BitmapText('Alert player:', {
                ...Fonts.Foxley_16,
                wordWrapWidth: 270
            })
        )
        alertLbl.position.set(10, 70)

        this.alert = this.addChild(
            new DOMText({
                kind: 'area',
                className: 'mk',
                id: '',
                maxlength: 0,
                fieldWidth: 245,
                fieldHeight: 60,
                fontSize: 16,
                fontColor: TextColor.black,
                initialValue: existing?.alert || '',
                bgObject: new FieldG(245, 55),
                padLeft: 5,
                padTop: 0,
                noBreaks: true,
                enterHint: '',
                inputMode: 'text'
            })
        )
        this.alert.position.set(10, 88)

        const cancelBtn = this.addChild(new BitmapTextButton(75, 'Cancel', BitmapTextButtonType.LIME))
        cancelBtn.position.set(40, this.alert.y + this.alert.height + 5)
        cancelBtn.addEventListener('pointertap', () => {
            this.trackPiece.stopPulse()
            Client.shared.userInterface.removeWindow(this)
        })

        const saveBtn = this.addChild(new BitmapTextButton(75, 'Save', BitmapTextButtonType.LIME))
        saveBtn.position.set(170, this.alert.y + this.alert.height + 5)
        saveBtn.addEventListener('pointertap', () => {
            const packId = this.prizePackId.getValue()
            const alert = this.alert.getValue().trim()
            let data: any = {}
            if (packId) {
                data.prizePack = Number(packId)
            }
            if (alert) {
                data.alert = alert
            }
            if (hideCheck.getActive()) {
                data.hide = true
            }
            if (exitChck.getActive()) {
                data.exit = true
            }
            if (Object.keys(data).length === 0) {
                data = null
            }
            console.log('saving furni data', this.trackPiece.getItem().itemId, data)
            Client.shared.serverBroker.send('save_furni_data', {
                ownedId: this.trackPiece.getItem().itemId,
                data
            })
            this.trackPiece.stopPulse()
            Client.shared.userInterface.removeWindow(this)
        })

        this.eventMode = 'static'
        this.alreadyBuilt = true

        await this.windowWasBuilt()

        this.sizeDidChange()
    }

    sizeDidChange() {
        this.x = Math.round((Constants.SIZE[0] - this.width) / 2) + 200
        this.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }
}
