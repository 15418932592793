/*
 * Text.ts
 * VMK Legacy Client
 */

import { BitmapText } from 'pixi.js'
import { Fonts } from '../../Fonts.js'
import { View } from './View.js'

export class Text extends View {
    font = Fonts.Foxley_16
    color = 0x000000

    override view: BitmapText

    constructor(readonly value: string) {
        super()
    }

    bold(): this {
        this.font = Fonts.FoxleyBold_16

        return this
    }

    override loadView(): void {
        this.view = new BitmapText(this.value, {
            ...this.font,
            tint: this.color
        })
    }

    override sizeToFit(proposed: { width?: number; height?: number }): void {
        if (proposed.width !== undefined) {
            this.view.style.wordWrap = true
            this.view.style.wordWrapWidth = proposed.width
        }
    }

    foregroundColor(color: number): this {
        this.color = color

        return this
    }
}
