import { EPerm } from '@vmk-legacy/common-ts'
import type { DestroyOptions } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { Client } from '../../../../Client.js'
import { ItemStack } from '../../../../data/ItemStack.js'
import { EWindow } from '../../../../enums.js'
import { Fonts } from '../../../../Fonts.js'
import { BitmapTextButton } from '../../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../../buttons/BitmapTextButtonType.js'
import { ContentBox } from '../../../containers/ContentBox.js'
import { DOMText } from '../../../DOMText.js'
import { Field } from '../../../fields/Field.js'
import { PagedThumbnailBox } from '../../../thumbs/PagedThumbnailBox.js'
import { TextColor } from '../../../VMKLText'
import { Separator } from '../../Separator.js'
import { UIWindowView } from '../../UIWindowView.js'
import type { ModWindow } from '../ModWindow.js'
import type { ChatLogView } from './ChatLogView.js'
import { MKAdjustCoinsPopup } from './popups/MKAdjustCoinsPopup.js'
import { MKAlertPopup } from './popups/MKAlertPopup.js'
import { MKBadgesPopup } from './popups/MKBadgesPopup.js'
import { MKBanPopup } from './popups/MKBanPopup.js'
import { MKChangeIgnPopup } from './popups/MKChangeIgnPopup.js'
import { MKChangeSignaturePopup } from './popups/MKChangeSignaturePopup.js'
import { MKGivePopup } from './popups/MKGivePopup.js'
import { MKSendMessagePopup } from './popups/MKSendMessagePopup.js'

export class UsersView extends UIWindowView<ModWindow> {
    padding = 5
    private topText: BitmapText
    private itemToDelete: ItemStack = null
    private notesTf: DOMText

    private pagedInventory: PagedThumbnailBox

    userPacket: {
        roomId: number
        uid: number
        siganture: string
        credits: number
        ign: string
        badges: number[]
        notes: string
    }
    private inventory: ItemStack[]
    nameField: DOMText

    constructor() {
        super()

        this.topText = new BitmapText('Type the in-game name of a user to learn more', {
            ...Fonts.FoxleyBold_16
        })
        this.topText.x = Math.round((325 - this.topText.width) / 2)
        this.addChild(this.topText)

        const separator1 = new Separator(325)
        separator1.y = 20
        this.addChild(separator1)

        const ign = new BitmapText('In-game name: ', {
            ...Fonts.Foxley_16
        })
        ign.y = 30
        this.addChild(ign)
        const searchBtn = new BitmapTextButton(79, 'SEARCH', BitmapTextButtonType.LIGHT_BLUE)
        searchBtn.x = 239
        searchBtn.y = 27
        this.addChild(searchBtn)
        searchBtn.addEventListener('pointerup', () => {
            if (nameTf.getValue().trim() === '') {
                return
            }
            Client.shared.serverBroker.send('mk_search', {
                ign: nameTf.getValue()
            })
            this.setSearching(true)
            const oldVal = nameTf.getValue()
            nameTf.setValue('')
            nameTf.getElement().placeholder = oldVal
        })

        const sep2 = new Separator(325)
        sep2.y = 53
        this.addChild(sep2)

        const infoDeets = new BitmapText('User ID:\nEmail:\nRegistered\nLast Online:\nCredits:\nSignature:', {
            ...Fonts.FoxleyBold_16
        })
        infoDeets.y = 63
        this.addChild(infoDeets)

        const infoVals = new BitmapText('', {
            ...Fonts.Foxley_16
        })
        infoVals.style.wordWrapWidth = 215
        infoVals.x = 110
        infoVals.y = 64
        this.addChild(infoVals)

        const col1 = 0
        const col2 = 165
        const row1 = 186
        const row2 = 212
        const row3 = 238
        const row4 = 264
        const row5 = 290
        const row6 = 316
        const row7 = 342

        // ROW 1

        if (Client.shared.selfRecord.can(EPerm.GameTeleport)) {
            const visitBtn = new BitmapTextButton(152, 'ENTER SAME ROOM', BitmapTextButtonType.LIGHT_BLUE)
            this.addChild(visitBtn)
            visitBtn.position.set(col1, row1)
            visitBtn.addEventListener('pointerup', () => {
                this.onEnterSameRoom()
            })
        }

        const msgBtn = new BitmapTextButton(152, 'SEND MESSAGE', BitmapTextButtonType.LIGHT_BLUE)
        this.addChild(msgBtn)
        msgBtn.position.set(col2, row1)
        msgBtn.addEventListener('pointerup', () => {
            this.onSendMessage()
        })

        // ROW 2
        if (Client.shared.selfRecord.can(EPerm.GameAlertRoom)) {
            const alertBtn = new BitmapTextButton(152, 'SEND ALERT', BitmapTextButtonType.LIGHT_BLUE)
            this.addChild(alertBtn)
            alertBtn.position.set(col1, row2)
            alertBtn.addEventListener('pointerup', () => {
                this.onAlert()
            })
        }

        const bootBtn = new BitmapTextButton(152, 'BOOT FROM ROOM', BitmapTextButtonType.RED)
        this.addChild(bootBtn)
        bootBtn.position.set(col2, row2)
        bootBtn.addEventListener('pointerup', () => {
            this.onBootFromRoom()
        })

        // ROW 3
        if (Client.shared.selfRecord.can(EPerm.GameDisconnect)) {
            const disconnectBtn = new BitmapTextButton(152, 'DISCONNECT USER', BitmapTextButtonType.RED)
            this.addChild(disconnectBtn)
            disconnectBtn.position.set(col1, row3)
            disconnectBtn.addEventListener('pointerup', () => {
                this.onDisconnect()
            })
        }

        if (Client.shared.selfRecord.can(EPerm.BansIssue)) {
            const banBtn = new BitmapTextButton(152, 'BAN', BitmapTextButtonType.RED)
            this.addChild(banBtn)
            banBtn.position.set(col2, row3)
            banBtn.addEventListener('pointerup', () => {
                this.onBan()
            })
        }

        // ROW 4

        const changeIgnBtn = new BitmapTextButton(152, 'EDIT IN-GAME NAME', BitmapTextButtonType.LIME)
        this.addChild(changeIgnBtn)
        changeIgnBtn.position.set(col1, row5)
        changeIgnBtn.addEventListener('pointerup', () => {
            this.onEditIgn()
        })

        const editSigBtn = new BitmapTextButton(152, 'EDIT SIGNATURE', BitmapTextButtonType.LIME)
        this.addChild(editSigBtn)
        editSigBtn.position.set(col2, row5)
        editSigBtn.addEventListener('pointerup', () => {
            this.onEditSignature()
        })

        // ROW 5 & 6
        const editBadgeBtn = new BitmapTextButton(152, 'EDIT BADGES', BitmapTextButtonType.LIME)
        this.addChild(editBadgeBtn)
        editBadgeBtn.position.set(col1, row6)
        editBadgeBtn.addEventListener('pointerup', () => {
            this.onEditBadges()
        })

        if (Client.shared.selfRecord.can(EPerm.RoomsPublicChatLogs)) {
            const viewChatLogs = new BitmapTextButton(152, 'VIEW CHAT LOGS', BitmapTextButtonType.ORANGE)
            this.addChild(viewChatLogs)
            viewChatLogs.position.set(col2, row6)
            viewChatLogs.addEventListener('pointerup', () => {
                this.onViewChatLogs()
            })
        }

        if (Client.shared.selfRecord.can(EPerm.PlayersInventoryAddition)) {
            const adjustCredsBtn = new BitmapTextButton(152, 'ADJUST CREDITS', BitmapTextButtonType.LIME)
            this.addChild(adjustCredsBtn)
            adjustCredsBtn.position.set(col1, row7)
            adjustCredsBtn.addEventListener('pointertap', () => this.onAdjustCreds())
        }

        if (Client.shared.selfRecord.can(EPerm.MKAccessWeb)) {
            const webBtn = new BitmapTextButton(152, 'VIEW IN WEB MK', BitmapTextButtonType.ORANGE)
            this.addChild(webBtn)
            webBtn.position.set(col2, row7)
            webBtn.addEventListener('pointerup', () => {
                window.open(
                    (process.env.NODE_ENV === 'development' ? 'http://mk.vmklegacy.test' : 'https://mk.vmklegacy.com') +
                        '/resources/players/' +
                        this.userPacket.uid
                )
            })
        }
        // Right column
        if (Client.shared.selfRecord.can(EPerm.PlayersInventoryView)) {
            const contentTitle = new BitmapText('INVENTORY', {
                fontFamily: 'Folio',
                fontSize: 12
            })
            contentTitle.x = 342 + Math.round((330 - contentTitle.width) / 2)
            this.addChild(contentTitle)

            const content = new ContentBox(325, 160)
            content.x = 335
            content.y = 20
            this.addChild(content)

            if (Client.shared.selfRecord.can(EPerm.PlayersInventoryRemoval)) {
                const removeItemBtn = new BitmapTextButton(130, 'DELETE SELECTED', BitmapTextButtonType.RED)
                removeItemBtn.x = 365
                removeItemBtn.y = 165
                this.addChild(removeItemBtn)
                removeItemBtn.addEventListener('pointerup', () => {
                    this.onDeleteItem()
                })
            }

            if (Client.shared.selfRecord.can(EPerm.PlayersInventoryAddition)) {
                const addItemBtn = new BitmapTextButton(130, 'GIVE ITEM', BitmapTextButtonType.GREEN)
                addItemBtn.x = 365 + 150
                addItemBtn.y = 165
                this.addChild(addItemBtn)
                addItemBtn.addEventListener('pointerup', () => {
                    this.onGiveItem()
                })
            }
        }

        // Left column
        const logTitle = new BitmapText('STAFF NOTES', {
            fontFamily: 'Folio',
            fontSize: 12
        })
        logTitle.x = 342 + Math.round((330 - logTitle.width) / 2)
        logTitle.y = 205
        this.addChild(logTitle)

        const nameTf = (this.nameField = new DOMText({
            kind: 'field',
            className: 'mk',
            id: 'reg.name',
            maxlength: 20,
            fieldWidth: 130,
            fieldHeight: 19,
            fontSize: 16,
            fontColor: TextColor.white,
            initialValue: '',
            bgObject: new Field('b', 120),
            padLeft: 6,
            padTop: -2
        }))
        nameTf.x = 96
        nameTf.y = 27
        this.addChild(nameTf)
        nameTf.getElement().addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key.toLowerCase() === 'enter') {
                if (nameTf.getValue().trim() === '') {
                    return
                }
                Client.shared.serverBroker.send('mk_search', {
                    ign: nameTf.getValue()
                })
                this.setSearching(true)
                const oldVal = nameTf.getValue()
                nameTf.setValue('')
                nameTf.getElement().placeholder = oldVal
            }
        })

        this.notesTf = new DOMText({
            kind: 'area',
            className: 'mk',
            id: 'mk.notes',
            maxlength: 1024,
            fieldWidth: 310,
            fieldHeight: 110,
            fontSize: 14,
            fontColor: TextColor.white,
            initialValue: '',
            bgObject: new ContentBox(310, 140),
            padLeft: 10,
            padTop: 5
        })
        this.notesTf.position.set(342, 220)
        this.addChild(this.notesTf)

        const saveNotes = new BitmapTextButton(130, 'SAVE NOTES', BitmapTextButtonType.GREEN)
        saveNotes.x = 365 + 157
        saveNotes.y = 137 + 210
        this.addChild(saveNotes)
        saveNotes.addEventListener('pointerup', () => {
            this.onSaveNotes()
        })

        this.once('added', () => {
            Client.shared.serverBroker.onEvent('mk_user_record', (record: any) => {
                this.setRecord(record, infoVals)
            })
        })
    }

    private async onDeleteItem() {
        if (this.itemToDelete) {
            if (
                await Client.shared.helpers.confirm(
                    `Are you sure you want to remove one ${this.itemToDelete.getName()} from user ${this.userPacket.ign}'s inventory? This event will be audited.`
                )
            ) {
                Client.shared.serverBroker.send('mk_delete_item', {
                    ownerId: this.userPacket.uid,
                    defUid: this.itemToDelete.defUid,
                    stars: this.itemToDelete.stars,
                    expires: this.itemToDelete.expires,
                    teleId: this.itemToDelete.teleporterId
                })
            }
        }
    }

    setSearching(searching: boolean) {}

    setRecord(r: any, infoTf: BitmapText) {
        this.setSearching(false)

        this.userPacket = {
            roomId: r.roomId,
            uid: r.id,
            siganture: r.signature,
            credits: r.credits,
            ign: r.ign,
            badges: r.badges,
            notes: r.notes
        }

        infoTf.text = `${r.id} (${r.ign})\n${r.email}\n${r.registered}\n${r.lastOnline}\n${r.credits}\n${r.signature}`

        if (this.pagedInventory) {
            this.removeChild(this.pagedInventory.getControllerContainer())
            this.removeChild(this.pagedInventory)
        }
        const items = []
        r.inventory.forEach((itemData: any) => {
            const item = new ItemStack(...itemData)
            items.push(item)
        })
        this.setInventory(items)
        this.notesTf.setValue(r.notes)
    }

    setInventory(items: ItemStack[]) {
        this.inventory = items
        this.pagedInventory = new PagedThumbnailBox({
            rows: 3,
            cols: 7,
            items: items,
            spacing: 1,
            onItemTap: (item: ItemStack) => {
                this.pagedInventory.setSelected(item)
                this.itemToDelete = item
            },
            onItemDoubleTap: () => {},
            pageTurnSpacing: 305,
            pageTurnFormat: PagedThumbnailBox.FORMAT_BLANK
        })
        this.addChild(this.pagedInventory)
        this.pagedInventory.x = 358
        this.pagedInventory.y = 25
        const arrows = this.pagedInventory.getControllerContainer()
        arrows.position.set(340, 80)
        this.addChild(arrows)

        this.itemToDelete = null
    }

    update() {}

    // MK FUNCTIONS

    onEnterSameRoom() {
        if (!this.userPacket?.roomId) {
            return
        }
        Client.shared.serverBroker.send('room_join_request', {
            roomId: String(this.userPacket.roomId)
        })
    }

    onBan() {
        new MKBanPopup(this.userPacket.uid, this.userPacket.ign)
    }

    onEditBadges() {
        new MKBadgesPopup(this.userPacket.uid, this.userPacket.ign, this.userPacket.badges)
    }

    onEditIgn() {
        new MKChangeIgnPopup(this.userPacket.uid, this.userPacket.ign)
    }

    onEditSignature() {
        new MKChangeSignaturePopup(this.userPacket.uid, this.userPacket.ign)
    }

    onSendMessage() {
        new MKSendMessagePopup(this.userPacket.uid, this.userPacket.ign)
    }

    onDisconnect() {
        Client.shared.serverBroker.send('mk_disconnect', {
            id: this.userPacket.uid
        })
    }

    onBootFromRoom() {
        Client.shared.serverBroker.send('mk_boot', {
            id: this.userPacket.uid
        })
    }

    onAlert() {
        new MKAlertPopup(this.userPacket.uid, this.userPacket.ign)
    }

    onGiveItem() {
        new MKGivePopup(this.userPacket.uid, this.userPacket.ign)
    }

    onAdjustCreds() {
        return new MKAdjustCoinsPopup(this.userPacket.uid, this.userPacket.ign, this.userPacket.credits)
    }

    onSaveNotes() {
        Client.shared.serverBroker.send('mk_save_notes', {
            userId: this.userPacket.uid,
            notes: this.notesTf.getValue()
        })
    }

    async onViewChatLogs() {
        const mkWin = (await Client.shared.userInterface.getWin(EWindow.Mod)) as ModWindow
        if (mkWin) {
            mkWin.setActiveTab(mkWin.chatLogTab)
            ;(mkWin.chatLogTab.view as ChatLogView).searchFor(this.userPacket.ign)
        }
    }

    override destroy(_options?: DestroyOptions | boolean): void {
        super.destroy(_options)

        Client.shared.serverBroker.offEvent('mk_user_record')
    }

    // public onSendAlert() {
    //     if (!this.userPacket) {
    //         return;
    //     }
    // }
}
