import { ESndGrp, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { BitmapText, Container } from 'pixi.js'
import { Client } from '../../../../../Client.js'
import { Constants } from '../../../../../Constants.js'
import { EWindow, UILayer } from '../../../../../enums.js'
import { Fonts } from '../../../../../Fonts.js'
import { BitmapTextButton } from '../../../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../../../buttons/BitmapTextButtonType.js'
import { ImageButton } from '../../../../buttons/ImageButton.js'
import { BasicBox } from '../../../../containers/BasicBox.js'
import { DOMText } from '../../../../DOMText.js'
import { FieldH } from '../../../../fields/FieldH.js'
import { UISoundLibrary } from '../../../../UISoundLibrary.js'
import { TextColor } from '../../../../VMKLText'
import type { IWindow } from '../../../IWindow.js'

export class MKAdjustCoinsPopup extends Container implements IWindow {
    readonly kind = EWindow.Other
    layer = UILayer.AlwaysOnTop
    private ign: string
    private id: number

    header: Sprite
    isDraggable = true
    exitBtn: ImageButton

    setVisible(visible: boolean) {
        this.container.visible = visible
        this.containerContainer.visible = visible
        this.visible = visible
    }

    reset() {}

    container: Container

    private totalWidth: number
    private totalHeight: number

    private creditAmt: DOMText

    private containerContainer: Container

    constructor(id: number, ign: string, balance: number) {
        super()

        this.id = id
        this.ign = ign

        this.exitBtn = new ImageButton('button.closeb.active', 'button.closeb.pressed')

        this.containerContainer = Pooler.newContainer()
        this.container = Pooler.newContainer()

        const bg: BasicBox = new BasicBox(286, 134)
        this.container.addChild(bg)

        const titleBg: FieldH = new FieldH(279)

        this.container.addChild(titleBg)
        titleBg.x = 9
        titleBg.y = 13

        this.eventMode = 'static'

        const titleText = new BitmapText(`Adjust Credits for ${ign}`, {
            ...Fonts.FoxleyBold_16,
            align: 'left'
        })
        titleText.y = 14
        const titlewidth: number = titleText.width
        titleText.x = Math.round((bg.width - titlewidth) / 2)
        this.container.addChild(titleText)

        const bodyText = new BitmapText(
            'Enter a positive amount to award credits, or a negative amount to take away credits. Current balance: ' +
                balance,
            {
                ...Fonts.Foxley_16,
                wordWrapWidth: 300
            }
        )

        bodyText.x = Math.round((bg.width - bodyText.width) / 2)
        bodyText.y = 38

        this.container.addChild(bodyText)
        this.header = Pooler.newSprite()

        this.creditAmt = new DOMText({
            kind: 'field',
            className: 'mk',
            id: 'mk.creds',
            maxlength: 2048,
            fieldWidth: 100,
            fieldHeight: 16,
            fontSize: 16,
            fontColor: TextColor.white,
            initialValue: '',
            bgObject: new FieldH(100),
            padLeft: 9,
            padTop: -3,
            noBreaks: true,
            enterHint: '',
            inputMode: 'numeric'
        })
        this.creditAmt.position.set(100, 100)
        this.container.addChild(this.creditAmt)

        const submitBtn = new BitmapTextButton(100, 'ADJUST', BitmapTextButtonType.GREEN)
        submitBtn.position.set(43, 125)
        this.container.addChild(submitBtn)
        submitBtn.addEventListener('pointerup', () => {
            cancelBtn.disable()
            submitBtn.disable()

            this.creditAmt.setReadonly(true)

            Client.shared.serverBroker
                .sendAck('mk_adjust_creds', {
                    id: +id,
                    amount: +this.creditAmt.getValue()
                })
                .then((successAmt) => {
                    if (successAmt === false) {
                        submitBtn.enable()
                        cancelBtn.enable()
                        this.creditAmt.setReadonly(false)
                    } else {
                        Client.shared.userInterface.removeWindow(this)
                        SoundManager.shared.play(ESndGrp.SFX, UISoundLibrary.TurnstileEntrance)
                        Client.shared.helpers.alert('Credits adjusted for ' + ign + ', new balance: ' + successAmt)
                    }
                })
        })

        const cancelBtn = new BitmapTextButton(100, 'CANCEL', BitmapTextButtonType.RED)
        cancelBtn.position.set(43 + 115, 125)
        this.container.addChild(cancelBtn)
        cancelBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.removeWindow(this)
        })

        this.containerContainer.addChild(this.container)
        this.addChild(this.containerContainer)
        this.center()
        Client.shared.userInterface.bringToFront(this)
    }

    onIgnore() {}

    center() {
        this.containerContainer.x = Math.round((Constants.SIZE[0] - this.width) / 2)
        this.containerContainer.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }

    tryRemove() {
        if (this.parent !== null) {
            this.parent.removeChild(this)
        }
    }

    getWidth(): number {
        return this.totalWidth
    }

    getHeight(): number {
        return this.totalHeight
    }
}
