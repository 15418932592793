import type { EPotcTeam, PotcEngineShipEntity, PotcShipType, POTCStage } from '@vmk-legacy/potc-engine'
import { PotcEntityKind, PotcStageEngine, Vector } from '@vmk-legacy/potc-engine'
import { ESndGrp, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import type { FederatedEvent, Sprite } from 'pixi.js'
import { Graphics, Point, Ticker } from 'pixi.js'
import { Client } from '../../Client.js'
import type { PotcGameRejoin, PotcPlayerInfoFull } from '../../room/renderer/extensions/PiratesPreshowHandler.js'
import { RoomViewer } from '../../room/renderer/RoomViewer.js'
import type { PotcDelegate } from './PotcDelegate.js'
import { PotcFactorShip } from './PotcFactorShip.js'
import type { PotcSound } from './PotcSound.js'

/*
## - Puff defs

puff = [#framelist: ["puff1","puff2","puff3","puff4","puff5","puff6","puff7","puff8","empty_frame"], #ink: 0]
explosion = [#framelist: ["blow0","blow1","blow2","blow3","blow4","empty_frame"], #ink: 0]
splash = [#framelist: ["splash0008", "splash0016", "splash0024", "splash0032", "splash0040","splash0048","splash0056","splash0064","splash0072","empty_frame"], #ink: 33]

## - Notes
#
# Z ranges for objects :
#
# lowest 		: whirlpool, flaming oil
# low        	: pointers
# middle     	: play objects
# high       	: explosions, cloud shadows
# highest    	: ship markers
# static +1  	: clouds, moonlight
# static +2  	: score floats
# static +3  	: splash gfx

## - Configurable Values

interface.shootdelay = 1000
interface.dblclickdelay = 300
interface.shootpntrdelay = 3500
game.cannonballspeed = 1400
game.cannonballtimetolive = 25
visual.noclouds = 15
visual.shipshakeprotection = 3
sound.voiceoverskip = 3
 */
export class PotcScene extends RoomViewer {
    private myself: PotcFactorShip
    private collisionVisual: Graphics
    navTarget: Sprite

    private sound: PotcSound

    lastShoot = 0
    shootState: { state: 'left' | 'right', time: number } = {}
    readonly ShootDelay = 1000

    clouds: Sprite[]
    shadows: Sprite[]

    engine: PotcStageEngine
    private navCursor: Sprite

    moveTicker: Ticker

    constructor(readonly delegate: PotcDelegate, readonly stage: POTCStage, cmap: number[][], readonly data: PotcGameRejoin) {
        super()

        this.navCursor = this.addChild(Pooler.newSprite('can_move'))
        this.navCursor.anchor.set(0.5)
        this.navCursor.eventMode = 'auto'

        this.engine = new PotcStageEngine(stage, cmap, delegate.mode)

        this.layoutIdentifier = 'pirates_level' + stage.level + '_stage' + stage.stage

        this.collisionVisual = this.sprites.addChild(new Graphics())
        this.collisionVisual.pivot.set(400, 300)
        this.collisionVisual.eventMode = 'none'

        this.navTarget = this.sprites.addChild(Pooler.newSprite('heading_towards'))
        this.navTarget.eventMode = 'none'
        this.navTarget.visible = false
        this.navTarget.anchor.set(0.5)

        this.sound = this.delegate.sound

        window.addEventListener('keyup', this.handleKey)
        this.eventMode = 'static'
        this.addEventListener('pointerup', this.moveShip)
        window.addEventListener('pointermove', this.cursorFollow, { passive: true })

        this.moveTicker = new Ticker()
        this.moveTicker.minFPS = 10
        this.moveTicker.maxFPS = 10

        const mover = () => {
            this.engine.ships.move()

            this.navTarget.visible = !!this.myself.navigationTarget
        }

        this.moveTicker.add(mover)
    }

    cursorFollow = (e: MouseEvent): void => {
        const mousePoint = new Point(
            e.clientX - Client.shared.containerEl.offsetLeft,
            e.clientY - Client.shared.containerEl.offsetTop
        )

        if (this.navCursor.visible) {
            const point = Client.shared.viewport.toLocal(mousePoint)
            this.navCursor.position.copyFrom(point)
        }
    }

    handleKey = (e: KeyboardEvent) => {
        const myShip = this.myself
        if (e.keyCode === 40) { //down
            // if (myShip.isCarryingFlag()) {
            // 	// send drop flag to server
            // }
            return
        }

        if (myShip.ammo.length > 0) {
            console.log(this.shootState.state, Date.now() - this.lastShoot, this.ShootDelay)
            if (e.keyCode === 37) { //left
                if (this.shootState.state === 'left') {
                    if (Date.now() - this.lastShoot >= this.ShootDelay) {
                        // send fire to server [l]
                        Client.shared.serverBroker.send('potc_shoot', 'l')
                        this.lastShoot = Date.now()
                        this.delegate.sound.playVoiceOverSeldom('fire')
                    }
                } else {
                    this.delegate.sound.playPreshoot()
                    this.shootState.state = 'left'
                    myShip.getVisual().markShoot('left')
                }
            } else if (e.keyCode === 39) { //right
                if (this.shootState.state === 'right') {
                    if (Date.now() - this.lastShoot >= this.ShootDelay) {
                        // send fire to server [r]
                        Client.shared.serverBroker.send('potc_shoot', 'r')
                        this.lastShoot = Date.now()
                        this.delegate.sound.playVoiceOverSeldom('fire')
                    }
                } else {
                    this.delegate.sound.playPreshoot()
                    this.shootState.state = 'right'
                    myShip.getVisual().markShoot('right')
                }
                this.shootState.time = Date.now()
            }
        } else {
            this.delegate.sound.playVoiceOver('OutOfAmmo')
        }
    }

    moveShip = (e: FederatedEvent) => {
        if (e.type !== 'pointerup' && !e.data.buttons) {
            return
        }
        const dest = this.toLocal(e.data.global)
        const axonPos = PotcStageEngine.inverseAxonPosition({
            x: dest.x,
            y: dest.y
        })

        this.sound.playAcknowledge()
        this.sound.playVoiceOverSeldom('Acknowledge')

        this.navTarget.visible = true
        this.navTarget.position.set(dest.x - 400, dest.y - 300)

        this.myself.collideCount = 0
        this.myself.navigationTarget = new Vector(axonPos.x, axonPos.y)
        this.myself.state.move = true
        this.myself.state.shoot = false
        this.myself.needToSync = true

        Client.shared.serverBroker.send('potc_move', [ axonPos.x, axonPos.y ])
    }

    get cacheKey(): string {
        return this.layoutIdentifier
    }


    async loadAssets(hideSprites = false, retrying = false): Promise<void> {
        await super.loadAssets(hideSprites, retrying)

        this.clouds = []
        this.shadows = []

        for (let i = 0; i < 5; i++) {
            const shadow = this.addChild(Pooler.newSprite('cloud_night_shadow'))
            const cloud = this.addChild(Pooler.newSprite('cloud_night'))

            this.clouds.push(cloud)
            this.shadows.push(shadow)

            cloud.anchor.set(0.5)
            shadow.anchor.set(0.5)
            cloud.x = Math.random() * 800
            cloud.y = Math.random() * 580
            shadow.x = cloud.x + 30
            shadow.y = cloud.y + 150
        }
    }

    moveClouds() {
        for (let i = 0; i < this.clouds.length; i++) {
            gsap.to([ this.clouds[i], this.shadows[i] ], {
                y: '+=' + (Math.random() * 3),
                duration: 1
            })
        }
    }

    async reveal() {
        await super.reveal()
        console.log('PotcScene.reveal', this.data)

        this.sound.playSea()

        const track = this.delegate.assets.sounds.get(this.stage.settings.music)

        SoundManager.shared.play(ESndGrp.Music, track)

        const players: { [id: number]: PotcPlayerInfoFull & { team: EPotcTeam } } = {}

        for (const t: EPotcTeam in this.data.teams) {
            for (const p: PotcPlayerInfoFull of this.data.teams[t].players) {
                players[p.id] = {
                    ...p,
                    team: t
                }
            }
        }

        for (const e of this.data.entities) {
            if (e.kind === PotcEntityKind.Ship) {
                const sh = e as PotcEngineShipEntity
                const p = players[sh.id]

                if (p) {
                    const ship = this.addShip(p.team, p.id, p.name, sh.type, sh.worldPosition, sh.direction)

                    if (p.id === Client.shared.selfRecord.getId()) {
                        console.log('>> myself ', ship)
                        this.myself = ship
                    }
                }
            } else if (e.kind === PotcEntityKind.Powerup) {

            }
        }

        this.engine.ships.setStats()
        this.moveTicker.start()

        // this.visualizeCMap();
    }

    visualizeCMap() {
        function componentToHex(c) {
            const hex = c.toString(16)
            return hex.length == 1 ? '0' + hex : hex
        }

        function rgbToHex(r, g, b) {
            return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
        }
        for (let y = 0; y < 600; y++) {
            for (let x = 0; x < 800; x++) {
                const axonPos = PotcStageEngine.inverseAxonPosition({
                    x,
                    y
                })
                const val = this.engine.axonGetMapAt(axonPos)
                if (val === -1 || val === null) {
                    continue
                }

                const color = val === 3 ? 0xffffff : val === 4 ? 0xff0000 : val === -1 ? 0x000000 : rgbToHex(50 * val, 50 * val, 0)
                this.collisionVisual.beginFill(color, 1)
                this.collisionVisual.drawCircle(x, y, 0.5)
                this.collisionVisual.endFill()
            }
        }
    }

    addShip(team: EPotcTeam, instanceId: number, ign: string, ship: PotcShipType, pos: {
        x: number;
        y: number;
    }, dir: number) {
        console.log('>> adding ship of type ' + ship)
        const factor = new PotcFactorShip(this, team, instanceId, ign, ship)

        this.engine.addShip(factor, pos, dir)

        // TODO: fixme
        const object = this.addEntity(factor)

        this.sprites.addChild(factor.visual)

        console.log('ship added', object)

        return object
    }

    getCastNames(): string[] {
        return [ `spaces/pirates2_level${this.stage.level}_stage${this.stage.stage}` ]
    }

    teardown() {
        super.teardown()

        window.removeEventListener('pointermove', this.cursorFollow)

        this.moveTicker?.stop()
        this.moveTicker = undefined
    }
}

