import { Pooler } from '@vmk-legacy/render-utils'
import { ClientNPCVisit, NPC } from '@vmk-legacy/server-protos'
import type { Sprite } from 'pixi.js'
import { Graphics } from 'pixi.js'
import { Client } from '../../../Client.js'
import { Helpers } from '../../../util/Helpers.js'
import type { Animation } from '../../Animation.js'
import type { AvatarEntity } from '../../entities/AvatarEntity.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export class DungeonHandler extends WalkableRoomExtension {
    static override identifier = 'dungeon'

    animation?: Animation

    override async init(): Promise<this> {
        await super.init()

        const sprite = this.room.sprites.getChildByName('dragon') as Sprite

        if (sprite) {
            const npc = this.room.addNPC('npc-dragon', 0, 0, {}, 'Maleficent the Dragon', true)
            npc.getVisual().position.set(sprite.x + sprite.width / 2, sprite.y + sprite.height / 2)
            this.room.addEntity(npc)

            this.animation = await this.room.setupAnimation('dragon_all.ani', true)

            if (this.animation) {
                this.animation.label = 'dragon'
                this.animation.zIndex = sprite.zIndex
                this.animation.position.set(sprite.x, sprite.y)

                this.room.animationMap.set(this.animation.name, this.animation)

                Pooler.release(sprite)

                this.room.sprites.addChild(this.animation)
            }
        }

        const pts = [305, 200, 442, 195, 591, 331, 438, 360, 314, 292, 246, 383, 231, 252]
        const g = new Graphics()
        g.beginFill(0x00ff00)
        g.drawPolygon(pts)
        g.endFill()
        g.alpha = 0
        g.position.set(-400, -300)
        g.zIndex = 99999
        g.eventMode = 'static'
        g.cursor = 'pointer'
        this.room.sprites.addChild(g)
        g.addEventListener('pointerup', () => {
            this.onTap()
        })

        Client.shared.serverBroker.onEvent('firefall', (data) => this.firefall(data))

        return this
    }

    override teardown(): void {
        Client.shared.serverBroker.offEvent('firefall')
    }

    private firefall({ id }: { id: string }): void {
        const entity = this.room.getEntityByRef(id) as AvatarEntity
        const avatar = entity?.visual

        if (avatar) {
            this.animation?.score.goToMarker('FIRE')
            Helpers.delay(575).then(() => {
                this.setInterval(() => {
                    let dir = avatar.getDirection() + 1
                    if (dir === 8) {
                        dir = 0
                    }
                    avatar.setDirection(dir)
                    avatar.setHeadDirection(dir)
                }, 50)
                this.tween(
                    avatar,
                    0.75,
                    {
                        x: 750 + this.room.sprites.pivot.x,
                        y: 700 + this.room.sprites.pivot.y
                    },
                    'power1.in',
                    () => this.room.removeEntity(entity)
                )
            })
        }
    }

    private onTap(): void {
        Client.shared.serverBroker.send(
            new ClientNPCVisit({
                npc: NPC.Maleficent
            })
        )
    }
}
