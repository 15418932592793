import { Pooler } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import type {
    Container,
    FederatedEvent,
    FederatedMouseEvent,
    FederatedPointerEvent,
    FederatedWheelEvent,
    Sprite
} from 'pixi.js'
import { Graphics, Rectangle } from 'pixi.js'
import { globalOpts } from '../../Client.js'
import { ImageButton } from '../buttons/ImageButton.js'
import { ScrollHeightVending } from '../HTMLView'
import { ResponsiveContainer } from '../views/AlertView.js'

export class ScrollArea extends ResponsiveContainer {
    private scrollbarTrack: Container
    private scrollbarHandle: ImageButton
    private scrollbarUp: ImageButton
    private scrollbarDown: ImageButton
    private scrollbarBGEnabled: Sprite
    private scrollbarBGDisabled: Sprite
    private scrollbarTrackHeight: number
    contentMask: Rectangle
    private totalWidth: number
    private containerWidth: number

    private middle: Sprite
    private leftMiddle: Sprite
    private bottomLeft: Sprite
    private bottomMiddle: Sprite
    private bottomRight: Sprite
    private rightMiddle: Sprite
    private topRight: Sprite
    private topMiddle: Sprite
    private topLeft: Sprite

    private scrollPct = 0

    constructor(
        public innerWidth: number,
        public innerHeight: number,
        public content: Container | (Container & ScrollHeightVending) = null,
        private paddingLeft = 10,
        private paddingTop = 9,
        readonly hideBg = false
    ) {
        super()

        this.eventMode = 'static'
        this.interactiveChildren = true

        // Draw background
        this.topLeft = Pooler.newSprite('content.top.left')
        this.topMiddle = Pooler.newSprite('content.top.middle')
        this.topRight = Pooler.newSprite('content.top.right')
        this.rightMiddle = Pooler.newSprite('content.right.middle')
        this.bottomRight = Pooler.newSprite('content.bottom.right')
        this.bottomMiddle = Pooler.newSprite('content.bottom.middle')
        this.bottomLeft = Pooler.newSprite('content.bottom.left')
        this.leftMiddle = Pooler.newSprite('content.left.middle')
        this.middle = Pooler.newSprite('content.middle.middle')

        this.scrollbarUp = new ImageButton(
            'button.scroll.up.active',
            'button.scroll.up.pressed',
            'button.scroll.up.passive'
        )
        this.scrollbarDown = new ImageButton(
            'button.scroll.down.active',
            'button.scroll.down.pressed',
            'button.scroll.down.passive'
        )
        this.scrollbarHandle = new ImageButton(
            'button.scroll.lift.active',
            'button.scroll.lift.pressed',
            'button.scroll.lift.passive'
        )
        this.scrollbarTrack = this.addChild(Pooler.newContainer())

        this.scrollbarDown.addEventListener('pointerup', (e: FederatedEvent) => {
            this.setScroll(this.scrollPct + 0.05)
        })
        this.scrollbarUp.addEventListener('pointerup', (e: FederatedEvent) => {
            this.setScroll(this.scrollPct - 0.05)
        })
        let lastY = 0
        this.scrollbarTrack.addEventListener('pointermove', (e: FederatedPointerEvent) => {
            if (e.buttons > 0) {
                const mouseY = this.scrollbarTrack.toLocal(e.global).y
                const deltaY = mouseY - lastY
                const newPct = (this.scrollbarHandle.y + deltaY) / this.scrollbarTrackHeight
                lastY = mouseY

                this.setScroll(newPct)
            }
        })
        this.addEventListener('wheel', (e: FederatedWheelEvent) => {
            this.setScroll(this.scrollPct + e.deltaY / 100)
        })
        let touchScrollEventPreventer = null
        let touchScrollStart = null
        let touchScrollOffsetStart = null

        this.addEventListener('touchstart', (e: FederatedMouseEvent) => {
            touchScrollStart = this.toLocal(e.global).y
            touchScrollOffsetStart = this.getContentHeight() * this.scrollPct
            console.log('>> start y ', touchScrollStart)
            clearTimeout(touchScrollEventPreventer)
            globalOpts.onlyAllowEventsOn = undefined
            touchScrollEventPreventer = setTimeout(() => {
                console.log('setting onlyAllowEventsOn')
                globalOpts.onlyAllowEventsOn = this
                this.content.eventMode = 'none'
            }, 200)
            console.log('>> start ' + touchScrollStart)
        })
        this.addEventListener('globaltouchmove', (e: FederatedMouseEvent) => {
            if (e.currentTarget !== this || touchScrollStart === null) {
                return
            }

            const moved = this.toLocal(e.global).y - touchScrollStart
            const fingerPos = touchScrollOffsetStart - moved
            const pctChange = fingerPos / this.getContentHeight()
            // console.log('>> yPos ' + yPos + ', hgt ' + this.contentMask.height + ', bouncedPos ' + bouncedPos + ', diff ' + diff + ', pctChange ' + pctChange)

            this.setScroll(pctChange, false)
        })
        const touchend = (e: FederatedMouseEvent) => {
            if (touchScrollStart === null) {
                return
            }
            e.stopImmediatePropagation()
            e.stopPropagation()
            e.bubbles = false
            console.log('>> scroll end')
            const scroll = { pos: this.scrollPct }
            gsap.to(scroll, {
                pos: Math.max(0, Math.min(1, this.scrollPct)),
                duration: 0.3,
                ease: 'power2.out',
                onUpdate: () => this.setScroll(scroll.pos, false)
            })
            this.content.eventMode = 'auto'
            clearTimeout(touchScrollEventPreventer)
            touchScrollStart = null
            console.log('disabling onlyAllowEventsOn')

            globalOpts.onlyAllowEventsOn = undefined
        }
        this.addEventListener('touchend', touchend)
        this.addEventListener('touchendoutside', touchend)
    }

    getContentHeight(): number {
        return (<ScrollHeightVending>this.content).scrollableHeight ?? this.content.height ?? 0
    }

    override sizeDidChange(): void {
        this.topLeft.x = this.topLeft.y = 0
        this.topMiddle.x = this.topLeft.width
        this.topMiddle.width = this.innerWidth
        this.topRight.x = this.topLeft.width + this.topMiddle.width
        this.rightMiddle.x = this.topRight.x
        this.rightMiddle.y = this.topMiddle.height
        this.rightMiddle.height = this.innerHeight
        this.bottomRight.x = this.rightMiddle.x
        this.bottomRight.y = this.topRight.height + this.rightMiddle.height
        this.bottomMiddle.x = this.bottomLeft.width
        this.bottomMiddle.y = this.bottomRight.y
        this.bottomMiddle.width = this.innerWidth
        this.bottomLeft.y = this.bottomMiddle.y
        this.leftMiddle.y = this.topLeft.height
        this.leftMiddle.height = this.innerHeight
        this.middle.x = this.topLeft.width
        this.middle.y = this.topLeft.height
        this.middle.width = this.innerWidth
        this.middle.height = this.innerHeight

        this.contentMask = new Rectangle(2, 2, this.innerWidth + 12, this.innerHeight + 13)

        this.addChild(
            this.topLeft,
            this.topMiddle,
            this.topRight,
            this.rightMiddle,
            this.bottomRight,
            this.bottomMiddle,
            this.bottomLeft,
            this.leftMiddle,
            this.middle
        )

        if (this.hideBg) {
            this.topLeft.visible =
                this.topMiddle.visible =
                this.topRight.visible =
                this.rightMiddle.visible =
                this.bottomRight.visible =
                this.bottomMiddle.visible =
                this.bottomLeft.visible =
                this.leftMiddle.visible =
                this.middle.visible =
                    false
        }
        this.containerWidth = this.topLeft.width + this.innerWidth + this.topRight.width

        // Draw scrollbar (TODO: abstract)
        const scrollbarX: number = this.topLeft.width + this.topMiddle.width + this.topRight.width + 3 // x location of
        // the scrollbar
        const scrollbarY: number = this.topRight.height + this.rightMiddle.height + this.bottomRight.height // y
        // location
        // of the
        // scrollbar
        this.scrollbarUp.x = this.scrollbarDown.x = scrollbarX
        this.scrollbarDown.y = scrollbarY - this.scrollbarDown.height
        this.addChild(this.scrollbarUp, this.scrollbarDown)

        // Draw scrollbar background
        const scrollbarBGActive = Pooler.newSprite('scrollbar.vertical.active')
        const scrollbarBGPassive = Pooler.newSprite('scrollbar.vertical.passive')
        this.addChild(scrollbarBGPassive, scrollbarBGActive)
        scrollbarBGActive.x = scrollbarBGPassive.x = scrollbarX
        scrollbarBGActive.y = scrollbarBGPassive.y = this.scrollbarUp.height
        scrollbarBGActive.height = scrollbarBGPassive.height = this.scrollbarDown.y - this.scrollbarUp.height
        this.scrollbarBGEnabled = scrollbarBGActive
        this.scrollbarBGDisabled = scrollbarBGPassive

        this.scrollbarTrackHeight = scrollbarBGActive.height // - this.scrollbarHandle.height;

        // Draw scrollbar handle
        this.scrollbarTrack.hitArea = new Rectangle(0, 0, this.scrollbarBGEnabled.width, this.scrollbarTrackHeight)
        this.scrollbarTrack.eventMode = 'static'
        this.scrollbarTrack.x = scrollbarX
        this.scrollbarTrack.y = this.scrollbarUp.y + this.scrollbarUp.height
        this.scrollbarTrack.addChild(this.scrollbarHandle)

        this.addChild(this.scrollbarTrack)

        this.totalWidth = scrollbarX + scrollbarBGActive.width

        if (this.content) {
            this.setContent(this.content)
        }
    }

    setScroll(percent: number, snap = true, updateScrollbarOnly = false): void {
        const snapped = Math.max(0, Math.min(1, percent))
        this.scrollPct = snap ? snapped : percent

        try {
            console.log('Content height: ' + this.getContentHeight())
            if (!this.content || !this.contentMask || this.getContentHeight() < this.contentMask.height - this.paddingTop) {
                this.disableScrollbar()
                return
            }

            this.enableScrollbar()

            if (!updateScrollbarOnly) {
                const maxScroll = this.contentMask.height - this.getContentHeight() - this.paddingTop
                if ('setScroll' in this.content) {
                    this.content.setScroll(percent)
                } else {
                    this.content.pivot.y = -this.scrollPct * maxScroll
                }
            }
            this.scrollbarHandle.anchor.y = snapped
            this.scrollbarHandle.y = snapped * this.scrollbarTrackHeight
        } catch (err) {
            console.log(err)
        }
    }

    getContainerWidth(): number {
        return this.containerWidth
    }

    getTotalWidth(): number {
        return this.totalWidth
    }

    setContent(c: Container): void {
        if (this.content && this.content !== c) {
            this.content.destroy({ children: true })
        }
        this.content = this.addChild(c)
        this.content.x = this.paddingLeft
        this.content.y = this.paddingTop
        this.content.pivot.y = 0

        if (c instanceof ResponsiveContainer) {
            c.refit()
        }

        if (!this.contentMask) {
            return
        }

        // Draw mask
        if (!this.content.mask) {
            const g = new Graphics()
                .rect(0, 0, this.contentMask.width, this.contentMask.height)
                .fill(0xffffff)
            g.eventMode = 'auto'

            g.position.set(this.contentMask.x, this.contentMask.y)
            this.addChildAt(g, 0)
            this.content.mask = g
        }
        this.setScroll(0)
    }

    getMaskRect(): Rectangle {
        return this.contentMask
    }

    disableScrollbar(): void {
        this.scrollbarHandle.disable()
        this.scrollbarUp.disable()
        this.scrollbarDown.disable()

        if (this.scrollbarBGEnabled) {
            this.scrollbarBGEnabled.alpha = 0
            this.scrollbarBGDisabled.alpha = 0.3
            this.scrollbarHandle.alpha = 0.4
            this.scrollbarUp.alpha = 0.4
            this.scrollbarDown.alpha = 0.4
        }
    }

    enableScrollbar(): void {
        this.scrollbarHandle.enable()
        this.scrollbarUp.enable()
        this.scrollbarDown.enable()

        if (this.scrollbarBGEnabled) {
            this.scrollbarBGEnabled.alpha = 1
            this.scrollbarBGDisabled.alpha = 0
            this.scrollbarHandle.alpha = 1
            this.scrollbarUp.alpha = 1
            this.scrollbarDown.alpha = 1
        }
    }

    resetScroll(): void {
        this.setScroll(0)
    }
}
