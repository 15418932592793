import { EPref } from '@vmk-legacy/common-ts'
import { AssetProvider, Pooler } from '@vmk-legacy/render-utils'
import Fuse from 'fuse.js'
import type { DestroyOptions, FederatedEvent } from 'pixi.js'
import { BitmapText, Container, Graphics, Texture } from 'pixi.js'
import { getVar } from '../../assets/ExternalConfigManager.js'
import { Client } from '../../Client.js'
import type { AvatarStack, AvatarStackedProvider } from '../../data/AvatarStack.js'
import type { StackedProvider } from '../../data/ItemStack.js'
import { DummyItem, ItemStack } from '../../data/ItemStack.js'
import { Fonts } from '../../Fonts.js'
import { AvatarTooltip } from '../AvatarTooltip.js'
import { ImageButton } from '../buttons/ImageButton.js'
import { ThumbnailBox } from '../containers/ThumbnailBox.js'
import { DOMText } from '../DOMText.js'
import { FieldG } from '../fields/FieldG.js'
import { TextColor } from '../VMKLText'

interface PTBOpts {
    search?: boolean
    rows: number
    cols: number
    items: StackedProvider[] | AvatarStackedProvider
    spacing?: number
    onItemTap?: (item: StackedProvider | AvatarStackedProvider | null, preview?: Container) => any
    onItemDoubleTap?: (item: StackedProvider | AvatarStackedProvider | null, preview?: Container) => any
    pageTurnSpacing?: number
    pageTurnFormat?: string
    dimUntradeables?: boolean
    initialPage?: number
    background?: boolean
    thumbWidth?: number
    thumbHeight?: number
    hideEmpty?: boolean
    stack?: boolean
}

export class PagedThumbnailBox extends Container {
    static readonly FORMAT_C: string = 'Page %cur% of %last%'
    static readonly FORMAT_E: string = '%cur%/%last%'
    static readonly FORMAT_BLANK: string = ''
    static readonly THUMB_SIZE: number = 40

    private searchBar?: DOMText

    // Thumbnails & items
    private rows: number
    private cols: number
    private thumbs: ThumbnailBox[]
    private itemStacksMap = new Map<string, StackedProvider | AvatarStackedProvider>()
    private dimUntradeables: boolean

    // Page controller
    private pageText: BitmapText
    private pageTurnL: ImageButton
    private pageTurnR: ImageButton
    private pageTurnSpacing: number
    private pageTurnFormat: string
    private pageTurnContainer: Container
    private pageNumber: number

    // Callbacks
    private onItemTapCallback?: (item: StackedProvider | AvatarStackedProvider | null, preview?: Container) => any
    private onItemDoubleTapCallback?: (item: StackedProvider | AvatarStackedProvider | null, preview?: Container) => any
    private tooltip: AvatarTooltip

    provider: AssetProvider
    private stack: boolean

    constructor({
        search,
        rows,
        cols,
        items,
        spacing = 0,
        onItemTap,
        onItemDoubleTap,
        pageTurnSpacing = 100,
        pageTurnFormat = PagedThumbnailBox.FORMAT_C,
        dimUntradeables = false,
        initialPage = 1,
        background = true,
        thumbWidth = PagedThumbnailBox.THUMB_SIZE,
        thumbHeight = thumbWidth,
        hideEmpty = true,
        stack = true
    }: PTBOpts) {
        super()

        this.provider = new AssetProvider(Client.shared.assetLoader)

        this.cursor = 'default'
        this.eventMode = 'static'

        this.stack = stack
        this.rows = rows
        this.cols = cols
        this.thumbs = []
        this.pageTurnSpacing = pageTurnSpacing
        this.pageTurnFormat = pageTurnFormat
        this.pageTurnContainer = Pooler.newContainer()
        this.dimUntradeables = dimUntradeables

        // Set callbacks
        this.onItemTapCallback = onItemTap
        this.onItemDoubleTapCallback = onItemDoubleTap

        const containPadding = background ? 3 : 0
        const containBorder = background ? 2 : 0
        const w = (containBorder + containPadding) * 2 + thumbWidth * cols + spacing * (cols - 1)
        const h = (containBorder + containPadding) * 2 + thumbHeight * rows + spacing * (rows - 1)

        let topPad = 0

        if (search) {
            const searchLabel = new BitmapText('Filter: ', Fonts.FoxleyBold_16)
            searchLabel.y = 4
            this.addChild(searchLabel)
            this.searchBar = new DOMText({
                kind: 'field',
                maxlength: 32,
                fieldWidth: w - searchLabel.width - 17,
                fieldHeight: 18,
                fontSize: 16,
                fontColor: TextColor.black,
                initialValue: '',
                bgObject: new FieldG(w - searchLabel.width - 17, 11),
                padLeft: 7,
                padTop: -1
            })
            this.searchBar.getElement().spellcheck = false
            this.searchBar.setSubmitHandler(() => {
                this.setPage(1)
            })
            this.searchBar.position.set(searchLabel.width + 5, 0)
            topPad += this.searchBar.height + containPadding * 2
            this.addChild(this.searchBar)
        }

        if (background) {
            const bg = this.addChild(new Graphics())
            bg.beginFill(0x439bd9)
            bg.drawRoundedRect(0, topPad, w, h, 7)
            bg.endFill()
            bg.beginFill(0x011f55)
            bg.drawRoundedRect(containBorder, topPad + containBorder, w - containBorder * 2, h - containBorder * 2, 5)
            bg.endFill()
            bg.eventMode = 'static'
            bg.addEventListener('pointermove', this.hoverThumb)
            bg.addEventListener('pointerout', this.hideTooltip)
        } else {
            const bg = this.addChild(Pooler.newSprite(Texture.EMPTY))
            bg.width = w
            bg.height = h
            bg.eventMode = 'static'
            bg.addEventListener('pointermove', this.hoverThumb)
            bg.addEventListener('pointerout', this.hideTooltip)
        }

        const thumbContain = this.addChild(Pooler.newContainer())
        thumbContain.position.set(containBorder + containPadding, containBorder + containPadding + topPad)

        this.tooltip = this.addChild(new AvatarTooltip(''))
        this.tooltip.eventMode = 'auto'
        this.tooltip.visible = false

        // Draw thumbs
        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                const thumbnail = new ThumbnailBox(thumbWidth, thumbHeight, hideEmpty)
                thumbnail.x = (thumbWidth + spacing) * col
                thumbnail.y = (thumbHeight + spacing) * row
                thumbnail.eventMode = 'static'
                thumbnail.addEventListener('pointermove', this.hoverThumb)
                thumbnail.addEventListener('pointerout', this.hideTooltip)
                this.thumbs.push(thumbnail)
                thumbContain.addChild(thumbnail)
            }
        }

        // Page turn UI
        this.pageTurnL = new ImageButton('char.button.left.active', 'char.button.left.pressed', '', '')
        this.pageTurnContainer.addChild(this.pageTurnL)
        this.pageTurnL.addEventListener('pointerup', () => {
            this.onPageBack()
        })

        this.pageTurnR = new ImageButton('char.button.right.active', 'char.button.right.pressed', '', '')
        this.pageTurnContainer.addChild(this.pageTurnR)
        this.pageTurnR.x = pageTurnSpacing + this.pageTurnL.width
        this.pageTurnR.addEventListener('pointerup', () => {
            this.onPageForward()
        })

        this.pageText = new BitmapText(' ', {
            ...Fonts.Foxley_16
        })
        this.pageText.y = 0

        // Add to container (this is the container that's added to the stage)
        this.pageTurnContainer.addChild(this.pageText)

        // Set initial page
        this.pageNumber = initialPage
        this.addItems(...items)
    }

    getFilteredItems(): StackedProvider[] | AvatarStackedProvider[] {
        const itemArr = Array.from(this.itemStacksMap.values())
        const term = this.searchBar?.getValue().trim()
        if (!term) {
            return itemArr
        }

        const fuse = new Fuse(itemArr, {
            useExtendedSearch: true,
            minMatchCharLength: Math.min(3, term.length),
            keys: ['name']
        })
        const result = fuse.search(term)

        return result.map((r) => r.item)
    }

    hideTooltip = () => (this.tooltip.visible = false)

    hoverThumb = (e: FederatedEvent): void => {
        const thumb = e.currentTarget

        if (!(thumb instanceof ThumbnailBox)) {
            this.tooltip.visible = false
            return
        }

        const item = thumb.getSourceItem()

        if (!item) {
            this.tooltip.visible = false
            return
        }

        this.searchBar?.forceBlur()

        if (Client.shared.prefs.get(EPref.ShowExtraTooltips)) {
            this.tooltip.setText(item.getName())
            this.tooltip.position.set(thumb.x + thumb.width / 2, thumb.y - 22 + (this.searchBar?.height ?? 0))
            this.tooltip.visible = true
        }
    }

    setSelected(item: StackedProvider | AvatarStackedProvider | null): void {
        for (const thumb of this.thumbs) {
            if (item === null) {
                thumb.setSelected(false)
            } else {
                thumb.setSelected(thumb.getSourceItem()?.getKey() === item.getKey())
            }
        }
    }

    getControllerContainer(): Container {
        return this.pageTurnContainer
    }

    private onPageBack(): void {
        this.setPage(this.pageNumber - 1)
    }

    private onPageForward(): void {
        this.setPage(this.pageNumber + 1)
    }

    private calculateLastPage(items?: ItemStack[] | AvatarStack[]): number {
        const lastPage = Math.ceil((items?.length ?? this.itemStacksMap.size) / (this.rows * this.cols))
        return lastPage > 0 ? lastPage : 1
    }

    setItems(...items: StackedProvider[] | AvatarStackedProvider[]): void {
        console.log('setting items ' + items.length + ', existing: ' + this.itemStacksMap.size)
        this.itemStacksMap.clear()

        this.addItems(...items)
    }

    removeStack(stack: StackedProvider | AvatarStackedProvider): void {
        if (this.itemStacksMap.delete(stack.getKey())) {
            this.setPage(this.pageNumber)
        }
    }

    addItems(...items: StackedProvider[] | AvatarStackedProvider[]): void {
        for (let i = 0; i < items.length; i++) {
            const item = items[i]
            const mapKey = item.getKey() + (this.stack ? '' : '-' + i)
            this.itemStacksMap.set(mapKey, item)
        }

        this.setPage(this.pageNumber)
    }

    getPageNumber(): number {
        return this.pageNumber
    }

    setPage(pageNumber: number): void {
        const filteredItems = this.getFilteredItems()
        const lastPage = this.calculateLastPage(filteredItems)
        if (pageNumber < 1) {
            this.setPage(1)
            return
        }

        this.tooltip.visible = false

        // Set page num
        this.pageNumber = pageNumber

        // Update page textfield
        const pageText = this.pageTurnFormat
            .replace(/\%cur\%/g, this.pageNumber.toString())
            .replace(/\%last\%/g, this.calculateLastPage(filteredItems).toString())
        this.pageText.text = pageText
        this.pageText.x = this.pageTurnL.width + Math.round((this.pageTurnSpacing - this.pageText.width) / 2)

        // Disablee turn btns at extremes
        this.pageTurnL.enable()
        this.pageTurnR.enable()
        this.pageTurnL.eventMode = this.pageTurnR.eventMode = 'static'
        if (this.pageNumber === 1) {
            this.pageTurnL.eventMode = 'auto'
            this.pageTurnL.disable()
        }

        if (this.pageNumber >= lastPage) {
            this.pageTurnR.eventMode = 'auto'
            this.pageTurnR.disable()
        }

        // get items subset
        const thumbsOnPage = this.rows * this.cols
        const startingThumbIndex = (pageNumber - 1) * thumbsOnPage
        const items = filteredItems.slice(startingThumbIndex, startingThumbIndex + thumbsOnPage + 1)

        const nontradeableItems: number[] = getVar('nontradable')

        for (let i = 0; i < this.thumbs.length; i++) {
            const thumb = this.thumbs[i]
            if (!thumb) {
                continue
            }
            thumb.clear()

            if (items.length <= i) {
                thumb?.hideLoadingAnim()
                continue
            }

            const sourceItem = items[i]
            if (!sourceItem) {
                console.error('Paged thumbnail box item is null at index ' + i)
                continue
            }
            if (sourceItem.getCount() === 0) {
                console.error('Item has no instances', sourceItem)
                continue
            }
            thumb.showLoadingAnim()
            const texLoaded = (tex?: Texture) => {
                thumb.hideLoadingAnim()
                const thumbImage = thumb.setThumbImage(Pooler.newSprite(tex))
                thumb.setPreviewImage(Pooler.newSprite(tex))
                thumb.setSelected(false)
                thumb.setSourceItem(sourceItem)
                thumb.setNote(sourceItem.getNote())
                thumb.setQuantity(sourceItem.getCount())

                if (sourceItem instanceof DummyItem || sourceItem instanceof ItemStack) {
                    thumb.setStars(sourceItem.stars)
                    thumb.setSingleUse(!!sourceItem.single)
                }

                if (sourceItem instanceof ItemStack) {
                    const isTradeable = !nontradeableItems.includes(sourceItem.defUid)

                    if (!isTradeable && this.dimUntradeables) {
                        thumb.eventMode = 'auto'
                        thumbImage.alpha = 0.4
                    }
                }

                thumb.eventMode = 'static'

                thumb.off('pointertap')
                thumb.addEventListener('pointertap', () => {
                    if (Date.now() - thumb.getLastTapMS() < 500) {
                        // double tap
                        if (this.onItemDoubleTapCallback) {
                            this.onItemDoubleTapCallback(thumb.getSourceItem(), thumb.getPreviewImage())
                        }
                    } else {
                        thumb.setLastTap(new Date())
                        if (this.onItemTapCallback) {
                            this.onItemTapCallback(thumb.getSourceItem(), thumb.getPreviewImage())
                        }
                    }
                })
            }
            sourceItem
                .getRendition(this.provider)
                .then(texLoaded)
                .catch((e) => {
                    console.error(e)
                    texLoaded()
                })
        }

        this.emit('change')
    }

    override destroy(_options?: DestroyOptions | boolean): void {
        console.log('destroying PTB')

        super.destroy(_options)

        this.provider.teardown()
    }
}
