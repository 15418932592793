import type { IRoomLayout } from '@vmk-legacy/common-ts'
import { ESndGrp, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import * as intersects from 'intersects'
import type { FederatedPointerEvent, RenderTexture } from 'pixi.js'
import { Point, Polygon, Rectangle, Texture } from 'pixi.js'
import { Client } from '../../Client.js'
import type { Animation } from '../../room/Animation.js'
import { Point3D } from '../../room/renderer/3DUtils.js'
import { RoomViewer } from '../../room/renderer/RoomViewer.js'
import { Helpers } from '../../util/Helpers.js'
import { RandomUtil } from '../../util/RandomUtil.js'
import JCEntitySprite from './JCEntitySprite.js'
import { jcRoomStartData } from './JCRoomStartData.js'
import { JungleCruiseAnimal } from './JungleCruiseAnimal.js'
import { JungleCruiseBoatTilt } from './JungleCruiseBoatTilt.js'
import type { JungleCruiseDelegate } from './JungleCruiseDelegate.js'
import { InputMode } from './JungleCruiseDelegate.js'
import RailGraphics from './RailGraphics.js'

export class JungleCruiseRoom extends RoomViewer {
    static rockTex: Texture
    static filmTex: Texture
    static gasTex: Texture

    private entitySprites: JCEntitySprite[] = []
    private rails: RailGraphics[] = []
    private clock: any

    private static boatMotor: AudioBufferSourceNode
    private static isSpeaking = false
    private static isCrashing = false

    activeAnimals: JungleCruiseAnimal[] = []
    private allAnimals: JungleCruiseAnimal[] = []
    private animalsSpawned = 0
    private static boarded = false

    protected override disableExtensions = true
    protected override disableSound = true
    protected override disableProgress = true

    private spawnStats = [
        {
            animalLife: 2500,
            animalSpacing: 3000,
            animalsToSpawn: 8
        },
        {
            animalLife: 2000,
            animalSpacing: 2000,
            animalsToSpawn: 11
        },
        {
            animalLife: 1500,
            animalSpacing: 1000,
            animalsToSpawn: 14
        }
    ]

    private animalTicker?: number
    private currentLevel: number
    private roomStartData: any
    private stageObj: any
    private playingMusic?: AudioBufferSourceNode
    private atmosphere?: AudioBufferSourceNode

    private nedLines = {
        positive: [
            {
                sound: 'SN-nice_shot',
                text: "Nice shot ol' chap!"
            },
            {
                sound: 'SN-excellent_photography',
                text: 'Excellent photography!'
            },
            {
                sound: 'SN-youre_a_natural',
                text: "You're a natural!"
            },
            {
                sound: 'SN-allright',
                text: 'All right!'
            },
            {
                sound: 'SN-excellent_job',
                text: 'Excellent job!'
            },
            {
                sound: 'SN-jolly_good_job',
                text: 'Jolly good show! Keep up the good work!'
            },
            {
                sound: 'SN-who_whou',
                text: 'Whou-ho, bag that one.'
            }
        ],

        negative: [
            {
                sound: 'SN-now_dont_loose_your_head',
                text: "Now don't lose your head!"
            },
            {
                sound: 'SN-heads_will_roll',
                text: 'Oh my! Heads will roll for this one.'
            },
            {
                sound: 'SN-nooooouuuu',
                text: 'Noooouuu!'
            },
            {
                sound: 'SN-no_monkey_business',
                text: 'Now no monkey business around here!'
            },
            {
                sound: 'SN-dont_get_ahead',
                text: "Now don't get ahead of yourself!"
            }
        ],

        attention: [
            {
                sound: 'SN-hurry_up_old_bean',
                text: 'Hurry up old bean!'
            },
            {
                sound: 'SN-how_about_some_headshots',
                text: 'How about some headshots?'
            }
        ],

        watch_out: [
            {
                sound: 'SN-dont_get_carried_away',
                text: "Don't get carried away, you can hardly steer this thing!"
            },
            {
                sound: 'SN-that_was_close',
                text: 'That was a closhave!'
            }
        ],

        almostDone: {
            sound: 'SN-almost_done',
            text: 'Amost done!'
        }
    }

    constructor(
        readonly delegate: JungleCruiseDelegate,
        readonly roomName: string
    ) {
        super()

        this.layoutIdentifier = roomName

        if (!JungleCruiseRoom.rockTex) {
            JungleCruiseRoom.rockTex = Texture.from('jc_hazard_01')
            JungleCruiseRoom.filmTex = Texture.from('film_pickup')
            JungleCruiseRoom.gasTex = Texture.from('gasoline_pickup')
        }

        const room = structuredClone(jcRoomStartData[roomName])
        if (!room) {
            throw new Error('Missing JC start data for ' + roomName)
        }
        room.startPos.x -= 400
        room.startPos.y -= 300

        for (const rail of room.rails) {
            for (const points of rail.points) {
                for (const point of points) {
                    point.x -= 400
                    point.y -= 300
                }
            }
        }

        this.roomStartData = room
    }

    override async enumerateScene(roomModel: IRoomLayout, hideSprites = false): Promise<void> {
        await super.enumerateScene(roomModel, hideSprites)

        if (this.layoutIdentifier === 'safari') {
            const disableAnims = ['safari_rhino.ani']

            for (const a of disableAnims) {
                const anim = this.sprites.getChildByName(a) as Animation
                if (anim) {
                    anim.score.teardown()
                    anim.destroy()
                }
            }
        }

        this.eventMode = 'static'
        this.interactiveChildren = false
        this.hitArea = new Rectangle(0, 0, this.width, this.height)

        // pointerdown fires same time as touchstart
        // supporting only touchstart for the touch input disables apple pencile
        this.addEventListener('pointerdown', (e: FederatedPointerEvent) => {
            if (JungleCruiseRoom.boarded) {
                e.stopPropagation()

                this.tryPic(e.clientX, e.clientY)
            }
        })
    }

    setupJCLevel(level: number): void {
        this.currentLevel = level
        this.animalsSpawned = 0
        console.log('setting up jc scene ' + this.layoutIdentifier)

        this.allAnimals = []
        this.entitySprites = []
        this.rails = []

        this.stageObj = this.delegate.stageData.get(`${this.roomName}.stage${level}`)

        if (!this.stageObj) {
            console.error(`Couldn't find JC stage "${this.roomName}.stage${level}"`)
            console.dir(this.delegate.stageData)
            return
        }

        for (const rail of this.roomStartData.rails) {
            for (const points of rail.points) {
                const railPoly = new Polygon(points)
                const railGfx = new RailGraphics().poly(railPoly).fill(0xffffff)
                railGfx.alpha = 0.5
                railGfx.zIndex = 9999999
                railGfx.hitPoly = railPoly
                railGfx.bounceDir = rail.bounceDir
                this.sprites.addChild(railGfx)
                railGfx.rail = 'left'
                this.rails.push(railGfx)
            }
        }

        if (this.stageObj.JUNGLECRUISE.HAZARDS) {
            const rocks = this.stageObj.JUNGLECRUISE.HAZARDS[0].ROCK

            for (const rock of rocks) {
                const pos = this.delegate.vectorToTriple(rock.$.POS)
                const spr = new JCEntitySprite(JungleCruiseRoom.rockTex)

                Point3D.apply(spr, pos.x, pos.y, pos.z)

                spr.hitPoly = new Polygon([new Point(24, 12), new Point(0, 24), new Point(24, 32), new Point(48, 24)])
                this.sprites.addChild(spr)
                this.entitySprites.push(spr)
                spr.entityType = 'rock'
            }
        }

        if (this.stageObj.JUNGLECRUISE.POWERUPS) {
            const film = this.stageObj.JUNGLECRUISE.POWERUPS[0].FILM
            if (film) {
                for (const fi of film) {
                    if (Math.random() > 0.5) {
                        continue
                    }
                    const pos = this.delegate.vectorToTriple(fi.$.POS)
                    // console.dir(POSITION);
                    const spr = new JCEntitySprite(JungleCruiseRoom.filmTex)

                    Point3D.apply(spr, pos.x, pos.y, pos.z)

                    this.sprites.addChild(spr)

                    const hitPoly = new Polygon([
                        new Point(24, 12),
                        new Point(0, 24),
                        new Point(24, 32),
                        new Point(48, 24)
                    ])
                    spr.hitPoly = hitPoly
                    this.entitySprites.push(spr)
                    spr.entityType = 'film'
                }
            }
        }

        if (this.stageObj.JUNGLECRUISE.POWERUPS) {
            const fuel = this.stageObj.JUNGLECRUISE.POWERUPS[0].FUEL
            if (fuel) {
                fuel.forEach((fu) => {
                    if (Math.random() > 0.5) {
                        return
                    }
                    const pos = this.delegate.vectorToTriple(fu.$.POS)
                    // console.dir(POSITION);
                    const spr = new JCEntitySprite(JungleCruiseRoom.gasTex)
                    Point3D.apply(spr, pos.x, pos.y, pos.z)

                    this.sprites.addChild(spr)

                    const hitPoly = new Polygon([
                        new Point(24, 12),
                        new Point(0, 24),
                        new Point(24, 32),
                        new Point(48, 24)
                    ])
                    spr.hitPoly = hitPoly
                    this.entitySprites.push(spr)
                    spr.entityType = 'fuel'
                })
            }
        }

        const boat = this.delegate.boat
        boat.setDirection(this.roomStartData.direction)
        this.sprites.addChild(boat)
        boat.position.set(75 - 400, 575 - 300)
        boat.zIndex = this.roomStartData.boatIndex
        boat.position.copyFrom(this.roomStartData.startPos)

        if (this.roomName === 'dock' && level === 1) {
            JungleCruiseRoom.boarded = false
            this.delegate.player.zIndex = 300
            this.sprites.addChild(this.delegate.player)
            this.sortChildren()
            this.delegate.player.position.set(510 - 400, 300 - 300)
        } else {
            JungleCruiseRoom.boarded = true
        }

        this.sortChildren()
    }

    override async reveal(): Promise<void> {
        await super.reveal()

        const isUp = this.roomStartData.direction === 'up'
        const boat = this.delegate.boat

        const rateMultiplier = 0.3
        const coastRateX: number = 2 * rateMultiplier
        const coastRateY: number = -1 * rateMultiplier
        const tiltRate = 4

        let deltaHoriz = 0
        let deltaVert = 1

        let mapY = -200
        let mapX = -200

        const boatOverlay = { value: 0 }
        this.clock = window.setInterval(() => {
            if (this.destroyed || !this.visible) {
                return
            }
            // game loop
            if (isUp) {
                mapY += 0.25
                mapX += 0.25
                boat.x = boat.x + coastRateX * deltaVert + deltaHoriz
                boat.y = boat.y + coastRateY * deltaVert + deltaHoriz
            } else {
                mapY -= 1
                mapX += 1
                boat.x = boat.x + coastRateX * deltaVert - deltaHoriz
                boat.y = boat.y - coastRateY * deltaVert + deltaHoriz
            }

            if (JungleCruiseRoom.boarded && boat.x > 930 - 400) {
                window.clearInterval(this.clock)
                this.delegate.nextStage()
                return
            }
            boat.overlay.alpha = boatOverlay.value

            if (!JungleCruiseRoom.boarded && boat.x > 580 - 400 && boat.y < 320 - 300) {
                JungleCruiseRoom.boarded = true
                if (this.delegate.inputMode === InputMode.MouseKeyboard) {
                    this.delegate.ui.reticle.visible = true
                }

                boat.boardAvi(this.delegate.player)
            }

            const boatPoly = boat.hitPoly as Polygon
            const boatPoints = []
            boatPoly.points.forEach((num, index) => {
                let value = num
                if (index % 2 === 0 || index === 0) {
                    value += boat.x - 90
                } else {
                    value += boat.y - 40
                }
                boatPoints.push(value)
            })

            for (const rail of this.rails) {
                if (rail.destroyed) {
                    continue
                }
                const railPoly = rail.hitPoly
                const railPoints = railPoly.points

                const intersected = intersects.polygonPolygon(boatPoints, railPoints)
                if (intersected) {
                    // rail.tint = 0x00FF00;

                    boatOverlay.value = 1
                    gsap.killTweensOf(boatOverlay)
                    gsap.to(boatOverlay, {
                        duration: 1,
                        value: 0
                    })

                    const bounceDir = rail.bounceDir
                    let invertRailFlag = false

                    let bounceVal = 6
                    if (bounceDir === 'down') {
                        bounceVal = 6
                    } else if (bounceDir === 'up') {
                        bounceVal = -6
                        if (!isUp) {
                            invertRailFlag = true
                        }
                    }
                    const bounceValX: number = bounceVal < 0 ? bounceVal : bounceVal / 2
                    const bounceValY: number = bounceVal < 0 ? bounceVal / 2 : bounceVal

                    this.crash(5)

                    if (invertRailFlag) {
                        gsap.to(boat, {
                            duration: 0.25,
                            x: boat.x + bounceValY,
                            y: boat.y + bounceValX
                        })
                    } else {
                        gsap.to(boat, {
                            duration: 0.25,
                            x: boat.x + bounceValX,
                            y: boat.y + bounceValY
                        })
                    }
                } else {
                    // rail.tint = 0xFF00FF;
                }
            }

            const removedSprites = []

            for (let i = 0; i < this.entitySprites.length; i++) {
                const entity = this.entitySprites[i]
                if (entity.destroyed) {
                    continue
                }
                const entityPoly = entity.hitPoly as Polygon
                const entityPoints = []

                for (let index1 = 0; index1 < entityPoly.points.length; index1++) {
                    let value = entityPoly.points[index1]

                    if (index1 % 2 === 0 || index1 === 0) {
                        value += entity.x - entity.anchor.x * entity.width
                    } else {
                        value += entity.y - entity.anchor.y * entity.height
                    }
                    entityPoints.push(value)
                }

                const intersected = intersects.polygonPolygon(boatPoints, entityPoints)

                if (intersected) {
                    // on hit
                    switch (entity.entityType) {
                        case 'fuel':
                            const fuelPowerup = this.delegate.soundMap.get('power_up_6')
                            SoundManager.shared.play(ESndGrp.SFX, fuelPowerup)

                            this.neutral()
                            this.delegate.controller.addFuel()
                            break
                        case 'film':
                            const filmPowerup = this.delegate.soundMap.get('power_up_5')
                            SoundManager.shared.play(ESndGrp.SFX, filmPowerup)

                            this.neutral()
                            this.delegate.controller.addFilm()
                            break
                        case 'rock':
                            this.crash()
                            boatOverlay.value = 1
                            gsap.killTweensOf(boatOverlay)
                            gsap.to(boatOverlay, {
                                duration: 1,
                                value: 0
                            })
                            break
                    }

                    entity.destroy()
                    removedSprites.push(i)
                }
            }

            for (const i of removedSprites) {
                this.entitySprites.splice(i, 1)
            }

            if (JungleCruiseRoom.boarded) {
                if (
                    Client.shared.keysDown.get(39) ||
                    Client.shared.keysDown.get(68) ||
                    Client.shared.keysDown.get(-2)
                ) {
                    // Right
                    deltaHoriz = tiltRate * rateMultiplier
                    boat.setTilt(JungleCruiseBoatTilt.RIGHT)
                } else if (
                    Client.shared.keysDown.get(37) ||
                    Client.shared.keysDown.get(65) ||
                    Client.shared.keysDown.get(-4)
                ) {
                    // Left
                    deltaHoriz = -tiltRate * rateMultiplier
                    boat.setTilt(JungleCruiseBoatTilt.LEFT)
                } else {
                    deltaHoriz = 0
                    boat.setTilt(JungleCruiseBoatTilt.STRAIGHT)
                }

                if (
                    Client.shared.keysDown.get(38) ||
                    Client.shared.keysDown.get(87) ||
                    Client.shared.keysDown.get(-1)
                ) {
                    // Up
                    deltaVert = 3
                    JungleCruiseRoom.boatMotor.playbackRate.value = 1.75
                } else if (
                    Client.shared.keysDown.get(40) ||
                    Client.shared.keysDown.get(83) ||
                    Client.shared.keysDown.get(-3)
                ) {
                    // Down
                    deltaVert = -0.5
                    JungleCruiseRoom.boatMotor.playbackRate.value = 0.75
                } else {
                    deltaVert = 1
                    JungleCruiseRoom.boatMotor.playbackRate.value = 1
                }
            }
            boat.overlay.alpha = boatOverlay.value

            if (JungleCruiseRoom.boarded && this.delegate.controller) {
                const fuelRate = Math.max(deltaVert, 1)
                this.delegate.controller.subtractFuel((1 / 96) * fuelRate)
                if (Math.round(this.delegate.controller.getFuel()) <= 0) {
                    this.delegate.endGame()
                }
            }

            for (const a of this.activeAnimals) {
                a.tick()
            }
        }, 20)

        const animals = this.stageObj.JUNGLECRUISE.ANIMALS
        const script = this.stageObj.JUNGLECRUISE.SCRIPT

        if (animals) {
            for (const animal of animals) {
                const meta = animal.$
                const id = +meta.ID
                const set = meta.SET
                const sound = meta.SOUND

                const spot = animal.SPOT[0].$
                const flip: boolean = +spot.FLIP === 1
                const pos = this.delegate.vectorToTriple(spot.POS)
                let scale = +spot.SCALE
                if (scale === 0 || isNaN(scale)) {
                    // a hyena in the Safari room (all stages) has 0 scale
                    // fallback to full scale so it doesn't play sound without showing
                    scale = 1
                }
                const time = +spot.TIME

                if (this.delegate.animals.hasOwnProperty(set)) {
                    const abc = new JungleCruiseAnimal(this.delegate, this.delegate.animals[set])

                    Point3D.apply(abc, pos.x, pos.y, pos.z)

                    abc.scale.set(scale)
                    if (flip) {
                        abc.scale.x = scale * -1
                    }
                    this.allAnimals.push(abc)
                }
            }
        }

        const stats = this.spawnStats[this.currentLevel - 1]
        this.spawnAnimal(stats.animalLife)
        this.animalTicker = window.setInterval(() => {
            if (this.animalsSpawned < stats.animalsToSpawn) {
                this.spawnAnimal(stats.animalLife)
                this.animalsSpawned++
            }
        }, stats.animalSpacing)

        // Play  loop
        const loop = this.delegate.soundMap.get(this.stageObj.JUNGLECRUISE.PARAMS[0].$.ATMOSPHERE)
        if (loop) {
            this.atmosphere = SoundManager.shared.play(ESndGrp.SFX, loop, true)
        }

        // Play music
        const sound = this.delegate.soundMap.get(this.stageObj.JUNGLECRUISE.PARAMS[0].$.MUSIC)
        if (sound) {
            this.playingMusic = SoundManager.shared.play(ESndGrp.Music, sound, true)
        } else {
            console.error('JC music "' + this.stageObj.JUNGLECRUISE.PARAMS[0].$.MUSIC + '" not in sound map.')
        }

        // Play motor
        if (!JungleCruiseRoom.boatMotor) {
            JungleCruiseRoom.boatMotor = SoundManager.shared.play(
                ESndGrp.SFX,
                this.delegate.soundMap.get('JC-boat-motor'),
                true
            )
        }

        if (this.currentLevel === 1 && this.roomName === 'dock') {
            const rollUp = this.delegate.soundMap.get('SN-roll_up_JC_leaves')
            this.delegate.ui.nedSpeakIndefinitely()
            SoundManager.shared.play(ESndGrp.SFX, rollUp)
            Helpers.delay(rollUp.duration * 1000).then(() => {
                this.delegate.ui?.nedStopSpeaking()
            })
            this.delegate.ui.setNedText(
                'Roll Up! Roll up! The world famous jungle cruise photo safari leaves momentarily!'
            )
        }
    }

    get cacheKey(): string {
        return this.layoutIdentifier
    }

    getCastNames(): string[] {
        return ['spaces/bganims', 'spaces/' + this.roomName + (Client.shared.holiday === 'xmas' ? '.xmas' : '')]
    }

    outOfFilm(): void {
        const phrase = {
            sound: 'SN-youre_out_of_film',
            text: "Oh no! You're out of film!"
        }

        if (!JungleCruiseRoom.isSpeaking) {
            if (Math.random() < 0.5) {
                const sfx = this.delegate.soundMap.get(phrase.sound)
                JungleCruiseRoom.isSpeaking = true

                SoundManager.shared.play(ESndGrp.SFX, sfx)
                Helpers.delay(sfx.duration * 1000).then(() => {
                    JungleCruiseRoom.isSpeaking = false
                    this.delegate.ui?.nedStopSpeaking()
                })
                this.delegate.ui.nedSpeakIndefinitely()
                this.delegate.ui.setNedText(phrase.text)
            }
        }
    }

    private tryPic(x: number, y: number): void {
        if (this.delegate.controller.subtractFilm()) {
            const camera = this.delegate.soundMap.get('JC-camera')
            SoundManager.shared.play(ESndGrp.SFX, camera)
        } else {
            this.outOfFilm()
            return
        }

        const mousePoint = new Point(
            x - Client.shared.containerEl.offsetLeft,
            y - Client.shared.containerEl.offsetTop
        )

        const point = Client.shared.viewport.toLocal(mousePoint)
        const roomPoint = this.sprites.toLocal(point, Client.shared.viewport)

        const width = 184
        const height = 124
        const region = new Rectangle(
            roomPoint.x - width / 2,
            roomPoint.y - height / 2,
            width,// * Client.shared.stage.scale.x,
            height// * Client.shared.stage.scale.y
        )
        const image = Client.shared.renderer.generateTexture({
            target: this.sprites,
            resolution: Client.shared.renderer.resolution,
            frame: region
        }) as unknown as RenderTexture

        this.delegate.ui.addPhoto(image)

        if (this.activeAnimals.length === 0) {
            this.floatPoints(0, point.x, point.y)
            return
        }

        // get adjacent animals
        for (const animal of this.activeAnimals) {
            if (animal.snapped) {
                continue
            }

            const screenX = animal.x - this.sprites.pivot.x
            const screenY = animal.y - this.sprites.pivot.y

            const distance = Math.hypot(point.x - screenX, point.y - screenY)

            if (distance >= 100) {
                continue
            }

            animal.snapped = true
            animal.leave()
            animal.setLeaveCallback(() => {
                const index = this.activeAnimals.indexOf(animal)
                if (index !== -1) {
                    this.activeAnimals.splice(index, 1)
                }
            })

            const distPts = 100 - distance
            const timeBetween = new Date().getTime() - animal.getSpawnTime() || 500

            const timePts = Math.max(0, 1000 - timeBetween) / 1.5
            const points = Math.round(1.5 * (distPts + timePts))

            this.delegate.controller.addPoints(points)
            this.floatPoints(points, point.x, point.y)

            if (points > 250) {
                this.positive()
            } else if (points < 100) {
                this.negative()
            }

            return
        }

        this.floatPoints(0, point.x, point.y)
    }

    floatPoints(pts: number, x: number, y: number): void {
        let spriteName
        if (pts >= 450) {
            spriteName = 'hit.flawless'
        } else if (pts >= 350) {
            spriteName = 'hit.awesome'
        } else if (pts >= 200) {
            spriteName = 'hit.good'
        } else if (pts >= 50) {
            spriteName = 'hit.ok'
        } else {
            spriteName = 'hit.oops'
        }
        console.log('points ' + pts + ' @ ' + x + ', ' + y + ' (' + spriteName + ')')

        const spr = Pooler.newSprite(spriteName)
        spr.anchor.set(0.5)
        spr.scale.set(1.5)
        spr.position.set(x, y)
        spr.zIndex = 99999999
        this.addChild(spr)
        gsap.to(spr, {
            duration: 3,
            y: y - 50,
            alpha: 0,
            onComplete: () => {
                Pooler.release(spr)
            }
        })
    }

    private negative(): void {
        const phrase = RandomUtil.getRandomElementFromArray(this.nedLines.negative)

        if (!JungleCruiseRoom.isSpeaking) {
            if (Math.random() < 0.2) {
                const sfx = this.delegate.soundMap.get(phrase.sound)
                JungleCruiseRoom.isSpeaking = true

                SoundManager.shared.play(ESndGrp.SFX, sfx)
                Helpers.delay(sfx.duration * 1000).then(() => {
                    JungleCruiseRoom.isSpeaking = false
                    this.delegate.ui?.nedStopSpeaking()
                })
                this.delegate.ui.nedSpeakIndefinitely()
                this.delegate.ui.setNedText(phrase.text)
            }
        }
    }

    private positive(): void {
        const phrase = RandomUtil.getRandomElementFromArray(this.nedLines.positive)

        if (!JungleCruiseRoom.isSpeaking) {
            if (Math.random() < 0.5) {
                const sfx = this.delegate.soundMap.get(phrase.sound)
                JungleCruiseRoom.isSpeaking = true
                SoundManager.shared.play(ESndGrp.SFX, sfx)
                Helpers.delay(sfx.duration * 1000).then(() => {
                    JungleCruiseRoom.isSpeaking = false
                    this.delegate.ui?.nedStopSpeaking()
                })
                this.delegate.ui.nedSpeakIndefinitely()
                this.delegate.ui.setNedText(phrase.text)
            }
        }
    }

    private neutral(): void {
        const phrase = RandomUtil.getRandomElementFromArray(this.nedLines.attention)

        if (!JungleCruiseRoom.isSpeaking) {
            if (Math.random() < 0.1) {
                const negative = this.delegate.soundMap.get(phrase.sound)

                JungleCruiseRoom.isSpeaking = true

                SoundManager.shared.play(ESndGrp.SFX, negative)
                Helpers.delay(negative.duration * 1000).then(() => {
                    JungleCruiseRoom.isSpeaking = false
                    this.delegate.ui?.nedStopSpeaking()
                })
                this.delegate.ui.nedSpeakIndefinitely()
                this.delegate.ui.setNedText(phrase.text)
            }
        }
    }

    private spawnAnimal(life: number): void {
        if (this.destroyed || !this.visible) {
            return
        }
        const index = RandomUtil.getRandomIndexFromArray(this.allAnimals)
        if (index !== null) {
            const elem = this.allAnimals.splice(index, 1)[0]
            console.log('Spawning animal in ' + this.layoutIdentifier)
            this.sprites.addChild(elem)
            this.sortChildren()
            this.activeAnimals.push(elem)

            setTimeout(() => {
                elem.leave()
                elem.setLeaveCallback(() => {
                    elem.destroy()
                    const index = this.activeAnimals.indexOf(elem)
                    if (index > -1) {
                        this.activeAnimals.splice(index, 1)
                    }
                })
            }, life)
        }
    }

    private endGame(): void {
        this.delegate.endGame()
    }

    private crash(subtractVal = 10): void {
        this.delegate.controller.subtractFuel(subtractVal)

        if (this.delegate.controller.getFuel() <= 0) {
            this.endGame()
            return
        }

        if (!JungleCruiseRoom.isCrashing) {
            JungleCruiseRoom.isCrashing = true
            const crash = this.delegate.soundMap.get('JC-crash')

            SoundManager.shared.play(ESndGrp.SFX, crash)
            Helpers.delay(crash.duration * 1000).then(() => {
                JungleCruiseRoom.isCrashing = false
            })
        }

        const phrase = RandomUtil.getRandomElementFromArray(this.nedLines.watch_out)

        if (!JungleCruiseRoom.isSpeaking) {
            if (Math.random() < 0.3) {
                const negative = this.delegate.soundMap.get(phrase.sound)

                JungleCruiseRoom.isSpeaking = true

                SoundManager.shared.play(ESndGrp.SFX, negative)
                Helpers.delay(negative.duration * 1000).then(() => {
                    JungleCruiseRoom.isSpeaking = false
                    this.delegate.ui?.nedStopSpeaking()
                })
                this.delegate.ui.nedSpeakIndefinitely()
                this.delegate.ui.setNedText(phrase.text)
            }
        }
    }

    override resetForReuse(): void {
        super.resetForReuse()

        window.clearInterval(this.clock)
        window.clearInterval(this.animalTicker)

        this.clock = null
        this.animalTicker = null

        this.allAnimals.forEach((anim) => anim.destroy())
        this.rails.forEach((rail) => rail.destroy())
        this.entitySprites.forEach((spr) => spr.destroy())

        this.activeAnimals.length = 0
        this.rails.length = 0
        this.entitySprites.length = 0

        SoundManager.shared.release(this.playingMusic)
        this.playingMusic = null
        SoundManager.shared.release(this.atmosphere)
        this.atmosphere = null
    }

    override async teardown(): Promise<void> {
        await super.teardown()

        window.clearInterval(this.clock)
        this.clock = null

        this.entitySprites = null
        this.rails = null

        SoundManager.shared.release(this.playingMusic)
        this.playingMusic = null

        SoundManager.shared.release(JungleCruiseRoom.boatMotor)
        JungleCruiseRoom.boatMotor = null

        this.allAnimals?.forEach((anim) => anim.destroy())
        this.allAnimals = null
        this.activeAnimals = null
        window.clearInterval(this.animalTicker)
        this.animalTicker = null
    }
}
